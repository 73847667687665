import React, { FunctionComponent, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import { FeedItem } from "../../../../types/types";
import { TenantReducerState } from "../../../../types/types";

import ItemHeader from "./itemHeader";

type PNSItemTypeProps = {
  item: FeedItem;
  isLastItem?: boolean;
};

const PNSItemType: FunctionComponent<PNSItemTypeProps> = ({ item, isLastItem }) => {
  const [tenantInfo] = useState<TenantReducerState>(
    useSelector((state: any) => state.app.tenant)
  );

  const pnsNavigation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (!item.model && !item.model.page && !item.model.page.id) return;
    window.location.href = `/page/${item.model.page.id}`;
  };

  return (
    <Card data-testid="pns-item" className={`p-3 item-container ${isLastItem ? '' : 'mb-3'}`} style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.10)', borderRadius: '10px' }}>
      <ItemHeader
        imgUrl={tenantInfo.feedLogo}
        headText={tenantInfo.title}
        className={"notification-icon"}
        iconClass="https://cdn.engageesp.com/img/feed/notification.png"
        time={item.model.created_at_humans || ""}
        contact_id={item.model.author ? item.model.author.contact_id : null}
        isImgRound={false}
      />
      {(item.model.title || item.model.message) && (
        <div className={`mt-3 mb-2 text-light-black`}>
          <h3 data-testid="pns-title" className="m-0 font-primary-text">{item.model.title || ""}</h3>
          <h3 data-testid="pns-message" className="mb-0 mt-3 fw-normal font-secondary-text">{item.model.message || ""}</h3>
        </div>
      )}
      {item.model.page &&
        (item.model.page.title ||
          item.model.page.preview_image ||
          item.model.page.preview_text) && (
          <div className="media border-top mt-3 pt-3">
            {item.model.page.preview_image && (
              <div
                className="float-left d-flex justify-content-center align-items-flex-start me-4 w-auto h-auto c-pointer"
                onClick={pnsNavigation}
                data-testid="page-image-link"
              >
                <img
                  src={item.model.page.preview_image}
                  alt="Image"
                  className="media-object preview-image"
                  data-testid="page-preview-image"
                />
              </div>
            )}
            <a
              className="media-body clearfix c-pointer d-block text-decoration-none"
              href={`/page/${item.model.page.id}`}
              onClick={pnsNavigation}
              data-testid="page-detail-link"
            >
              <h3 data-testid="page-title" className="m-0 mt-0 text-light-black font-primary-text">
                {item.model.page.title || ""}
              </h3>
              <h4 data-testid="page-preview-text" className="m-x-0 mb-0 mt-3 fw-normal text-light-black font-secondary-text">
                {item.model.page.preview_text || ""}
              </h4>
            </a>
          </div>
        )}
    </Card>
  );
};

export default PNSItemType;
