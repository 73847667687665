import React, { useCallback } from 'react';
import { Box, SxProps, Paper, Typography } from '@mui/material';

export type FilePreviewListProps = {
    files: File[];
    sx?: SxProps;
    handleRemoveFile: (e: React.MouseEvent<HTMLButtonElement>, index: number) => void | undefined;
};

export const iconMappings: Record<string, string> = {
    pdf: 'pdf.png',
    doc: 'word.png',
    docx: 'word.png',
    ppt: 'powerpoint.png',
    pptx: 'powerpoint.png',
    csv: 'csv.png',
    mp4: 'video.png',
    mpe4: 'video.png',
    mov: 'video.png',
    txt: 'text.png',
};

export const FilePreviewList = (props: FilePreviewListProps) => {
    const { files, sx, handleRemoveFile } = props;

    const getFileIcon = useCallback((file: File): string => {
        const fileExtension = file.name.split('.').pop();
        const defaultIcon = 'text.png';
        if (!fileExtension) return defaultIcon;
        else return iconMappings[fileExtension.toLowerCase()] || defaultIcon;
    }, []);

    return (
        <Paper sx={sx} data-testid="file-preview-list">
            {files?.map((file: File, index: number) => {
                return (
                    <Box key={index} sx={{ position: 'relative' }}>
                        <img
                            src={
                                file.type.includes('image')
                                    ? URL.createObjectURL(file)
                                    : `${process.env.REACT_APP_CMS_API_URL}/images/icons/${getFileIcon(file)}`
                            }
                            data-testid="file-preview-list-image"
                            alt={file.name}
                            height={'150px'}
                            width={'150px'}
                            style={{
                                objectFit: 'cover',
                                borderRadius: '8px',
                                padding: !file.type.includes('image') ? '20px' : '0px',
                                border: !file.type.includes('image') ? '1px solid #E0E0E0' : 'none',
                                maxWidth: '150px',
                            }}
                        />
                        {!file.type.includes('image') && (
                            <Typography sx={{ textAlign: 'center', fontSize: '12px', maxWidth: '150px' }}>{file.name}</Typography>
                        )}
                        <button
                            className="unstyled-btn"
                            style={{
                                position: 'absolute',
                                top: '-15px',
                                left: '140px',
                                fontSize: '20px',
                            }}
                            aria-label="Remove file"
                            data-testid="remove-preview-file-btn"
                            onClick={(e) => handleRemoveFile(e, index)}
                        >
                            <span className="fa-solid fa-circle-xmark" />
                        </button>
                    </Box>
                );
            })}
        </Paper>
    );
};
