import { useMutation } from "react-query";
import Client from "../services/axiosService";

export const muteRequest = async (id: string, data: any) => { 
	return await Client().request({
		url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${id}/mute`,
		method: "post",
		data: data,
	});
};

export const useMarkAsMute = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation((data: any) => {
        return muteRequest(data.threadID, data.formData);
    }, {
        onSuccess: onSuccess,
        onError: onError,
    });
};