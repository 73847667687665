import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

import { EmailCheckInfo } from '../../types/types';

const registrationProvider = {
    registrateUser(regInfo: any, success: Function, error?: Function) {
        Client(true).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/api/registration`,
            method: 'post',
            data: regInfo
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    checkEmailAvailability(emailInfo: EmailCheckInfo, success: Function, error?: Function) {
        Client(true).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/api/reg_check/email`,
            method: 'post',
            data: emailInfo
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    getInfo(tenantRef: string, success: Function, error?: Function) {
        Client(false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/api/registration?reference=${tenantRef}`,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default registrationProvider;

