import { useInfiniteQuery } from "react-query";
import Client from "../../services/axiosService";

/**
 * @description: Fetches results from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchOptions = async (page: number) => {
  const response = await Client().request({
    url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/attributes?page=${page}`,
    method: "get"
  });
  return response.data;
}

/**
 * @description: Fetches a list of connect filter attributes from the API and returns the data 
 * @returns 
 */
export const useConnectFilterAttrQuery = () => {
  return useInfiniteQuery(
    ['connect-attributes'],
    ({ pageParam = 1 }) => fetchOptions(pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.links?.next) return pages.length + 1;
        return undefined;
      },
      refetchOnWindowFocus: false,
    }
  );
}