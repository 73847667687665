export default {
  "menus": {
    "home": "Início",
    "feed": "Cronologia",
    "messages": "Mensagens",
    "connect": "Ligar",
    "awards": "Prémios",
    "settings": "Configurações e Privacidade",
    "profile": "Perfil",
    "notifications": "Notificações",
    "change password": "Mudar a Palavra-passe",
    "signOut": "Encerrar Sessão",
    "mark as read": "Marcar como Lido"
  },
  "settingOptions": {
    "title": "Configurações de privacidade",
    "content": "Cookies são pequenos arquivos de texto que são colocados em seu computador ou dispositivo móvel quando você visita um site ou usa um aplicativo. Eles são amplamente utilizados para fazer sites e aplicativos funcionarem, ou funcionarem de forma mais eficiente, além de fornecer informações aos proprietários do site/aplicativo. A tabela abaixo explica os cookies que usamos e por quê.",
    "subQuestion": "Que cookies utilizamos?",
    "subText": "Para mais detalhes, consulte nossa política de privacidade e cookies",
    "subTextDefault": "Para mais detalhes, consulte nossa ",
    "necessaryTitle": "Estritamente necessário",
    "necessary": "Os cookies necessários são essenciais para a funcionalidade e segurança do aplicativo principal. Se você concordar, também queremos usar cookies para:",
    "performanceTitle": "Cookies de desempenho",
    "notificationSettings": "Configurações de notificação",
    "emails": "E-mails",
    "p2pEmailsLabel": "Envie-me notificações por e-mail para {{p2pLabel}}",
    "performance": {
      "firstLine": "• Melhore o desempenho do aplicativo",
      "secondLine": "• Entenda como o aplicativo é usado",
      "thirdLine": "• Otimize o conteúdo"
    },
    "analyticsTitle": "Cookies de análise",
    "analytics": {
      "firstLine": "• Melhore a experiência do aplicativo",
      "secondLine": "• Personalizar serviços"
    },
    "save": "Salvar preferências",
    "error": {
      "label": "Erro",
      "text": "Houve um erro, por favor tente novamente mais tarde."
    }
  },
  "type search field": "Escreva e prima Enter..."
};