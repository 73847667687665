import React, { FunctionComponent } from "react";
import { LocationItem } from "../../../../types/types";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/pro-light-svg-icons";

type AllItemTypeProps = {
  item: LocationItem;
  locationId: string;
  selectedLocationId: string;
};

const LocationsListPage: FunctionComponent<AllItemTypeProps> = ({
  item,
  locationId,
  selectedLocationId,
}) => {
  const navigate = useNavigate();
  const gotoLocation = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    navigate(`/location/${locationId}`);
  };
  return (
    <Link
      data-testid="locations-link"
      to={`/location/${locationId}`}
      onClick={gotoLocation}
      className="rowItem"
    >
      <div
        id={`location${locationId}`}
        data-sel={selectedLocationId}
        className="rowItemDiv d-flex justify-content-space-between"
      >
        <div className="d-flex align-items-center justify-content-space-between pb pt location-item w-100">
          <div className="d-flex align-items-center">
            <FontAwesomeIcon icon={faLocationDot} className="locationIcon" />
            <div className="stardiv d-flex align-items-center">
              <h3 data-testid="location-title" className="mt0 mb0 location-title">
                {item.list_title != "" ? item.list_title : item.location_name}
              </h3>
            </div>
          </div>
        </div>
        {locationId == selectedLocationId ? (
          <div className="active-list-item bg-engage-primary-dark" />
        ) : null}
      </div>
    </Link>
  );
};

export default LocationsListPage;
