import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import "./CustomPagination.scss";

type CustomPaginationProps = {
	currentPage: number;
	totalPage: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
	handlePageChange: (pageNo: number) => void;
};

const CustomPagination: React.FC<CustomPaginationProps> = ({
	currentPage,
	totalPage,
	setCurrentPage,
	handlePageChange,
}) => {
	const onPageChange = (pageNo: number) => {
		setCurrentPage(pageNo);
		handlePageChange(pageNo);
	};

	const renderPaginationItem = () => {
		const items: Array<React.ReactElement> = [];
		for (let page = 1; page <= totalPage; page++) {
			items.push(
				<PaginationItem
					className="pagination-item mx-1"
					active={currentPage === page ? true : false}
				>
					<PaginationLink
						data-testid={`${page}-page`}
						className="pagination-link"
						onClick={() => onPageChange(page)}
					>
						{page}
					</PaginationLink>
				</PaginationItem>
			);
		}

		return items;
	};

	return (
		<Pagination data-testid="custom-pagination" aria-label="Page navigation example">
			<PaginationItem data-testid="prev-page-item" disabled={currentPage === 1 ? true : false}>
				<PaginationLink
				 	data-testid="prev-page-btn"
					className="prev-page-btn"
					onClick={() => onPageChange(currentPage - 1)}
				>
					<p className="fa-regular fa-angle-left prev-page-icon"></p>
				</PaginationLink>
			</PaginationItem>
			{renderPaginationItem()}
			<PaginationItem disabled={currentPage === totalPage ? true : false}>
				<PaginationLink
				 	data-testid="next-page-btn"
					className="next-page-btn"
					onClick={() => onPageChange(currentPage + 1)}
				>
					<p className="fa-regular fa-angle-right next-page-icon"></p>
				</PaginationLink>
			</PaginationItem>
		</Pagination>
	);
};

export default CustomPagination;
