import React from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import defaultIcon from '../../../icons/no-result-widget-icon.svg';

export type NoResultsWidgetProps = {
    icon?: string;
    title: string;
    titleSx?: SxProps;
    description?: string | React.ReactNode;
    containerSx?: SxProps;
    descriptionSx?: SxProps;
    iconSx?: SxProps;
};

export const NoResultsWidget = (props: NoResultsWidgetProps) => {
    const { icon, title, titleSx, description, containerSx, descriptionSx, iconSx = { height: '300px', width: '300px' } } = props;

    return (
        <Box
            sx={{
                height: '46vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                ...containerSx,
            }}
            data-testid='no-results-widget'
        >
            <Box sx={{ ...titleSx }}>
                <Typography sx={{ fontSize: '22px', fontWeight: '600' }}>{title}</Typography>
            </Box>
            <Box sx={{ ...iconSx }}>{icon ? <img src={props.icon} alt='No results found' /> : <img src={defaultIcon} alt='No results found' />}</Box>
            <Box sx={descriptionSx}>
                {typeof description === 'string' ? (
                    <Typography sx={{ fontSize: '17px', fontWeight: '500', textAlign: 'center' }}>{description}</Typography>
                ) : (
                    description
                )}
            </Box>
        </Box>
    );
};
