import { AxiosError, AxiosResponse } from "axios";
import notificationProvider from "../providers/notificationProvider";

const notificationService = {
    saveNotificationPermission(data:any, success: Function, error?: Function){
        notificationProvider.saveNotification(data,
            (resp: AxiosResponse) => {
                let data = resp.data.data;
                if(success){
                    success(data);
                }
            }, (err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default notificationService;