export default {
    "please enter your company reference": "Por favor, introduzca la referencia de tu empresa",
    "enter user registration credentials": "Por favor, introduzca sus datos a continuación",
    "enter email": "Por favor, introduzca su correo electrónico",
    "enter password": "Por favor, introduzca su contraseña",
    "repeat password": "Por favor, repita su contraseña",
    "registration successful": "Registro correcto.",
    "registration successful instruction": "Se ha enviado un enlace de confirmación por tiempo limitado al correo electrónico especificado. Siga las instrucciones del correo electrónico para completar la configuración de la cuenta. (Por favor revise su carpeta de correo no deseado si no puede ver el correo electrónico)",
    "errors": {
      "domain name can't be empty": "El nombre de dominio no puede estar vacío",
      "domain does not exist": "El dominio '{{domain}}' no existe",
      "email can not be empty": "El correo electrónico no puede estar vacío",
      "password can not be empty": "La contraseña no puede estar vacía",
      "custom fields not set": "Alguno de los campos obligatorios esta vacío",
      "passwords dont match": "Las contraseñas no son iguales",
      "email is not available": "El correo electrónico seleccionado no esta disponible",
      "email format is not valid": "El formato del correo electrónico no es válido",
      "password requirements": "La contraseña debe contener 8 carácteres, números y carácteres en mayúscula y minúscula.",
      "User with this email already exists": "Ya existe un usuario con los datos que has introducido",
      "Error loading security information from server": "Error al cargar la información de seguridad del servidor. Vuelva a cargar la página para volver a intentarlo.",
      "Page doesnt have data parameters loaded": "La página no tiene parámetros de datos cargados. Intenta abrir el enlace del correo electrónico de nuevo."
    },
    "verify": "REGISTRARSE",
    "back": "ATRÁS"
};