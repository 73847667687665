import React, { FunctionComponent } from 'react';
import Notifications from './web/components/common/notifications/notifications';

import AppWrapper from './web/components/appWrapper';
import LoginPage from './web/components/loginPage/loginPage';
import RegistrationPage from './web/components/registrationPage/registrationPage';
import SecurityPage from './web/components/securityPage/securityPage';
import { ThemeProvider } from '@mui/material';
import { Theme } from './styles/themes/themes'

// Add global styles
import './styles/main.scss';

// Import Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faCircle, faAngleDown, faTrophy, faHome, faSearch, faBars, faTimes, faSignOutAlt,
  faUser, faBell, faLock, faAngleLeft } from '@fortawesome/pro-solid-svg-icons';

// Routing
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Redux
import { Provider } from 'react-redux'

import store from './store';
import PnServiceWorker from "./web/components/common/pushNotification/pnServiceWorker";

// Add Font Awesome icons to library
library.add(...[faArrowLeft, faCircle, faAngleDown, faTrophy, faHome, faSearch, faBars, faTimes, faSignOutAlt,
  faUser, faBell, faLock, faAngleLeft]);

const App: FunctionComponent = () => {
  return (
    <React.Fragment>
      <ThemeProvider theme={Theme}>
      <Provider store={ store }>
        <BrowserRouter> 
          <Notifications />
          <Routes>
              <Route
                path="/:tenantName?/registration"
                element={<RegistrationPage />}
              />
              {/* <Route 
                path="/security"
                component={SecurityPage}
              /> */}
              <Route
                path={`${process.env.REACT_APP_URL_PREFIX || ''}/:tenantRef?/login`}
                element={<LoginPage />}
              />
              <Route
                path={`${process.env.REACT_APP_URL_PREFIX || ''}/*`}
                element={<AppWrapper />}
              />
            </Routes>
        </BrowserRouter>
      </Provider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
