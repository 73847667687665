import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const notificationsProvider = {
    getAll(page: number, success: Function, error?: Function){
        Client().request({
            url: process.env.REACT_APP_CMS_API_URL+'/react/notifications?page='+page,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    markAsRead(id: number, isRead: boolean, success: Function, error?: Function){
        Client().request({
            url: process.env.REACT_APP_CMS_API_URL+'/react/notifications',
            method: 'post',
            data: {
                notification_id: id,
                is_read: isRead
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    markAllAsRead(success: Function, error?: Function){
        Client().request({
            url: process.env.REACT_APP_CMS_API_URL+'/react/notifications',
            method: 'post'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default notificationsProvider;