import React, { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification } from '../../../../types/types';
import { removeNotification } from '../../../../shared/reducers/notifications/actionTypes';

import NotificationItem from './notificationItem';

import './notifications.scss';

type NotificationsProps = {
}

const Notifications: FunctionComponent<NotificationsProps> = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((state: any) => state.app.notifications);

    const remove = (notification: Notification) => {
        dispatch(removeNotification(notification.id));
    }

    return (
        <div data-testid="notifications" className="notifications-container">
            {
                notifications.map((notification: Notification) => (
                    <NotificationItem key={notification.id} 
                        notification={notification} removeNotification={() => remove(notification)}
                    />))
            }
        </div>
    );
}

export default Notifications;