import Client from "../../services/axiosService";

export const getFollowers = async (id: number, page: number) => {
	if (!id) return Promise.resolve(null);
	return (
		await Client().request({
			url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/${id}/followers?page=${page}`,
			method: "get",
		})
	).data;
};

export const getFollowings = async (id: number, page: number) => {
	if (!id) return Promise.resolve(null);
	return (
		await Client().request({
			url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/${id}/following?page=${page}`,
			method: "get",
		})
	).data;
};

export const followUserRequest = async (data: any) => {
	return await Client(true, false).request({
		url: `${process.env.REACT_APP_CMS_API_URL}/react/user/follow`,
		method: "post",
		data: data,
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
};

export const unfollowUserRequest = async (data: any) => {
	return await Client(true, false).request({
		url: `${process.env.REACT_APP_CMS_API_URL}/react/user/unfollow`,
		method: "post",
		data: data,
		headers: { "Content-Type": "multipart/form-data" },
	});
};
