import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import objectAssign from 'object-assign';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import locales from '../../../../shared/i18next/locales/locales';

import './localeSelector.scss';

type LocaleSelectorProps = {
    className?: string,
    labelColour?: string,
    isDisabled?: boolean
};

enum MouseOverType {
    Disp,
    Select
}

const LocaleSelector:FunctionComponent<LocaleSelectorProps> = (props) => {
    const { i18n } = useTranslation();
    
    // Save selected language to local storage
    useEffect(() => {
        loadLang();
    }, []);

    const loadLang = () => {
        const lang = localStorage.getItem('userLanguage');
        if(!lang)
            return;
        languageClick(lang);
    }

    const saveLang = () => {
        localStorage.setItem('userLanguage', i18n.language);
    } 

    // Mouse hover detection and language selection popup
    const [langSelectorShown, showLangSelector] = useState(false);  
    
    const [mouseState, setMouseState] = useState({
        onDisp: false,
        onSelect: false
    });
    const [selectionTimeout, setSelectionTimeout] = useState<any>();
    const openSelection = (mode: MouseOverType) => {
        showLangSelector(true);
        let newObj = (mode === MouseOverType.Disp) ? { onDisp: true } : { onSelect: true };
        updateMouseState(objectAssign({}, mouseState, newObj));
    }
    const closeSelection = (mode: MouseOverType) => {
        let newObj = (mode === MouseOverType.Disp) ? { onDisp: false } : { onSelect: false };
        updateMouseState(objectAssign({}, mouseState, newObj));
    }
    const updateMouseState = (newState: any) => {
        setMouseState(newState);
        if(selectionTimeout)
            clearTimeout(selectionTimeout);
        setSelectionTimeout(setTimeout(() => closeSelectionTimeout(newState), 500));
    }
    const closeSelectionTimeout = (mouseState: any) => {
        if(mouseState.onDisp === false && mouseState.onSelect === false)
            showLangSelector(false);
    }

    const languageClick = (id: string) => {
        i18n.changeLanguage(id);
        saveLang();

        showLangSelector(false);
        if(selectionTimeout)
            clearTimeout(selectionTimeout);
    }

    const renderSelectedLang = () => {
        let currentLang = i18n.language;
        let langItem = locales.languages.filter((item) => item.code === currentLang)[0];

        return (
            <React.Fragment>
                <i className={`flag flag-${langItem?.code} me-1`} />
                <span data-testid="selected-language" className={`me-1`} style={{color: props.labelColour || ''}}>{ langItem?.name }</span>
                <FontAwesomeIcon icon={faAngleDown} style={{color: props.labelColour || ''}} />
            </React.Fragment>
        );
    }

    const renderLangItem = (item: any) => {
        return (
            <li data-testid={`${item.name}-language`} key={item.code} onClick={() => languageClick(item.code)} className={`${item.code === i18n.language ? 'fw-bold' : ''} hover-bold text-light-black c-pointer d-flex align-items-center`}
                style={{ marginTop: '5px' }}
            >
                <i className={`flag flag-${item.code} d-inline-block me-1`} />
                <span>{ item.name }</span>
            </li>
        );
    }

    return (
            <div data-testid="lang-selector"  className={classNames(`d-flex align-items-center position-relative ${props.isDisabled ? "disable-right-panel" : ""}`, props.className)}
                style={{zIndex:1}}
                onMouseOver={() => openSelection(MouseOverType.Disp)}
                onMouseLeave={() => closeSelection(MouseOverType.Disp)}
            >
                { renderSelectedLang() }

                <div data-testid="lang-select" className={classNames('lang-select-tooltip', { 'd-none': !langSelectorShown })}
                    onMouseOver={() => openSelection(MouseOverType.Select)}
                    onMouseLeave={() => closeSelection(MouseOverType.Select)}
                >
                    <div className={'triangle bg-white'} />
                    <ul data-testid="language-list" className={'lang-select-tooltip-body text-dark bg-white m-0 p-3'}>
                        { locales.languages.map( (item) => renderLangItem(item)) }
                    </ul>
                </div>
            </div>
    );
}

export default LocaleSelector;
