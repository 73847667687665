import configProvider from '../providers/configProvider';

const configService = {
    getByKeys(keys: string, tenantId: string, success: Function, error?: Function) {
        configProvider.getByKeys(keys, tenantId, success, error);
    },

    getFromData(data: any, key: string, def: any) {
        if(data && data[key])
            return data[key];
        return def;
    }
}

export default configService;