import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { ContactItem } from "../../../../types/types";
import { useNavigate, useParams } from "react-router-dom";
import "./contactPage.scss";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
import AwardsModalComponent from "../../common/modal/awardsModal";
import { isWhite } from "../../../utils/colourCheck";
import { useSelector } from "react-redux";
import { PostTabDetailType } from "./contactDetails";

type AwardType = {
  background_image: string,
  badge_image: string,
  created_at: string,
  expired_at: string,
  id: number,
  overlay_image: string,
  page_editing_url: string,
  page_id: number,
  page_title: string,
  page_url: string,
  preview_image: string,
  text: string,
  title: string,
}

type ContactListItemTypeProps = {
  item: ContactItem;
  is_favourite: boolean;
  updateItem: Function;
  contactUserId: string;
  setPostTabDetail: React.Dispatch<React.SetStateAction<PostTabDetailType>>;
};

const ContactPageList: FunctionComponent<ContactListItemTypeProps> = ({
  item,
  is_favourite,
  updateItem,
  contactUserId,
  setPostTabDetail,
}) => {
  const [isAwardOpen, setIsAwardOpen] = useState<boolean>(false);
  const tenantBranding = useSelector((state:any) => state.app.branding);
  const [awardList, setAwardList] = useState<AwardType[]>([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const mainContainerRef = useRef<any>(null);
  const favIconRef = useRef<any>(null);
  const containerRef = useRef<any>(null);
  const imageRef = useRef<any>(null);
  const userRef = useRef<any>(null);

  useEffect(() => {
    const setAwardSectionWidth = () => {
      const rowWidth = mainContainerRef.current.offsetWidth;
      let containerWidth = rowWidth;
      if (favIconRef && favIconRef.current) {
        const favIcon = favIconRef.current.offsetWidth;
        containerWidth = containerWidth - favIcon;
      }
      const imageWidth = imageRef.current.offsetWidth;
      const userNameWidth = userRef.current.offsetWidth;
      const availableWidth = containerWidth - imageWidth - userNameWidth - 32;
      const numberOfAwards = Math.floor(availableWidth / 32); // One award contain 32px
      if (item.awards.length > 0) renderAwards(numberOfAwards);
    };

    setAwardSectionWidth();
    window.addEventListener("resize", setAwardSectionWidth);

    return () => {
      window.removeEventListener("resize", setAwardSectionWidth);
    };
  },[]);

  const renderAwards = (displayNumberOfAwards: number = 3) => {
    let awards:any[] = [];
    if (item.awards.length > displayNumberOfAwards) {
      if (displayNumberOfAwards > 1) {
        item?.awards.slice(0, displayNumberOfAwards - 1).map((award) => { 
          awards.push(
            <div key={award?.id} className="ms-1 me-1">
              <ReactTooltip anchorId={`tooltip_${award.id}`} />
              <a data-testid="contact-user-award-link" href={`connect/people/${item.contact_id}`}>
                <img 
                  data-testid="contact-user-award-image" 
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setIsAwardOpen(true);
                  }} 
                  src={award.badge_image ?? award.preview_image} 
                  className="thumb24" 
                  alt={award.badge_image ? "award badge image" : "award preview image"} 
                  id={`tooltip_${award.id}`} 
                  data-tooltip-content={award.title} 
                  data-iscapture="true"
                />
              </a>
            </div>
          )
        })
      }

      awards.push(
        <div className="ms-1">
          <ReactTooltip anchorId={`remaining_${item.id}`} />
          <a 
            className="awards-count-link"
            data-testid="remaining-awards"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setIsAwardOpen(true);
            }} 
            style={{
              color: fgColor,
            }}
          >
            <p 
              className="m-0 awards-count"
              style={{
                backgroundColor: bgColor,
              }}
              id={`remaining_${item.id}`} 
              data-tooltip-content={`+${displayNumberOfAwards <= 1 ? item.awards.length : item.awards.length - (displayNumberOfAwards - 1)} ${t("awards.awards")}`} 
              data-iscapture="true"
            >
              <span className="m-0 p-0">+</span>
              <span className="m-0 p-0 awards-count-span">{displayNumberOfAwards <= 1 ? item.awards.length : item.awards.length - (displayNumberOfAwards - 1)}</span>
            </p>
          </a>
        </div>
      );
    } else {
      item?.awards.map((award) => { 
        awards.push(
          <div key={award?.id} className="ms-1 me-1">
            <ReactTooltip anchorId={`tooltip_${award.id}`} />
            <a data-testid="contact-user-award-link" href={`connect/people/${item.contact_id}`}>
              <img 
                data-testid="contact-user-award-image" 
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setIsAwardOpen(true);
                }} 
                src={award.badge_image ?? award.preview_image} 
                className="thumb24" 
                alt={award.badge_image ? "award badge image" : "award preview image"} 
                id={`tooltip_${award.id}`} 
                data-tooltip-content={award.title} 
                data-iscapture="true"
              />
            </a>
          </div>
        )
      })
    }

    setAwardList(awards);
  };

  const renderModalData = () => {
    return (
      <ul className="user-awards-list padding-left-20 position-relative list-style-type-none">
        {item?.awards && item?.awards.length > 0
            ? item?.awards.map((res, index) => (
                  <li key={res.id} className={`user-award-list-item d-flex justify-content-space-between ${index == 0 ? "" : "mt-3"} pb-3 ${index == (item?.awards?.length - 1) ? "" : "border-bottom"}`}>
                      <div className="user-award-container d-flex justify-content-space-between w-100">
                        <div className="me-3 modal-image-section">
                            <img src={res.preview_image} className="award-image" alt="award-preview-image"></img>
                        </div>
                        <div className="modal-detail-section  w-100 d-flex justify-content-between">
                            <div className="awards-details me-2">
                                <a
                                  href={res.page_url}
                                  className={`${'text-decoration-none award-title text-black mb-1 cursor-pointer '}${
                                      res.page_url == '#' ? 'not-clickable' : ''
                                  }`}
                                >
                                  {res.title}
                                </a>
                                <p className="m-0 awarded text-black mb-1">{t('connect.awarded')} {res.created_at}</p>
                                <p className="m-0 award-text text-black">{res.text}</p>
                            </div>
                            <div className="award-badge-image-section h-100 d-flex align-items-center">
                                <img src={res.badge_image ?? res.preview_image} className="award-badge-image" alt="award-badge-image" />
                            </div>
                        </div>
                      </div>
                  </li>
              ))
            : null}
      </ul>
    );
  };

  const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
  const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
  const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

  return (
    <div
      ref={mainContainerRef}
      data-testid="contact-page-list"
      id={`location ${item.id}`}
      className="rowItemDiv d-flex justify-content-between w-100 contact-page-list"
    >
      <div className="d-flex align-items-center justify-content-between w-100 pb pt contact-page">
        <div className="d-flex pt-1 pb-1 w-100">
          <a 
            ref={containerRef}
            data-testid="contact-user-section"
            onClick={(e) => {
              e.preventDefault();
              if (setPostTabDetail && ((item.contact_id != id) || !id)) {
                setPostTabDetail(prev => ({
                  ...prev,
                  isInitialDataLoad: false,
                  postData: [],
                  pageNo: 1
                }));
              };
              navigate("/connect/people/" + item.contact_id);
            }}
            className="rowItem pt-1 connect-link d-flex w-100 cursor-pointer"
          >
            <img 
              ref={imageRef}
              data-testid="contact-user-image" 
              src={item.image_url}
              alt={`${item.full_name} profile image`}
              width="30"
              className="media-object img-responsive rounded-circle thumb48 mr connect-display-image"
            />
            <div className="stardiv">
              <div className="d-flex align-items-center">
                <h3 ref={userRef} data-testid="contact-user-name" className="mt0 mb0 contact-user-name">{item.full_name}</h3>
                <div className="awards-section me-2">
                  {awardList && awardList.map((award: any, index) => (
                    <React.Fragment key={index}>
                      {award}
                    </React.Fragment>
                  ))}
                  {isAwardOpen && (
                      <AwardsModalComponent
                        username={item?.full_name}
                        isOpen={isAwardOpen}
                        awards={item?.awards}
                        setIsOpen={setIsAwardOpen}
                        renderModalData={renderModalData}
                      />
                  )}
                </div>
              </div>
              <p data-testid="contact-user-job-title" className="mt0 contact-job-title mb0">{item.job_title}</p>
            </div>
          </a>
        </div>
        {is_favourite && (
          <i ref={favIconRef} data-testid="favourite-icon" className={`fa-solid favorite-icon fa-star fav text-orange mr-lg ml-sm cursor-pointer`}
            onClick={() => {
              updateItem(item.contact_id, !is_favourite);
            }}
            style={{
              paddingRight: item.contact_id != contactUserId ? "10px" : "0px",
              color: bgColor,
            }}
          ></i>
        )}
      </div>
      {item.contact_id == contactUserId ? (
        <div className="active-list-item"></div>
      ) : null}
    </div>
  );
};

export default ContactPageList;
