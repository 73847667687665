import React, { useState } from 'react';

export type SearchHook = {
    searchQuery: any;
    handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleSearchReset?: () => void;
};

/**
 * @description: Custom hook for handling search query
 * @param initalValue: string | null
 * @returns: SearchHook
 */
export const useSearchHook = (initalValue: any): SearchHook => {
    const [searchQuery, setSearchQuery] = useState<any>(initalValue);

    // Handling search query when pressing 'Enter'
    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = async (e) => {
        if (e.key === 'Enter') {
            const searchQuery = e.currentTarget.value;
            setSearchQuery(searchQuery);
        }
    };

    // Handling and setting search query when typing
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (typeof searchQuery === 'object') {
            setSearchQuery({ ...searchQuery, [e.target.name]: e.target.value });
            return;
        }
        setSearchQuery(e.target.value);
    };

    // Handling search query reset
    const handleSearchReset = (): void => {
        setSearchQuery(initalValue);
    };

    return { searchQuery, handleKeyDown, handleChange, handleSearchReset };
};
