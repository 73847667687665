import usersProvider from '../providers/usersProvider';
import { AxiosResponse } from 'axios';

const usersService = {
    getUsers(filterText: string, page: number, success: Function, error?: Function){
        usersProvider.getUsers(filterText, page, success, error);
    },

    snooze(snoozeMins: number, userId: number, success: Function, error?: Function) {
        usersProvider.snooze(snoozeMins, userId, success, error);
    },

    getMentionUsers(value: string, success: Function, error?: Function) {
        usersProvider.getMentionUsers(value, success, error);
    }
}

export default usersService;