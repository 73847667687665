import React from 'react';
import { Box, SxProps } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

export interface SearchFieldProps {
    handleKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
    handleSearch?: React.ChangeEventHandler<HTMLInputElement>;
    searchQuery: string | any | number | readonly string[] | undefined;
    sx?: SxProps;
    placeholder?: string;
    iconStyle?: React.CSSProperties;
    name?: string;
}

export const SearchField = (props: SearchFieldProps) => {
    const { handleKeyDown, sx, handleSearch, searchQuery, placeholder, iconStyle, name } = props;

    return (
        <Box data-testid='search-field' className='search-field' sx={sx}>
            <FontAwesomeIcon
                icon={faMagnifyingGlass}
                fontSize='20px'
                style={
                    iconStyle
                        ? iconStyle
                        : {
                              padding: '0 10px',
                          }
                }
            />
            <input
                className='search-field-input'
                placeholder={placeholder}
                onKeyDown={handleKeyDown}
                data-testid='search-field-input'
                onChange={handleSearch}
                value={searchQuery}
                name={name}
            />
        </Box>
    );
};
