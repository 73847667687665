import { useInfiniteQuery,  } from "react-query";
import Client from "../../services/axiosService";

export type GroupListQueryParams = {
    searchQuery: string | null;
    prefix: string;
}

/**
 * @description: Fetches community group list results from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchGroups = async ({searchQuery, prefix}: GroupListQueryParams, page: number) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/community-groups?search=${searchQuery}&prefix=${prefix}&page=${page}`,
        method: "get"
    })

    return response.data;
}

/**
 * @description: Fetches group list results from the API and returns the data in the form of infinite query
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useGroupListQuery = (groupListQueryParams: GroupListQueryParams) => {
    const { searchQuery, prefix } = groupListQueryParams;
    return useInfiniteQuery(
        ['groups-list', searchQuery, prefix],
        ({ pageParam = 1 }) => fetchGroups(groupListQueryParams, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
        }
    );
}