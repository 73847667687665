import { getI18n } from 'react-i18next';

export function getLikesStr(likes: number) {
    if(likes === 1)
        return getI18n().t('general.like');
    return getI18n().t('general.likes');
}

export function getCommentsStr(comments: number) {
    if(comments === 1)
        return getI18n().t('general.comment');
    return getI18n().t('general.comments');
}

export function getPeopleStr(comments: number) {
    if(comments === 1)
        return getI18n().t('general.person');
    return getI18n().t('general.people');
}