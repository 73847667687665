import { useMutation } from "react-query";
import Client from "../services/axiosService";

/**
 * @description: Makes a Post request to the API to mark a thread as read
 * @param threadId: The id of the thread to mark as read
 * @returns: The response from the API
 */
const removeParticipantRequest = async (data: any) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${data.threadId}/members/${data.userId}`,
        method: 'delete',
    });
    return response.data;
};

/**
 * @description: A hook to mark a thread as read
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useRemoveParticipant = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(removeParticipantRequest, {
        onSuccess: onSuccess,
        onError: onError,
    });
};


/**
 * @description: Makes a Post request to the API to mark a thread as read
 * @param threadId: The id of the thread to mark as read
 * @returns: The response from the API
 */
const addParticipantRequest = async (data: any) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${data.threadId}/members`,
        method: 'post',
        data: data.body,
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

/**
 * @description: A hook to mark a thread as read
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useAddParticipant = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(addParticipantRequest, {
        onSuccess: onSuccess,
        onError: onError,
    });
};

/**
 * @description: Makes a Post request to the API to mark a thread as read
 * @param threadId: The id of the thread to mark as read
 * @returns: The response from the API
 */
const addAdminRequest = async (data: any) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${data.threadId}/members/${data.userId}/grant-admin`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

/**
 * @description: A hook to mark a thread as read
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useAddAdminHook = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(addAdminRequest, {
        onSuccess: onSuccess,
        onError: onError,
    });
};


/**
 * @description: Makes a Post request to the API to mark a thread as read
 * @param threadId: The id of the thread to mark as read
 * @returns: The response from the API
 */
const removeAdminRequest = async (data: any) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${data.threadId}/members/${data.userId}/revoke-admin`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

/**
 * @description: A hook to mark a thread as read
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useRemoveAdminHook = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(removeAdminRequest, {
        onSuccess: onSuccess,
        onError: onError,
    });
};