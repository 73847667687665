import locationsProvider from "../providers/locationsProvider";

const locationsService = {
  getLocationListingData(page: number, success: Function, error?: Function) {
    locationsProvider.getLocationListingData(page, success, error);
  },

  getSearchedLocationData(
    searchTerm: any,
    page: number,
    success: Function,
    error?: Function
  ) {
    locationsProvider.getSearchedLocationData(searchTerm, page, success, error);
  },

  getLocationDetails(id: string, success: Function, error?: Function) {
    locationsProvider.getLocationDetails(id, success, error);
  },
};

export default locationsService;
