import { useInfiniteQuery } from "react-query";
import Client from "../../services/axiosService";

/**
 * @description: Fetches results from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchOptions = async (filterID: string, page: number) => {
  const response = await Client().request({
    url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/filters?filter_id=${filterID}&page=${page}`,
    method: "get"
  });
  return response.data;
}

/**
 * @description: Fetches a list of filter options from the API and returns the data
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useCustomFilterOptionsQuery = (filterID: string) => {
  return useInfiniteQuery(
    ['connect-attributes-filter', filterID],
    ({ pageParam = 1 }) => fetchOptions(filterID, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.links?.next) return pages.length + 1;
        return undefined;
      },
      refetchOnWindowFocus: false,
      enabled: filterID !== ''
    }
  );
};