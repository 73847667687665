import { AnyAction } from 'redux';
import { BrandingReducerState } from '../../../types/types';
import { SideNavItem } from '../../../types/types';
import axios from 'axios';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
export const SET_BRANDING = "SET_BRANDING";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export function setBranding(brandingData: BrandingReducerState){
    return {
        type: SET_BRANDING,
        payload: brandingData
    }
}

export function setMenuItems(menu_items: SideNavItem[]){
    return {
        type: SET_MENU_ITEMS,
        payload: menu_items
    }
}

type SetBrandingAction = {
    type: typeof SET_BRANDING,
    payload: Omit<BrandingReducerState, "menu_items">;
} | {
    type: typeof SET_MENU_ITEMS,
    payload: SideNavItem[]
}

export const fetchMenuItems = ():ThunkAction<Promise<void>, {}, {}, AnyAction> => {
    return async (dispatch: ThunkDispatch<{}, {}, AnyAction>):Promise<void> => {
        await axios.get(`${process.env.REACT_APP_CMS_API_URL}/react/nav/items`).then((resp:any) => {
            dispatch(setMenuItems(resp.data.items));
        })
    }
}

export type BrandingActionTypes = SetBrandingAction;