import React from 'react';
import { Box, Paper, SxProps, Typography } from '@mui/material';
import { IconCard } from '../../components/IconCard/iconCard';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { isWhite } from '../../../web/utils/colourCheck';

import './ThreadListContacts.scss';
import { ParticipantUserInterface } from '../../../types/interface/ThreadList';
import { useSelector } from "react-redux";

export type ThreadListContactsProps = {
    data: any;
    setDisplayCreateChat: React.Dispatch<React.SetStateAction<boolean>>;
    fetchNextPage: () => void;
    fetchingNextPage: boolean;
    sx: SxProps;
    displayCreateNewGroup: boolean;
    participantUsers: ParticipantUserInterface[];
    setDisplayCreateNewGroup: React.Dispatch<React.SetStateAction<boolean>>;
    setParticipantUsers: React.Dispatch<React.SetStateAction<ParticipantUserInterface[]>>;
    handleResetContactSearch?: ((value: string | number | readonly string[] | undefined) => void) | undefined;
    handleResetThreadSearch?: ((value: string | number | readonly string[] | undefined) => void) | undefined;
};

export const ThreadListContacts = (props: ThreadListContactsProps) => {
    const {
        data,
        sx,
        displayCreateNewGroup,
        participantUsers,
        setDisplayCreateChat,
        setDisplayCreateNewGroup,
        fetchNextPage,
        fetchingNextPage,
        setParticipantUsers,
        handleResetContactSearch,
    } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const newGroupIconBg = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const newGroupIconFg = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    const handleScroll = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLElement;
        const bottom = Math.floor(target.scrollHeight - target.scrollTop) === target.clientHeight;
        if (bottom && !fetchingNextPage) {
            fetchNextPage();
        }
    };

    const isParticipantUserPresent = (participantUser: ParticipantUserInterface) => {
        return participantUsers.find((user) => user.id === participantUser.id);
    };

    return (
        <Box sx={{ margin: '10px 0' }} data-testid="thread-list-contacts-container">
            {!displayCreateNewGroup && (
                <button 
                    data-testid="new-group-btn" 
                    className="user-group-btn" 
                    onClick={() => {
                        setDisplayCreateNewGroup(true);
                        if(handleResetContactSearch) handleResetContactSearch("");
                    }}
                >
                    <IconCard
                        title={t('chat.newGroup')}
                        titleClassName="user-group-title"
                        icon={
                            <div className="icon-section"
                                 style={{ backgroundColor: newGroupIconBg }}>
                                <svg
                                    className="user-group-icon"
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.125 14.0833C7.16082 14.0833 6.21828 13.7974 5.41659 13.2618C4.61491 12.7261 3.99006 11.9647 3.62109 11.0739C3.25211 10.1831 3.15557 9.20293 3.34367 8.25727C3.53177 7.31161 3.99607 6.44297 4.67785 5.76119C5.35964 5.07941 6.22828 4.61511 7.17393 4.42701C8.11959 4.2389 9.09979 4.33545 9.99058 4.70442C10.8814 5.0734 11.6427 5.69824 12.1784 6.49993C12.7141 7.30162 13 8.24415 13 9.20834C12.9986 10.5008 12.4845 11.74 11.5706 12.6539C10.6566 13.5678 9.41749 14.0819 8.125 14.0833ZM16.25 21.6667C16.2483 20.2306 15.677 18.8539 14.6616 17.8384C13.6461 16.823 12.2694 16.2517 10.8333 16.25H5.41667C3.98061 16.2517 2.60385 16.823 1.5884 17.8384C0.572955 18.8539 0.00172018 20.2306 0 21.6667L0 26H16.25V21.6667ZM18.9583 9.75C17.9942 9.75 17.0516 9.46409 16.2499 8.92842C15.4482 8.39275 14.8234 7.63137 14.4544 6.74058C14.0854 5.84979 13.9889 4.86959 14.177 3.92394C14.3651 2.97828 14.8294 2.10964 15.5112 1.42786C16.193 0.746076 17.0616 0.281777 18.0073 0.093674C18.9529 -0.094429 19.9331 0.00211227 20.8239 0.371089C21.7147 0.740067 22.4761 1.36491 23.0117 2.1666C23.5474 2.96829 23.8333 3.91082 23.8333 4.875C23.8319 6.16749 23.3178 7.40663 22.4039 8.32056C21.49 9.23449 20.2508 9.74857 18.9583 9.75ZM20.5833 11.9167H15.1667C14.9765 11.9254 14.787 11.9446 14.599 11.9741C14.2051 12.8855 13.6233 13.7036 12.8917 14.3748C14.4802 14.8242 15.8789 15.7792 16.8759 17.095C17.8729 18.4109 18.4138 20.0158 18.4167 21.6667H26V17.3333C25.9983 15.8973 25.427 14.5205 24.4116 13.5051C23.3961 12.4896 22.0194 11.9184 20.5833 11.9167Z"
                                        fill={ newGroupIconFg }
                                    />
                                </svg>
                            </div>
                        }
                    />
                </button>
            )}
            <Box>
                <Typography sx={{ fontSize: '16px', fontWeight: 600, margin: '0px 10px' }}>{t('chat.allContacts')}</Typography>
            </Box>
            <Paper sx={sx} elevation={0} className="thread-list-contacts" data-testid="thread-list-contacts" onScroll={handleScroll}>
                {data?.pages?.map((group: any, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            {group?.data.map((contact: any) => {
                                return (
                                    <IconCard
                                        data-testid={`user-${contact.id}`}
                                        title={contact?.full_name}
                                        icon={contact?.image_url}
                                        key={contact?.id}
                                        sx={{
                                            direction: 'ltr',
                                            fontSize: '16px',
                                            fontWeight: 600,
                                            fontFamily: 'Gilroy',
                                            padding: '10px 10px',
                                            ':hover': {
                                                backgroundColor: '#F4F4F4',
                                                borderRadius: '8px',
                                            },
                                        }}
                                        titleStyle={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            ml: '5px',
                                        }}
                                        handleClick={() => {
                                            if (!displayCreateNewGroup) {
                                                navigate(`/chat/new`, {
                                                    state: {
                                                        title: contact?.full_name,
                                                        description: contact?.job_title,
                                                        icon_url: contact?.image_url,
                                                        id: contact?.id,
                                                    },
                                                });
                                                setDisplayCreateChat(false);
                                            } else {
                                                if (!isParticipantUserPresent(contact)) {
                                                    setParticipantUsers((prev) => {
                                                        return [...prev, contact];
                                                    });
                                                    handleResetContactSearch && handleResetContactSearch("");
                                                }
                                            }
                                        }}
                                    >
                                    </IconCard>
                                );
                            })}
                            {fetchingNextPage && <SpinnerLoad className="w-100 d-flex align-items-center justify-content-center" size={50} />}
                        </React.Fragment>
                    );
                })}
            </Paper>
        </Box>
    );
};
