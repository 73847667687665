import feedProvider from '../providers/feedProvider';

const feedService = {
    getLayout(success: Function, error?: Function) {
        feedProvider.getLayout(success, error);
    },
    
    getData(page: number, success: Function, error?: Function) {
        feedProvider.getData(page, success, error);
    },

    getLastComments(success: Function, error?: Function) {
        feedProvider.getLastComments(success, error);
    },
}

export default feedService;