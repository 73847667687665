import { useMutation } from "react-query";
import Client from "../services/axiosService";


export type removeReactionParams = {
    threadID: string;
    messageID: string;
    reaction: string;
}

/**
 * @description: Makes a Delete request to the API to remove a reaction for an individual message
 * @param param 
 * @returns 
 */
export const removeReaction = async ({ threadID, messageID, reaction } : removeReactionParams) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${threadID}/messages/${messageID}/reaction`,
        method: "delete",
        data: {
            reaction: reaction,
        },
    });
    return response.data;
}

/**
 * @description: A hook to remove a reaction from an individual message
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useRemoverReaction = (onSuccess?: (data: any) => void, onError?: () => void) => {
    return useMutation(removeReaction, {
      onSuccess: onSuccess,
      onError: onError,
    })
  }
