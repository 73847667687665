import React from 'react';
import { Box, Typography, Skeleton, SxProps } from '@mui/material';
import { IconText } from '../iconText/IconText';

export type LocationCardProps = {
    locationTitle?: string;
    latitude?: number;
    longitude?: number;
    phoneNo?: string;
    address?: string;
    loading?: boolean;
    id?: number;
    handleClick?: (id: number) => void;
    containerSx?: SxProps;
};

export const LocationCard = (props: LocationCardProps) => {
    const { locationTitle, latitude, longitude, phoneNo, address, loading, handleClick, id, containerSx } = props;

    if (loading) {
        return (
            <Box
                sx={{
                    height: '140px',
                    borderRadius: '12px',
                    background: '#FFFFFF',
                    boxShadow: '0px 5px 5px 0px #00000026',
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                }}
                data-testid='location-card-loading'
            >
                <Skeleton variant='text' width={250} height={20} />
                <Skeleton variant='text' width={150} height={20} />
                <Skeleton variant='rectangular' width={250} height={70} sx={{ marginTop: '10px' }} />
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: '145px',
                borderRadius: '12px',
                background: '#FFFFFF',
                boxShadow: '0px 5px 5px 0px #00000026',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                cursor: 'pointer',
                ...containerSx,
            }}
            data-testid='location-card'
            onClick={() => handleClick && handleClick(id as number)}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                    sx={{
                        fontSize: '18px',
                        fontWeight: 600,
                        color: '#000000',
                        padding: '0 10px',
                    }}
                >
                    {locationTitle}
                </Typography>
                <i className='fa-light fa-users' style={{ fontSize: '18px', lineHeight: '1.2' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'start', padding: '0 10px' }}>
                <Typography
                    sx={{
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#000000',
                    }}
                >
                    {`${latitude?.toFixed(6)}° N, ${longitude?.toFixed(6)}° W`}
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px', padding: '0 10px' }}>
                <IconText icon='fa-light fa-phone' text={phoneNo ?? ''} />
                <IconText
                    icon='fa-light fa-location-dot'
                    text={address ?? ''}
                    textProps={{
                        sx: {
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                    }}
                />
            </Box>
        </Box>
    );
};
