import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import ThreadService from '../../services/threadService';
import objectAssign from 'object-assign';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { NoResultsWidget } from '../noResultWidget/noResultWidget';
import Scrollbar from '../common/scrollbar/scrollbar';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import PagesItemType from './itemTypes/pages/pagesItem';
import PNSItemType from './itemTypes/pnsItem';
import TYCardsItemType from './itemTypes/tyCardsItem';
import SocialPostItemType from './itemTypes/socialPost/SocialPostItem';
import UsersListModal from '../common/usersList/usersListModal';
import CreateSocialPost from './createSocialPost';
import RecentComments from '../customLayout/recentComments';
import './feedPage.scss';
import ProfileSection from './profileSection/profileSection';
import { LayoutContainer, FeedSocialPost } from '../../../types/types';
import { Box, Typography } from '@mui/material';
import feedService from '../../services/feedService';
import CustomLayout from '../customLayout/customLayout';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommunityFeedFilter } from '../../modules/CommunityFeedFilter/CommunityFeedFilter';
import { useCommunityFilterQuery } from '../../hooks/query/useCommunityFilterOptionsQuery';
import { useSearchHook } from '../../hooks/useSearchHook';
import { useDebounce } from '../../hooks/useDebounceHook';
import { GroupItem } from '../../modules/GroupItem/GroupItem';
import { useGroupMembersQuery } from '../../hooks/query/useGroupMembersQuery';
import { MembersListModal } from '../../modules/MembersListModal/MembersListModal';

export type FeedPageProps = {
    customLayoutFeed?: boolean;
    fixedContainer?: boolean;
};

const FeedPage = ({ customLayoutFeed, fixedContainer }: FeedPageProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const userInfo = useSelector((state: any) => state.app.user);

    const [feedData, setFeedData] = useState<Array<any>>([]);
    const [commentsData, setComments] = useState<Array<any>>([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [pageToShare, setSharePage] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [userData, setUserData] = useState<any>({});
    const [hasMoreData, setHasMoreData] = useState(true);
    const [layoutLoading, setLayoutLoading] = useState<boolean>(false);
    const [templateId, setTemplateId] = useState<number>(-1);
    const [layoutContainers, setLayoutContainers] = useState<LayoutContainer[]>([]);
    const [config, setConfig] = useState<any>();
    const { searchQuery: searchCommunityGroup, handleChange: handleSearchCommunityGroup } = useSearchHook('');
    const { searchQuery: searchQueryMembers, handleChange: handleSearchMembers } = useSearchHook('');
    const [displayCommunityFilter, setDisplayCommunityFilter] = useState<boolean>(false);
    const { debouncedValue: debounceSearchCommunityGrouo } = useDebounce(searchCommunityGroup);
    const { debouncedValue: debouncedSearchValueMembers } = useDebounce(searchQueryMembers);
    const {
        data: communityGroup,
        isFetching: fetchingCommunityGroups,
        isLoading: loadingCommunityGroups,
        fetchNextPage: communityGroupsNextPage,
        isFetchingNextPage: fetchingNextCommunityGroups,
    } = useCommunityFilterQuery({ searchQuery: debounceSearchCommunityGrouo });
    const [displayMembersModal, setDisplayMembersModal] = useState<{
        open: boolean;
        id?: string;
    }>({
        open: false,
        id: '',
    });
    const {
        data: groupMembers,
        isLoading: loadingGroupMembers,
        fetchNextPage: fetchNextPageGroupMembers,
        isFetchingNextPage: isFetchingNextPageGroupMembers,
    } = useGroupMembersQuery({
        searchQuery: debouncedSearchValueMembers,
        id: displayMembersModal.id || '',
    });
    const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
    const [selectedGroupId, setSelectedGroupId] = useState<string>('');
    const filterOptions = {
        all: 'All',
        myGroups: 'My Groups',
    };
    const [isSelected, setIsSelected] = useState<string>(filterOptions.all);
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false);
    const [latestGroupPost, setLatestGroupPost] = useState<any>(null);
    const displayGroupFeedList: boolean = selectedGroup.length > 0 || isSelected === filterOptions.myGroups;

    const handleFilterClick = (text: string) => {
        setIsSelected(text);
        if (text === filterOptions.all || text === filterOptions.myGroups) {
            setSelectedGroup([]);
            setSelectedGroupId('');
        }
    };

    const onMembersClick = (id: number) => {
        setDisplayMembersModal({
            open: true,
            id: id.toString(),
        });
    };

    const handleScrollMembers = (e: any) => {
        let bottom = Math.round(e.target.scrollTop + e.target.clientHeight) + 1 >= e.target.scrollHeight;
        if (bottom && !isFetchingNextPageGroupMembers) {
            fetchNextPageGroupMembers();
        }
    };

    useEffect(() => {
        if ((communityGroup && communityGroup?.pages[0].data.length > 0) || searchCommunityGroup) {
            setDisplayCommunityFilter(true);
        } else {
            setDisplayCommunityFilter(false);
        }
    }, [communityGroup]);

    useEffect(() => {
        if (selectedGroup.length > 0 && communityGroup?.pages[0]?.data) {
            const group = communityGroup?.pages[0].data.find((group: any) => group.title === selectedGroup[0]);
            if (group) {
                setSelectedGroupId(group.id);
            }
        }
    }, [selectedGroup, communityGroup]);

    useEffect(() => {
        const fetchData = () => {
            setLayoutLoading(true);
            feedService.getLayout((response: AxiosResponse) => {
                if (response.data.layout == 'custom') {
                    setLayoutContainers(response.data.layout_data[0].data.containers);
                    setTemplateId(response.data.layout_data[0].data.templateId);
                }
                setConfig(response.data.layout);
                setLayoutLoading(false);
            });
        };
        fetchData();
    }, []);

    const loadMoreContentPages = () => {
        setPage((page) => page + 1);
        setPageLoading(true);
        feedService.getData(
            page,
            (resp: AxiosResponse) => {
                appendFeedData(resp.data.data);
                setPageLoading(false);
                setHasMoreData(page < resp.data.meta.last_page);
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Feed Data`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setPageLoading(false);
            }
        );
    };

    useEffect(() => {
        feedService.getLastComments(
            (resp: AxiosResponse) => {
                setComments(resp.data.data);
                setCommentsLoading(false);
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Last Comments`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setCommentsLoading(false);
            }
        );
        loadMoreContentPages();
    }, []);

    const pageScroll = (e: any, loadMorePages?: boolean) => {
        let scrollBottom = Math.round(e.scrollTop + e.clientHeight) >= e.scrollHeight;
        setScrollIsBottom(scrollBottom);
        if (!pageLoading && hasMoreData && scrollBottom && isSelected === filterOptions.all) {
            loadMorePages && loadMoreContentPages();
        }
    };

    const updatePost = (post: FeedSocialPost) => {
        const newFeedData = [...feedData];
        const item = newFeedData.find((p) => (p.type === 'post' || p.type === 'card-p2p' || p.type === 'repost') && p.model.id === post.model.id);
        objectAssign(item, post);
        setFeedData(newFeedData);
    };

    const removeItem = (itemId: number, checkModel: boolean = true) => {
        setFeedData(
            feedData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model : item;
                return dataSrc.id !== itemId;
            })
        );
    };

    const removeContentByUser = (userId: number, checkModel: boolean = true) => {
        setFeedData(
            feedData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model.author : item;
                if (item.type === 'post' || item.type === 'repost') return dataSrc.id !== userId;
                else return true;
            })
        );
    };

    const renderItems = () => {
        const items: Array<React.ReactElement> = [];
        if (!feedData) return items;

        if (feedData.length === 0) {
            return renderEmptyItemsContainer();
        }

        const feedPageFooterData = tenantInfo;

        feedData.forEach((item, index) => {
            switch (item.type) {
                case 'page':
                    items.push(
                        <PagesItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            feedPageFooterData={feedPageFooterData}
                            setSharePage={setSharePage}
                            likePage={likePage}
                        />
                    );
                    break;
                case 'pn':
                    items.push(<PNSItemType isLastItem={index === feedData.length - 1} item={item} />);
                    break;
                case 'card':
                    items.push(<TYCardsItemType isLastItem={index === feedData.length - 1} item={item} tyBranding={tenantInfo.tyBranding} />);
                    break;
                case 'card-p2p':
                    items.push(
                        <SocialPostItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            setLiked={likeP2P}
                            setItem={updatePost}
                            removeItem={removeItem}
                            tyBranding={tenantInfo.tyBranding}
                            removeContentByUser={removeContentByUser}
                            addRepostToFeed={updateFeedData}
                        />
                    );
                    break;
                case 'post':
                    items.push(
                        <SocialPostItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            setLiked={likePost}
                            setItem={updatePost}
                            removeItem={removeItem}
                            removeContentByUser={removeContentByUser}
                            tyBranding={tenantInfo.tyBranding}
                            addRepostToFeed={updateFeedData}
                        />
                    );
                    break;
                case 'repost':
                    items.push(
                        <SocialPostItemType
                            isLastItem={index === feedData.length - 1}
                            item={item}
                            setLiked={likeRepost}
                            setItem={updatePost}
                            removeItem={removeItem}
                            removeContentByUser={removeContentByUser}
                            tyBranding={tenantInfo.tyBranding}
                            addRepostToFeed={updateFeedData}
                            repostWithThoughts={true}
                        />
                    );
                    break;
            }
        });
        return items;
    };

    const renderEmptyItemsContainer = () => {
        return (
            <NoResultsWidget
                title={t('connect.noResultsFound')}
                titleSx={{ display: 'none' }}
                containerSx={{
                    flexGrow: 1,
                }}
                description={
                    <>
                        <Typography sx={{ fontSize: '21px', fontWeight: '520', textAlign: 'center', margin: '12px 0px' }}>
                            {t('general.noData')}
                        </Typography>
                        <Typography sx={{ fontSize: '17px', fontWeight: '500', textAlign: 'center' }}>{t('general.noDataDescription')}</Typography>
                    </>
                }
            />
        );
    };

    const shareToUsers = (selectedUsers: Array<number>) => {
        const pageUrl = `${window.location.origin}/page/${pageToShare.model.id}`;
        const pageName = pageToShare.model.title;
        const messageStr = `${pageUrl} ${pageName}`;

        setSharePage(null);

        ThreadService.createThread(
            selectedUsers,
            messageStr,
            (resp: AxiosResponse) => {
                window.location.href = `/chat/${resp.data.id}`;
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Thread Creation`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const likeContent = (contentType: string, contentId: number, isLiked: boolean) => {
        const newFeedData = feedData ? [...feedData] : [];
        newFeedData.forEach((item) => {
            if (item.type !== contentType || !item.model || item.model.id != contentId) return;
            item.model.is_liked = isLiked;
            item.model.likes += isLiked ? 1 : -1;
        });
        setFeedData(newFeedData);
    };

    const likePage = (pageId: number, isLiked: boolean) => {
        likeContent('page', pageId, isLiked);
    };

    const likePost = (postId: number, isLiked: boolean) => {
        likeContent('post', postId, isLiked);
    };

    const likeRepost = (postId: number, isLiked: boolean) => {
        likeContent('repost', postId, isLiked);
    };

    const likeP2P = (postId: number, isLiked: boolean) => {
        likeContent('card-p2p', postId, isLiked);
    };

    const updateFeedData = (newElem: Array<any>) => {
        if (selectedGroup.length > 0) {
            const specificGroupPosts = newElem.filter((post) => post.model.community_group.id === selectedGroupId);
            if (specificGroupPosts && specificGroupPosts.length > 0) {
                setLatestGroupPost(newElem);
                setFeedData((prevData) => [...specificGroupPosts, ...feedData]);
            }
        } else if (isSelected === filterOptions.myGroups) {
            const groupPosts = newElem.filter((post) => post.model.community_group);
            if (groupPosts.length > 0) {
                setLatestGroupPost(newElem);
                setFeedData([...groupPosts, ...feedData]);
            }
        } else {
            setLatestGroupPost(newElem);
            setFeedData([...newElem, ...feedData]);
        }
    };

    const appendFeedData = (newElem: Array<any>) => {
        setFeedData([...feedData, ...newElem]);
    };

    const renderFeedData = () => {
        return (
            <>
                {userInfo.spEnabled && (
                    <CreateSocialPost
                        className={'p-1 mb-3 card create-social-post'}
                        userData={userData}
                        userInfo={userInfo}
                        feedDataMethod={updateFeedData}
                        defaultSelectedGroup={selectedGroup[0]}
                    />
                )}
                {displayCommunityFilter && (
                    <CommunityFeedFilter
                        groupData={communityGroup}
                        searchQuery={searchCommunityGroup}
                        handleSearchQuery={handleSearchCommunityGroup}
                        isFetchingNextPage={fetchingNextCommunityGroups}
                        selectedGroup={selectedGroup}
                        setSelectedGroup={setSelectedGroup}
                        setIsSelected={setIsSelected}
                        isSelected={isSelected}
                        filterOptions={filterOptions}
                        fetchingNextPage={fetchingCommunityGroups}
                        fetchNextPage={communityGroupsNextPage}
                        handleFilterClick={handleFilterClick}
                        isLoading={loadingCommunityGroups}
                    />
                )}
                {isSelected === filterOptions.myGroups ? (
                    <Box>
                        <GroupItem
                            id={selectedGroupId.toString()}
                            onMembersClick={onMembersClick}
                            displayButton={false}
                            hideScrollBar={true}
                            parentScrollIsBottom={scrollIsBottom}
                            setParentScrollIsBottom={setScrollIsBottom}
                            myGroups={isSelected === filterOptions.myGroups}
                            latestGroupPost={latestGroupPost}
                            updateFeedData={updateFeedData}
                            loading={loadingCommunityGroups}
                        />
                    </Box>
                ) : (
                    <Box>
                        {selectedGroup.length > 0 ? (
                            <GroupItem
                                id={selectedGroupId.toString()}
                                onMembersClick={onMembersClick}
                                displayButton={false}
                                hideScrollBar={true}
                                parentScrollIsBottom={scrollIsBottom}
                                setParentScrollIsBottom={setScrollIsBottom}
                                latestGroupPost={latestGroupPost}
                                loading={loadingCommunityGroups}
                                updateFeedData={updateFeedData}
                            />
                        ) : pageLoading && feedData.length === 0 ? (
                            <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                        ) : (
                            renderItems()
                        )}
                        {pageLoading && feedData.length > 0 && selectedGroup.length === 0 && (
                            <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                        )}
                    </Box>
                )}
            </>
        );
    };

    if (customLayoutFeed && fixedContainer) {
        return (
            <Box
                sx={{ height: '100%', position: 'relative', overflow: 'auto', maxWidth: '1200px', margin: '0 auto' }}
                onScroll={(e: any) => pageScroll(e.target, !displayGroupFeedList)}
            >
                {renderFeedData()}
            </Box>
        );
    }

    if (customLayoutFeed && !fixedContainer) {
        return (
            <InfiniteScroll
                hasMore={!displayGroupFeedList ? hasMoreData : false}
                loader={<></>}
                dataLength={!displayGroupFeedList ? feedData.length : 0}
                next={!displayGroupFeedList ? loadMoreContentPages : () => {}}
                scrollableTarget='page-frame-contents'
                onScroll={(e: any) => pageScroll(e.target, false)}
            >
                <Box sx={{ maxWidth: '1200px', margin: '0 auto' }}>{renderFeedData()}</Box>
            </InfiniteScroll>
        );
    }

    if (layoutLoading) {
        return <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />;
    }

    if (config === 'custom') {
        return (
            <div data-testid='custom-layout' className='custom-layout'>
                <CustomLayout templateId={templateId} components={layoutContainers} />
            </div>
        );
    }

    return (
        <>
            {config === 'standard' && (
                <Container fluid className='h-100 p-0'>
                    {pageToShare !== null && (
                        <UsersListModal
                            title={t('feed.createThread')}
                            btnText={t('feed.done')}
                            isOpen={true}
                            toggle={() => setSharePage(null)}
                            doneCallback={shareToUsers}
                        />
                    )}
                    <Row className={'h-100'}>
                        <Col lg={3} xxl={2} className={'h-100 section profile-section'}>
                            <ProfileSection setUserData={setUserData} />
                        </Col>

                        <Col lg={6} xxl={7} className={'h-100 section feed-section'} style={{ paddingRight: '0.2rem' }}>
                            <Scrollbar onScroll={(e: any) => pageScroll(e, !displayGroupFeedList)} rightScroll={true} className='me-2' noScrollX>
                                {renderFeedData()}
                                <MembersListModal
                                    open={displayMembersModal.open}
                                    memberList={groupMembers?.pages?.map((page) => page?.data).flat() || []}
                                    handleModalClose={() => {
                                        setDisplayMembersModal((prev) => ({ ...prev, open: false }));
                                    }}
                                    loading={loadingGroupMembers}
                                    handleScroll={handleScrollMembers}
                                    fetchingNextPage={isFetchingNextPageGroupMembers}
                                    fetchNextPage={fetchNextPageGroupMembers}
                                    header={t('connect.groupMembers') as string}
                                    displaySearchField
                                    searchFieldProps={{
                                        placeholder: t('general.search') as string,
                                        searchQuery: searchQueryMembers,
                                        handleSearch: handleSearchMembers,
                                    }}
                                    totalMembers={groupMembers?.pages[0]?.meta?.total || 0}
                                />
                            </Scrollbar>
                        </Col>
                        <Col lg={3} className={'h-100 section comments-section'}>
                            {commentsLoading ? (
                                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                            ) : (
                                <RecentComments feedCommentList={commentsData} />
                            )}
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default FeedPage;
