import React, { FunctionComponent, useState, useEffect } from 'react';
import { Input, FormGroup } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import classnames from 'classnames';
import objectAssign from 'object-assign';

type ValidatedInputProps = {
    label?: string,
    className?: string,
    name: string,
    type?: InputType,
    placeholder?: string,

    foregroundColor?: string,
    backgroundColor?: string,
    borderColor?: string,
    labelColor?: string,
    styles?: React.CSSProperties,

    regExp?: RegExp,
    regExpError?: string,
    required?: boolean,

    validateOnBlur?: boolean,
    validateOnChange?: boolean,

    onChange?: Function,
    // This function will give parent component the "validate" function it can save
    // and call in future to validate the field
    getValidation: Function,
    // Same as above, but returns input data
    getValue?: Function
    disabled?: boolean
}

const ValidatedInput: FunctionComponent<ValidatedInputProps> = (props) => {
    const [isValid, setValid] = useState(true);
    const [inputValue, setValue] = useState('');
    const [errorsList, setErrors] = useState<Array<string>>([]);

    // Validates the value of "inputValue" by default, but can be set by hand
    // (to battle the setState async wait)
    const validate = (input: string = '') => {
        const value = input || inputValue;

        if(!value && props.required) {
            setErrors([`${props.label || 'Field'} is required`]);
            setValid(false);
            return false;
        }
        if(props.regExp && !props.regExp.test(value)) {
            setErrors([props.regExpError || 'Invalid input']);
            setValid(false);
            return false;
        }

        setValid(true);
        setErrors([]);
        return true;
    }

    const reset = () => {
        setValid(true);
        setErrors([]);
    }

    const inputChange = (e: React.FormEvent<HTMLInputElement>) => {
        reset();
        setValue(e.currentTarget.value);

        if(props.onChange)
            props.onChange(e);
    } 

    const getValue = () => {
        return {
            name: props.name,
            value: inputValue
        };
    }

    useEffect(() => {
        props.getValidation(validate);
        if(props.getValue)
            props.getValue(getValue);
    });

    const getFieldStyles = () => {
        const styles: React.CSSProperties = {};
        if(props.foregroundColor)
            styles.color = props.foregroundColor;
        if(props.backgroundColor)
            styles.backgroundColor = props.backgroundColor;
        if(props.borderColor)
            styles.borderColor = props.borderColor;
        return objectAssign({}, styles, props.styles || {});
    }

    const getLabelStyles = () => {
        const styles: React.CSSProperties = {};
        if(props.labelColor)
            styles.color = props.labelColor;
        return objectAssign({}, styles, props.styles || {});
    }

    return (
        <FormGroup className={classnames('mb-1', props.className)}>
            { props.label && 
                <small  data-testid="validate-input-field-label" style={getLabelStyles()} className={classnames("mt-3", { 'text-danger': !isValid })}> 
                    { `${props.required ? '*' : ''}${props.label}` }
                </small> 
            }
            <Input data-testid="validate-input" className={classnames('w-100', {'is-invalid': !isValid })} value={inputValue} style={getFieldStyles()}
                name={props.name} type={props.type || 'text'} placeholder={props.placeholder}
                onChange={inputChange} onBlur={() => { if(props.validateOnBlur) validate()}}
                disabled={props.disabled}
            />

            <div data-testid="validated-input-error-list" className="text-danger">
                { errorsList.length > 0 &&
                    errorsList.map((error: string) => (
                        <small key={error} className="font-weight-bold">{ error }</small>
                    ))
                }
            </div>
        </FormGroup>
    );
}

export default ValidatedInput;