import React from 'react';
import { OrgChartData } from '~/types/types';
import { useSelector } from 'react-redux';
import { Tree, TreeNode } from 'react-organizational-chart';
import '../common/modal/orgChartModal.scss';
import { Box } from '@mui/material';
import classnames from 'classnames';

export type OrgChartProps = {
    headerName: string;
    renderModalData: { data: OrgChartData };
};

export const OrgChart = (props: OrgChartProps) => {
    const { headerName, renderModalData } = props;
    const branding = useSelector((state: any) => state.app.branding);
    const fgColor = branding.org_chart.foreground_colour;
    const bgColor = branding.org_chart.background_colour;
    const managerFgColor = branding.org_chart.manager_foreground_colour;
    const managerBgColor = branding.org_chart.manager_background_colour;

    const renderTreeChildren = (data: OrgChartData) => {
        if (data.children.length < 1) return;
        return data?.children.map((kid) => {
            return (
                <React.Fragment>
                    <TreeNode
                        label={
                            <div
                                data-testid={`child-node-${kid.id}`}
                                className="org-chart-label"
                                style={{ backgroundColor: bgColor ? bgColor : '#294f60' }}
                            >
                                {renderNode(kid, true)}
                            </div>
                        }
                    >
                        {renderTreeChildren(kid)}
                    </TreeNode>
                </React.Fragment>
            );
        });
    };

    const renderNode = (element: OrgChartData, childNode: boolean = false) => {
        return (
            <a
                data-testid={`node-${element.id}`}
                className="node-element"
                href={`/connect/people/${element.contact_id}`}
                style={
                    childNode
                        ? {
                              color: fgColor ? fgColor : '#ffffff',
                          }
                        : {
                              color: managerFgColor ? managerFgColor : '#ffffff',
                          }
                }
            >
                <img data-testid={`node-${element.id}-image`} className="tree-node-img root" src={element.image_url} />
                <strong data-testid={`node-${element.id}-name`}>{element.name}</strong>
                <span data-testid={`node-${element.id}-title`}>{element.title}</span>
                <span data-testid={`node-${element.id}-department`}>{element.department}</span>
            </a>
        );
    };

    return (
        <Box data-testid="org-chart" className={classnames('modal-xl')}>
            <div data-testid="org-chart-contain" className="modal-body w-100 org-chart-container">
                <Tree
                    lineWidth={'2px'}
                    lineColor="#294f60"
                    label={
                        <div
                            data-testid="root-node"
                            className="org-chart-label root-node"
                            style={{
                                backgroundColor: managerBgColor ? managerBgColor : '#0091b8',
                            }}
                        >
                            {renderNode(renderModalData?.data)}
                        </div>
                    }
                >
                    {renderTreeChildren(renderModalData?.data)}
                </Tree>
            </div>
        </Box>
    );
};
