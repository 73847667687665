import Echo from "laravel-echo";
window.io = require('socket.io-client');

declare global {
    interface Window {
        io: any;
        Echo: Echo;
    }
}

export function createSocketConnection() {
    if (!window.Echo) {
        window.Echo = new Echo({
            broadcaster: "socket.io",
            host: window.location.hostname + ':6001',
            auth: {
                headers: {
                    "X-CSRF-TOKEN": "tlinE6dYGxLQuTIIHndx3chZmVgHkDbyYn3jzsrR"
                },
            },
        });
    }
}