import Client from "../services/axiosService";
import { AxiosResponse, AxiosError } from "axios";

const locationsProvider = {
  getLocationListingData(page: number, success: Function, error?: Function) {
    Client()
      .get(process.env.REACT_APP_CMS_API_URL + "/react/locations?page=" + page)
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  getSearchedLocationData(
    searchTerm: any,
    page: number,
    success: Function,
    error?: Function
  ) {
    Client()
      .get(
        process.env.REACT_APP_CMS_API_URL +
          "/react/locations?search=" +
          searchTerm +
          "&page=" +
          page
      )
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  getLocationDetails(id: string, success: Function, error?: Function) {
    Client()
      .get(process.env.REACT_APP_CMS_API_URL + "/react/locations/" + id)
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};

export default locationsProvider;
