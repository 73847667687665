import React, {FunctionComponent, useEffect, useState} from 'react';
import { AxiosResponse } from "axios";
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import socialPostsService from '../../../../services/socialPostsService';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';

import Scrollbar from '../../../common/scrollbar/scrollbar';
import SpinnerLoad from '../../../common/spinnerLoad/spinnerLoad';

type SocialPostLikesModalProps = {
    postId: number,
    isOpen: boolean,
    toggle: any
}

const SocialPostLikesModal: FunctionComponent<SocialPostLikesModalProps> = ({ postId, isOpen, toggle }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [likesData, setLikes] = useState<Array<any>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [dataLoading, setDataLoading] = useState(true);

    useEffect(() => {
        if (isOpen) {
            loadNextLikes();
        }
    }, [isOpen]);

    const loadNextLikes = () => {
        socialPostsService.loadPostLikes(postId, currentPage, (resp: AxiosResponse) => {
            let meta = resp.data.meta;
            setCurrentPage(meta.current_page);

            setDataLoading(false);
            setLikes(resp.data.data);
        }, () => {
            dispatch(addNotification({ label: `Likes`, text: t('general.errors.errorLoadingPage'), type: 'danger' }));
            setDataLoading(false);
        });
    }

    const navigateUser = (e: any, userId: string) => {
        e.preventDefault();
        navigate(`/connect/people/${userId}`);
    }

    const renderLike = (like: any) => {
        return (
            <a data-testid="like-section" key={like.id} href={`/connect/people/${like.author.contact_id}`} className="d-block text-decoration-none">
                <Card data-testid="like-card" className="social-likes-modal-item c-pointer mb-2 border-0" onClick={(e) => navigateUser(e, like.author.contact_id)}>
                    <div className="p-2 d-flex align-items-center">
                        <img data-testid="author-image" className="rounded-circle thumb64" src={like.author.image_url} alt={`${like.author.full_name}'s Image`} />
                        <div className="ms-3">
                            <h3 data-testid="author-full-name" className="m-0 text-secondary">{ like.author.full_name }</h3>
                            <span data-testid="timestamp" className="text-primary">{ like.created_at_humans }</span>
                        </div>
                    </div>
                </Card>
            </a>
        );
    }

    return (
        <Modal data-testid="social-post-likes-modal" className="social-post-likes-modal" isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <h2 data-testid="likes" className="fw-bold mb-0 text-secondary">{ t('general.likes') }</h2> 
            </ModalHeader>
            
            <ModalBody>
                {dataLoading ?
                    <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={30} />
                    :
                    <Scrollbar>
                        {likesData.map((like: any) => (renderLike(like)))}
                    </Scrollbar>
                }
            </ModalBody>

            <ModalFooter>
                <Button data-testid="done-btn" className="w-100" onClick={toggle}>{ t('feed.done') }</Button>
            </ModalFooter>
        </Modal>
    );
}

export default SocialPostLikesModal;
