import { useInfiniteQuery } from "react-query";
import Client from "../../services/axiosService";

/**
 * @description: Fetches results from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchOptions = async ({ type, searchQuery } : {
    type: string, 
    searchQuery?: string
}, page: number) => {
  const response = await Client().request({
    url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/filters?page=${page}&type=${type}&search=${searchQuery}`,
    method: "get"
  });
  return response.data;
}

/**
 * @description: Fetches a list of filter options from the API and returns the data
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useFilterOptionsQuery = ({ type, searchQuery } : {
    type: string, 
    searchQuery?: string
}) => {
  return useInfiniteQuery(
    [type, type, searchQuery],
    ({ pageParam = 1 }) => fetchOptions({type, searchQuery}, pageParam),
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage?.links?.next) return pages.length + 1;
        return undefined;
      },
      refetchOnWindowFocus: false,
    }
  );
}