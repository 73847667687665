import React from 'react';
import { SxProps, Typography, Button, hexToRgb, alpha } from '@mui/material';
import { ThreadBranding } from '../../../types/types';
import { useSelector } from 'react-redux';

export type ReactionProps = {
    sx?: SxProps;
    bubbleType: string;
    threadBranding: ThreadBranding;
    reaction: string;
    count: number;
    highlight: boolean;
    handleReactionClick?: () => void;
};

export const Reaction = (props: ReactionProps) => {
    const { sx, bubbleType, threadBranding, reaction, count, handleReactionClick, highlight } = props;

    const tenantBranding = useSelector((state: any) => state.app.branding);

    const primaryHex = threadBranding?.sender_bubble_colour ?? tenantBranding.messaging.sender_bubble_colour;
    const secondaryHex = threadBranding?.receiver_bubble_colour ?? tenantBranding.messaging.receiver_bubble_colour;
    const primaryColour = hexToRgb(primaryHex);
    const secondaryColour = hexToRgb(secondaryHex);
  
    const primaryFontHex = threadBranding?.sender_text_colour ?? tenantBranding.messaging.sender_text_colour;
    const secondaryFontHex = threadBranding?.receiver_text_colour ?? tenantBranding.messaging.receiver_text_colour;
    const primaryFontColour = hexToRgb(primaryFontHex);
    const secondaryFontColour = hexToRgb(secondaryFontHex);

    const getColor = () => {
        if (bubbleType === 'primary') {
            return highlight ? primaryColour : alpha(primaryColour, 0.5).toString();
        } else {
            return highlight ? secondaryColour : alpha(secondaryColour, 0.5).toString();
        }
    };
    const getFontColor = () => {
      return bubbleType === 'primary' ? primaryFontColour : secondaryFontColour;
    };

    return (
        <Button
            className="unstyled-btn"
            aria-label="reaction"
            sx={{
                display: 'flex',
                gap: '5px',
                borderRadius: '7px',
                color: getFontColor(),
                backgroundColor: getColor(),
                width: 'fit-content',
                minWidth: 'fit-content',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '4px 8px 2px',
                '&:hover': {
                    backgroundColor: getColor(),
                },
                ...sx,
            }}
            data-testid="reaction"
            onClick={handleReactionClick}
        >
            <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>{count}</Typography>
            <Typography sx={{ fontSize: '13px', fontWeight: '600' }}>{reaction}</Typography>
        </Button>
    );
};
