import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Box, Avatar, Tooltip, Typography, Alert, Button } from '@mui/material';
import { SxProps } from '@mui/material';
import { ChatBubbleReactions } from '../chatBubbleReactions/chatBubbleReactions';
import { UseMutateFunction } from 'react-query';
import { addReactionParams } from '../../hooks/useAddReactionHook';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThreadBranding } from '../../../types/types';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AuthorType } from '../../../types/interface/Thread';
import { v4 as uuidv4 } from 'uuid';
import { deleteMessageParams } from '../../hooks/useDeleteMessageHook';

export type ChatBubbleProps = {
    children?: React.ReactNode;
    bubbleType: 'primary' | 'secondary';
    startSequence?: string;
    author?: AuthorType | undefined;
    sx?: SxProps;
    createdAt?: string;
    messageID: string;
    allowReactions: boolean;
    threadBranding: ThreadBranding;
    sendingMessage?: boolean;
    error?: boolean;
    deleted?: boolean;
    error_message?: string;
    threadType: string;
    addReaction: UseMutateFunction<any, unknown, addReactionParams, unknown>;
    handleResend: (id: string) => void;
    onReplyToClick: () => void;
    deleteMessage: UseMutateFunction<any, unknown, deleteMessageParams, unknown>;
};

export const ChatBubble = React.memo((props: ChatBubbleProps) => {
    const {
        children,
        bubbleType,
        startSequence,
        author,
        sx,
        createdAt,
        messageID,
        threadBranding,
        sendingMessage,
        error,
        error_message,
        allowReactions,
        deleted,
        threadType,
        addReaction,
        handleResend,
        onReplyToClick,
        deleteMessage,
    } = props;
    const navigate = useNavigate();
    const uniqueId = uuidv4();
    const shouldDisplayAvatar = bubbleType === 'secondary' && (startSequence === 'end' || startSequence === 'single');
    const [displayTooltip, setDisplayTooltip] = useState<boolean>(false);
    const [displayReactions, setDisplayReactions] = useState<boolean>(false);
    const [emojiAnchorEl, setEmojiAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [ellipsisAnchorEl, setEllipsisAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const bubbleRef = useRef<HTMLDivElement>(null);
    const reactionRef = useRef<HTMLDivElement>(null);
    const [clientHeight, setClientHeight] = useState<number | null>(null);
    const open = Boolean(emojiAnchorEl);
    const openEllipsis = Boolean(ellipsisAnchorEl);
    const id = open ? messageID + '-simple-popover' : undefined;
    const ellipsisId = openEllipsis ? messageID + '-ellipsis-popover' : undefined;
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const { id: threadID } = useParams<{ id: string }>();

    useEffect(() => {
        if(!displayReactions) {
            setEmojiAnchorEl(null);
            setEllipsisAnchorEl(null);
        }
    }, [displayReactions])

    const onEmojiClick = (emojiObject: any) => {
        addReaction({
            threadID: threadID as string,
            messageID,
            reaction: emojiObject.emoji,
        });
        setDisplayReactions(false);
        setEmojiAnchorEl(null);
    };

    const renderChildrenWithProps = useCallback(() => {
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child as React.ReactElement<any>, { setDisplayTooltip });
            }
            return child;
        });
    }, [children]);

    const handleEmojiClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEmojiAnchorEl(event.currentTarget);
        setDisplayTooltip(false);
    };

    const handleEmojiClose = () => {
        setEmojiAnchorEl(null);
        setDisplayReactions(false);
        setDisplayTooltip(false);
    };

    const handleEllipsisClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setEllipsisAnchorEl(event.currentTarget);
        setDisplayTooltip(false);
    };

    const handleEllipsisClose = () => {
        setEllipsisAnchorEl(null);
        setDisplayReactions(false);
        setDisplayTooltip(false);
    };

    const handleMessageDelete = () => {
        deleteMessage({
            threadID: threadID as string,
            messageID,
        });
    };

    useEffect(() => {
        if (bubbleRef.current && reactionRef.current) {
            const secondDivHeight = bubbleRef.current.clientHeight;
            reactionRef.current.style.height = `${secondDivHeight}px`;
            setClientHeight(secondDivHeight);
        }
    }, []);

    const primaryBg =
        threadBranding && threadBranding.sender_bubble_colour ? threadBranding.sender_bubble_colour : tenantBranding.messaging.sender_bubble_colour;
    const primaryFg =
        threadBranding && threadBranding.sender_text_colour ? threadBranding.sender_text_colour : tenantBranding.messaging.sender_text_colour;
    const secondaryBg =
        threadBranding && threadBranding.receiver_bubble_colour
            ? threadBranding.receiver_bubble_colour
            : tenantBranding.messaging.receiver_bubble_colour;
    const secondaryFg =
        threadBranding && threadBranding.receiver_text_colour ? threadBranding.receiver_text_colour : tenantBranding.messaging.receiver_text_colour;
    const bubbleStyle =
        bubbleType == 'primary'
            ? {
                  backgroundColor: primaryBg,
                  color: primaryFg,
                  filter: sendingMessage ? 'opacity(0.5)' : 'none',
              }
            : {
                  backgroundColor: secondaryBg,
                  color: secondaryFg,
                  filter: sendingMessage ? 'opacity(0.5)' : 'none',
              };

    return (
        <>
            <Box className={`bubble-container ${bubbleType}`} sx={sx}>
                {error && error_message && !deleted && (
                    <Box
                        ref={reactionRef}
                        sx={{
                            alignSelf: 'center',
                            mx: '10px',
                            display: 'flex',
                            height: clientHeight,
                            alignItems: 'center',
                            width: '50%',
                            justifyContent: 'flex-end',
                        }}
                    >
                        {/* update icon padding */}
                        <Alert
                            severity="error"
                            sx={{
                                '& .MuiAlert-icon': {
                                    marginRight: '5px',
                                },
                            }}
                        >
                            {error_message}
                        </Alert>
                        <Tooltip title={'Send again'}>
                            <Box sx={{ ml: '10px', fontSize: '16px', color: 'black', cursor: 'pointer' }}>
                                <span className="fa-solid fa-rotate" onClick={() => handleResend(messageID)} />
                            </Box>
                        </Tooltip>
                    </Box>
                )}
                {bubbleType === 'primary' && !deleted && (
                    <Box
                        sx={{
                            alignSelf: 'center',
                            mx: '20px',
                            display: sendingMessage === true ? 'none' : 'flex',
                            height: clientHeight,
                            width: '50%',
                            justifyContent: 'flex-end',
                        }}
                        ref={reactionRef}
                        data-testid="chat-bubble-reactions-container"
                        onMouseEnter={() => setDisplayReactions(true)}
                        onMouseLeave={() => setDisplayReactions(false)}
                    >
                        <ChatBubbleReactions
                            displayReactions={displayReactions}
                            handleEmojiClick={handleEmojiClick}
                            handleEmojiClose={handleEmojiClose}
                            handleMessageDelete={handleMessageDelete}
                            handleEllipsisClick={handleEllipsisClick}
                            handleEllipsisClose={handleEllipsisClose}
                            allowReactions={allowReactions}
                            id={id}
                            messageID={messageID}
                            ellipsisId={ellipsisId}
                            openEllipsis={openEllipsis}
                            open={open}
                            emojiAnchorEl={emojiAnchorEl}
                            ellipsisAnchorEl={ellipsisAnchorEl}
                            onEmojiClick={onEmojiClick}
                            onReplyToClick={onReplyToClick}
                            primary
                        />
                    </Box>
                )}
                {bubbleType === 'secondary' &&
                    (shouldDisplayAvatar ? (
                        <>
                            <ReactTooltip className="tool-tip" anchorId={`${uniqueId}_${author?.id}`} />
                            <Avatar
                                id={`${uniqueId}_${author?.id}`}
                                className="avatar c-pointer thumb32"
                                alt="avatar"
                                src={author?.avatar_url}
                                sx={{
                                    alignSelf: 'flex-end',
                                }}
                                data-tooltip-content={author?.name}
                                onClick={() => {
                                    navigate(`/connect/people/${author?.contact_id}`);
                                }}
                            />
                        </>
                    ) : (
                        <Box className="avatar-hide thumb32" />
                    ))}
                <Tooltip
                    title={<Typography sx={{ fontSize: '13px' }}>{createdAt}</Typography>}
                    open={displayTooltip && !open}
                    placement={bubbleType === 'primary' ? 'left' : 'right'}
                    componentsProps={{
                        tooltip: {
                            sx: {
                                borderRadius: '10px',
                            },
                        },
                    }}
                >
                    <Box
                        className={`chat-bubble ${bubbleType} ${startSequence} ${bubbleType == 'primary' ? 'sender-link' : 'receiver-link'} ${
                            deleted && 'deleted-message'
                        }`}
                        sx={bubbleStyle}
                        data-testid="chat-bubble"
                        ref={bubbleRef}
                        onMouseEnter={() => {
                            if (sendingMessage === true) return;
                            setDisplayTooltip(true);
                            setDisplayReactions(false);
                        }}
                        onMouseLeave={() => {
                            setDisplayTooltip(false);
                        }}
                    >
                        {bubbleType === 'secondary' && (threadType != 'private') && (startSequence == 'start' || startSequence == 'single') && (
                            <p 
                                data-testid='chat-bubble-sender-name'
                                className={`m-0 pt-0 ps-0 pe-0 chat-bubble-author-name ${(children as any)?.props?.children[0]?.props?.reply_to_message ? 'pb-0' : 'pb5'}`}
                            >
                                {author?.name}
                            </p>
                        )}
                        {renderChildrenWithProps()}
                    </Box>
                </Tooltip>
                {bubbleType === 'secondary' && (
                    <Box
                        sx={{
                            alignSelf: 'center',
                            px: '20px',
                            display: sendingMessage === true ? 'none' : 'flex',
                            height: clientHeight,
                            width: '50%',
                            justifyContent: 'flex-start',
                        }}
                        ref={reactionRef}
                        onMouseEnter={() => setDisplayReactions(true)}
                        onMouseLeave={() => setDisplayReactions(false)}
                    >
                        <ChatBubbleReactions
                            displayReactions={displayReactions}
                            allowReactions={allowReactions}
                            handleEmojiClick={handleEmojiClick}
                            handleEmojiClose={handleEmojiClose}
                            handleEllipsisClick={handleEllipsisClick}
                            handleEllipsisClose={handleEllipsisClose}
                            handleMessageDelete={handleMessageDelete}
                            id={id}
                            messageID={messageID}
                            ellipsisId={ellipsisId}
                            open={open}
                            openEllipsis={openEllipsis}
                            emojiAnchorEl={emojiAnchorEl}
                            ellipsisAnchorEl={ellipsisAnchorEl}
                            onEmojiClick={onEmojiClick}
                            onReplyToClick={onReplyToClick}
                            primary={false}
                        />
                    </Box>
                )}
            </Box>
            <style>{`
                .sender-link a {
                    color: ${primaryFg}
                }

                .receiver-link a {
                    color: ${secondaryFg}
                }
            `}</style>
        </>
    );
});
