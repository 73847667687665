import { FunctionComponent, useEffect } from "react";
import { AxiosResponse } from "axios";
import analyticsService from "../../../services/analyticsService";
import { useSelector } from "react-redux";

type analyticProps = {
  activityId: string;
};

const AnalyticsComponent: FunctionComponent<analyticProps> = ({
  activityId,
}) => {
  let time = 0;
  const userInfo = useSelector((state: any) => state.app.user);

  useEffect(() => {
    if (activityId && userInfo.cookiesEnabled && userInfo.recordAnalytics) {
      const interval = setInterval(() => {
        time = time + 10;
        analyticsService.analytics(activityId, (resp: AxiosResponse) => {});
      }, 10000);
      return () => clearInterval(interval);
    }
  });
  return null;
};
export default AnalyticsComponent;
