import navigationProvider from "../providers/navigationProvider";

const navigationService = {
  getSideNavigation(success: Function, error?: Function) {
    navigationProvider.getSideNavigation(success, error);
  },
  savePreferences(data: any, success: Function, error?: Function) {
    navigationProvider.savePreferences(data, success, error);
  },
};

export default navigationService;
