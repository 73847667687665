export default {
	"award": "{{text, en-handle-an}}",
	"awards": "Awards",
	"send": "Send {{text, en-handle-an}}",
	"all": "All",
	"inbox": "To Me",
	"sent": "Sent",
	"team": "Team",
	"choose": "Choose",
	"toggleManager": "Toggle Manager Messages",
	"manager": "Manager",
	"received": "received {{text, en-handle-an}}",
	"from": "from",
	"message": "Message",
	"managerMessage": "Message to manager",
	"setVisibility": "Set visibility",
	"showInFeed": "Shown in the feed",
	"onlyRecipients": "Only seen by recipients",
	"recipients": "Recipients",
	"for": "for",
	"messagePlaceholder": "Type your message in here",
	"thankYou": "Thank You"
};