import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {Button, Modal, ModalHeader} from "reactstrap";
import classnames from "classnames";
import {useTranslation} from "react-i18next";

interface props {
    isOpen: any,
    file?: any,
    setIsOpen?: any,
    className?: string
}

const ModalComponent: FunctionComponent<props> = ({isOpen, file, setIsOpen, className }) => {
    const { t } = useTranslation();

    const setVisibility = () => {
        setIsOpen(false);
    }

    const escHandle = useCallback((event: { keyCode: number; }) => {
        if (event.keyCode === 27) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escHandle, false);
        return () => {
            document.addEventListener('keydown', escHandle, false);
        }
    }, []);

    return isOpen ? (
        <Modal data-testid="modal" isOpen={isOpen} className={classnames('files-list-modal')}>
            <ModalHeader>
                <h4 data-testid="modal-file-name" className="fw-bold text-light-black">{file.name}</h4>
            </ModalHeader>

            <div className="files-container p-1">
                { file && file.type === 'image' ?
                    <img data-testid="modal-image-file" src={file.url} alt="image" className={'img-preview-modal c-pointer'}/>
                    :
                    <video data-testid="modal-video-file" className="w-100" controls>
                        <source src={file ? file.url : ''} type={file ? file.mime_type : ''} />
                    </video>
                }
            </div>

            <div className="m-1">
                <Button data-testid="modal-close-button" className="w-100 text-white" color="success"
                        style={{ backgroundColor: '#47a58d' }}
                        onClick={setVisibility}>
                    {t('socialPost.close')}
                </Button>
            </div>
        </Modal>
    ): null;
}

export default ModalComponent;