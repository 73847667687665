import { useQuery } from 'react-query';
import Client from '../services/axiosService';
 

export type LinkPreview = {
    title: string;
    description: string;
    image: string;
    url: string;
}

const getLinkPreview = async (url: string): Promise<LinkPreview> => {
    if(!url) return Promise.reject('No url provided')
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/link-metadata?url=${url}`,
        method: 'get',
    });
    const data = await response.data;
    return data;
}

export const useLinkPreview = (url: string) => {
    return useQuery<LinkPreview>(['link-preview', url], () => getLinkPreview(url), {
        staleTime: 1000 * 60 * 60 * 24,
        cacheTime: 1000 * 60 * 60 * 24 * 2,
        enabled: !!url,
        retry: false,
    });
}