import React, { FunctionComponent, useState, useRef } from "react";
import { Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import SocialPostCommentInput from "./socialCommentInput";
import commentsService from "../../../../services/commentsService";
import { getLikesStr, getCommentsStr } from "../../feedUtils";
import { confirmAlert } from "react-confirm-alert";
import { addNotification } from "../../../../../shared/reducers/notifications/actionTypes";
import "react-confirm-alert/src/react-confirm-alert.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import {
  faHeart as faHeartReg,
  faComment,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";

import FilesDisplay from "../../../common/filesDisplay/filesDisplay";
import { useNavigate } from "react-router-dom";
import { fileData } from "../../createSocialPost";

const renderHtml = require("html-react-parser");

type SocialPostCommentItemProps = {
  comment: any;
  childCount: number;
  index: string;
  rootId: number;
  showRepliesTo: Array<number>;
  likeComment: Function;
  removeComment: Function;
  handleRepliesClick: Function;
  sendReply: Function;
  isReply?: boolean;
};

const SocialPostCommentItem: FunctionComponent<SocialPostCommentItemProps> = ({
  comment,
  childCount,
  index,
  rootId,
  showRepliesTo,
  likeComment,
  removeComment,
  handleRepliesClick,
  sendReply,
  isReply = false,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userInfo = useSelector((state: any) => state.app.user);
  const dispatch = useDispatch();

  const [replyOpen, setReplyOpen] = useState(false);
  const [deleteVisibility, setDeleteVisibility] = useState({ display: "none" });
  const dataContainerRef = useRef(null);

  const replyToComment = (message: string, files: Array<fileData>) => {
    if (!message && files.length == 0) {
      return;
    }
    setReplyOpen(false);
    sendReply(dataContainerRef.current, message.trim(), comment.id, files);
  };

  const deleteComment = () => {
    confirmAlert({
      title: `${t("feed.deleteComment")}`,
      message: `${t("feed.deleteCommentDesc")}`,
      buttons: [
        {
          label: t("general.yes"),
          onClick: () => {
            commentsService.deleteComment(
              comment.id,
              () => {
                removeComment(comment);
              },
              () => {
                dispatch(
                  addNotification({
                    label: `Delete Comment`,
                    text: t("general.errors.errorLoadingData"),
                    type: "danger",
                  })
                );
              }
            );
          },
        },
        { label: t("general.no"), onClick: () => {} },
      ],
      overlayClassName: "zIndex1050",
    });
  };

  const navigateToUser = (contact_id: string, isDeleted: number) => {
    if (isDeleted !== 1) {
      navigate(`/connect/people/${contact_id}`);
    }
  };

  {
    /* 15 is default parent left margin + 48 for image + other for nested */
  }
  const leftMargin = `${(childCount > 0 ? 15 * childCount : 15) + 48}px`;
  return (
    <div
      className={`sp-comment-item position-relative mt-3 mb-3${
        isReply ? "ms-5" : ""
      }`}
      id={`comment-item-${comment.id}`}
      data-testid={`comment-item-${comment.id}`}
    >
      <img
        data-testid="author-image"
        className="user-profile-image rounded-circle thumb48 d-inline-block position-absolute mt-2 cursor-pointer"
        onClick={() =>
          navigateToUser(comment.author.contact_id, comment.author.isDeleted)
        }
        alt={`${comment.author.full_name} profile image`}
        src={comment.author.image_url}
      />

      <div
        className="sp-comment-body mb-3 p-1 px-2 pb-2"
        id={`comment-item-body-${comment.id}`}
        style={{
          marginLeft: leftMargin,
          borderRadius: "10px",
          backgroundColor: "#f4f4f4",
        }}
        ref={dataContainerRef}
        data-id={comment.id}
        data-root={rootId}
        data-level={childCount}
        data-index={index}
        onMouseEnter={(e) => {
          setDeleteVisibility({ display: "inline-block" });
        }}
        onMouseLeave={(e) => {
          setDeleteVisibility({ display: "none" });
        }}
      >
        <div className="my-1">
          <h3
            data-testid="author-fullname"
            className="d-inline-block mb-0 font-primary-text"
          >
            {comment.author.full_name}
          </h3>

          {comment.author.id === userInfo.id ? (
            <FontAwesomeIcon
              icon={faTrashAlt}
              style={{ ...deleteVisibility, color: "#d9534f", float: "right" }}
              className="c-pointer mt-2 me-2"
              onClick={deleteComment}
              data-testid="delete-comment-btn"
            />
          ) : (
            ""
          )}

          <span
            data-testid="comment-timestamp"
            className="d-block mt-n1 mb-2 small font-secondary-text"
          >
            {comment.updated_at_humans}
          </span>
        </div>

        <FilesDisplay
          files={comment.files}
          className="mb-2"
          displayCompact={true}
        />
        {renderHtml(comment.value)}
      </div>
      <Row className="mt-n3 pt-1 ms-5 px-1">
        <Col
          data-testid="like-btn"
          sm={"auto"}
          onClick={(e) =>
            likeComment(dataContainerRef.current, !comment.is_liked)
          }
        >
          <FontAwesomeIcon
            icon={comment.is_liked ? faHeart : faHeartReg}
            style={{ color: comment.is_liked ? "#d9534f" : "inherit" }}
            className="c-pointer"
          />
          <span className="ms-1 me-0 c-pointer font-secondary-text">
            {t("general.like")}
          </span>
        </Col>
        <Col
          data-testid="reply-btn"
          sm={"auto"}
          onClick={() => setReplyOpen(!replyOpen)}
        >
          <FontAwesomeIcon icon={faComment} className="c-pointer" />
          <span
            className={classnames("ms-1 me-0 c-pointer font-secondary-text")}
          >
            {t("general.reply")}
          </span>
        </Col>
        <Col sm={"auto"}>
          {comment.subcomments.length > 0 ? (
            <span
              data-testid="show-replies-btn"
              onClick={() =>
                handleRepliesClick(
                  comment.id,
                  showRepliesTo.indexOf(comment.id) !== -1 ? false : true
                )
              }
              className="c-pointer text-primary"
            >
              {showRepliesTo.indexOf(comment.id) !== -1
                ? t("feed.hideReplies")
                : t("feed.showReplies")}
            </span>
          ) : (
            <div />
          )}
        </Col>
        <Col sm></Col>
        <Col
          data-testid="total-likes-comments"
          sm={"auto"}
          className="text-secondary font-secondary-text"
        >
          {comment.like_count > 0
            ? `${comment.like_count} ${getLikesStr(comment.like_count)}`
            : ""}
          {comment.like_count > 0 && comment.subcomments.length > 0
            ? "  •  "
            : ""}
          {comment.subcomments.length > 0
            ? `${comment.subcomments.length} ${getCommentsStr(
                comment.subcomments.length
              )}`
            : ""}
        </Col>
      </Row>

      {replyOpen ? (
        <SocialPostCommentInput
          className="mt-2 ms-5 ps-2"
          onSubmit={(message: string, filesInfo: Array<fileData>) =>
            replyToComment(message, filesInfo)
          }
        />
      ) : (
        ""
      )}
      {comment.subcomments.length > 0
        ? showRepliesTo.indexOf(comment.id) !== -1
          ? comment.subcomments.map((sub: any, childIndex: string) => (
              <SocialPostCommentItem
                comment={sub}
                childCount={childCount + 1}
                showRepliesTo={showRepliesTo}
                likeComment={likeComment}
                removeComment={removeComment}
                handleRepliesClick={handleRepliesClick}
                sendReply={sendReply}
                rootId={rootId}
                index={`${index}/${childIndex}`}
                isReply={true}
              />
            ))
          : ""
        : ""}
    </div>
  );
};

export default SocialPostCommentItem;
