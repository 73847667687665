import React, { FunctionComponent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AxiosError, AxiosResponse } from 'axios';
import classnames from 'classnames';
import { Col, Row } from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faTimes } from '@fortawesome/pro-light-svg-icons';

import './socialCommentInput.scss';
import socialPostsService from '../../../../../web/services/socialPostsService';
import { fileData } from '../../createSocialPost';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';
import Spinner from "../../../../../web/components/common/spinnerLoad/spinnerLoad";
import ModalComponent from '../../../../../web/components/common/modal/modal';
import DraftEditor from '../../../../../web/components/common/draftEditor/DraftEditor';

type SocialPostCommentInputProps = {
    className?: string,
    onSubmit: Function
    placeholder?: string
}

const SocialPostCommentInput: FunctionComponent<SocialPostCommentInputProps> = ({ className, onSubmit, placeholder }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [textValue, setValue] = useState('');
    const [contentLoading, setContentLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [file, setFile] = useState<fileData>();
    const [isOpen, setIsOpen] = useState(false);
    const [filesInfo, setFilesInfo] = useState<Array<fileData>>([]);
    const [isSubmitContent, setIsSubmitContent] = useState(false);
    const fileButton = useRef<HTMLInputElement>(null);
    const tenant = useSelector((state: any) => state.app.tenant);
    const userInfo = useSelector((state: any) => state.app.user);

    const sendText = () => {
        setIsSubmitContent(true);
    }

    const handleInput = (e: any) => {
        setValue(e.currentTarget.value);
    }

    const keyPress = (e: any) => {
        if (e.key == 'Enter' && !e.shiftKey) {
            sendText();
        }
    }

    const updateEvent = (event: any) => {
        event.target.value = null;
    }

    const triggerInputFile = () => {
        if (fileButton && fileButton.current) {
            fileButton.current.click();
        }
    }

    const saveInS3 = (event: any) => {
        let files = event.target.files;

        if (files.length > 0)
            setContentLoading(true);

        let formData = new FormData();
        let toSend = 0;
        let tooBigFiles = 0;

        for (let i = 0; i < files.length; i++) {
            if (parseInt(((files[i].size / 1024) / 1024).toFixed(2)) <= tenant.uploadMaxSize) {
                setCount(count + 1);
                toSend = toSend + 1;
                formData.append('files[]', files[i]);
            } else {
                tooBigFiles = tooBigFiles + 1;
            }
        }

        if (toSend > 0) {
            socialPostsService.saveSocialPostFile(formData, (resp: AxiosResponse) => {
                setFilesInfo(filesInfo => [...filesInfo, ...resp.data.data]);
                setContentLoading(false);
            }, (err: AxiosError) => {
                setContentLoading(false);
                dispatch(addNotification({ label: t('socialPost.socialPost'), text: t('socialPost.mediaFail'), type: 'danger' }));
            });
        }
        if (tooBigFiles > 0) {
            if (toSend === 0) setContentLoading(false);

            dispatch(addNotification({ label: t('socialPost.socialPost'), text: t('socialPost.mediaTooBig') + tenant.uploadMaxSize + ' MB', type: 'danger' }));
        }
    }

    const render = () => {
        if (filesInfo.length > 0) {
            let content: Array<React.ReactElement> = [];
            filesInfo.forEach(element => {
                content.push(structure(element));
            });

            return <Row className="px-2">{content}</Row>;
        }
    }

    const removeImage = (mediaID: string) => {
        setCount(count - 1);
        setFilesInfo(filesInfo.filter(item => item.id !== mediaID));
    }

    const modalTrigger = (file: fileData) => {
        setFile(file);
        setIsOpen(true);
    }

    const structure = (file: fileData) => {
        if (file.type === 'image') {
            return <Col xs={'4'} data-fileID={file.id} style={{ paddingRight: '0px' }} className={'mb-1 d-flex'}>
                <div className={'image-container'} style={{}}>
                    <img src={file.url} alt="image"
                        className={'img-preview-sp c-pointer'}
                        onClick={() => modalTrigger(file)} />
                </div>
                <div style={{ float: 'right', position: 'relative' }} className={'pb-3'}>
                    <FontAwesomeIcon icon={faTimes}
                        onClick={() => removeImage(file.id)}
                        className={"media-icons c-pointer ms-1"}
                        style={{ color: '#d9534f', fontSize: '16px', float: 'right' }} />
                </div>
            </Col>;

        } if (file.type === 'video') {
            let videoPreview = 'url(' + file.preview + ')';
            return <Col xs={'4'} data-fileID={file.id} style={{ paddingRight: '0px' }} className={'mb-1 d-flex'}>
                <div className={"d-flex justify-content-center align-items-center video-display-preview"}
                    onClick={() => modalTrigger(file)}
                    style={{ backgroundImage: videoPreview }}>
                    <img className="video-preview-icon position-absolute img-responsive cursor-pointer"
                        src="/assets/img/video-preview-icon.svg" alt="video" />
                </div>
                <div style={{ float: 'right' }} className={'pb-3'}>
                    <FontAwesomeIcon icon={faTimes}
                        onClick={() => removeImage(file.id)}
                        className={"media-icons c-pointer ms-1"}
                        style={{ fontSize: '16px', color: '#d9534f', float: 'right' }} />
                </div>
            </Col>;
        } else {
            return <Col xs={'4'} data-fileID={file.id} style={{ paddingRight: '0px' }} className={'mb-1 d-flex'}>
                <a className="doc-preview d-block mb-1 doc-container" href={file.url} target="_blank">
                    <img className="d-inline-block ms-0 img-preview-sp" src={file.preview} />
                </a>
                <div style={{ float: 'right' }} className={'pb-3'}>
                    <FontAwesomeIcon icon={faTimes}
                        onClick={() => removeImage(file.id)}
                        className={"media-icons c-pointer ms-1"}
                        style={{ fontSize: '16px', color: '#d9534f', float: 'right' }} />
                </div>
            </Col>;
        }
    }

    const updateCommentInputHeight = (e: any) => {
        const inputAreaEl: any = document.querySelector('.input-area');
        if (inputAreaEl) {
            const editorRootEl: any = document.querySelector('.DraftEditor-root');
            inputAreaEl.style.height = 'auto';
            inputAreaEl.style.minHeight = '48px';
            inputAreaEl.style.height = `${(editorRootEl ? editorRootEl.height : 0) + 5}px`;
        }
    }

    const handleEditorContentSubmit = (value: string) => {
        onSubmit(value, filesInfo);
        setIsSubmitContent(false);
    }

    return (
        <>
            <div data-testid="social-comment-input" className={classnames("comment-input-comp position-relative", className)}>
                <img data-testid="user-profile-image" src={userInfo.image_url} alt="user-image" className="user-img rounded-circle thumb48 position-absolute" />
                <DraftEditor
                    className='input-area'
                    isSubmitContent={isSubmitContent}
                    handleKeyPress={updateCommentInputHeight}
                    handleEditorContentSubmit={handleEditorContentSubmit}
                    allowSubmitOnEnter
                />
                <span className={"py-0 media-elements position-absolute"}>
                    <input type='file' id='file'
                        name={'upfiles'}
                        ref={fileButton}
                        style={{ display: 'none' }}
                        accept={"application/*, text/*, video/*, image/*, .docx"}
                        onChange={saveInS3}
                        onClick={updateEvent}
                        multiple
                        data-testid="attach-media-btn"
                    />
                    <img 
                        className={"media-icons attachment-icon c-pointer ms-1"} 
                        src="https://cdn.engageesp.com/img/icon/link.svg" 
                        alt="attachment-icon"
                        onClick={triggerInputFile} 
                    />
                </span>
                <span data-testid="send-comment-button" className={classnames("text-primary position-absolute mt-0 c-pointer")} style={{ top: 'calc(1rem - 2px)', right: '1em' }} onClick={sendText}>
                    {t('general.send')}
                    <FontAwesomeIcon icon={faPaperPlane} className="send-icon c-pointer text-primary ms-1"
                        style={{ fontSize: '16px' }}
                    />
                </span>
            </div>
            {isOpen ? <ModalComponent isOpen={isOpen} file={file} setIsOpen={setIsOpen} /> : ''}
            <div className={classnames('files-display-comp-sp', className)} style={{ marginTop: count > 0 ? '40px' : '20px' }}>
                {contentLoading ?
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <Spinner />
                    </div>
                    : ''}
                <div className={classnames("media-container-sp mt-3")}>
                    {count > 0 ? render() : ''}
                </div>
            </div>
        </>
    );
}

export default SocialPostCommentInput;