import React, { FunctionComponent } from 'react';
import { Modal, ModalHeader, Button } from 'reactstrap';
import classnames from 'classnames';
import { FileData } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

type FilePreviewProps = {
    isOpen: boolean,
    toggle: any,
    file: FileData | null,
    modalClass?: string
};

const FilePreview: FunctionComponent<FilePreviewProps> = ({ isOpen, toggle, file, modalClass }) => {
    const { t } = useTranslation();

    return (
        <Modal data-testid="file-preview" isOpen={isOpen} toggle={toggle} className={classnames('file-preview-modal', modalClass)} size="lg">
            <ModalHeader toggle={toggle}>
                <h4 data-testid="file-name" className="fw-bold text-light-black">{ file && file.original }</h4>
            </ModalHeader>

            <div className="preview-container d-flex justify-content-center">
                { file && file.type === 'image' ? 
                    <img data-testid="file-preview-img" src={file.url} />
                : 
                    <video data-testid="file-preview-video" className="w-100" controls>
                        <source src={file ? file.url : ''} type={file ? file.mime_type : ''} />
                    </video>
                }
            </div>

            <div className="m-1">
                <Button data-testid="file-preview-done-btn" className="w-100 text-white" color="success" style={{ backgroundColor: '#47a58d' }} onClick={toggle}>
                    { t('feed.done') }    
                </Button>
            </div>
        </Modal>
    );
}

export default FilePreview;