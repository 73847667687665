export default {
  "please enter your company reference": "Inserisci il riferimento della tua azienda",
  "enter user registration credentials": "Inserisci i tuoi dati qui sotto",
  "enter email": "Inserisci la tua email",
  "enter password": "Inserisci la tua password",
  "repeat password": "Ripeti la password",
  "registration successful": "Registrazione avvenuta con successo.",
  "registration successful instruction": "Un link di conferma a tempo limitato è stato inviato all'e-mail specificata. Segui le istruzioni contenute nell'e-mail per completare la configurazione dell'account. (Controlla la cartella spam se non riesci a vedere l'e-mail)",
  "errors": {
    "domain name can't be empty": "Il nome di dominio non può essere vuoto",
    "domain does not exist": "Il dominio '{{domain}}' non esiste",
    "email can not be empty": "L'email non può essere vuota",
    "password can not be empty": "La password non può essere vuota",
    "custom fields not set": "Non tutti i campi obbligatori sono compilati",
    "passwords dont match": "Le password non coincidono",
    "email is not available": "L'email selezionata non è disponibile",
    "email format is not valid": "Il formato e-mail non è valido",
    "password requirements": "La password deve contenere 8 caratteri, numeri, una lettera maiuscola e una minuscola",
    "User with this email already exists": "Esiste già un utente con i dati che hai inserito",
    "Error loading security information from server": "Errore durante il caricamento delle informazioni di sicurezza dal server. Ricarica la pagina per riprovare.",
    "Page doesnt have data parameters loaded": "La pagina non ha parametri di dati caricati. Prova ad aprire nuovamente il link e-mail."
  },
  "verify": "REGISTRARE",
  "back": "INDIETRO"
};