import React, { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import PageTile from "../customLayout/tileTypes/pageTile";
import { ContentIdParam, ContentType } from "../../../types/types";

const TileView: FunctionComponent = () => {
  const params = useParams<ContentIdParam>();

  return (
    <div data-testid="tile-view-container">
      {params.id && params.id.length && (
        <PageTile
          showSearch={true}
          showFilter={true}
          showSort={true}
          contentType={params.type as ContentType}
          contentId={parseInt(params.id)}
        />
      )}
    </div>
  );
};

export default TileView;
