import commentsProvider from '../providers/commentsProvider';

const socialPostsService = {
    likeComment(commentId: number, isLiked: boolean, success: Function, error?: Function){
        commentsProvider.likeComment(commentId, isLiked, success, error);
    },
    deleteComment(commentId: number, success: Function, error?: Function){
        commentsProvider.deleteComment(commentId, success, error);
    }
}

export default socialPostsService;