import React, { FunctionComponent } from 'react';

import './slider.scss';

type SliderProps = {
    state: boolean,
    onChange?: Function,
    className?: string,
    color?: string;
}

const Slider: FunctionComponent<SliderProps> = (props) => {
    const clickHandler = (e: React.SyntheticEvent) => {
        if(props.onChange)
            props.onChange();
    }

    return (
        <div className={props.className}>
            <label className={'switch'} style={{ width: '45px', height: '20px' }}>
                <input data-testid="toggle-read-btn" type="checkbox" className={'slider-checkbox'} checked={props.state} onChange={clickHandler} />
                <span className={'slider round'} />
            </label>
        </div>
    );
}

export default Slider;