import React, { FunctionComponent, useState, ReactNode, useEffect } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import classNames from 'classnames';
import objectAssign from 'object-assign';

type ScrollbarProps = {
    children?: ReactNode,
    className?: string,
    contentProps?: any,
    onScroll?: Function,
    onMouseEnter?: Function,
    rightScroll?: boolean,
    noScrollX?: boolean
}

const ScrollbarComponent: FunctionComponent<ScrollbarProps> = ({ children, className = '', contentProps, onScroll, onMouseEnter, rightScroll = true, noScrollX = false }) => {
    const [wrapperClasses, setWrapperClasses] = useState(className);
    useEffect(() => {
        setWrapperClasses(className);
    }, [className]);

    const [scrollOpacity, setScrollOpacity] = useState(0);
    const [scrollOpacityTimeout, setScrollTimeout] = useState<any>();
    const hideScroll = () => {
        setScrollOpacity(0);
    }
    const scrollVisibilityUpdate = (e: any) => {
        setScrollOpacity(1);
        if(scrollOpacityTimeout){
            clearTimeout(scrollOpacityTimeout);
        }
        setScrollTimeout(setTimeout(hideScroll, 2000));
    }

    const onScrollEvent = (e: any) => {
        scrollVisibilityUpdate(e);
        if(onScroll)
            onScroll(e);
    }

    const onMouseEnterEvent = (e: any) => {
        scrollVisibilityUpdate(e);
        if(onMouseEnter)
            onMouseEnter(e);
    }

    return (
        <Scrollbar data-testid="scroll-bar" onScroll={onScrollEvent} onMouseEnter={onMouseEnterEvent} noScrollX={noScrollX}
            removeTrackXWhenNotUsed contentProps={contentProps}
            wrapperProps={{
                renderer: renderProps => {
                    const { elementRef, style, ...restProps } = renderProps;
                    const newStyle = objectAssign({}, style, { right: 0 });
                    return <span {...restProps} style={newStyle} ref={elementRef} className={classNames("h-100 ", wrapperClasses)} />;
                }
            }}
            trackYProps={{
                renderer: renderProps => {
                    const { elementRef, style, ...restProps } = renderProps;
                    let newStyle = objectAssign({}, style, { opacity: scrollOpacity, top: 0, width: '7px', borderRadius: '7px', height: '100%' },
                        rightScroll ? { right: 0 } : { left: 0 });
                    return <span {...restProps} ref={elementRef} className="trackY" style={newStyle} />;
                }
            }}
        >
            { children }
        </Scrollbar>
    );
}

export default ScrollbarComponent;