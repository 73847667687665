import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { SubNavLinks } from './interface/SubNavLinks';

// States
export class UserReducerState {
    constructor(id: number, full_name: string, image_url: string, jobTitle: string, token: string, tenantId: string, isAuth: boolean) {
        this.id = id;
        this.full_name = full_name;
        this.image_url = image_url;
        this.jobTitle = jobTitle;
        this.token = token;
        // this.tenantId = tenantId;
        this.isAuthenticated = isAuth;
    }
    id: number;
    full_name: string;
    image_url: string;
    jobTitle: string;
    token: string;
    // tenantId: string;
    isAuthenticated: boolean;
}

export type ThreadBranding = {
    receiver_bubble_colour: string | null;
    receiver_text_colour: string | null;
    sender_bubble_colour: string | null;
    sender_text_colour: string | null;
};

export class BrandingReducerState {
    constructor(
        web_navbar_background: string,
        web_navbar_foreground: string,
        web_menubar_background: string,
        web_menubar_foreground: string,
        web_menubar_active_foreground: string,
        web_menubar_active_background: string,
        web_sidebar_foreground: string,
        web_sidebar_background: string,
        cookies_link: string,
        cookies_header_colour: string,
        cookies_button_background: string,
        cookies_text: string,
        menu_items: SideNavItem[],
        messaging: ThreadBranding
    ) {
        this.web_navbar_background = web_navbar_background;
        this.web_navbar_foreground = web_navbar_foreground;
        this.web_menubar_background = web_menubar_background;
        this.web_menubar_foreground = web_menubar_foreground;
        this.web_menubar_active_foreground = web_menubar_active_foreground;
        this.web_menubar_active_background = web_menubar_active_background;
        this.web_sidebar_foreground = web_sidebar_foreground;
        this.web_sidebar_background = web_sidebar_background;
        this.cookies_link = cookies_link;
        this.cookies_header_colour = cookies_header_colour;
        this.cookies_button_background = cookies_button_background;
        this.cookies_text = cookies_text;
        this.menu_items = [];
        this.messaging = messaging;
    }
    web_navbar_background: string;
    web_navbar_foreground: string;
    web_menubar_background: string;
    web_menubar_foreground: string;
    web_menubar_active_foreground: string;
    web_menubar_active_background: string;
    web_sidebar_foreground: string;
    web_sidebar_background: string;
    cookies_link: string;
    cookies_header_colour: string;
    cookies_button_background: string;
    cookies_text: string;
    menu_items: SideNavItem[];
    messaging: ThreadBranding;
}

export type TYBrandingData = {
    tycIcon: string;
    tycText: TYBrandingTextData;
};

export type TYBrandingTextData = {
    btn: any;
    header: any;
    menu: any;
    pn: any;
};

export type TenantSessionInfo = {
    session_timeout: number;
    before_end: number;
};

export class TenantReducerState {
    constructor(
        title: string,
        logo: string,
        feedLogo: string,
        ref: string,
        bodyTemplate: string,
        shareSubject: string,
        messagesEnabled: boolean,
        allowP2P: number,
        allowPublic: boolean,
        allowCookies: boolean,
        p2pTypeDefault: string,
        session: TenantSessionInfo,
        tyBranding: TYBrandingData,
        webPNStatus: string
    ) {
        this.title = title;
        this.logo = logo;
        this.feedLogo = logo;
        this.ref = ref;
        this.bodyTemplate = bodyTemplate;
        this.webPNStatus = webPNStatus;
        this.shareSubject = shareSubject;
        this.messagesEnabled = messagesEnabled;
        this.allowP2P = allowP2P;
        this.allowPublic = allowPublic;
        this.allowCookies = allowCookies;
        this.p2pTypeDefault = p2pTypeDefault;
        this.session = session;
        this.tyBranding = tyBranding;
    }
    title: string;
    logo: string;
    feedLogo: string;
    ref: string;
    bodyTemplate: string;
    webPNStatus: string;
    shareSubject: string;
    messagesEnabled: boolean;
    allowP2P: number;
    allowPublic: boolean;
    allowCookies: boolean;
    p2pTypeDefault: string;
    session: TenantSessionInfo;

    tyBranding: TYBrandingData;
}

// BindingModels
export class LoginFormModel {
    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }
    username: string;
    password: string;
}

export class UserAuthModel {
    constructor(id: string, tenantRef: string, username: string, password: string) {
        this.id = id;
        this.tenantRef = tenantRef;
        this.username = username;
        this.password = password;
    }
    id: string; // tenant id
    tenantRef: string;
    username: string;
    password: string;
}

export class UserCredentials {
    constructor(name: string, password: string) {
        this.username = name;
        this.password = password;
    }
    username: string;
    password: string;
}

export class TYCardLoadInfo {
    constructor(cardsResp: TYCardResponse, loader: Function, method: Function) {
        this.awardsInfo = cardsResp;
        this.arrayLoader = loader;
        this.serverMethod = method;
    }
    awardsInfo: TYCardResponse;
    arrayLoader: Function;
    serverMethod: Function;
}

export class TYCardResponse {
    constructor(data: TYCard[], link: any, meta: any) {
        this.data = data;
        this.link = link;
        this.meta = meta;
    }
    data: TYCard[];
    link: any;
    meta: any;
}

export class AwardsLoadInfo {
    constructor(cardsResp: AwardsResponse, loader: Function, method: Function) {
        this.awardsInfo = cardsResp;
        this.arrayLoader = loader;
        this.serverMethod = method;
    }
    awardsInfo: AwardsResponse;
    arrayLoader: Function;
    serverMethod: Function;
}

export class AwardsResponse {
    constructor(data: Award[], link: any, meta: any) {
        this.data = data;
        this.link = link;
        this.meta = meta;
    }
    data: Award[];
    link: any;
    meta: any;
}

export class NewAwardInfo {
    constructor(recipientMessage: string, managerMessage: string, isVisible: boolean) {
        this.recipientMessage = recipientMessage;
        this.managerMessage = managerMessage;
        this.isVisible = isVisible;
    }
    recipientMessage: string;
    managerMessage: string;
    isVisible: boolean;
}

export type Award = {
    id: number;
    post_id: number;
    message: string;
    image_url: string;
    image_title: string;
    created_at: Date;
    updated_at: Date;
    receiver: TYCardUser;
    sender: TYCardUser;
    isUserManager: boolean;
    manager_message: string;
    likes: Array<any>;
    comments: Array<any>;
    comments_count: number;
    type: string;
};

export enum AwardsFilterMode {
    received,
    sent,
    all,
    team,
}

export type AwardTemplate = {
    id: number;
    title: string;
    description: string;
    url: string;
    created_at: Date;
    updated_at: Date;
};

export class Tenant {
    constructor() {
        this.id = '';
        this.logo = '';
        this.reference = '';
        this.name = '';
        this.fields = '';
        this.questions_number = 0;
        this.is_registration_enabled = false;
    }
    id: string;
    logo: string;
    reference: string;
    name: string;
    fields: string;
    questions_number: number;
    is_registration_enabled: boolean;
}

export class LoginConfigs {
    constructor() {
        this.id = '';
        this.foreground_logo = '';
        this.background_logo = '';
        this.reference = '';
        this.name = '';
        this.regFields = '';
        this.is_registration_enabled = false;
        this.background_colour = '';
        this.textbox_foreground_colour = '';
        this.textbox_background_colour = '';
        this.textbox_border_colour = '';
        this.username_field_placeholder = '';
        this.username_label = '';
        this.password_field_placeholder = '';
        this.password_label = '';
        this.button_foreground_colour = '';
        this.button_background_colour = '';
        this.dots_colour = '';
        this.dots_colour_active = '';
        this.forgot_password_link = '';
        this.forgot_password_link_colour = '';
        this.remember_me_link_colour = '';
        this.label_colour = '';
        this.contact_support_link_colour = '';
        this.placeholder_colour = '';
        this.button_colour_hover = '';
        this.welcome_text = '';
        this.support_email = '';
        this.multi_lang = true;
    }
    id: string;
    foreground_logo = '';
    background_logo: string;
    reference: string;
    name: string;
    regFields: string;
    is_registration_enabled: boolean;
    background_colour: string;
    textbox_foreground_colour: string;
    textbox_background_colour: string;
    textbox_border_colour: string;
    username_field_placeholder: string;
    username_label: string;
    password_field_placeholder: string;
    password_label: string;
    button_foreground_colour: string;
    button_background_colour: string;
    dots_colour: string;
    dots_colour_active: string;
    forgot_password_link: string;
    forgot_password_link_colour: string;
    remember_me_link_colour: string;
    label_colour: string;
    contact_support_link_colour: string;
    placeholder_colour: string;
    button_colour_hover: string;
    welcome_text: string;
    support_email: string;
    multi_lang: boolean;
}

export type TYCardUser = {
    id: number;
    full_name: string;
    image_url: string;
};

export type TYCard = {
    id: number;
    message: string;
    image_url: string;
    created_at: Date;
    receiver: TYCardUser;
    sender: TYCardUser;
};

export type TYCardTypeCount = {
    allCards: number;
    sentCards: number;
    inboxCards: number;
};

export enum TYCardFilterMode {
    received,
    sent,
    all,
}

export type SideNavItem = {
    id: number;
    label: string;
    class: IconProp;
    path: string;
    title: string;
    is_visible_title: boolean;
    icon_class: string;
    custom_icon: string;
    route_url: string;
    children: SubNavLinks[] | null;
};

export type NotificationItem = {
    id: number;
    title: string;
    message: string;
    dateString: string;
    url: string;
    is_read: boolean;
    additionalData: number;
};

export type EmailCheckInfo = {
    tenant_id: string;
    email: string;
};

export type Question = {
    id: number;
    question: string;
};

export type SecurityData = {
    questions: Array<Question>;
    password_description: string;
    password_regex: RegExp;
};

export type FeedPageData = {
    items: Array<FeedItem>;
};

export type FeedItem = {
    type: string;
    pageType: number;
    reason: string;
    comment: any;
    pns: any;
    model: any;
    page: any;
    publisher: any;
    author: any;
    likes: number;
    comments: number;
    published_at: string;
    updated_at: number;
};

export type FeedItemModel = {
    id: number;
    sender: any;
    image_url: string;
    title: string;
    message: string;
    created_at: any;
    page: any;
};

export type FeedSocialPost = {
    model: any;
    additional_model?: any;
    type: string;
    reason: string;
    author: any;
    is_liked?: boolean;
    likes: Array<any>;
    created_at_humans: string;
    files: Array<any>;
    repost_parent?: any;
};

export type GroupPost = {
    id: number;
    title: string;
    description: string;
    url: string;
    image_url: string;
    can_post: boolean;
    is_member: boolean;
}

export type LatestComment = {
    content_id: number;
    author: any;
    value: any;
    page: any;
    id?: number;
    created_at_humans: string;
    updated_at_humans: string;
};

export type FeedPageFooterData = {
    shareSubject: string;
    bodyTemplate: string;
    messagesEnabled: boolean;
};

export type UsersListItem = {
    id: number;
    full_name: string;
    job_title: string;
    image_url: string;
};

export type CollapseHeightData = {
    minHeight: number;
    maxHeight: number;
};

export type FileData = {
    id?: number;
    name: string;
    original: string;
    preview: string;
    type: string;
    mime_type: string;
    url: string;
};

export type Notification = {
    id: string;
    label: string;
    text: string;
    type: string;
};

export type NotificationItemType = {
    id: string;
    label: string;
    text: string;
    type: string;
    meta: {
        current_page: number;
        last_page: number;
        total_unread: number;
    };
    data: [NotificationItem];
};

export type NotificationModel = {
    label: string;
    text: string;
    type: string;
};

export type Layout = {
    background_colour_web: string;
    background_image_web: string;
    icon_image_web: string;
    id: number;
    label_colour_web: string;
    order: number;
    subtitle: string;
    title: string;
    web_page_layout: string;
};

export type AllItem = {
    id: number;
    full_name: string;
    job_title: string;
    image_url: string;
};

export type LocationItem = {
    id: number;
    list_title: string;
    list_subtitle: string;
    location_name: string;
};

export type ContactItem = {
    id: string;
    contact_id: string;
    full_name: string;
    job_title: string;
    image_url: string;
    awards: ContactAward[];
};

export type PostItemAuthor = {
    full_name: string;
    image_url: string;
    contact_id: string;
    id: number;
};

export type SubcommentsData = {
    id: number;
    subcomments: [];
};

export type PostComment = {
    id: number;
    subcomments: SubcommentsData[];
    last_comment: PostComment;
};

export type PostItemModel = {
    id: number;
    author: PostItemAuthor;
    message: string;
    created_at_humans: string;
    manager_name: string;
    manager_message: string;
    is_liked: boolean;
    likes: number;
    comments: number;
    post_id: number;
    last_comment: PostComment;
    files: [];
};

export type PostItem = {
    model: any;
    is_liked: boolean;
    likes: Array<any>;
    like_count: number;
    id: number;
    type: string;
    reason: string;
    created_at_humans: string;
    author: any;
    files: Array<any>;
    unavailable_user: Array<number>;
};

export type SocialModelData = {
    comments: number;
    post_id: number;
    id: number;
    last_comment: LastComments;
};

export type LastComments = {
    subcomments: SubcommentsData[];
    id: number;
    last_comment: LastComments;
};

export type SocialPostItemData = {
    model: SocialModelData;
    is_liked: boolean;
    like_count: number;
};

export type PostComments = {
    id: number;
    last_comment: LastComments;
    subcomments: SubcommentsData[];
};

export type LocationDetailsData = {
    id: number;
    title: string;
    longitude: string;
    latitude: string;
    address: string;
    description: string;
    dynamic_attributes: DynamicAttributesData[];
    users_count: number;
};

export type DynamicAttributesData = {
    attribute_type: string;
    value: string;
    icon_class: string;
    title: string;
    editable: number;
    id: number;
    options: any;
};

export type OrgChartData = {
    contact_id: number;
    department: string;
    id: number;
    image_url: string;
    location: string;
    name: string;
    title: string;
    children: OrgChartData[];
};

export type ContactAttrDetails = [DynamicAttributesData];
export type ContactUserData = {
    department: string;
    is_visible_department: boolean;
    full_name: string;
    image_url: string;
    job_title: string;
    is_visible_title: boolean;
    id: number;
    header_image_url: string;
    default_header_img: string;
    can_edit_header: boolean;
    awards: ContactAward[];
    has_manager: boolean;
    contact_id: number;
};

export type ContactUserMainDetails = {
    allow_messages: boolean;
    bio: string;
    date_of_birth: string;
    dynamic_attributes: [DynamicAttributesData];
    email: string;
    followed: boolean;
    followers: number;
    following: number;
    id: number;
    is_favorite: boolean;
    location: string;
    location_contact_id: number;
    location_id: number;
    manager: {
        full_name: string;
        image_url: string;
    };
    message_link: string;
    snoozed: boolean;
    snoozed_until: string;
    social_post_view: boolean;
    start_date: string;
};

export type ContactAward = {
    id?: number;
    title: string;
    page_url: string;
    page_id: number;
    text: string;
    badge_image: string;
    preview_image: string;
    created_at: string;
    expired_at: string;
};

export type ContentData = {
    id: number;
    content_type: number;
    type: string;
    counts: {
        likes: number;
        comments: number;
        views: number;
    };
    image: string;
    title: string;
    subtitle: string;
    contents: string;
    created_at: string;
    updated_at: string;
    timestamp: number;
    contentPage: {
        id: number;
        title: string;
        preview_text: string;
        preview_image: string;
        allow_comments: boolean;
        allow_likes: boolean;
        allow_share: boolean;
        published_at: string;
        pageUrl: string;
        is_liked: false;
        url: string;
        publisher?: Alias;
    };
    categoryUrl: string;
    folderColour: string;
    web_layout: string;
    type_name: string;
};

export type Alias = {
    entity: string;
    id: string;
    image: string;
    name: string;
    is_round: boolean;
};

export type LayoutData = {
    data: {
        breadcrumbs: Breadcrumb[];
        containers: LayoutContainer[];
        templateId: number;
        activity_id: string;
    };
};

export type Breadcrumb = {
    label: string;
    url: string;
};

export type BannerSlide = {
    img: string;
    path: string;
    title: string;
    id: string;
};

export type BannerData = {
    pos: number;
    slides: BannerSlide[];
    speed: number;
};

export type LayoutContainer = {
    banner: BannerData;
    comment: {
        pos: number;
        comments: RecentComment[];
    };
    html: {
        pos: number;
        content: string;
    };
    content: {
        pos: number;
        rootId?: string;
        searchBar: string;
        filters: string;
        sorting: string;
        isSection: string;
    };
    sections: {
        filters: boolean;
        isSection: boolean;
        pos: number;
        rootId?: string;
        searchBar: boolean;
        sorting: boolean;
    };
    feed: {
        pos: number;
    };
};

export type RecentComment = {
    author: {
        full_name: string;
        id: number;
        image_url: string;
        contact_id: string;
    };
    content_id: number;
    created_at: string;
    files: [];
    id: number;
    updated_at: string;
    updated_at_humans: string;
    value: string;
};

export type ContentIdParam = {
    type: string;
    id: string;
};

export enum ContentType {
    Section = 'section',
    Folder = 'category',
    Page = 'page',
}

export type EditUserData = {
    date_of_birth: string;
    date_of_birth_editable: boolean;
    date_of_birth_visible: boolean;
    department: string;
    department_editable: boolean;
    department_visible: boolean;
    dynamic_attributes: [DynamicAttributesData];
    editable: {
        [key: string]: number;
    };
    email: string;
    email_editable: boolean;
    email_visible: boolean;
    id: number;
    start_date: string;
    start_date_editable: boolean;
    start_date_visible: boolean;
    user: LoggedUser;
    description: string;
    [key: string]: any;
};

export type LoggedUser = {
    awards: Array<any>;
    can_edit_header: boolean;
    contact_id: number;
    default_header_img: string;
    first_name: string;
    full_name: string;
    has_manager: boolean;
    header_image_url: string;
    id: number;
    image_url: string;
    is_editable_firstName: boolean;
    is_editable_lastName: boolean;
    is_editable_title: boolean;
    is_visible_title: boolean;
    job_title: string;
    last_name: string;
    [key: string]: any;
};

export type AppReducerState = {
    mark_as_read_message: boolean;
};
