import React from 'react';
import { Box, Skeleton, SxProps, Typography } from '@mui/material';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useResizeObserver } from '../../hooks/useResizeObserverHook';

export type CommunityCardProps = {
    title: string;
    description: string;
    members: number;
    containersSx?: SxProps;
    img: string;
    imgAlt?: string;
    id: number;
    handleClick: () => void;
    membersClick: (id: number) => void;
    loading: boolean;
};

export const CommunityCard = (props: CommunityCardProps) => {
    const { title, description, members, containersSx, img, imgAlt, handleClick, membersClick, id, loading } = props;
    const { t } = useTranslation();
    const [containerRef, containerObj] = useResizeObserver();
    const extraSmallContainerSize: number = 450;
    const extraSmallContainer: boolean = containerObj?.width && containerObj?.width < extraSmallContainerSize;

    if (loading) {
        return (
            <Box
                sx={{
                    backgroundColor: '#fff',
                    boxShadow: '0px 4px 4px 0px #00000026',
                    height: '184px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderRadius: '8px',
                    ...containersSx,
                    marginBottom: '18px',
                    color: '#000',
                    cursor: 'pointer',
                }}
                data-testid='community-group-card-loading'
            >
                <Box
                    sx={{
                        margin: '12px 20px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Skeleton variant='text' width={400} height={40} />
                        <Skeleton variant='text' width={400} height={40} />
                        <Skeleton variant='text' width={400} height={40} />
                        <Skeleton
                            variant='text'
                            width={100}
                            height={40}
                            sx={{
                                marginTop: '10px',
                            }}
                        />
                    </Box>
                </Box>
                <Box>
                    <Skeleton variant='rectangular' width={300} height={184} />
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: '#fff',
                boxShadow: '0px 4px 4px 0px #00000026',
                height: '184px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                borderRadius: '8px',
                ...containersSx,
                marginBottom: '18px',
                color: '#000',
                cursor: 'pointer',
            }}
            onClick={(e) => {
                e.stopPropagation();
                handleClick();
            }}
            data-testid='community-group-card'
            ref={containerRef}
        >
            <Box
                sx={{
                    margin: '12px 20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                }}
            >
                <Box
                    sx={{
                        marginX: '4px',
                    }}
                >
                    <Box sx={{ fontSize: '24px', fontWeight: '600' }}>{title}</Box>
                    <Box
                        sx={{
                            fontWeight: '300',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {description}
                    </Box>
                </Box>
                <Box
                    sx={{
                        marginY: '4px',
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '5px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginX: '4px',
                            cursor: 'pointer',
                            width: 'fit-content',
                            ':hover': {
                                borderBottom: '0.5px solid #000000',
                            },
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            membersClick(id);
                        }}
                    >
                        <FontAwesomeIcon icon={faUsers} fontSize={'16px'} />
                        <Typography sx={{ fontSize: '14px' }}>
                            {members} {t('general.members').toLocaleLowerCase()}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    aspectRatio: '16/9',
                    display: extraSmallContainer ? 'none' : 'block',
                }}
            >
                <img
                    src={img}
                    alt={imgAlt}
                    style={{
                        height: '100%',
                        width: '100%',
                        borderTopRightRadius: '8px',
                        borderBottomRightRadius: '8px',
                    }}
                />
            </Box>
        </Box>
    );
};
