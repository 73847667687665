import React, { FunctionComponent } from 'react';
import { CustomTemplateOne } from '../../modules/CustomTemplateOne/CustomTemplateOne';
import { CustomTemplateTwo } from '../../modules/CustomTemplateTwo/CustomTemplateTwo';
import { CustomTemplateThree } from '../../modules/CustomTemplateThree/CustomTemplateThree';
import { CustomTemplateFour } from '../../modules/CustomTemplateFour/CustomTemplateFour';
import { CustomTemplateFive } from '../../modules/CustomTemplateFive/CustomTemplateFive';
import { CustomTemplateSix } from '../../modules/CustomTemplateSix/CustomTemplateSix';
import { CustomTemplateSeven } from '../../modules/CustomTemplateSeven/CustomTemplateSeven';
import { LayoutContainer, ContentType } from '../../../types/types';
import Banner from '../customLayout/banner';
import HtmlContent from '../customLayout/htmlContent';
import RecentComments from '../customLayout/recentComments';
import PageTile from '../customLayout/tileTypes/pageTile';
import Sections from '../customLayout/sections';
import FeedPage from '../feedPage/feedPage';
import { useParams } from 'react-router-dom';
import { ContentIdParam } from '../../../types/types';

type CustomLayoutProps = {
    templateId: number;
    components: any[];
};

const CustomLayout: FunctionComponent<CustomLayoutProps> = ({ templateId, components }) => {
    const addFixedProp = (pos: number, templateId: number): boolean => {
        switch (templateId) {
            case 7:
                return pos !== 3;
            case 6:
                return true;
            case 5:
                return true;
            case 4:
                return true;
            case 3:
                return pos !== 2;
            case 2:
                return pos !== 2;
            case 1:
                return pos !== 3;
            default:
                return true;
        }
    };
    const params = useParams<ContentIdParam>();

    const getLayoutComponents = (containers: LayoutContainer[], templateId: number) => {
        let components: any[] = [];
        containers.forEach((container) => {
            if (container.banner) {
                const banner = container.banner;
                components[banner.pos] = <Banner data={banner} />;
            } else if (container.html) {
                const html = container.html;
                components[html.pos] = <HtmlContent content={html.content} />;
            } else if (container.comment) {
                const comments = container.comment;
                const latestComments = comments.comments;
                components[comments.pos] = <RecentComments key='comments' data={latestComments} />;
            } else if (container.content) {
                const content = container.content;
                let contentType = params.type ? (params.type as ContentType) : ContentType.Section;
                let contentId = params.id && params.id.length ? parseInt(params.id) : 0;
                if (content.rootId != null && content.rootId != '') {
                    contentType = content.isSection && content.isSection.toLowerCase() === 'true' ? ContentType.Section : ContentType.Folder;
                    contentId = parseInt(content.rootId);
                }
                const showSearch = content.searchBar != null && content.searchBar.toLowerCase() === 'true';
                const showFilter = content.filters != null && content.filters.toLowerCase() === 'true';
                const showSort = content.sorting != null && content.sorting.toLowerCase() === 'true';
                if (contentId && contentId > 0) {
                    components[content.pos] = (
                        <PageTile
                            showSearch={showSearch}
                            showFilter={showFilter}
                            showSort={showSort}
                            contentType={contentType}
                            contentId={contentId}
                            fixedContainer={addFixedProp(+content.pos, templateId)}
                        />
                    );
                }
            } else if (container.sections) {
                const sections = container.sections;
                components[sections.pos] = <Sections key='section' sectionsId={`${sections.rootId}`} />;
            } else if (container.feed) {
                const feed = container.feed;
                if (!feed) return;
                components[feed.pos] = <FeedPage customLayoutFeed={true} fixedContainer={addFixedProp(+feed.pos, templateId)} />;
            }
        });

        return components;
    };

    const layoutComponents = getLayoutComponents(components, templateId);

    switch (templateId) {
        case 7:
            return <CustomTemplateSeven components={layoutComponents} />;
        case 6:
            return <CustomTemplateSix components={layoutComponents} />;
        case 5:
            return <CustomTemplateFive components={layoutComponents} />;
        case 4:
            return <CustomTemplateFour components={layoutComponents} />;
        case 3:
            return <CustomTemplateThree components={layoutComponents} />;
        case 2:
            return <CustomTemplateTwo components={layoutComponents} />;
        default:
            return <CustomTemplateOne components={layoutComponents} />;
    }
};

export default CustomLayout;
