export default {
    "please enter your company reference": "Por favor insira a referência da sua empresa",
    "enter user registration credentials": "Por favor insira seus dados abaixo",
    "enter email": "Por favor insira o seu email",
    "enter password": "Por favor insira a sua palavra-passe",
    "repeat password": "Por favor repita a sua palavra-passe",
    "registration successful": "Registo concluído com sucesso.",
    "registration successful instruction": "Foi enviada uma hiperligação de confirmação, que expirará em breve, para o email que nos indicou. Por favor siga as instruções no email para completar a configuração da sua conta. (Por favor procure na pasta de spam se não conseguir encontrar o email)",
    "errors": {
      "domain name can't be empty": "O nome de domínio não pode estar vazio",
      "domain does not exist": "Domínio '{{domain}}' não existe",
      "email can not be empty": "O campo “Email” não pode ficar em branco",
      "password can not be empty": "O campo “Palavra-passe” não pode ficar em branco",
      "custom fields not set": "Não preencheu todos os campos obrigatórios",
      "passwords dont match": "As palavras-passe não combina",
      "email is not available": "O email selecionado não está disponível",
      "email format is not valid": "O formato do email não é válido",
      "password requirements": "A palavra-passe deve conter 8 caracteres, números, uma letra maiúscula e uma minúscula",
      "User with this email already exists": "Já existe um utilizador com os dados que inseriu",
      "Error loading security information from server": "Erro ao carregar informação de segurança do servidor. Por favor refresque a página para tentar novamente.",
      "Page doesnt have data parameters loaded": "A página não foi encontrada / Por favor tente abrir novamente o link do email."
    },
    "verify": "REGISTAR",
    "back": "RETROCEDER"
};