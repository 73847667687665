import { combineReducers } from "redux";

import UserReducer from './user/reducer';
import BrandingReducer from './branding/reducer';
import TenantReducer from './tenant/reducer';
import NotificationsReducer from './notifications/reducer';
import AppReducer from './app/reducer';

const reducers = combineReducers({
    user: UserReducer,
    branding: BrandingReducer,
    tenant: TenantReducer,
    notifications: NotificationsReducer,
    app: AppReducer
});

export default reducers;