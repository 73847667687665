import registrationProvider from '../providers/registrationProvider';

import { EmailCheckInfo } from '../../types/types';

const registrationService = {
    registrateUser(emailInfo: any, success: Function, error?: Function) {
        registrationProvider.registrateUser(emailInfo, success, error);
    },

    checkEmailAvailability(emailInfo: EmailCheckInfo, success: Function, error?: Function) {
        registrationProvider.checkEmailAvailability(emailInfo, success, error);
    },

    getInfo(tenantRef: string, success: Function, error?: Function) {
        registrationProvider.getInfo(tenantRef, success, error);
    }
}

export default registrationService;