export default {
  "menus": {
    "home": "Home",
    "feed": "Mangimi",
    "messages": "Messaggi",
    "connect": "Connetti",
    "awards": "Premi",
    "settings": "Impostazioni e privacy",
    "profile": "Profilo",
    "notifications": "Notifiche",
    "change password": "Cambia password",
    "signOut": "Esci",
    "mark as read": "Segna come letto"
  },
  "settingOptions": {
    "title": "Impostazioni sulla privacy",
    "content": "I cookie sono piccoli file di testo che vengono salvati sul tuo computer o dispositivo mobile quando visiti un sito o utilizzi un'app. Sono ampiamente utilizzati per far funzionare siti Web e app, o in modo più efficiente, nonché per fornire informazioni ai proprietari del sito/app. La tabella seguente spiega i cookie che utilizziamo e perché.",
    "subQuestion": "Quali cookie utilizziamo?",
    "subText": "Per maggiori dettagli, consulta il nostro",
    "subTextDefault": "Per maggiori dettagli, consulta la nostra politica sulla privacy e sui cookie",
    "necessaryTitle": "Strettamente necessario",
    "necessary": "I cookie necessari sono essenziali per la funzionalità e la sicurezza principali dell'app. Se sei d'accordo, vogliamo utilizzare i cookie anche per",
    "performanceTitle": "Cookie prestazionali",
    "notificationSettings": "Impostazioni di notifica",
    "emails": "Email",
    "p2pEmailsLabel": "Inviami notifiche e-mail per {{p2pLabel}}",
    "performance": {
      "firstLine": "• Migliora le prestazioni dell'App",
      "secondLine": "• Comprendere come viene utilizzata l'App",
      "thirdLine": "• Ottimizzazione dei contenuti"
    },
    "analyticsTitle": "Cookie analitici",
    "analytics": {
      "firstLine": "• Migliora l'esperienza dell'app",
      "secondLine": "• Personalizza i servizi"
    },
    "save": "Salva preferenze",
    "error": {
      "label": "Errore",
      "text": "C'è stato un errore, riprova più tardi."
    }
  },
  "type search field": "Digita e premi Invio..."
};