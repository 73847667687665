import Client from "../services/axiosService";
import axios from "axios";

import { UserAuthModel } from "../../types/types";
import { AxiosResponse, AxiosError } from "axios";

const authProvider = {
  authenticateUser(
    loginModel: UserAuthModel,
    tenantRef: string,
    success: Function,
    error?: Function
  ) {
    // Client(false).request({
    //     url: process.env.REACT_APP_CMS_API_URL+'/react/login',
    //     method: 'post',
    //     data: loginModel
    // })
    //     .then((resp: AxiosResponse) => {
    //         success(resp);
    //     }).catch((err: AxiosError) => {
    //         if(error){
    //             error(err);
    //         }
    //     });

    // Switched to the cookie session
    axios
      .post(
        `${process.env.REACT_APP_CMS_API_URL}/react/${tenantRef}/login`,
        loginModel
      )
      .then((resp) => {
        success(resp);
      })
      .catch((err) => {
        if (error) error(err);
      });
  },

  authTenant(
    target: string,
    getByDomain: boolean,
    success: Function,
    error?: Function
  ) {
    Client(false)
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/api/web-login-config?${
          getByDomain ? "domain" : "reference"
        }=${target}`,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  getUserTenantAndBrandingInfo(success: Function, error?: Function) {
    Client(false)
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/user-data`,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  getTenantData(success: Function, error?: Function) {
    Client(false)
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/tenant-data`,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  locale(langCode: string, success: Function, error?: Function) {
    Client(false)
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/api/locale`,
        method: "post",
        data: {
          langCode: langCode,
        },
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};

export default authProvider;
