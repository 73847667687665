import Client from '../../services/axiosService';

/**
 * @description: Fetches threads from the API and returns the data as a promise
 * @param search : string | null
 * @returns : Promise<ThreadListInterface | null | undefined>
 */
export const fetchThreads = async (search: string | null, archived: boolean = false, pageParam: number) => {
    const url = search
        ? `react/messages/threads?search=${search}&archived=${archived}&page=${pageParam}`
        : `react/messages/threads?archived=${archived}&page=${pageParam}`;
    return (
        await Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/${url}`,
            method: 'get',
        })
    ).data;
};

export const fetchMessages = async (id: string | undefined, pageParam: number) => {
    if (!id) return Promise.resolve(null);
    return (
        await Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${id}/messages?page=${pageParam}`,
            method: 'get',
        })
    ).data;
};

export const fetchParticipants = async (id: string | undefined, page: number, search: string) => {
    if (!id) return Promise.resolve(null);
    return (
        await Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${id}/members?page=${page}&search=${search}`,
            method: 'get',
        })
    ).data;
};

export const fetchContacts = async (debouncedContactSearchQuery: string | null, displayCreateChat: boolean, pageParam: number) => {
    if (!displayCreateChat) return Promise.resolve(null);
    let url = `${process.env.REACT_APP_CMS_API_URL}/json/users`;
    return (
        await Client().request({
            url: debouncedContactSearchQuery ? url + `/${debouncedContactSearchQuery}?page=${pageParam}` : url + `?page=${pageParam}`,
            method: 'get',
        })
    ).data;
};
