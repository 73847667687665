import Client from "../services/axiosService";
import { AxiosResponse, AxiosError } from "axios";
import { ContentType } from "~/types/types";

const customLayoutProvider = {
  getLayoutData(
    type: ContentType,
    id: number,
    success: Function,
    error?: Function
  ) {
    Client()
      .request({
        url: `/react/custom/${type}/${id}`,
        method: "get",
      })
      .then((response: AxiosResponse) => {
        success(response);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getContent(
    type: ContentType,
    id: number,
    page: number,
    search: string,
    success: Function,
    error?: Function
  ) {
    Client()
      .request({
        url: `/react/custom/content/${id}/${type}?page=${page}&search=${search}`,
        method: "get",
      })
      .then((response: AxiosResponse) => {
        success(response);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getFilteredContent(
    type: ContentType,
    id: number,
    value: string,
    page: number,
    success: Function,
    error?: Function
  ) {
    Client()
      .request({
        url: `/react/custom/content/${id}/${type}?page=${page}&search=${value}`,
        method: "get",
      })
      .then((response: AxiosResponse) => {
        success(response);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getSectionsData(
    ids: string,
    page: number,
    success: Function,
    error?: Function
  ) {
    let idQuery = ids == null || ids == 'null' ? '' : `/${ids}`;
    Client()
      .request({
        url: `/react/custom/section-content${idQuery}?page=${page}`,
        method: "get",
      })
      .then((response: AxiosResponse) => {
        success(response);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};

export default customLayoutProvider;
