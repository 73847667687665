import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, NotificationsActionTypes } from './actionTypes';
import { Notification } from '../../../types/types';
import objectAssign from 'object-assign';

export default (state: Array<Notification> = [], action: NotificationsActionTypes) => {
    switch(action.type) {
        case ADD_NOTIFICATION:
            const notificationData: Notification = objectAssign({}, action.payload, 
                { id: new Date().getTime().toString() + Math.floor(Math.random() * 1000).toString() } // get eandom ID
            );
            return [...state, notificationData];
        case REMOVE_NOTIFICATION:
            const newState = state.filter(item => item.id !== action.payload);
            return newState;
        default:
            return state;
    }
}