import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import { ThreadBranding } from "../../../types/types";
const renderHtml = require('html-react-parser');

export type ChatBubbleReplyMessageProps = {
    reply_to_message: any;
    threadBranding: ThreadBranding;
};

export const ChatBubbleReplyMessage = (props: ChatBubbleReplyMessageProps) => {
    const { reply_to_message, threadBranding } = props;
    const tenantBranding = useSelector((state: any) => state.app.branding);
    
    const replyColour = threadBranding?.receiver_bubble_colour ?? tenantBranding.messaging.receiver_bubble_colour;
    
    return (
        <Box
            sx={{
                borderRadius: '10px',
                padding: '10px 15px',
                backgroundColor: '#fff',
                position: 'relative',
                margin: '10px 0',
            }}
            data-testid="chat-bubble-reply-message"
        >
            <Box
                sx={{
                    padding: '0 15px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '1px',
                    height: 'fit-content',
                    '&::before': {
                        height: 'calc(100% - 10px) !important',
                      backgroundColor: replyColour,
                    },
                }}
                className="vertical-line"
            >
                <Typography
                    sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        color: 'black',
                    }}
                    data-testid="chat-bubble-reply-to-message-name"
                >
                    {reply_to_message?.author?.name}
                </Typography>
                {reply_to_message?.files?.length > 0 && (
                    <Box sx={{ position: 'relative' }}>
                        <img
                            src={reply_to_message?.files?.[0]?.preview}
                            alt="reply to image"
                            height="150px"
                            width="100%"
                            data-testid="chat-bubble-reply-to-message-image"
                            style={{
                                borderRadius: '10px',
                                objectFit: 'cover',
                                filter: reply_to_message?.files?.length > 1 ? 'brightness(0.5)' : 'none',
                            }}
                        />
                        {reply_to_message.files?.length > 1 && (
                            <Box className="chat-bubble-img-placeholder-text" data-testid="chat-bubble-reply-to-message-image-placeholder-text">
                                +{reply_to_message.files?.length - 1}
                            </Box>
                        )}
                    </Box>
                )}
                <Box>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#333333',
                            '-webkit-line-clamp': '3 !important',
                        }}
                        data-testid="chat-bubble-reply-to-message-post"
                        className="preview-text"
                    >
                        {renderHtml(reply_to_message?.post ?? '')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
