export default {
  "menus": {
    "home": "Accueil",
    "feed": "Nourrir",
    "messages": "Messages",
    "connect": "Connecter",
    "awards": "Récompenses",
    "settings": "Paramètres et confidentialité",
    "profile": "Profil",
    "notifications": "Notifications",
    "change password": "Changer le mot de passe",
    "signOut": "Déconnectez-vous",
    "mark as read": "Marquer comme lu"
  },
  "settingOptions": {
    "title": "Paramètres de confidentialité",
    "content": "Les cookies sont de petits fichiers texte placés sur votre ordinateur ou votre appareil mobile lorsque vous visitez un site ou utilisez une application. Ils sont largement utilisés pour faire fonctionner les sites Web et les applications, ou pour fonctionner plus efficacement, ainsi que pour fournir des informations aux propriétaires du site/de l'application. Le tableau ci-dessous explique les cookies que nous utilisons et les raisons pour lesquelles nous les utilisons.",
    "subQuestion": "Quels cookies utilisons-nous ?",
    "subText": "Pour plus de détails, consultez notre",
    "subTextDefault": "Pour plus de détails, consultez notre politique de confidentialité et de cookies",
    "necessaryTitle": "Strictement nécessaire",
    "necessary": "Les cookies nécessaires sont essentiels aux fonctionnalités et à la sécurité de base de l'application. Si vous êtes d'accord, nous souhaitons également utiliser des cookies pour",
    "performanceTitle": "Cookies de performance",
    "notificationSettings": "Paramètres de notification",
    "emails": "Courriels",
    "p2pEmailsLabel": "Envoyez-moi des notifications par e-mail pour {{p2PLabel}}",
    "performance": {
      "firstLine": "• Améliorer les performances de l'application",
      "secondLine": "• Comprendre comment l'application est utilisée",
      "thirdLine": "• Optimisez le contenu"
    },
    "analyticsTitle": "Cookies d'analyse",
    "analytics": {
      "firstLine": "• Améliorez l'expérience de l'application",
      "secondLine": "• Personnalisez les services"
    },
    "save": "Enregistrer les préférences",
    "error": {
      "label": "Erreur",
      "text": "Une erreur s'est produite, veuillez réessayer ultérieurement."
    }
  },
  "type search field": "Tapez et appuyez sur Entrée..."
};