import React from 'react';
import { Grid, Box } from '@mui/material';

export type CustomTemplateFiveProps = {
    components: any[];
};

export const CustomTemplateFive = (props: CustomTemplateFiveProps) => {
    const { components } = props;

    return (
        <Grid container data-template={5} spacing={2} data-testid='custom-template-5'>
            <Grid item xs={9} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <Box
                    data-pos={1}
                    sx={{
                        maxHeight: '35vh',
                    }}
                >
                    {components[1]}
                </Box>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Box
                        data-pos={2}
                        sx={{
                            maxHeight: '53vh',
                            overflow: 'auto',
                            position: 'relative',
                            flexGrow: 1,
                            flexBasis: 0,
                        }}
                    >
                        {components[2]}
                    </Box>
                    <Box data-pos={3} sx={{ maxHeight: '53vh', overflow: 'auto', flexGrow: 1, flexBasis: 0 }}>
                        {components[3]}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={3}>
                <Box
                    data-pos={4}
                    sx={{
                        position: 'sticky',
                        top: 0,
                        height: '88vh',
                        overflow: 'auto',
                    }}
                >
                    {components[4]}
                </Box>
            </Grid>
        </Grid>
    );
};
