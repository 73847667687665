import i18n from "i18next";
import { initReactI18next, getI18n } from "react-i18next";

import locales from './locales/locales';

i18n
    .use(initReactI18next)
    .init({
        resources: locales.translations,

        lng: "en",
        fallbackLng: "en",
        supportedLngs: locales.languages.map((lang) => lang.code),

        interpolation: {
            escapeValue: false,
            format: function (value, format) {
                if (typeof (value) !== 'string') return '';

                if (format === 'en-handle-an') return getWithArticle(value);
                if (format === 'en-handle-an-capitalized') return getWithArticle(value, true);
                return value;
            }
        }
    },
        (err) => {
            if (err) return console.log('something went wrong loading', err);
        }
    );

function getWithArticle(inputSrt: string, capitalize: boolean = false): string {
    // Run for English only
    if (getI18n().language !== 'en') {
        return inputSrt;
    }

    let article = 'a';
    const checkLetters = ['a', 'e', 'i', 'o', 'u', 'h'];
    if (checkLetters.indexOf(inputSrt.substring(0, 1).toLowerCase()) !== -1) {
        article = 'an';
    }

    if (capitalize)
        article = article.charAt(0).toUpperCase() + article.slice(1);
    return `${article} ${inputSrt}`;
}
