import React from "react";
import { Box, SxProps } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";

export type RightNavSidePanelProps = {
    onBack?: () => void;
    children?: React.ReactNode
    title: string,
    sx?: SxProps
}

export const RightNavSidePanel = (props: RightNavSidePanelProps) => {
    const { onBack, children, title, sx } = props;
    return (
        <Box sx={{backgroundColor: 'white', height: '100%', ...sx}} className="text-secondary pt-3 d-flex flex-column"
        style={{
            transition: "0.5s right",
            right: "-100%"
        }} data-testid='rightnav-side-panel'>
            <h1 className="ms-3 mb-2 font-weight-600 font-24 c-pointer" onClick={onBack} data-testid='rightnav-side-panel-title'>
                <FontAwesomeIcon className={"me-3"} icon={faAngleLeft} />
                <span>{title}</span>
             </h1>
             <Box sx={{padding: '0 30px'}} className='font-16 flex-grow' data-testid='rightnav-side-panel-children'>
                {children}
             </Box>
        </Box>
    )
}