import React, { useEffect, useState } from 'react';
import { Modal } from 'reactstrap';
import { Box, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import { ModalImageList } from '../modalImageList/modalImageList';

export type ChatImageModalProps = {
    isOpen: boolean;
    image_url: string;
    handleClose: () => void;
    files?: any;
    imageIndex: number;
};

export const ChatImageModal = React.memo((props: ChatImageModalProps) => {
    const { isOpen, image_url, handleClose, files, imageIndex } = props;
    const [modalImage, setModalImage] = useState<string>(image_url);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(imageIndex);

    useEffect(() => {
        if (isOpen) {
            setModalImage(image_url);
            setCurrentImageIndex(imageIndex);
        }
    }, [isOpen]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = files[currentImageIndex].path;
        link.download = files[currentImageIndex].path;
        document.body.appendChild(link);
        link.click();
    };

    return (
        <Modal
            isOpen={isOpen}
            style={{ maxWidth: '100vw', margin: 0, height: '100vh', overflow: 'hidden' }}
            contentClassName="chat-image-modal"
            data-testid="chat-img-modal"
            toggle={handleClose}
        >
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2, height: '5vh', marginRight: '5px' }}>
                <button className="unstyled-btn" onClick={handleDownload} data-testid="chat-img-modal-download">
                    <span className="fa-stack" style={{ fontSize: '24px' }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#fff', fontStyle: 'normal' }}></i>
                        <i className="fa-regular fa-download fa-stack-1x fa-inverse" style={{ color: '#000', fontStyle: 'normal' }}></i>
                    </span>
                </button>
                <button
                    className="unstyled-btn"
                    onClick={() => {
                        handleClose();
                        setCurrentImageIndex(0);
                    }}
                    data-testid="chat-img-modal-close"
                >
                    <span className="fa-stack" style={{ fontSize: '24px' }}>
                        <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#fff', fontStyle: 'normal' }}></i>
                        <i className="fa-regular fa-xmark fa-stack-1x fa-inverse" style={{ color: '#000', fontStyle: 'normal' }}></i>
                    </span>
                </button>
            </Box>
            <Grid container sx={{ height: '70vh' }}>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box>
                        <button
                            className="unstyled-btn"
                            style={{ display: files?.length > 1 ? 'flex' : 'none' }}
                            onClick={() => {
                                if (currentImageIndex > 0) {
                                    setCurrentImageIndex((prev) => prev - 1);
                                    setModalImage(
                                        files[currentImageIndex - 1].type.includes('image') || files[currentImageIndex - 1].type.includes('video')
                                            ? files[currentImageIndex - 1].path
                                            : files[currentImageIndex - 1].preview
                                    );
                                }
                            }}
                        >
                            <span className="fa-stack" style={{ fontSize: '32px' }}>
                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#fff', fontStyle: 'normal', opacity: currentImageIndex == 0 ? 0.5 : 1  }} />
                                <i className="fa-regular fa-chevron-left fa-stack-1x" style={{ color: '#000', fontStyle: 'normal', opacity: currentImageIndex == 0 ? 0.5 : 1 }} />
                            </span>
                        </button>
                    </Box>
                </Grid>
                <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box
                        sx={{
                            margin: files?.length > 1 ? '0' : '30px 0',
                        }}
                        data-testid="chat-img-modal-image"
                    >
                        {files?.[currentImageIndex]?.type.includes('video') ? (
                            <video
                                src={modalImage}
                                style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain', borderRadius: '25px' }}
                                data-testid="chat-img-modal-preview"
                                controls
                            />
                        ) : (
                            <img
                                src={modalImage}
                                style={{ maxWidth: '100%', maxHeight: '70vh', objectFit: 'contain', borderRadius: '25px' }}
                                data-testid="chat-img-modal-preview"
                            />
                        )}
                        <Typography sx={{ textAlign: 'center', fontSize: '16px', color: '#fff', marginTop: '10px', fontWeight: '600' }}>
                            {!files?.[currentImageIndex]?.type.includes('image') &&
                                !files?.[currentImageIndex]?.type.includes('video') &&
                                files?.[currentImageIndex]?.name}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Box>
                        <button
                            className="unstyled-btn"
                            style={{ display: files?.length > 1 ? 'flex' : 'none' }}
                            onClick={() => {
                                if (files?.length > currentImageIndex + 1) {
                                    setCurrentImageIndex((prev) => prev + 1);
                                    setModalImage(
                                        files[currentImageIndex + 1].type.includes('image') || files[currentImageIndex + 1].type.includes('video')
                                            ? files[currentImageIndex + 1].path
                                            : files[currentImageIndex + 1].preview
                                    );
                                }
                            }}
                        >
                            <span className="fa-stack" style={{ fontSize: '32px' }}>
                                <i className="fa-solid fa-circle fa-stack-2x" style={{ color: '#fff', fontStyle: 'normal', opacity: files?.length == currentImageIndex + 1 ? 0.5 : 1 }} />
                                <i className="fa-regular fa-chevron-right fa-stack-1x" style={{ color: '#000', fontStyle: 'normal', opacity: files?.length == currentImageIndex + 1 ? 0.5 : 1 }} />
                            </span>
                        </button>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ height: '22vh', display: 'flex', justifyContent: 'center' }}>
                {files?.length > 1 && (
                    <ModalImageList
                        files={files}
                        handleClick={(url: string, index: number) => {
                            setModalImage(url);
                            setCurrentImageIndex(index);
                        }}
                    />
                )}
            </Grid>
        </Modal>
    );
});
