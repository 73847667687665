import { useEffect, useState } from "react";

export type DebounceHook = {
    debouncedValue: any;
    setDebouncedValue: (value: any) => void;
}

/**
 * @description: Custom hook for handling debounce
 * @param value: any 
 * @param delay: time between each debounce @default 300
 * @returns: {debouncedValue, setDebouncedValue}
 */
export const  useDebounce = (value: any, delay: number = 300): DebounceHook => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  /**
   * @description: Sets the debounced value after the delay
   */
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return { debouncedValue, setDebouncedValue };
}
