import React, { useState } from "react";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { AxiosResponse } from "axios";

import "./ConnectFilter.scss";
import CustomSearchText, {
	AttributeTypes,
} from "./customSearch/CustomSearchText";
import CustomSearchPickList, {
	FilterItemType,
} from "./customSearch/CustomSearchPickList";
import connectService from "../../../../web/services/connectService";
import { addNotification } from "../../../../shared/reducers/notifications/actionTypes";
import SpinnerLoad from "../spinnerLoad/spinnerLoad";
import CustomPagination from "../customPagination/CustomPagination";

const searchStringInitialState = {
	department: "",
	location: "",
	email: "",
	manager: "",
	badge: "",
	workingHours: "",
	skills: "",
	funFacts: "",
	phoneNumber: "",
	hobbies: "",
};

export const filterSearchPickListString = {
	location: "",
	manager: "",
	badge: "",
};

const initialIsShowTextFilterCount = {
	department: false,
	primaryEmail: false,
};

const defaultFilterData = {
	department: "",
	email: "",
	location: [],
	badge: [],
	manager: [],
};

export enum DefaultFilterType {
	Department = "Department",
	Location = "Location",
	Email = "Email",
	Manager = "Manager",
	Badge = "Badge",
}

export type FiltersType = {
	department: string;
	location: string;
	email: string;
	manager: string;
	badge: string;
};

export type SearchFilterData = {
	department?: string;
	location?: FilterItemType[];
	email?: string;
	manager?: FilterItemType[];
	badge?: FilterItemType[];
	[key: string]: any;
};

type SearchTextFilterCountType = {
	department: boolean;
	primaryEmail: boolean;
	[key: string]: boolean;
};

export type SearchPickListStringType = {
	location: string;
	manager: string;
	badge: string;
};

type AttributeItemType = {
	id: number;
	title: string;
	type_id: number;
};

interface CustomAttributeFilterValue {
	[key: string]: string;
}

type ConnectFiltersPropsType = {
	handleFilterContactList: (filters: any, isClear?: boolean) => void;
};

export const removeEmptyKeyValue = (obj: any) => {
	for (var propName in obj) {
		if (obj[propName].length === 0) {
			delete obj[propName];
		}
	}
	return obj;
};

const ConnectFilter: React.FC<ConnectFiltersPropsType> = ({
	handleFilterContactList,
}) => {
	const dispatch = useDispatch();
	const [connectFilterDropdown, setConnectFilterDropdown] = useState(false);
	const [clearFilters, setClearFilters] = useState(false);
	const [filterSearchString, setFilterSearchString] = useState("");
	const [totalFilterCount, setTotalFilterCount] = useState(0);
	const [isShowFilterCount, setIsShowFilterCount] =
		useState<SearchTextFilterCountType>({
			...initialIsShowTextFilterCount,
		});
	const [locationSelectedItems, setLocationSelectedItems] = useState<
		FilterItemType[]
	>([]);
	const [badgeSelectedItems, setBadgeSelectedItems] = useState<
		FilterItemType[]
	>([]);
	const [managerSelectedItems, setManagerSelectedItems] = useState<
		FilterItemType[]
	>([]);
	const [locationList, setLocationList] = useState<FilterItemType[]>([]);
	const [managerList, setManagerList] = useState<FilterItemType[]>([]);
	const [badgeList, setBadgeList] = useState<FilterItemType[]>([]);
	const [allFilterSearchString, setAllFilterSearchString] =
		useState<FiltersType>({
			...searchStringInitialState,
		});
	const [searchPickListString, setSearchPickListString] =
		useState<SearchPickListStringType>({ ...filterSearchPickListString });
	const [dropdownOptions, setDropdownOptions] = useState<SearchFilterData>({
		...defaultFilterData,
	});

	const [attributeList, setAttributeList] = useState<AttributeItemType[]>([]);
	const [isLoading, setLoading] = useState(false);
	const [customAttributesFilter, setCustomAttributesFilter] =
		useState<CustomAttributeFilterValue>({});
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [totalPage, setTotalPage] = useState<number>(1);

	const toggleConnectFilterDropdown = (event: any) => {
		event.stopPropagation();
		setConnectFilterDropdown((prevState) => !prevState);
	}

	const fetchConnectAttributeList = (pageNo: number) => {
		setLoading(true);
		connectService.getAttributes(
			pageNo,
			(resp: AxiosResponse) => {
				setAttributeList(resp.data.data);
				setCurrentPage(resp.data.meta.current_page);
				if (pageNo === 1) {
					setTotalPage(resp.data.meta.last_page);
				}
				setLoading(false);
			},
			() => {
				setLoading(false);
				dispatch(
					addNotification({
						label: `Connect Attributes API`,
						text: "Failed to load Attributes data",
						type: "danger",
					})
				);
			}
		);
	};

	const getConcatedSearchString = (allFilterSearchString: any) => {
		let filterText = "";
		Object.keys(allFilterSearchString).forEach(function (key, index) {
			if (index === 0) {
				filterText = allFilterSearchString[key];
			} else {
				filterText += " + " + allFilterSearchString[key];
			}
		});

		return filterText;
	};

	const handleSearchFilterChange = (
		label: string,
		text: string,
		selectedItems: FilterItemType[] = []
	) => {
		if (
			Object.values(DefaultFilterType).includes(
				label as unknown as DefaultFilterType
			)
		) {
			switch (label) {
				case DefaultFilterType.Department:
					allFilterSearchString.department = text;
					isShowFilterCount.department = true;
					dropdownOptions.department = text;
					break;
				case DefaultFilterType.Location:
					allFilterSearchString.location = text;
					dropdownOptions.location = selectedItems;
					break;
				case DefaultFilterType.Email:
					allFilterSearchString.email = text;
					isShowFilterCount.primaryEmail = true;
					dropdownOptions.email = text;
					break;
				case DefaultFilterType.Manager:
					allFilterSearchString.manager = text;
					dropdownOptions.manager = selectedItems;
					break;
				case DefaultFilterType.Badge:
					allFilterSearchString.badge = text;
					dropdownOptions.badge = selectedItems;
					break;
				default:
					break;
			}
		} else {
			customAttributesFilter[label] = text;
			isShowFilterCount[label] = true;
		}

		let appliedFilter = [];
		appliedFilter = removeEmptyKeyValue({
			...allFilterSearchString,
			...customAttributesFilter,
		});
		setTotalFilterCount(Object.keys(appliedFilter).length);
		setFilterSearchString(getConcatedSearchString(appliedFilter));
		if (handleFilterContactList)
			handleFilterContactList(
				removeEmptyKeyValue({ ...dropdownOptions, ...customAttributesFilter })
			);
	};

	const handleClearAllFilters = () => {
		setClearFilters(true);
		setAllFilterSearchString({ ...searchStringInitialState });
		setIsShowFilterCount({ ...initialIsShowTextFilterCount });
		setSearchPickListString({ ...filterSearchPickListString });
		setDropdownOptions({ ...defaultFilterData });
		setCustomAttributesFilter({});
		setLocationSelectedItems([]);
		setBadgeSelectedItems([]);
		setManagerSelectedItems([]);
		setFilterSearchString("");
		setTotalFilterCount(0);
		handleFilterContactList({}, true);
	};

	const renderCustomAttributeElements = () => {
		return (
			<div
				data-testid="bottom-filter-section"
				className="row gy-3 row-cols-5 m-0 bottom-filter-section"
			>
				{attributeList.map((attribute) => {
					return (
						<CustomSearchText
							label={attribute.title}
							type={attribute.type_id}
							className={"p-0 col"}
							displayFilterCount={isShowFilterCount[attribute.title]}
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							handleSearchFilterChange={handleSearchFilterChange}
						/>
					);
				})}
			</div>
		);
	};

	return (
		<>
			<Dropdown
				data-testid="connect-filters"
				className="h-100 connect-filters-dropdown ms-2"
				isOpen={connectFilterDropdown}
				toggle={toggleConnectFilterDropdown}
			>
				<DropdownToggle
					onClick={() => {
						if (attributeList && attributeList.length > 0) return;
						fetchConnectAttributeList(currentPage);
					}}
					data-testid="connect-filter-btn"
					className={`filter-btn p-2 ${
						connectFilterDropdown ? "dropdown-btn" : ""
					}`}
				>
					<p className="fa-regular fa-filters m-0 connect-filter-icon"></p>
					{totalFilterCount > 0 && (
						<p
							data-testid="connect-filters-counts"
							className="ms-1 filter-counts m-0"
						>
							{"(" + totalFilterCount + ")"}
						</p>
					)}
				</DropdownToggle>
				<DropdownMenu data-testid="connect-filters-dropdown-menu">
					<DropdownItem className="connect-filters-header" header>
						{t("connect.quickFilters")}
						<button
							data-testid="clear-btn"
							onClick={handleClearAllFilters}
							className="clear-btn"
						>
							{t("connect.clearAll")}
						</button>
					</DropdownItem>
					<div
						className="all-filters d-flex me-3"
					>
						<p className="fa-regular form-control connect-search-icon fa-magnifying-glass m-0 ms-1 me-2"></p>
						<p data-testid="filter-string" className="m-0 d-flex align-items-center">{filterSearchString}</p>
					</div>

					<div data-testid="top-filter-section" className="top-filter-section">
						<CustomSearchText
							label={DefaultFilterType.Department}
							type={AttributeTypes.Text}
							displayFilterCount={isShowFilterCount.department}
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							handleSearchFilterChange={handleSearchFilterChange}
						/>
						<CustomSearchPickList
							label={DefaultFilterType.Location}
							selectedItems={locationSelectedItems}
							customSearchString={searchPickListString.location}
							clearFilters={clearFilters}
							dropdownOptions={locationList}
							setClearFilters={setClearFilters}
							setCustomSearchString={setSearchPickListString}
							setSelectedItems={setLocationSelectedItems}
							setDropdownOptions={setLocationList}
							handleSearchFilterChange={handleSearchFilterChange}
						/>
						<CustomSearchText
							label={DefaultFilterType.Email}
							type={AttributeTypes.Email}
							displayFilterCount={isShowFilterCount.primaryEmail}
							clearFilters={clearFilters}
							setClearFilters={setClearFilters}
							handleSearchFilterChange={handleSearchFilterChange}
						/>
						<CustomSearchPickList
							label={DefaultFilterType.Manager}
							selectedItems={managerSelectedItems}
							customSearchString={searchPickListString.manager}
							dropdownOptions={managerList}
							clearFilters={clearFilters}
							setSelectedItems={setManagerSelectedItems}
							setCustomSearchString={setSearchPickListString}
							setDropdownOptions={setManagerList}
							setClearFilters={setClearFilters}
							handleSearchFilterChange={handleSearchFilterChange}
						/>
						<CustomSearchPickList
							label={DefaultFilterType.Badge}
							selectedItems={badgeSelectedItems}
							customSearchString={searchPickListString.badge}
							dropdownOptions={badgeList}
							clearFilters={clearFilters}
							setSelectedItems={setBadgeSelectedItems}
							setCustomSearchString={setSearchPickListString}
							setDropdownOptions={setBadgeList}
							setClearFilters={setClearFilters}
							handleSearchFilterChange={handleSearchFilterChange}
						/>
					</div>
					{isLoading ? (
						<SpinnerLoad className="mt-5" />
					) : (
						renderCustomAttributeElements()
					)}
					{
						totalPage > 1 && (
							<div className="footer-section">
								<CustomPagination 
									currentPage={currentPage}
									totalPage={totalPage}
									setCurrentPage={setCurrentPage}
									handlePageChange={fetchConnectAttributeList}
								/>
							</div>
						)
					}
				</DropdownMenu>
			</Dropdown>
		</>
	);
};

export default ConnectFilter;
