import React, {
  FunctionComponent,
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";

type ImageCropProps = {
  src: string;
  fitImgParentSelector: string;
  className?: string;
  backgroundColor?: string;
  imgProps?: any;
  imgContainerStyleProps?: any;
};

const ImageCrop: FunctionComponent<ImageCropProps> = ({
  src,
  fitImgParentSelector,
  className,
  backgroundColor,
  imgProps,
  imgContainerStyleProps,
}) => {
  const [containerHeight, setHeight] = useState<number | null>(null);

  const containerRef = useRef(null);
  const imgRef = useRef(null);

  useLayoutEffect(() => {
    setTimeout(() => {
      processContainer();
    }, 400);
  }, []);

  useEffect(() => {
    const eventFunc = () => {
      processContainer();
    };
    window.addEventListener("resize", eventFunc);
    return () => {
      window.removeEventListener("resize", eventFunc);
    };
  }, []);

  const processContainer = () => {
    if (!containerRef.current) return;

    const parentEl = document.querySelector(fitImgParentSelector);
    const parentToFitHeight = parentEl
      ? parentEl.clientHeight
      : document.documentElement.clientHeight;
    if (!parentToFitHeight) return;

    const el: any = containerRef.current;
    if (el.clientHeight <= parentToFitHeight) {
      // If image is wide
      const imgEl: any = imgRef.current;
      if (imgEl && imgEl.clientWidth > imgEl.clientHeight) {
        setHeight(el.clientHeight);
      } else {
        return;
      }
    }

    setHeight(parentToFitHeight);
  };

  return (
    <div
      data-testid="image-crop"
      style={{
        display: "flex",
        height: containerHeight || "",
        justifyContent: "center",
        backgroundColor: backgroundColor || "#c6c6c6",
        ...imgContainerStyleProps,
      }}
      className={className}
      ref={containerRef}
    >
      <img
        src={src}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "scale-down",
          borderRadius: "4px",
        }}
        ref={imgRef}
        alt="social-post"
        {...imgProps}
      />
    </div>
  );
};

export default ImageCrop;
