import React, { useState } from 'react';
import { Box, Popover } from '@mui/material';
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFaceSmile, faCircleEllipsis, faReply, IconDefinition, faEllipsis } from '@fortawesome/pro-light-svg-icons';

export type ChatBubbleReactionsProps = {
    displayReactions: boolean;
    handleEmojiClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleEmojiClose: () => void;
    handleEllipsisClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleEllipsisClose: () => void;
    handleMessageDelete: () => void;
    id: string | undefined;
    messageID: string;
    ellipsisId: string | undefined;
    openEllipsis: boolean;
    open: boolean;
    emojiAnchorEl: HTMLButtonElement | null;
    ellipsisAnchorEl: HTMLButtonElement | null;
    onEmojiClick: (event: any, emojiObject: any) => void;
    primary: boolean;
    onReplyToClick?: () => void;
    allowReactions: boolean;
    isFromSendMessagePanel?: boolean;
};

export const ChatBubbleReactions = (props: ChatBubbleReactionsProps) => {
    const {
        displayReactions,
        handleEmojiClick,
        handleEmojiClose,
        id,
        ellipsisId,
        messageID,
        open,
        openEllipsis,
        allowReactions,
        emojiAnchorEl,
        onEmojiClick,
        primary,
        onReplyToClick,
        isFromSendMessagePanel = false,
        handleEllipsisClick,
        handleEllipsisClose,
        handleMessageDelete,
        ellipsisAnchorEl,
    } = props;

    const baseStyle = {
        display: displayReactions ? 'flex' : 'none',
        gap: '10px',
        fontSize: isFromSendMessagePanel ? '24px' : '20px',
        alignItems: 'center',
        marginTop: isFromSendMessagePanel ? '0px' : '8px',
    };

    const reactionOrder = primary ? (isFromSendMessagePanel ? [faFaceSmile] : [faCircleEllipsis ,faReply, faFaceSmile]) : (isFromSendMessagePanel ? [faFaceSmile] : [faFaceSmile, faReply]);

    const handleReactionClick = (e: any, reaction: IconDefinition) => {
        if (reaction === faFaceSmile) handleEmojiClick(e);
        if (reaction === faReply && onReplyToClick) onReplyToClick();
        if (reaction === faCircleEllipsis) handleEllipsisClick(e);
    }

    return (
        <Box sx={{ ...baseStyle }} data-testid="chat-bubble-reactions">
            {reactionOrder.map((reaction, index) => (
                (reaction === faFaceSmile ? allowReactions : true) && (
                    <span
                        onClick={(e) => {
                            handleReactionClick(e, reaction);
                        }}
                        key={index}
                        className={`c-pointer ${isFromSendMessagePanel ? "d-flex align-items-center" : ""}`}
                        data-testid={'reaction-' + reaction.iconName}
                    >
                        <FontAwesomeIcon icon={reaction} />
                    </span>
                )
            ))}
            <Popover
                open={open}
                anchorEl={emojiAnchorEl}
                onClose={handleEmojiClose}
                id={id}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: primary ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: primary ? 'right' : 'left',
                }}
                sx={{display: displayReactions ? 'block' : 'none'}}
            >
                <EmojiPicker
                    onEmojiClick={onEmojiClick}
                    height={'300px'}
                    emojiStyle={EmojiStyle.NATIVE}
                    data-testid="emoji-picker"
                    previewConfig={{
                        showPreview: false,
                    }}
                />
            </Popover>
            <Popover
                open={openEllipsis}
                anchorEl={ellipsisAnchorEl}
                onClose={handleEllipsisClose}
                id={ellipsisId}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: primary ? 'left' : 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: primary ? 'right' : 'left',
                }}
                sx={{display: displayReactions ? 'block' : 'none'}}
                className="thread-options"
            >
                {/* <Box
                    sx={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: 1,
                    }}
                >
                    <span className="fa-regular fa-pen-circle" />
                    <span className="c-pointer" data-testid="ellipsis-delete">
                        Edit
                    </span>
                </Box> */}
                <Box
                    sx={{
                        cursor: 'pointer',
                        width: '100%',
                        display: 'flex',
                        padding: '5px',
                        gap: '5px'
                    }}
                    onClick={() => {
                        handleMessageDelete();
                        handleEllipsisClose();
                    }}
                >
                    <span className="fa-regular fa-trash-can" style={{lineHeight: 1.4}} />
                    <span data-testid="ellipsis-delete">Delete</span>
                </Box>
            </Popover>
        </Box>
    );
};
