import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const usersProvider = {
    getUsers(filterText: string, page: number, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/json/users${filterText.trim() != '' ? `/${filterText}` : ''}?page=${page}`,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    snooze(snoozeMins: number, userId: number, success: Function, error?: Function) {
        Client(false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/user/snooze`,
            method: 'post',
            data: {
                user_id: userId,
                time: snoozeMins
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    getMentionUsers(value: string, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/user/allUsers?search=${value}`,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default usersProvider;