import webPNProvider from '../providers/webPNProvider';

const webPNService = {
    /**
     *
     * @param pushSubscription
     * @param success
     * @param error
     */
    sendPush(pushSubscription: PushSubscription, success: Function, error?: Function) {
        webPNProvider.sendPush(pushSubscription, success, error);
    }
}

export default webPNService;