import React from 'react';
import type { ThreadListInterface } from '../../../types/interface/ThreadList';
import type { ThreadInterface } from '../../../types/interface/Thread';
import { Thread } from '../../components/thread/thread';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { useMarkAsRead } from '../../hooks/useMarkAsReadHook';
import { useQueryClient } from 'react-query';
import { useSelector, useDispatch } from 'react-redux';

export type ThreadListThreadsProps = {
    data: any;
    selectedThread?: string;
    isArchived?: boolean;
    fetchNextPage: () => void;
    fetchingNextPage: boolean;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    handleArchiveThread: (threadId: string | undefined) => void;
};

export const ThreadListThreads = React.memo((props: ThreadListThreadsProps) => {
    const { data, selectedThread, fetchNextPage, fetchingNextPage, isArchived, refreshThreadDetail, handleArchiveThread } = props;
    const queryClient = useQueryClient();
    const appState = useSelector((state: any) => state.app.app);
    const dispatch = useDispatch();

    //need to handle error for markAsRead
    const { mutate: markAsRead } = useMarkAsRead(() => {
        queryClient.invalidateQueries('threads');
    });

    const handleThreadClick = (id: number, unread_message: boolean) => {
        if (unread_message) {
            markAsRead(id);
            dispatch({
                type: 'SET_APP_STATE',
                payload: {
                    ...appState,
                    mark_as_read_message: true,
                },
            });
        }
    };

    const handleScroll = (e: React.SyntheticEvent) => {
        const target = e.target as HTMLElement;
        const bottom = Math.floor(target.scrollHeight - target.scrollTop) === target.clientHeight;
        if (bottom && !fetchingNextPage) {
            fetchNextPage();
        }
    };

    return (
        <div className='thread-list' data-testid='thread-list' onScroll={handleScroll}>
            {data?.pages?.map((group: ThreadListInterface, index: number) => {
                return (
                    <React.Fragment key={index}>
                        {group?.data.map((thread: ThreadInterface) => (
                            <Thread
                                image_url={thread.icon_url}
                                title={thread.title}
                                updatedAt={thread.updated_at}
                                updatedAtHuman={thread.updated_at_human}
                                post={thread.preview_text}
                                deleted={thread?.deleted}
                                key={thread.id}
                                id={thread.id}
                                description={thread.description}
                                threadType={thread.type}
                                mutedUntil={thread.muted_until}
                                mutedByAdmin={thread.muted_by_admin}
                                unread_count={thread.unread_count}
                                isArchived={isArchived}
                                handleClick={handleThreadClick}
                                refreshThreadDetail={refreshThreadDetail}
                                handleArchiveThread={handleArchiveThread}
                                sx={{
                                    backgroundColor: selectedThread === thread?.id?.toString() ? '#F5F5F5' : '#FFFFFF',
                                }}
                            />
                        ))}
                        {fetchingNextPage && <SpinnerLoad className='w-100 d-flex align-items-center justify-content-center' size={50} />}
                    </React.Fragment>
                );
            })}
        </div>
    );
});
