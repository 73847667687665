import { NotificationModel } from '../../../types/types';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export function addNotification(notificationData: NotificationModel) {
    return {
        type: ADD_NOTIFICATION,
        payload: notificationData
    };
}

export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export function removeNotification(label: string) {
    return {
        type: REMOVE_NOTIFICATION,
        payload: label
    };
}

interface AddNotification {
    type: typeof ADD_NOTIFICATION,
    payload: NotificationModel
}

interface RemoveNotification {
    type: typeof REMOVE_NOTIFICATION,
    payload: string
}

export type NotificationsActionTypes = AddNotification | RemoveNotification;