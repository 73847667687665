import './recentComments.scss';
import React, { FunctionComponent } from 'react';
import { Card, CardHeader, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import dayjs from 'dayjs';
const renderHtml = require('html-react-parser');
import { useTranslation } from 'react-i18next';
import { LatestComment, RecentComment } from '~/types/types';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

type RecentCommentsProps = {
    data?: RecentComment[];
    feedCommentList?: LatestComment[];
};

const RecentComments: FunctionComponent<RecentCommentsProps> = ({ data = [], feedCommentList = [] }) => {
    const { t } = useTranslation();
    const brandingColors = useSelector((state: any) => state.app.branding);
    
    const renderComment = (comment: LatestComment|RecentComment) => {
        return (
          <ListGroupItem
            key={comment.id}
            data-testid='recent-comment-list'
            className='mt-1'
            style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'flex-start',
            }}
          >
              <img
                src={comment.author.image_url}
                alt={`${comment.author.full_name} profile photo`}
                className='rounded-circle thumb48 c-pointer'
                data-testid='profile-icon'
                style={{ objectFit: 'cover' }}
                onClick={() => {
                    window.location.href = `/connect/people/${comment.author.contact_id}`;
                }}
              />
              <div style={{ marginLeft: '10px', flex: 1 }}>
                  <div className='user-comment-detail mb-2' style={{ display: 'flex', flexDirection: 'column'}}>
                      <ListGroupItemHeading
                        tag='h3'
                        className='font-weight-bold mb-0 py-1 pb-0 font-primary-text author-name'
                        data-testid='author-fullname'
                        onClick={() => {
                            window.location.href = `/connect/people/${comment.author.contact_id}`;
                        }}
                      >
                          {comment.author.full_name}
                      </ListGroupItemHeading>
                      <div className='timestamp'
                           style={{ marginTop: '-0.25em', fontSize: '0.9em' }}
                           onClick={() => {
                               window.location.href = `/page/${comment.content_id}`;
                           }}>
                          <p data-testid='updated-at-humans'
                             className='my-0 font-secondary-text comment-timestamp'>
                              {comment.updated_at_humans}
                          </p>
                      </div>
                  </div>
                  <ListGroupItemText
                    data-testid='comment-text'
                    className='mb-0 font-secondary-text comment-value'
                    onClick={() => {
                        window.location.href = `/page/${comment.content_id}`;
                    }}
                  >
                      {renderHtml(comment.value)}
                  </ListGroupItemText>
              </div>
          </ListGroupItem>
        );
    }
    
    return (
        <div data-testid='recent-comments' className={'custom-layout-latest-comments pb-3'}>
            <Card className={'latest-comments-list h-100'} style={{ borderRadius: 10, overflow: 'hidden' }}>
                <CardHeader id='latest-comments-header' style={{ backgroundColor: brandingColors.web_navbar_background }}>
                    <h2
                        data-testid='latest-comments-title'
                        className='font-weight-bold mb-0 py-1'
                        style={{ color: brandingColors.web_navbar_foreground }}
                    >
                        {t('feed.LatestComments')}
                    </h2>
                </CardHeader>
                {data?.length === 0 && feedCommentList?.length === 0 ? (
                    <div
                        data-testid='empty-comments-section'
                        className={'h-100'}
                        style={{
                            padding: '0.5rem 1rem',
                            display: 'flex',
                            justifyContent: 'flex-start',
                        }}
                    >
                        {t('feed.noCommentsYet')}
                    </div>
                ) : (
                    <Box sx={{ height: '100%' }}>
                        <ListGroup flush>
                            {data.length > 0 ? (
                                <>
                                    {data?.map(renderComment)}
                                </>
                            ) : (
                                <>
                                    {feedCommentList?.map(renderComment)}
                                </>
                            )}
                        </ListGroup>
                    </Box>
                )}
            </Card>
        </div>
    );
};

export default RecentComments;
