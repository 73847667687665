import { useInfiniteQuery, InfiniteData } from 'react-query';
import { fetchThreads, fetchMessages, fetchContacts } from './chatProvider';

export const useThreadsQuery = (debouncedSearchQuery: string | null, archived: boolean, handleResetThreadList: (data: InfiniteData<unknown>) => void) => {
    return useInfiniteQuery(
        ['threads', [debouncedSearchQuery, archived]],
        ({ pageParam = 1 }) => fetchThreads(debouncedSearchQuery, archived, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onSuccess: handleResetThreadList
        }
    );
};

export const useMessagesQuery = (threadID: string | undefined) => {
    return useInfiniteQuery(['messages', threadID], ({ pageParam = 1 }) => fetchMessages(threadID, pageParam), {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.links?.next) return pages.length + 1;
            return undefined;
        },
        refetchOnWindowFocus: false,
    });
};

export const useContactsQuery = (debouncedContactSearchQuery: string | null, displayCreateChat: boolean, isEnabled: boolean = true) => {
    return useInfiniteQuery(
        ['contacts', debouncedContactSearchQuery, displayCreateChat],
        ({ pageParam = 1 }) => fetchContacts(debouncedContactSearchQuery, displayCreateChat, pageParam),
        {
            enabled: isEnabled,
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
        }
    );
};
