import React from 'react';
import { Grid, Box, Tooltip } from '@mui/material';
import { IconText } from '../../components/iconText/IconText';
import { Select } from '../../components/select/select';
import newspaperIcon from '../../../icons/newspaper-light-icon.svg';
import handPointerIcon from '../../../icons/hand-pointer-light-icon.svg';
import peopleGroupIcon from '../../../icons/people-group-light-icon.svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isWhite } from '../../utils/colourCheck';
import { useResizeObserver } from '../../hooks/useResizeObserverHook';

export type CommunityFeedFilterProps = {
    groupData: any;
    searchQuery: string;
    handleSearchQuery: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
    isFetchingNextPage: boolean;
    selectedGroup: string[];
    setSelectedGroup: React.Dispatch<React.SetStateAction<string[]>>;
    isSelected: string;
    filterOptions: {
        all: string;
        myGroups: string;
    };
    handleFilterClick: (text: string) => void;
    setIsSelected: React.Dispatch<React.SetStateAction<string>>;
    fetchNextPage: any;
    fetchingNextPage: boolean;
    isLoading: boolean;
};

export const CommunityFeedFilter = (props: CommunityFeedFilterProps) => {
    const {
        groupData,
        searchQuery,
        handleSearchQuery,
        isFetchingNextPage,
        selectedGroup,
        setSelectedGroup,
        isSelected,
        filterOptions,
        handleFilterClick,
        setIsSelected,
        fetchNextPage,
        fetchingNextPage,
        isLoading,
    } = props;

    const { t } = useTranslation();
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const handleSelectChange = (event: any) => {
        event.stopPropagation();
        if (selectedGroup?.includes(event.target.value)) {
            setSelectedGroup([]);
            setIsSelected(filterOptions.all);
        } else {
            setSelectedGroup([event.target.value] as string[]);
            setIsSelected('Individual Group');
        }
    };

    const handleSelectScroll = (e: any) => {
        e.stopPropagation();
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (Math.round(scrollTop + clientHeight) + 1 >= scrollHeight) {
            !fetchingNextPage && fetchNextPage();
        }
    };
    const [containerRef, containerObj] = useResizeObserver();
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    const selectCommunityGroupOptions = (groupData: any) => {
        const communityGroupOptions: {
            id: string;
            title: string;
            image?: string;
        }[] = [];
        groupData?.pages?.map((page: any) => {
            page?.data?.map((group: any) => {
                communityGroupOptions.push({ id: group.id, title: group.title, image: group.image_url });
            });
        });
        getSelectedGroupImage(communityGroupOptions);
        return communityGroupOptions;
    };

    const groupIsSelected = selectedGroup?.length > 0;

    const getSelectedGroupImage = (
        options: {
            id: string;
            title: string;
            image?: string;
        }[]
    ) => {
        if (selectedGroup.length === 0) return '';
        let group = options.find((option: any) => {
            return option.title === selectedGroup[0];
        });
        return group?.image ? group?.image : 'https://cdn.engageesp.com/img/person.png';
    };

    const checkGroupSelected = (text: string) => {
        if (isSelected === text) {
            return {
                bgColor,
                fgColor,
            };
        }
        return {
            bgColor: 'white',
            fgColor: 'black',
        };
    };

    const extraSmallContainerSize: number = 450;
    const extraSmallContainer: boolean = containerObj?.width && containerObj?.width < extraSmallContainerSize;

    return (
        <Grid container spacing={1} sx={{ marginBottom: '1rem' }} ref={containerRef}>
            <Grid item xs={12} sm={4}>
                <Tooltip
                    title={extraSmallContainer && t('feed.AllPosts')}
                    placement='bottom'
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: checkGroupSelected(filterOptions.all).bgColor,
                            borderRadius: '8px',
                            cursor: 'pointer',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 4px 4px #0000001a',
                        }}
                        onClick={() => {
                            handleFilterClick(filterOptions.all);
                        }}
                    >
                        <IconText
                            icon={
                                <Box
                                    sx={{
                                        backgroundColor: checkGroupSelected(filterOptions.all).fgColor,
                                        width: '20px',
                                        height: '20px',
                                        WebkitMask: `url(${newspaperIcon}) no-repeat center`,
                                        mask: `url(${newspaperIcon}) no-repeat center`,
                                    }}
                                />
                            }
                            text={extraSmallContainer ? '' : t('feed.AllPosts')}
                            textProps={{
                                sx: {
                                    color: checkGroupSelected(filterOptions.all).fgColor,
                                },
                            }}
                            IconTextContainerSx={{
                                gap: extraSmallContainer ? '0' : '10px',
                            }}
                            handleClick={() => handleFilterClick(filterOptions.all)}
                        />
                    </Box>
                </Tooltip>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Tooltip
                    title={extraSmallContainer && t('feed.MyGroups')}
                    placement='bottom'
                    PopperProps={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, -10],
                                },
                            },
                        ],
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: checkGroupSelected(filterOptions.myGroups).bgColor,
                            borderRadius: '8px',
                            cursor: 'pointer',
                            height: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 4px 4px #0000001a',
                        }}
                        onClick={() => handleFilterClick('My Groups')}
                    >
                        <IconText
                            icon={
                                <Box
                                    sx={{
                                        backgroundColor: checkGroupSelected(filterOptions.myGroups).fgColor,
                                        width: '20px',
                                        height: '20px',
                                        WebkitMask: `url(${peopleGroupIcon}) no-repeat center`,
                                        mask: `url(${peopleGroupIcon}) no-repeat center`,
                                    }}
                                />
                            }
                            text={extraSmallContainer ? '' : t('feed.MyGroups')}
                            textProps={{
                                sx: {
                                    color: checkGroupSelected(filterOptions.myGroups).fgColor,
                                },
                            }}
                            handleClick={() => handleFilterClick('My Groups')}
                            IconTextContainerSx={{
                                gap: extraSmallContainer ? '0' : '10px',
                            }}
                        />
                    </Box>
                </Tooltip>
            </Grid>
            <Tooltip
                title={extraSmallContainer && (groupIsSelected ? selectedGroup[0] : t('feed.SpecificGroup'))}
                placement='bottom'
                PopperProps={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -10],
                            },
                        },
                    ],
                }}
            >
                <Grid item xs={12} sm={4}>
                    <Select
                        name={'community-group'}
                        inputLabel={true}
                        options={selectCommunityGroupOptions(groupData)}
                        displayOptionImages
                        label={
                            <span style={{ width: '175px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                <img
                                    src={groupIsSelected ? getSelectedGroupImage(selectCommunityGroupOptions(groupData)) : handPointerIcon}
                                    style={
                                        groupIsSelected
                                            ? { width: '24px', height: '24px', borderRadius: '50%', marginRight: '10px' }
                                            : { width: '25px', height: '20px', marginRight: '5px', paddingBottom: '4px' }
                                    }
                                />
                                {!extraSmallContainer && (groupIsSelected ? selectedGroup[0] : t('feed.SpecificGroup'))}
                            </span>
                        }
                        multiple={false}
                        handleChange={handleSelectChange}
                        titleValue
                        selectedValue={selectedGroup}
                        setSelectedValue={setSelectedGroup}
                        displaySearchField={true}
                        handleScroll={(e) => handleSelectScroll(e)}
                        selectSx={{
                            backgroundColor: checkGroupSelected('Individual Group').bgColor,
                            height: '40px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 4px #0000001a',
                        }}
                        iconStyle={{ marginLeft: 0, marginRight: '10px', color: checkGroupSelected('Individual Group').fgColor }}
                        inputLabelSx={{
                            marginLeft: '10px',
                            color: checkGroupSelected('Individual Group').fgColor,
                            paddingTop: '9px',
                            '& div': {
                                display: 'none',
                            },
                        }}
                        handleOptionClick={() => {}}
                        selectContainerSx={{ width: '100%' }}
                        searchFieldProps={{
                            placeholder: t('feed.Search') as string,
                            searchQuery: searchQuery,
                            sx: { margin: '5px', height: '35px', borderRadius: '8px' },
                            handleSearch: handleSearchQuery,
                            handleKeyDown: (e) => {
                                e.stopPropagation();
                            },
                        }}
                        loadingNextPage={isFetchingNextPage}
                        loading={isLoading}
                    />
                </Grid>
            </Tooltip>
        </Grid>
    );
};
