export default {
  "menus": {
    "home": "Menu principal",
    "feed": "Feed",
    "messages": "Mensajes",
    "connect": "Conectar",
    "awards": "Premios",
    "settings": "Configuraciones y Privacidad",
    "profile": "Perfil",
    "notifications": "Notificaciones",
    "change password": "Cambia la contraseña",
    "signOut": "Desconectar",
    "mark as read": "Marcar como Leído"
  },
  "settingOptions": {
    "title": "Configuraciones de Privacidad",
    "content": "Las cookies son pequeños archivos de texto que se colocan en su navegador o dispositivo móvil cuando visita un sitio o usa una aplicación. Se utilizan ampliamente para hacer que los sitios web y las aplicaciones funcionen, o funcionen de manera más eficiente, así como para proporcionar información a los propietarios del sitio/aplicación. La siguiente tabla explica las cookies que utilizamos y por qué.",
    "subQuestion": "¿Qué cookies utilizamos?",
    "subText": "Para más detalles, revisa nuestra ",
    "subTextDefault": "Para más detalles, revisa nuestra politica de privacidad y cookies",
    "necessaryTitle": "Estrictamente Necesarias",
    "necessary": "Las cookies necesarias son esenciales para la seguridad y la funcionalidad principal de la aplicación. Si está de acuerdo, también queremos utilizar cookies para:",
    "performanceTitle": "Cookies de rendimiento",
    "notificationSettings": "Configuración de las notificaciones",
    "emails": "Correos electrónicos",
    "p2pEmailsLabel": "Enviarme notificaciones por correo electrónico para {{p2pLabel}}",
    "performance": {
      "firstLine": "• Mejorar el rendimiento de la aplicación",
      "secondLine": "• Comprender cómo se utiliza la aplicación",
      "thirdLine": "• Optimizar el contenido"
    },
    "analyticsTitle": "Cookies de Analítica",
    "analytics": {
      "firstLine": "• Mejorar la experiencia de la aplicación",
      "secondLine": "• Servicios personalizados"
    },
    "save": "Guardar preferencias",
    "error": {
      "label": "Error",
      "text": "Se ha producido un error, por favor inténtelo de nuevo más tarde."
    }
  },
  "type search field": "Escriba y pulse enter.."
};