import React, { FunctionComponent } from 'react';

type HtmlContentProps = {
    content: string;
};

const HtmlContent: FunctionComponent<HtmlContentProps> = ({ content }) => {
    return (
        <div data-testid='html-content' className={'custom-layout-html h-100 pb-3'}>
            <div
                data-testid='html-content-value'
                className='text-wrap card h-100'
                dangerouslySetInnerHTML={{ __html: content }}
                style={{
                    borderRadius: '10px',
                    padding: '20px',
                    overflow: 'auto',
                }}
            ></div>
        </div>
    );
};

export default HtmlContent;
