import React, { FunctionComponent } from "react";
import classnames from "classnames";
import { Col } from "reactstrap";
import Scrollbar from "../../common/scrollbar/scrollbar";
import { LoginConfigs } from "../../../../types/types";
import configService from "../../../services/configService";

type NotificationPageProps = {
  className?: string;
  textClassName?: string;
  message: string;
  instruction?: string;
  data: LoginConfigs;
  loginData: LoginConfigs;
};

const NotificationPage: FunctionComponent<NotificationPageProps> = (props) => {
  const config = (key: string, def: any) => {
    return configService.getFromData(props.loginData, key, def);
  };

  return (
    <React.Fragment>
      <div
        data-testid="notification-page"
        style={{
          backgroundImage: `url(${props.loginData.background_logo})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          background: props.loginData.background_colour,
        }}
        className={"notification-page h-100"}
      >
        <Scrollbar
          contentProps={{
            className: "d-flex justify-content-center align-items-center",
          }}
        >
          <Col
            xs={"12"}
            sm={"8"}
            md={"6"}
            lg={"3"}
            className={"card reg-card b0 m-auto mt-2 mb-2"}
          >
            <div
              className={
                "card-header reg-header border-bottom-0 text-center w-100"
              }
            >
              <img
                data-testid="notification-page-image"
                src={config("foreground_logo", "/assets/img/logo.png")}
                alt="Image"
                className={"block-center w-60"}
              />
            </div>

            <div
              data-testid="notification-page-message"
              className={classnames(
                "alert alert-success mx-3",
                props.className
              )}
            >
              {props.message}
            </div>

            <div
              data-testid="notification-page-instruction"
              className={classnames(
                "font-weight-bold p-3",
                props.textClassName
              )}
              style={{ color: `${props.data.label_colour}` }}
            >
              {props.instruction}
            </div>
          </Col>
        </Scrollbar>
      </div>
    </React.Fragment>
  );
};

export default NotificationPage;