import customLayoutProvider from "../providers/customLayoutProvider";
import { ContentType } from "~/types/types";

const customLayoutService = {
  getLayoutData(
    type: ContentType,
    id: number,
    success: Function,
    error?: Function
  ) {
    customLayoutProvider.getLayoutData(type, id, success, error);
  },
  getContent(
    type: ContentType,
    id: number,
    page: number,
    search: string,
    success: Function,
    error?: Function
  ) {
    customLayoutProvider.getContent(type, id, page, search, success, error);
  },
  getFilteredContent(
    type: ContentType,
    id: number,
    value: string,
    page: number,
    success: Function,
    error?: Function
  ) {
    customLayoutProvider.getFilteredContent(
      type,
      id,
      value,
      page,
      success,
      error
    );
  },
  getSectionsData(
    ids: string,
    page: number,
    success: Function,
    error?: Function
  ) {
    customLayoutProvider.getSectionsData(ids, page, success, error);
  },
};

export default customLayoutService;
