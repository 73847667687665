import { useMutation } from "react-query";
import Client from "../services/axiosService";

export const newGroupThread = async (data: any) => { 
	return await Client(true, false).request({
		url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads`,
		method: "post",
		data: data,
        headers: { "Content-Type": "multipart/form-data" },
	});
};

export const useNewGroupThread = (onSuccess? : (data: any) => void, onError? : (error: any) => void) => {
    return useMutation((data: any) => {
        return newGroupThread(data);
    }, {
        onSuccess: onSuccess,
        onError: onError,
    });
};

export const updateGroupThread = async (id: any, data: any, isFormData: boolean = false) => {
	return await Client(true, isFormData ? false : true).request({
		url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${id}`,
		method: 'post',
		data: data,
        headers: { "Content-Type": isFormData ? "multipart/form-data" : "application/json-patch+json" },
	});
};

export const updateGroupThreadHook = (onSuccess? : (data: any) => void, onError? : (error: any) => void) => {
    return useMutation((data: any) => {
        return updateGroupThread(data.id, data.formData, data.isFormData);
    }, {
        onSuccess: onSuccess,
        onError: onError,
    });
};