import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../feedPage/feedPage.scss';
import objectAssign from 'object-assign';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import SocialPostItem from '../../feedPage/itemTypes/socialPost/SocialPostItem';
import { useSelector } from 'react-redux';
import { PostTabDetailType } from './contactDetails';
import connectService from '../../../services/connectService';
import { AxiosResponse } from 'axios';
import { NoResultsWidget } from '../../noResultWidget/noResultWidget';
import { Typography } from '@mui/material';
import socialPostsService from '../../../services/socialPostsService';

type PostItemProps = {
    selectedInUserId: string;
    scrollIsBottom: boolean;
    postTabDetail: PostTabDetailType;
    setPostTabDetail: React.Dispatch<React.SetStateAction<PostTabDetailType>>;
    groupsOnly?: boolean;
    groupID?: string;
    myGroups?: boolean | undefined;
    updateFeedData?: Function;
};
type PostItemModel = {
    id: string;
};
type FeedSocialPost = {
    model: PostItemModel;
    type: string;
    reason: string;
    author: string;
    is_liked?: boolean;
    likes: [];
    created_at_humans: string;
    files: [];
};

const PostsPage: FunctionComponent<PostItemProps> = ({
    selectedInUserId,
    scrollIsBottom,
    postTabDetail,
    setPostTabDetail,
    groupsOnly,
    groupID,
    myGroups,
    updateFeedData,
}) => {
    const { t } = useTranslation();
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    useEffect(() => {
        if (groupsOnly) {
            if (groupID) {
                getPostsData(groupID, 1);
            } else if (myGroups) {
                getPostsData('', 1);
            }
        } else {
            if (postTabDetail.postData.length == 0 && !postTabDetail.isInitialDataLoad) {
                getPostsData(selectedInUserId, 1);
            }
        }
    }, [groupID, groupsOnly, myGroups]);

    useEffect(() => {
        if (scrollIsBottom) {
            if (!postTabDetail.nextPageAvailable) return;
            setPostTabDetail((prev) => ({
                ...prev,
                pageNo: postTabDetail.pageNo + 1,
            }));
            if (groupsOnly) {
                if (groupID) {
                    getPostsData(groupID, postTabDetail.pageNo + 1);
                } else {
                    getPostsData('', postTabDetail.pageNo + 1);
                }
            } else {
                getPostsData(selectedInUserId, postTabDetail.pageNo + 1);
            }
        }
    }, [scrollIsBottom]);

    const handleResponse = (resp: AxiosResponse, pageNo: number) => {
        const isFirstPage = pageNo === 1;
        const newPostData = resp.data.data;
        const nextPageAvailable = resp.data.links.next != null;
        setPostTabDetail((prev) => {
            return {
                ...prev,
                postData: isFirstPage ? newPostData : [...prev.postData, ...newPostData],
                isPostsLoading: false,
                isLoadingMore: false,
                isInitialDataLoad: true,
                nextPageAvailable,
            };
        });
    };

    const getPostsData = (id: string | null | undefined, pageNo: number) => {
        pageNo === 1
            ? setPostTabDetail((prev) => ({
                  ...prev,
                  isPostsLoading: true,
                  pageNo: 1,
              }))
            : setPostTabDetail((prev) => ({
                  ...prev,
                  isLoadingMore: true,
              }));
        if (groupsOnly) {
            if (groupID) {
                socialPostsService.saveGroupsPostsData(id ?? '', pageNo, (resp: AxiosResponse) => {
                    handleResponse(resp, pageNo);
                });
            } else if (myGroups) {
                connectService.getMyGroupsPostsData(pageNo, (resp: AxiosResponse) => {
                    handleResponse(resp, pageNo);
                });
            }
        } else {
            connectService.getPostData(id ?? '', pageNo, (resp: AxiosResponse) => {
                handleResponse(resp, pageNo);
            });
        }
    };

    const likeContent = (contentType: string, contentId: number, isLiked: boolean) => {
        const newPostData = postTabDetail.postData ? [...postTabDetail.postData] : [];
        newPostData.forEach((item) => {
            if (item.type !== contentType || !item.model || item.model.id != contentId) return;
            item.model.is_liked = isLiked;
            item.model.likes += isLiked ? 1 : -1;
        });
        setPostTabDetail((prev) => ({
            ...prev,
            postData: newPostData,
        }));
    };

    const likePost = (postId: number, isLiked: boolean) => {
        likeContent('post', postId, isLiked);
    };

    const likeRepost = (postId: number, isLiked: boolean) => {
        likeContent('repost', postId, isLiked);
    };

    const updatePost = (post: FeedSocialPost) => {
        const newPostData = [...postTabDetail.postData];
        const item = newPostData.find((p) => (p.type === 'post' || p.type === 'card-p2p' || p.type === 'repost') && p.model.id === post.model.id);
        objectAssign(item, post);
        setPostTabDetail((prev) => ({
            ...prev,
            postData: newPostData,
        }));
    };

    const removeItem = (itemId: number, checkModel: boolean = true) => {
        setPostTabDetail((prev) => ({
            ...prev,
            postData: postTabDetail.postData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model : item;
                return dataSrc.id !== itemId;
            }),
        }));
    };

    const removeContentByUser = (userId: number, checkModel: boolean = true) => {
        setPostTabDetail((prev) => ({
            ...prev,
            postData: postTabDetail.postData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model.author : item;
                if (item.type === 'post') return dataSrc.id !== userId;
                else return true;
            }),
        }));
    };

    const renderPosts = () => {
        if (postTabDetail.postData.length > 0) {
            const contData: Array<React.ReactElement> = [];
            if (!postTabDetail.postData) return contData;
            postTabDetail.postData.forEach((item, index) => {
                contData.push(
                    <SocialPostItem
                        isLastItem={index === postTabDetail.postData.length - 1}
                        item={item}
                        setLiked={item.type === 'post' ? likePost : likeRepost}
                        setItem={updatePost}
                        removeItem={removeItem}
                        tyBranding={tenantInfo.tyBranding}
                        removeContentByUser={removeContentByUser}
                        repostWithThoughts={true}
                        addRepostToFeed={updateFeedData}
                    />
                );
            });
            return contData;
        } else {
            return (
                <NoResultsWidget
                    title={t('connect.noResultsFound')}
                    titleSx={{ display: 'none' }}
                    containerSx={{
                        flexGrow: 1,
                    }}
                    iconSx={{
                        '& img': {
                            width: '200px',
                            height: '200px',
                        },
                    }}
                    description={
                        <>
                            <Typography sx={{ fontSize: '20px', fontWeight: '520', textAlign: 'center', marginBottom: '12px' }}>
                                {t('general.noData')}
                            </Typography>
                            <Typography sx={{ fontSize: '16px', fontWeight: '500', textAlign: 'center' }}>
                                {t('general.noDataDescription')}
                            </Typography>
                        </>
                    }
                />
            );
        }
    };

    return (
        <>
            {postTabDetail.isPostsLoading ? (
                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center my-3' size={50} />
            ) : (
                <>
                    {renderPosts()}
                    {postTabDetail.isLoadingMore && (
                        <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center my-3' size={50} />
                    )}
                </>
            )}
        </>
    );
};

export default PostsPage;
