
import { useMutation } from 'react-query';
import Client from '../services/axiosService';

/**
 * @description: Makes a Post request to the API to post a favourite
 * @param data: The data to post
 * @returns: The response from the API
 */
export const updateFavourite = async ({ id, data }: { id: string; data: any }) => {
    return await Client(true, false).request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/connect/` + id + '/favorite',
        method: 'post',
        data: data,
        headers: { 'Content-Type': 'multipart/form-data' },
    });
}

/**
 * @description: A hook to use the postFavourite function
 * @returns: The response from the API
 */
export const useUpdateFavourite = (onSuccess?: (data: any) => void, onError?: () => void) => {
    return useMutation(updateFavourite, {
        onSuccess: onSuccess,
        onError: onError,
    });
  }