import React from 'react';
import { Box, Tab, Tabs, SxProps } from '@mui/material';
import { Link } from 'react-router-dom';
import { TabLinks } from '../../../types/interface/TabLinks';

export type TablistProps = {
    value: number;
    handleChange: (event: React.SyntheticEvent, newValue: number) => void;
    tabs: string[] | TabLinks[];
    links?: boolean;
    tabSx?: SxProps;
    tabsSx?: SxProps;
};

const a11yProps = (index: number) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
};

export const Tablist = (props: TablistProps) => {
    const { value, handleChange, tabs, links, tabSx, tabsSx } = props;
    return (
        <Box sx={{ width: '100%' }} data-testid="tablist-container">
            <Tabs value={value} onChange={handleChange} aria-label="tablist" sx={tabsSx} data-testid="tablist">
                {links
                    ? tabs.map((tab: TabLinks | string, index: number) => {
                          const { name, path, icon } = tab as TabLinks;
                          return (
                              <Tab
                                  key={index}
                                  label={name}
                                  value={index}
                                  tabIndex={index}
                                  component={Link}
                                  to={path}
                                  sx={tabSx}
                                  iconPosition="start"
                                  icon={<span className={icon} />}
                                  data-testid="tab-link"
                              />
                          );
                      })
                    : tabs.map((tab: TabLinks | string, index: number) => {
                          return (
                              <Tab
                                  key={index}
                                  label={tab as string}
                                  value={index}
                                  tabIndex={index}
                                  {...a11yProps(index)}
                                  sx={tabSx}
                                  data-testid="tab"
                              />
                          );
                      })}
            </Tabs>
        </Box>
    );
};
