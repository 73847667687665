import breadcrumbsProvider from "../providers/breadcrumbsProvider";
import { ContentType }     from "../../types/types";

const breadcrumbsService = {
  getData(id: number, type: ContentType, success: Function, error?: Function) {
    breadcrumbsProvider.getData(id, type, success, error);
  },
};

export default breadcrumbsService;
