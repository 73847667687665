import { AppReducerState } from '../../../types/types';

export const SET_APP_STATE = "SET_APP_STATE";

export function setAppState(appData: AppReducerState){
    return {
        type: SET_APP_STATE,
        payload: appData
    }
}

type SetAppAction = {
    type: typeof SET_APP_STATE,
    payload: AppReducerState;
} 


export type AppActionTypes = SetAppAction;