const urlRegex = /\b(?:\w+\.)?([a-z-0-9]+)\.(aero|arpa|asia|biz|cat|com|coop|de|edu|es|eu|gov|info|in|int|it|jobs|mil|museum|name|net|org|pro|pt|tel|travel|co\.uk|co\.jp)(?:\/[a-z0-9-]+)*\b/gi;

/**
 * @description: Get all urls from a string
 * @param text : string 
 * @returns : string[]
 */
const getAllUrls = (text: string): string[] => text.match(urlRegex) || [];

/**
 * @description: Get the first url from a string
 * @param message : string
 * @returns : string
 */
const getFirstUrl = (message: string): string => getAllUrls(message).length > 0 ? getAllUrls(message)[0] : ''
    
export {
    getAllUrls,
    getFirstUrl
}