import { Box, SxProps, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FetchNextPageOptions, InfiniteQueryObserverResult, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faPersonFromPortal } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { UserGroupsType } from '../../../types/interface/Thread';
import Scrollbar from '../common/scrollbar/scrollbar';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { IconCard } from '../IconCard/iconCard';
import './threadParticipantList.scss';
import { isWhite } from '../../../web/utils/colourCheck';

export type ThreadParticipantListProps = {
    fetchingUserGroups?: boolean;
    hasNextPage?: boolean;
    userGroups?: UserGroupsType;
    sx?: SxProps;
    isThreadAdmin: boolean;
    threadType: string | undefined;
    participantSearchString?: string;
    fetchNextPage?: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<any, unknown>>;
    handleSearchValueChange?: (value: any) => void;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    setDisplayThreadInfoEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    setOpenManageParticipantModal: React.Dispatch<React.SetStateAction<boolean>>;
    setOpenLeaveGroupModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const ThreadListParticipants: React.FC<ThreadParticipantListProps> = ({
    fetchingUserGroups,
    isThreadAdmin,
    threadType,
    hasNextPage,
    userGroups,
    sx,
    handleSearchValueChange,
    fetchNextPage,
    setDisplayThreadInfoEl,
    setOpenManageParticipantModal,
    setOpenLeaveGroupModal,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { id: threadID } = useParams<{ id: string }>();
    const tenantBranding = useSelector((state: any) => state.app.branding);

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        setSearchValue('');
    }, [threadID]);

    const handleCardClick = (contactId: number) => {
        navigate(`/connect/people/${contactId}`);
    };

    const renderParticipantList = useMemo(
        () =>
            userGroups?.map((userGroup) => {
                return userGroup.data.map((user, i) => (
                    <IconCard
                        key={i}
                        title={user.full_name}
                        icon={user.avatar_url}
                        handleClick={() => handleCardClick(user.contact_id)}
                        titleStyle={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {user.is_admin && <span className='font-16 fa-regular fa-crown' />}
                    </IconCard>
                ));
            }),
        [userGroups]
    );

    const pageScroll = (e: { scrollTop: number; clientHeight: number; scrollHeight: number }) => {
        if (!fetchingUserGroups && hasNextPage && Math.ceil(e.scrollTop + e.clientHeight) >= e.scrollHeight) {
            if (fetchNextPage) fetchNextPage();
        }
    };

    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const leaveButtonBg = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const leaveButtonFg = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;
    return (
        <Box sx={sx} className='participants-info mt-2' data-testid='participants-info'>
            <div className='d-flex justify-content-between'>
                <Typography className='mb-1' sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '1.5' }}>
                    {t('chat.participants')}
                </Typography>
                {isThreadAdmin && threadType == 'multiple' && (
                    <React.Fragment>
                        <button className='manage-participant-button' onClick={() => setOpenManageParticipantModal(true)}>
                            <Typography className='me-2' sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '1.5' }}>
                                {t('chat.manage')}
                            </Typography>
                            <svg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                <path
                                    d='M9.5 0C7.62108 0 5.78435 0.557165 4.22209 1.60104C2.65982 2.64491 1.44218 4.12861 0.723149 5.86451C0.00411622 7.60041 -0.184015 9.51054 0.182544 11.3534C0.549104 13.1962 1.45389 14.8889 2.78249 16.2175C4.11109 17.5461 5.80383 18.4509 7.64665 18.8175C9.48947 19.184 11.3996 18.9959 13.1355 18.2769C14.8714 17.5578 16.3551 16.3402 17.399 14.7779C18.4428 13.2157 19 11.3789 19 9.5C18.9973 6.98128 17.9955 4.5665 16.2145 2.78549C14.4335 1.00449 12.0187 0.00272419 9.5 0ZM12.6667 10.2917H10.2917V12.6667C10.2917 12.8766 10.2083 13.078 10.0598 13.2265C9.91133 13.3749 9.70997 13.4583 9.5 13.4583C9.29004 13.4583 9.08868 13.3749 8.94021 13.2265C8.79175 13.078 8.70834 12.8766 8.70834 12.6667V10.2917H6.33334C6.12337 10.2917 5.92201 10.2083 5.77354 10.0598C5.62508 9.91133 5.54167 9.70996 5.54167 9.5C5.54167 9.29004 5.62508 9.08867 5.77354 8.94021C5.92201 8.79174 6.12337 8.70833 6.33334 8.70833H8.70834V6.33333C8.70834 6.12337 8.79175 5.92201 8.94021 5.77354C9.08868 5.62507 9.29004 5.54167 9.5 5.54167C9.70997 5.54167 9.91133 5.62507 10.0598 5.77354C10.2083 5.92201 10.2917 6.12337 10.2917 6.33333V8.70833H12.6667C12.8766 8.70833 13.078 8.79174 13.2265 8.94021C13.3749 9.08867 13.4583 9.29004 13.4583 9.5C13.4583 9.70996 13.3749 9.91133 13.2265 10.0598C13.078 10.2083 12.8766 10.2917 12.6667 10.2917Z'
                                    fill={leaveButtonBg}
                                />
                            </svg>
                        </button>
                    </React.Fragment>
                )}
            </div>
            <div className='search-section d-flex align-items-center'>
                <p className='fa-regular form-control search-icon fa-magnifying-glass m-0'></p>
                <input
                    type='text'
                    className='participants-search'
                    placeholder={`${t('chat.searchParticipants')}`}
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                        queryClient.invalidateQueries(['participants', threadID, e.target.value]);
                        if (handleSearchValueChange) handleSearchValueChange(e);
                    }}
                />
            </div>
            <div className='participants-list h-100'>
                {userGroups && userGroups[0]?.data?.length === 0 ? (
                    <p className='m-0 mt-2 w-100 d-flex justify-content-center'>{t('connect.noResultsFound')}</p>
                ) : (
                    <Scrollbar onScroll={pageScroll} className='following-detail-modal-scrollbar' rightScroll={true} noScrollX>
                        {renderParticipantList}
                    </Scrollbar>
                )}
                {fetchingUserGroups && <SpinnerLoad className='w-100 d-flex align-items-center justify-content-center mt-3' size={50} />}
            </div>
            {!isThreadAdmin && (
                <>
                    <button
                        className='leave-group-btn d-flex justify-content-center align-items-center mt-1 mb-4'
                        id='leave-group-btn'
                        onClick={() => {
                            setDisplayThreadInfoEl(null);
                            setOpenLeaveGroupModal(true);
                        }}
                        style={{ backgroundColor: leaveButtonBg, color: leaveButtonFg }}
                    >
                        <FontAwesomeIcon className='leave-group-icon me-2' icon={faPersonFromPortal} />
                        <p className='m-0 leave-group-text'>{t('chat.leaveGroup')}</p>
                    </button>
                </>
            )}
        </Box>
    );
};

export default ThreadListParticipants;
