import { useEffect, useRef, useState } from 'react';

type ObserverRect = Omit<DOMRectReadOnly, 'toJSON'>;

export const useResizeObserver = (): Array<any> => {
    const ref = useRef<any>(null);
    const [container, setContainer] = useState<ObserverRect>();

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (ref.current) {
                const boundingRect = ref.current.getBoundingClientRect();
                setContainer(boundingRect);
            }
        });
        if (ref.current) {
            observer?.observe(ref.current);
        }
        return () => observer.disconnect();
    }, [ref]);

    return [ref, container];
};
