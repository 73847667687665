

export const truncateText = (fileName: string, firstCharCount = fileName.length, endCharCount: number, dotCount: number) => {
    if (fileName.length <= firstCharCount + endCharCount) {
        return fileName;
    }

    const firstPortion = fileName.slice(0, firstCharCount);
    const endPortion = fileName.slice(-endCharCount);
    const dots = '.'.repeat(dotCount);

    return `${firstPortion}${dots}${endPortion}`;
}