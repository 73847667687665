import { useInfiniteQuery,  } from "react-query";
import Client from "../../services/axiosService";

export type LocationQueryParams = {
    searchQuery: string | null;
    prefix: string;
}

/**
 * @description: Fetches locations results from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchLocations = async ({searchQuery, prefix}: LocationQueryParams, page: number) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/locations?search=${searchQuery}&page=${page}&prefix=${prefix}`,
        method: "get",
    })

    return response.data;
}

/**
 * @description: Fetches locations results from the API and returns the data in the form of infinite query
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useLocationsQuery = (LocationQueryParams: LocationQueryParams) => {
    const { searchQuery, prefix } = LocationQueryParams;
    return useInfiniteQuery(
        ['locations', searchQuery, prefix],
        ({ pageParam = 1 }) => fetchLocations(LocationQueryParams, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
        }
    );
}