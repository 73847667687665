import { useInfiniteQuery } from 'react-query';
import Client from '../../services/axiosService';

const fetchOptions = async (searchQuery: string | undefined, page: number) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/community-groups/mine?page=${page}&search=${searchQuery}`,
        method: 'get',
    });
    return response.data;
};

export const useCommunityFilterQuery = ({ searchQuery }: { searchQuery?: string }) => {
    return useInfiniteQuery(['community-groups', searchQuery], ({ pageParam = 1 }) => fetchOptions(searchQuery, pageParam), {
        getNextPageParam: (lastPage, pages) => {
            if (lastPage?.links?.next) return pages.length + 1;
            return undefined;
        },
        refetchOnWindowFocus: false,
    });
};
