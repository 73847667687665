import notificationsProvider from '../providers/notificationsProvider';

const notificationsService = {
    getAll(page: number, success: Function, error?: Function){
        notificationsProvider.getAll(page, success, error);
    },

    markAsRead(id: number, isRead: boolean, success: Function, error?: Function){
        notificationsProvider.markAsRead(id, isRead, success, error);
    },

    markAllAsRead(success: Function, error?: Function){
        notificationsProvider.markAllAsRead(success, error);
    }
}

export default notificationsService;