import React from 'react';
import { Box, Typography, BoxProps, TypographyProps } from '@mui/material';
import { getAlphabets } from '../../utils/getAlphabets';

export type AlphabetListProps = {
    containerProps?: BoxProps;
    alphabetProps?: TypographyProps;
    handleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
    selectedAlphabet?: string;
};

export const AlphabetList = (props: AlphabetListProps) => {
    const { containerProps, alphabetProps, handleClick, selectedAlphabet } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
                alignItems: 'center',
                justifyContent: 'center',
                ...containerProps?.sx,
            }}
            data-testid='alphabet-list'
            {...containerProps}
        >
            {getAlphabets().map((alphabet, index) => {
                return (
                    <Typography
                        key={index}
                        sx={{
                            fontSize: '14px',
                            fontWeight: selectedAlphabet === alphabet ? '600' : '400',
                            cursor: 'pointer',
                            flexGrow: 1,
                            ...alphabetProps?.sx,
                        }}
                        data-char={alphabet}
                        onClick={handleClick}
                        {...alphabetProps}
                    >
                        {alphabet}
                    </Typography>
                );
            })}
        </Box>
    );
};
