import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import './createSocialPost.scss';
import socialPostsService from '../../services/socialPostsService';
import { AxiosError, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ModalComponent from '../../../web/components/common/modal/modal';
import Spinner from '../../../web/components/common/spinnerLoad/spinnerLoad';
import DraftEditor from '../common/draftEditor/DraftEditor';
import { FeedSocialPost } from '../../../types/types';
import { Alert, Typography } from '@mui/material';
import { RepostItem } from '../repostItem/repostItem';
import { useNavigate } from 'react-router-dom';
import { Select } from '../select/select';
import { useSearchHook } from '../../hooks/useSearchHook';
import { useCommunityFilterQuery } from '../../hooks/query/useCommunityFilterOptionsQuery';
import { useDebounce } from '../../hooks/useDebounceHook';
import handPointerIcon from '../../../icons/hand-pointer-light-icon.svg';

const renderHtml = require('html-react-parser');

type SocialPostCreateProps = {
    userData?: any;
    userInfo?: any;
    className?: string;
    feedDataMethod?: Function;
    repostModal?: boolean;
    repost?: boolean;
    repostItem?: FeedSocialPost;
    setRepostModal?: Function;
    defaultSelectedGroup?: string;
    isSelected?: string;
    filterOptions?: {
        all: string;
        myGroups: string;
    };
};
export interface fileData {
    id: string;
    mime_type: string;
    preview: string;
    url: string;
    type: string;
    name: string;
}

const CreateSocialPost: FunctionComponent<SocialPostCreateProps> = ({
    userData,
    userInfo,
    className,
    feedDataMethod,
    repostModal,
    repost,
    repostItem,
    setRepostModal,
    defaultSelectedGroup,
    isSelected,
    filterOptions,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { tenant, user } = useSelector((state: any) => state.app);
    const [isSubmitContent, setIsSubmitContent] = useState(false);
    const [editorTextValue, setEditorTextValue] = useState(''); // DraftEditor Input value
    const [isBoldText, setIsBoldText] = useState(false);
    const [isItalicText, setIsItalicText] = useState(false);
    const { searchQuery: searchCommunityGroup, handleChange: handleSearchCommunityGroup } = useSearchHook('');
    const { debouncedValue: debounceSearchCommunityGroup } = useDebounce(searchCommunityGroup);
    const [selectedGroup, setSelectedGroup] = useState<string[]>([]);
    const [canPost, setCanPost] = useState<boolean>(true);
    const {
        data: communityGroup,
        isFetching: fetchingCommunityGroups,
        isLoading,
        fetchNextPage: communityGroupsNextPage,
        isFetchingNextPage: fetchingNextCommunityGroups,
    } = useCommunityFilterQuery({ searchQuery: debounceSearchCommunityGroup });
    const navigate = useNavigate();
    const groupSelected: boolean = selectedGroup.length > 0;

    const [socialPostModal, setSocialPostModal] = useState(false);
    const [disableGroupSelect, setDisableGroupSelect] = useState(false);
    const toggleCreateSocialPostModal = () => {
        if (!socialPostModal) {
            if (defaultSelectedGroup) {
                setSelectedGroup([defaultSelectedGroup]);
                checkCanPost(defaultSelectedGroup);
                setDisableGroupSelect(true);
            } else {
                setSelectedGroup([]);
                setDisableGroupSelect(false);
            }
        }
        setSocialPostModal(!socialPostModal);
        if (socialPostModal) {
            setRepostModal && setRepostModal(false);
        }
        setFilesInfo([]);
    };

    useEffect(() => {
        const root = document.documentElement;
        root?.style.setProperty('--cursor-type', socialPostModal ? 'text' : 'pointer');
    }, [socialPostModal]);

    useEffect(() => {
        if (defaultSelectedGroup) {
            setSelectedGroup([defaultSelectedGroup]);
            checkCanPost(defaultSelectedGroup);
        } else if (groupSelected && !defaultSelectedGroup) {
            setSelectedGroup([]);
            setCanPost(true);
        }
    }, [defaultSelectedGroup]);

    // Load Spinner init
    const [contentLoading, setContentLoading] = useState(false);

    // FILES HANDLING
    const fileButton = useRef<HTMLInputElement>(null);
    const videoButton = useRef<HTMLInputElement>(null);
    const imageButton = useRef<HTMLInputElement>(null);

    const [filesInfo, setFilesInfo] = useState<Array<fileData>>([]);
    const [count, setCount] = useState(0);

    // Triggers for the file inputs
    const triggerInputImage = () => {
        if (imageButton && imageButton.current) {
            imageButton.current.click();
        }
    };

    const triggerInputVideo = () => {
        if (videoButton && videoButton.current) {
            videoButton.current.click();
        }
    };

    const triggerInputFile = () => {
        if (fileButton && fileButton.current) {
            fileButton.current.click();
        }
    };

    const checkCanPost = (selectedOption: string | null) => {
        if (!selectedOption) {
            setCanPost(true);
            return;
        }
        if (communityGroup) {
            communityGroup?.pages.map((page: any) => {
                page?.data.find((group: any) => {
                    if (selectedOption === group.title) {
                        setCanPost(group.can_post);
                        return;
                    }
                });
            });
        } else {
            setCanPost(true);
        }
    };

    const selectCommunityGroupOptions = (groupData: any) => {
        const communityGroupOptions: {
            id: string;
            title: string;
            image?: string;
        }[] = [];
        groupData?.pages?.map((page: any) => {
            page?.data?.map((group: any) => {
                communityGroupOptions.push({ id: group.id, title: group.title, image: group.image_url });
            });
        });
        return communityGroupOptions;
    };

    const [isOpen, setIsOpen] = useState(false);
    const [file, setFile] = useState<fileData>();

    useEffect(() => {
        if (repostModal) {
            setSocialPostModal(repostModal);
            if (repost && repostItem) {
                const originalGroupPost = repostItem?.model.community_group;
                if (originalGroupPost && originalGroupPost.can_post) {
                    setSelectedGroup([originalGroupPost.title]);
                    setCanPost(true);
                }
            }
        }
    }, [repostModal, repost, repostItem]);

    /**
     * Saves the files in S3 and prepares the data to render.
     * @param event
     */
    const saveInS3 = (event: any) => {
        let files = event.target.files;

        if (files.length > 0) setContentLoading(true);

        let formData = new FormData();
        let toSend = 0;
        let tooBigFiles = 0;

        for (let i = 0; i < files.length; i++) {
            if (parseInt((files[i].size / 1024 / 1024).toFixed(2)) <= tenant.uploadMaxSize) {
                setCount(count + 1);
                toSend = toSend + 1;
                formData.append('files[]', files[i]);
            } else {
                tooBigFiles = tooBigFiles + 1;
            }
        }

        if (toSend > 0) {
            socialPostsService.saveSocialPostFile(
                formData,
                (resp: AxiosResponse) => {
                    setFilesInfo((filesInfo) => [...filesInfo, ...resp.data.data]);
                    setContentLoading(false);
                },
                (err: AxiosError) => {
                    setContentLoading(false);
                    console.log('err', err);
                    dispatch(
                        addNotification({
                            label: t('socialPost.socialPost'),
                            text: t('socialPost.mediaFail'),
                            type: 'danger',
                        })
                    );
                }
            );
        }
        if (tooBigFiles > 0) {
            if (toSend === 0) setContentLoading(false);

            dispatch(
                addNotification({
                    label: t('socialPost.socialPost'),
                    text: t('socialPost.mediaTooBig') + tenant.uploadMaxSize + ' MB',
                    type: 'danger',
                })
            );
        }
    };

    /**
     * Call the structure for the social post.
     */
    const render = () => {
        if (filesInfo.length > 0) {
            let content: Array<React.ReactElement> = [];
            filesInfo.forEach((element) => {
                content.push(structure(element));
            });

            return <Row className='px-2'>{content}</Row>;
        }
    };

    const handleSelectChange = (event: any) => {
        event.stopPropagation();
        if (selectedGroup?.includes(event.target.value)) {
            setSelectedGroup([]);
            checkCanPost(null);
        } else {
            setSelectedGroup([event.target.value] as string[]);
            checkCanPost(event.target.value);
        }
    };

    /***
     * Removes the element by id.
     * @param mediaID
     */
    const removeImage = (mediaID: string) => {
        setCount(count - 1);
        setFilesInfo(filesInfo.filter((item) => item.id !== mediaID));
    };

    const modalTrigger = (file: fileData) => {
        setFile(file);
        setIsOpen(true);
    };

    const getSelectedGroupData = (
        options: {
            id: string;
            title: string;
            image?: string;
        }[]
    ):
        | {
              id: string;
              title: string;
              image?: string;
          }
        | undefined => {
        if (selectedGroup.length === 0) return undefined;
        let group = options.find((option: any) => {
            return option.title === selectedGroup[0];
        });
        return group;
    };

    /**
     * Create the structure to add the files to the
     * preview of the social post.
     * @param file
     */
    const structure = (file: fileData) => {
        if (file.type === 'image') {
            return (
                <Col xs={'4'} data-fileID={file.id} style={{ paddingRight: '0px' }} className={'mb-1 d-flex'}>
                    <div className={'image-container'} style={{}}>
                        <img src={file.url} alt='image' className={'img-preview-sp c-pointer'} onClick={() => modalTrigger(file)} />
                    </div>
                    <div style={{ float: 'right', position: 'relative' }} className={'pb-3'}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => removeImage(file.id)}
                            className={'media-icons c-pointer ms-1'}
                            style={{ color: '#d9534f', fontSize: '16px', float: 'right' }}
                        />
                    </div>
                </Col>
            );
        }
        if (file.type === 'video') {
            let videoPreview = 'url(' + file.preview + ')';
            return (
                <Col xs={'4'} data-fileID={file.id} style={{ paddingRight: '0px' }} className={'mb-1 d-flex'}>
                    <div
                        className={'d-flex justify-content-center align-items-center video-display-preview'}
                        onClick={() => modalTrigger(file)}
                        style={{ backgroundImage: videoPreview }}
                    >
                        <img
                            className='video-preview-icon position-absolute img-responsive cursor-pointer'
                            src='/assets/img/video-preview-icon.svg'
                            alt='video'
                        />
                    </div>
                    <div style={{ float: 'right' }} className={'pb-3'}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => removeImage(file.id)}
                            className={'media-icons c-pointer ms-1'}
                            style={{ fontSize: '16px', color: '#d9534f', float: 'right' }}
                        />
                    </div>
                </Col>
            );
        } else {
            return (
                <Col xs={'4'} data-fileID={file.id} style={{ paddingRight: '0px' }} className={'mb-1 d-flex'}>
                    <a className='doc-preview d-block mb-1 doc-container' href={file.url} target='_blank'>
                        <img className='d-inline-block ms-0 img-preview-sp' src={file.preview} />
                    </a>
                    <div style={{ float: 'right' }} className={'pb-3'}>
                        <FontAwesomeIcon
                            icon={faTimes}
                            onClick={() => removeImage(file.id)}
                            className={'media-icons c-pointer ms-1'}
                            style={{ fontSize: '16px', color: '#d9534f', float: 'right' }}
                        />
                    </div>
                </Col>
            );
        }
    };

    /**
     * Save the data in DB, reset the social post create
     * element and insert the new item in the feed.
     */
    const saveSocialPost = (value: string) => {
        setIsSubmitContent(false);

        let fileIds: Array<any> = [];

        if (filesInfo.length > 0) {
            filesInfo.forEach((el) => {
                fileIds.push(el.id);
            });
        }

        // Check if the social post has content before saving
        if ((value.length === 0 || value === '<p><br></p>') && fileIds && fileIds.length === 0) {
            dispatch(
                addNotification({
                    label: t('socialPost.socialPost'),
                    text: t('socialPost.emptyField'),
                    type: 'danger',
                })
            );
            return;
        }
        // Pass message value as object where contentValue = editorTextContent with block format, textValue = plain text with user id
        const message = value;

        if (repost && repostItem) {
            socialPostsService.saveRepostWithThoughts(
                repostItem.model.id,
                message,
                (resp: AxiosResponse) => {
                    addSocialPostItem(resp.data.data);
                    toggleCreateSocialPostModal();
                    dispatch(addNotification({ label: '', text: t('socialPost.repostSuccessMessage'), type: 'success' }));
                },
                () => {
                    dispatch(addNotification({ label: t('socialPost.SocialPost'), text: t('socialPost.postedFail'), type: 'danger' }));
                }
            );
            return;
        }

        if (groupSelected && isSelected === filterOptions?.myGroups) {
            socialPostsService.saveSocialPostForGroup(
                {
                    fileIds: fileIds,
                    message: message,
                },
                parseInt(getSelectedGroupData(selectCommunityGroupOptions(communityGroup))?.id as string),
                (resp: AxiosResponse) => {
                    addSocialPostItem(resp.data.data);
                    toggleCreateSocialPostModal();
                },
                () => {
                    toggleCreateSocialPostModal();
                    dispatch(
                        addNotification({
                            label: t('socialPost.socialPost'),
                            text: t('socialPost.postedFail'),
                            type: 'danger',
                        })
                    );
                }
            );
            return;
        }

        socialPostsService.saveSocialPost(
            {
                fileIds: fileIds,
                message: message,
            },
            (resp: AxiosResponse) => {
                // Create the new item in feed
                addSocialPostItem(resp.data.data);
                toggleCreateSocialPostModal();
            },
            () => {
                toggleCreateSocialPostModal();
                dispatch(
                    addNotification({
                        label: t('socialPost.socialPost'),
                        text: t('socialPost.postedFail'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    /**
     * Reset the social post create elements.
     * @param resp
     */
    const addSocialPostItem = (resp: any) => {
        setCount(0);
        setFilesInfo([]);
        feedDataMethod && feedDataMethod([resp]);
    };
    /**
     * Reset the event to be able to upload the same file multiple times.
     *
     * @param event
     */
    const updateEvent = (event: any) => {
        event.target.value = null;
    };

    const handleSelectScroll = (e: any) => {
        e.stopPropagation();
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (Math.round(scrollTop + clientHeight) + 1 >= scrollHeight) {
            !fetchingCommunityGroups && communityGroupsNextPage();
        }
    };

    return (
        <div className={classnames('social-post-input-comp position-relative', className)}>
            <Row
                onClick={toggleCreateSocialPostModal}
                data-testid='create-social-post'
                className='create-social-post-row p-3 pb-1'
                style={{
                    display: repost ? 'none' : 'block',
                }}
            >
                <Col lg={12} xs={12}>
                    <Row>
                        <Col lg={12} xs={12} className='d-flex align-items-center pr-2'>
                            <img
                                className={'user-profile-image rounded-circle thumb48 d-inline-block mt-2 mb-3'}
                                alt='user profile image'
                                src={userInfo.image_url}
                            />
                            <div style={{ wordBreak: 'break-all' }} className={'editor-section ms-2'}>
                                <input
                                    className='create-social-post-input'
                                    type='text'
                                    placeholder={`${t('socialPost.what do you have to say')} ${user.full_name}?`}
                                    readOnly
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className={'mb-2 border-bottom pb-3 text-light-black'} />
                    <Row className={'media-bar'}>
                        <Col lg={4} xs={4} className={'py-0 d-flex justify-content-center align-items-center'}>
                            <input type='file' id='images' name={'images'} style={{ display: 'none' }} multiple />
                            <button className='d-flex justify-content-center align-items-center border-none' style={{ background: 'white' }}>
                                <i
                                    className='fa-solid fa-camera c-pointer ms-1 media-icons'
                                    style={{
                                        fontSize: '26px',
                                        color: '#009abc',
                                        fontStyle: 'normal',
                                    }}
                                ></i>
                                <h3 className='ms-3 mt-2 font-primary-text'>{t('feed.Photo')}</h3>
                            </button>
                        </Col>
                        <Col lg={4} xs={4} className={'py-0 media-elements d-flex justify-content-center align-items-center'}>
                            <input type='file' id='videos' name={'videos'} style={{ display: 'none' }} accept={'video/*'} multiple />
                            <button className='d-flex justify-content-center align-items-center border-none' style={{ background: 'white' }}>
                                <i
                                    className='fa-brands fa-youtube c-pointer ms-1 media-icons'
                                    style={{
                                        fontSize: '26px',
                                        color: '#62257D',
                                        fontStyle: 'normal',
                                    }}
                                ></i>
                                <h3 className='ms-3 mt-2 font-primary-text'>{t('feed.Video')}</h3>
                            </button>
                        </Col>
                        <Col lg={4} xs={4} className={'py-0 media-elements d-flex justify-content-center align-items-center'}>
                            <input
                                type='file'
                                id='file'
                                name={'upfiles'}
                                style={{ display: 'none' }}
                                accept={'application/*, text/*, .docx'}
                                multiple
                            />
                            <button className='d-flex justify-content-center align-items-center border-none' style={{ background: 'white' }}>
                                <i
                                    className='fa-solid fa-link-simple c-pointer ms-1 media-icons'
                                    style={{
                                        fontSize: '26px',
                                        color: '#EB5A3B',
                                        fontStyle: 'normal',
                                        transform: 'rotate(-30deg)',
                                    }}
                                ></i>
                                <h3 className='ms-3 mt-2 font-primary-text'>{t('feed.Files')}</h3>
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {socialPostModal && (
                <Modal
                    className='create-social-post-modal modal-dialog-centered'
                    isOpen={socialPostModal}
                    data-testid='create-social-post-modal'
                    toggle={toggleCreateSocialPostModal}
                >
                    <ModalHeader className='w-100'>
                        <Row className='w-100'>
                            <Col lg={12} xs={12} className='d-flex align-items-center justify-content-center'>
                                <h3 className='create-social-post-header mt-1'>{t('feed.CreateASocialPost')}</h3>
                            </Col>
                        </Row>
                        <div
                            onClick={toggleCreateSocialPostModal}
                            data-testid='modal-close-btn'
                            className='modal-close-btn d-flex align-items-center'
                        >
                            <svg xmlns='http://www.w3.org/2000/svg' id='Outline' viewBox='0 0 24 24' width='30' height='30'>
                                <path d='M18,6h0a1,1,0,0,0-1.414,0L12,10.586,7.414,6A1,1,0,0,0,6,6H6A1,1,0,0,0,6,7.414L10.586,12,6,16.586A1,1,0,0,0,6,18H6a1,1,0,0,0,1.414,0L12,13.414,16.586,18A1,1,0,0,0,18,18h0a1,1,0,0,0,0-1.414L13.414,12,18,7.414A1,1,0,0,0,18,6Z' />
                            </svg>
                        </div>
                    </ModalHeader>
                    <ModalBody className='pb-0'>
                        <Row>
                            <Col lg={12} xs={12} className='d-flex'>
                                <img
                                    className={'user-profile-image rounded-circle thumb48 d-inline-block mt-1 mb-3'}
                                    alt='user profile image'
                                    src={userInfo.image_url}
                                />
                                <div className={'editor-section ms-2'}>
                                    <DraftEditor
                                        className='create-social-post-input-modal'
                                        isSubmitContent={isSubmitContent}
                                        placeholder={`${t('socialPost.what do you have to say')} ${user.full_name}?`}
                                        isBoldText={isBoldText}
                                        isItalicText={isItalicText}
                                        setEditorTextValue={setEditorTextValue}
                                        handleEditorContentSubmit={saveSocialPost}
                                        setIsBoldText={setIsBoldText}
                                        setIsItalicText={setIsItalicText}
                                        sendHTMLContent
                                    />
                                </div>
                            </Col>
                        </Row>

                        {contentLoading ? (
                            <div className='d-flex align-items-center justify-content-center h-100 mt-2'>
                                <Spinner />
                            </div>
                        ) : (
                            ''
                        )}
                        <div className={'files-display-comp-sp ms-5'} style={{ marginTop: count > 0 ? '10px' : '' }}>
                            {count > 0 && <div className={classnames('media-container-sp')}>{render()}</div>}
                        </div>
                        <div className={'media-bar d-flex justify-content-between align-items-center'}>
                            <span id='toolbar' className={'py-0 ms-5'} style={{ display: 'inline-block', verticalAlign: 'super' }}>
                                <span className='ql-formats d-flex align-items-center'>
                                    <button
                                        data-testid='bold-btn'
                                        className='ql-bold'
                                        style={{ height: '35px' }}
                                        onClick={() => setIsBoldText(!isBoldText)}
                                    >
                                        <img src='https://cdn.engageesp.com/img/icon/bold.svg' alt='bold-icon' />
                                    </button>
                                    <button
                                        data-testid='italic-btn'
                                        className='ql-italic'
                                        style={{ height: '35px', marginLeft: '3px' }}
                                        onClick={() => setIsItalicText(!isItalicText)}
                                    >
                                        <img src='https://cdn.engageesp.com/img/icon/italic.svg' alt='italic-icon' />
                                    </button>
                                </span>
                            </span>
                            {!repost && (
                                <div className='py-0 d-flex justify-content-end align-items-center'>
                                    <div data-testid='social-option' className='image p-0 m-0'>
                                        <input
                                            type='file'
                                            id='images'
                                            name={'images'}
                                            ref={imageButton}
                                            style={{ display: 'none' }}
                                            accept={'image/*'}
                                            onChange={saveInS3}
                                            onClick={updateEvent}
                                            multiple
                                        />
                                        <button
                                            className='d-flex justify-content-center align-items-center border-none'
                                            style={{ background: 'white' }}
                                            onClick={triggerInputImage}
                                        >
                                            <i
                                                className='fa-solid fa-camera c-pointer ms-1 media-icons'
                                                style={{
                                                    fontSize: '26px',
                                                    color: '#009abc',
                                                    fontStyle: 'normal',
                                                }}
                                            ></i>
                                            <h4 className='ms-2 mt-2 font-primary-text' style={{ fontWeight: '600' }}>
                                                {t('feed.Photo')}
                                            </h4>
                                        </button>
                                    </div>
                                    <div data-testid='social-option' className='video p-0 m-0'>
                                        <input
                                            type='file'
                                            id='videos'
                                            name={'videos'}
                                            ref={videoButton}
                                            style={{ display: 'none' }}
                                            accept={'video/*'}
                                            onChange={saveInS3}
                                            onClick={updateEvent}
                                            multiple
                                        />
                                        <button
                                            className='d-flex justify-content-center align-items-center border-none'
                                            style={{ background: 'white' }}
                                            onClick={triggerInputVideo}
                                        >
                                            <i
                                                className='fa-brands fa-youtube c-pointer ms-1 media-icons'
                                                style={{
                                                    fontSize: '26px',
                                                    color: '#62257D',
                                                    fontStyle: 'normal',
                                                }}
                                            ></i>
                                            <h4 className='ms-2 mt-2 font-primary-text' style={{ fontWeight: '600' }}>
                                                {t('feed.Video')}
                                            </h4>
                                        </button>
                                    </div>
                                    <div data-testid='social-option' className='files p-0 m-0'>
                                        <input
                                            type='file'
                                            id='file'
                                            name={'upfiles'}
                                            ref={fileButton}
                                            style={{ display: 'none' }}
                                            accept={'application/*, text/*, .docx'}
                                            onChange={saveInS3}
                                            onClick={updateEvent}
                                            multiple
                                        />
                                        <button
                                            className='d-flex justify-content-center align-items-center border-none'
                                            style={{ background: 'white' }}
                                            onClick={triggerInputFile}
                                        >
                                            <i
                                                className='fa-solid fa-link-simple c-pointer ms-1 media-icons'
                                                style={{
                                                    fontSize: '26px',
                                                    color: '#EB5A3B',
                                                    fontStyle: 'normal',
                                                    transform: 'rotate(-30deg)',
                                                }}
                                            ></i>
                                            <h4 className='ms-2 mt-2 font-primary-text' style={{ fontWeight: '600' }}>
                                                {t('feed.Files')}
                                            </h4>
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isOpen ? <ModalComponent isOpen={isOpen} file={file} setIsOpen={setIsOpen} /> : ''}
                        </div>
                        {communityGroup?.pages[0].data.length > 0 && <div className='ms-5'>
                            <Select
                                name={'select-community-group'}
                                inputLabel={true}
                                displayOptionImages
                                options={selectCommunityGroupOptions(communityGroup)}
                                label={
                                    <Typography>
                                        <span style={{ width: '175px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                            <img
                                                src={
                                                    groupSelected
                                                        ? getSelectedGroupData(selectCommunityGroupOptions(communityGroup))?.image ||
                                                          'https://cdn.engageesp.com/img/person.png'
                                                        : handPointerIcon
                                                }
                                                alt={getSelectedGroupData(selectCommunityGroupOptions(communityGroup))?.title + '-img' || 'label-img'}
                                                style={
                                                    groupSelected
                                                        ? { width: '24px', height: '24px', borderRadius: '50%', marginRight: '10px' }
                                                        : { width: '25px', height: '20px', marginRight: '5px', paddingBottom: '4px' }
                                                }
                                            />
                                            {groupSelected ? selectedGroup[0] : t('feed.selectGroupToPost')}
                                        </span>
                                    </Typography>
                                }
                                multiple={false}
                                handleChange={handleSelectChange}
                                titleValue
                                selectedValue={selectedGroup}
                                setSelectedValue={setSelectedGroup}
                                displaySearchField={true}
                                handleScroll={(e) => handleSelectScroll(e)}
                                selectSx={{
                                    backgroundColor: 'white',
                                    height: '40px',
                                    borderRadius: '8px',
                                }}
                                iconStyle={{ marginLeft: 0, marginRight: '10px', color: 'black' }}
                                inputLabelSx={{
                                    color: 'black',
                                    paddingTop: '9px',
                                    '& div': {
                                        display: 'none',
                                    },
                                }}
                                handleOptionClick={() => {}}
                                selectContainerSx={{ width: '100%' }}
                                searchFieldProps={{
                                    placeholder: t('feed.Search') as string,
                                    searchQuery: searchCommunityGroup,
                                    sx: { margin: '5px', height: '35px', borderRadius: '8px' },
                                    handleSearch: handleSearchCommunityGroup,
                                    handleKeyDown: (e) => {
                                        e.stopPropagation();
                                    },
                                }}
                                loadingNextPage={fetchingNextCommunityGroups}
                                loading={isLoading}
                                disabled={repost}
                            />
                        </div>}
                        {repost && repostItem && (
                            <RepostItem
                                item={repostItem.model}
                                containerProps={{
                                    sx: {
                                        backgroundColor: '#F2F2F2',
                                        padding: '0 10px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '5px',
                                        mt: '5px',
                                        ml: '55px',
                                        pb: '5px',
                                        borderRadius: '14px',
                                    },
                                }}
                                handleRepostClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`/social/post/${repostItem.model.id}`);
                                }}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter className='d-flex justify-content-center align-items-center w-100 border-0'>
                        {!canPost && (
                            <Alert severity='error'>
                                <Typography>{t('feed.cannotPostAlert')}</Typography>
                            </Alert>
                        )}
                        <button
                            onClick={() => setIsSubmitContent(true)}
                            data-testid='post-btn'
                            className='post-btn p-2 d-flex justify-content-center align-items-center'
                            disabled={(editorTextValue.length > 0 || filesInfo.length > 0) && canPost ? false : true}
                            style={{
                                background: (editorTextValue.length > 0 || filesInfo.length > 0) && canPost ? '#15385B' : '#C0C0C0',
                            }}
                        >
                            <h4 className='m-0'>{t('feed.Post')}</h4>
                            <i className='fa-solid fa-paper-plane media-icon c-pointer ms-2'></i>
                        </button>
                    </ModalFooter>
                </Modal>
            )}
        </div>
    );
};

export default CreateSocialPost;
