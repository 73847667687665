import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const threadProvider = {
    createThread(userIds: Array<number>, messageText: string, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/json/thread`,
            method: 'post',
            data: {
                users: userIds,
                message: messageText
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default threadProvider;