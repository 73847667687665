import './registrationPage.scss';

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import AuthService from '../../services/authService';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { AxiosResponse } from 'axios';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';

import registrationService from '../../services/registrationService';

import { LoginConfigs, UserReducerState } from '../../../types/types';

import NotificationPage from '../common/notificationPage/notificationPage';
import RegistrationUserScreen from './registrationUserScreen/registrationUserScreen';
import LocaleSelector from '../common/localeSelector/localeSelector';

const RegistrationPage: FunctionComponent = () => {
    const { t } = useTranslation(); 
    const dispatch = useDispatch();
    const matchParams = useParams();

    const [isLoading, setLoading] = useState(true);
    const [domainInfo, setDomainInfo] = useState<LoginConfigs>(new LoginConfigs());

    const [isRegComplete, setRegComplete] = useState(false);

    // Log user out to support further login and load tenant using ref from URL
    useEffect(() => {
        AuthService.logout();

        setLoading(true);
        AuthService.authTenant(matchParams.tenantName || '', false, (resp: AxiosResponse) => {
            console.log(resp.data.data);
            setDomain(resp.data.data);
            setLoading(false)
        }, () => {
            setLoading(false);
        });
    }, []);

    const setDomain = (info: LoginConfigs) => {
        setDomainInfo(info);

        // Save tenantId to send it in headers
        let data = new UserReducerState(0, '', '', '', '', '', false);
        // data.tenantId = info.id;
        AuthService.storeUserInfo(JSON.stringify(data));
    }

    const sendUserData = (regModel: any) => {
        setLoading(true);
        registrationService.registrateUser(regModel, () => {
            setLoading(false);
            setRegComplete(true);
        }, () => {
            dispatch(addNotification({ label: `Registration`, text: t('registration.errors.User with this email already exists'), type: 'danger' }));
            setLoading(false);
        });
    }

    return (
        <React.Fragment>
            { domainInfo.multi_lang &&
              <LocaleSelector labelColour={domainInfo.label_colour} className={'position-absolute me-4 mt-3 right0 lang-select-container'} />
            }
            { isRegComplete &&
                <NotificationPage message={ t('registration.registration successful') } instruction={ `${t('registration.registration successful instruction')}` } data={domainInfo} loginData={domainInfo} />
            }

            { isLoading &&
                <SpinnerLoad className={'reg-spinner position-absolute bg-white place-at-top d-flex justify-content-center align-items-center stick-top stick-left h-100 w-100'} />
            }
            
            <div data-testid="registration-page" style={{ backgroundImage: `url(${domainInfo.background_logo})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', background: domainInfo.background_colour }} 
                className={'registration-page h-100'} 
            >
                <RegistrationUserScreen data={domainInfo} tenantRef={matchParams.tenantName || ''}
                    setRegData={sendUserData} loginData={domainInfo}
                />
            </div>
        </React.Fragment>
    );
}
  
export default RegistrationPage;
