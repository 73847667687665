import { useCallback, useRef } from "react";
import { debounce } from "lodash-es";

const useDebouncedCallback = (callback, delay = 300) => {
  const callbackRef = useRef();
  callbackRef.current = callback;

  return useCallback(
    debounce((...args) => callbackRef.current(...args), delay),
    []
  );
};

export default useDebouncedCallback;
