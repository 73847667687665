export default {
    "please enter your company reference": "Please enter your company reference",
    "enter user registration credentials": "Please enter your details below",
    "enter email": "Please enter your email",
    "enter password": "Please enter your password",
    "repeat password": "Please repeat your password",
    "registration successful": "Registration successful.",
    "registration successful instruction": "A time-limited confirmation link has been sent to the specified email. Please follow the instructions in the email to complete account setup. (Please check your spam folder if you cannot see the email)",
    "errors": {
      "domain name can't be empty": "Domain name can't be empty",
      "domain does not exist": "Domain '{{domain}}' does not exist",
      "email can not be empty": "Email can not be empty",
      "password can not be empty": "Password can not be empty",
      "custom fields not set": "Not all of required fields are filled",
      "passwords dont match": "Passwords don't match",
      "email is not available": "Selected email is not available",
      "email format is not valid": "Email format is not valid",
      "password requirements": "Password must contain 8 characters, numbers, an upper and a lower case letter",
      "User with this email already exists": "A user already exists with the details you entered",
      "Error loading security information from server": "Error loading security information from server. Please reload the page to try again.",
      "Page doesnt have data parameters loaded": "Page doesn't have data parameters loaded. Please try to open the email link again."
    },
    "verify": "REGISTER",
    "back": "BACK"
};