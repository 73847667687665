
/*
* @description: checks if a string has HTML tags
*/
const hasHtmlTags = (html: string): boolean => {
    const htmlTagRegex = /<[a-z][\s\S]*>/i; // Regex to match HTML tags
    return htmlTagRegex.test(html);
};

/**
 * @description Remove HTML tags from a string
 * @param html: string - string with HTML tags
 * @returns: string - string without HTML tags
*/
const removeHtmlTags = (html: string): string => {
    if (!hasHtmlTags(html)) return html;
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const textContent = doc.body.textContent || "";

    // Remove '@' symbol at the start of words
    const cleanTextContent = textContent.replace(/\B@(\w+)/g, '$1');
    return cleanTextContent;
};

/**
 * @description Remove anchor tags from a string and replace them with the text inside them. 
 * This is also used to remove the '@' symbol from the start of words in anchor tags. 
 * @example: <a href="/user/username">@username</a> => username,
 * @param html: string - string with HTML tags
 * @returns: string - string without HTML tags
*/
const removeUserTags = (text: string) => {
    const regex = /<a[^>]*>([^<]+)<\/a>/;
    const match = text.match(regex);

    if (match && match[1]) {
        const replacedString = match[1][0] === '@' ? match[1].slice(1) : match[1];
        return text.replace(regex, replacedString);
    }

    return text;
}

export {
    hasHtmlTags,
    removeHtmlTags,
    removeUserTags
}