import React, { useEffect, useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./CustomSearchText.scss";

export enum AttributeTypes {
	Text = 1,
	Email = 2,
	Phone = 3,
	Url = 4,
	MultilineText = 5,
	Number = 7,
	Date = 8,
}

type CustomSearchTextProps = {
	label: string;
	type: number;
	className?: string;
	displayFilterCount: boolean;
	clearFilters: boolean;
	setClearFilters: React.Dispatch<React.SetStateAction<boolean>>;
	handleSearchFilterChange: (label: string, text: string) => void;
};

const CustomSearchText: React.FC<CustomSearchTextProps> = ({
	label,
	type,
	className,
	displayFilterCount,
	clearFilters,
	setClearFilters,
	handleSearchFilterChange,
}) => {
	const { t } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);

	useEffect(() => {
		if (clearFilters) {
			setClearFilters(false);
			setShowDropdown(false);
		}
	}, [clearFilters]);

	const toggleSearchTextDropdown = () =>
		setShowDropdown((prevState) => !prevState);

	const handleKeyDown = (
		e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (e.key == "Enter" && !e.shiftKey) {
			handleSearchFilterChange(label, e.currentTarget.value);
			toggleSearchTextDropdown();
		}
	};

	const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleSearchFilterChange(label, moment(new Date(e.target.value)).format("DD/MM/YYYY"));
		toggleSearchTextDropdown();
	};

	const renderSearchInput = () => {
		switch (type) {
			case AttributeTypes.Text:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-text-input`}
						className="search-filter-box form-control"
						type="text"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);

			case AttributeTypes.MultilineText:
				return (
					<textarea
						data-testid={`${label.toLowerCase()}-custom-search-textarea-input`}
						className="search-filter-box form-control"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);

			case AttributeTypes.Email:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-email-input`}
						className="search-filter-box form-control"
						type="email"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);
			case AttributeTypes.Number:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-number-input`}
						className="search-filter-box form-control"
						type="number"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);
			case AttributeTypes.Phone:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-phone-input`}
						className="search-filter-box form-control"
						type="tel"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);
			case AttributeTypes.Date:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-date-input`}
						className="search-filter-box form-control"
						type="date"
						onChange={(e) => handleDateChange(e)}
					/>
				);
			case AttributeTypes.Url:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-url-input`}
						className="search-filter-box form-control"
						type="url"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);
			default:
				return (
					<input
						data-testid={`${label.toLowerCase()}-custom-search-default-text-input`}
						className="search-filter-box form-control"
						type="text"
						placeholder={`${t("connect.customSearchTextPlaceholder")}`}
						onKeyDown={(e) => handleKeyDown(e)}
					/>
				);
		}
	};

	return (
		<>
			<Dropdown
				data-testid="custom-search-text-dropdown"
				className={`dropdown-filter ${className}`}
				isOpen={showDropdown}
				toggle={toggleSearchTextDropdown}
			>
				<DropdownToggle
					data-testid={`${label.toLowerCase()}-custom-search-text-dropdown-btn`}
					className="dropdown-filter-toggle-btn w-100"
				>
					<p
						data-testid="custom-search-text-label"
						className="m-0 custom-search-text-filter-label"
					>
						{label} {displayFilterCount && !showDropdown ? "/ 1" : null}
					</p>
					<p className="fa-regular fa-angle-down m-0 ms-2"></p>
				</DropdownToggle>
				<DropdownMenu className="custom-search-filter-menu">
					<div className="search-section">{renderSearchInput()}</div>
				</DropdownMenu>
			</Dropdown>
		</>
	);
};

export default CustomSearchText;
