import React, { useEffect, useState } from 'react';
import { AxiosError, AxiosResponse } from 'axios';

import customLayoutService from '../../../web/services/customLayoutService';
import { Layout } from '../../../types/types';
import StandardLayout from '../homePage/layouts/standardLayout';
import './sections.scss';

type SectionViewProps = {
    sectionsId: string;
};

const sections: React.FC<SectionViewProps> = ({ sectionsId }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [sectionsData, setSectionsData] = useState<Layout[]>([]);
    const loadSectionsData = () => {
        setIsLoading(true);
        setCurrentPage((currentPage) => currentPage + 1);
        customLayoutService.getSectionsData(
            sectionsId,
            currentPage,
            (response: AxiosResponse) => {
                setSectionsData(response.data.data);
                setIsLoading(false);
            },
            (error: AxiosError) => {
                setIsLoading(false);
            }
        );
    };

    useEffect(() => {
        loadSectionsData();
    }, []);

    return (
        <div data-testid='section-layout' className='section-layout'>
            <StandardLayout layoutData={sectionsData} isLoading={isLoading} />
        </div>
    );
};

export default sections;
