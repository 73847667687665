import React, { FunctionComponent, useEffect, useState } from 'react';
import { Layout, LayoutContainer } from '../../../types/types';
import './homePage.scss';
import homepageService from '../../services/homepageService';
import { AxiosResponse } from 'axios';
import OneRowLayout from './layouts/oneRowLayout';
import StandardLayout from './layouts/standardLayout';
import CustomLayout from '../customLayout/customLayout';
import { useTranslation } from 'react-i18next';

const HomePage: FunctionComponent = () => {
    const { i18n } = useTranslation();
    const [layoutData, setLayoutData] = useState<Layout[]>([]);
    const [templateId, setTemplateId] = useState<number>(-1);
    const [layoutContainers, setLayoutContainers] = useState<LayoutContainer[]>([]);
    const [config, setConfig] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);

            homepageService.getData((response: AxiosResponse) => {
                if (response.data.config == 'custom') {
                    setLayoutContainers(response.data.layout_data[0].data.containers);
                    setTemplateId(response.data.layout_data[0].data.templateId);
                } else {
                    setLayoutData(response.data.data);
                }
                setConfig(response.data.config);
                setIsLoading(false);
            });
        };
        fetchData();

        i18n.on('languageChanged', () => {
            setTimeout(() => {
                fetchData();
            }, 500); // Added timeout to wait until changeLanguage method is executed
        });

        return () => {
            i18n.off('languageChanged', fetchData);
        };
    }, [i18n]);

    let layout = <OneRowLayout layoutData={layoutData} isLoading={isLoading} />;

    if (config === 'standard') {
        layout = (
            <div className='standard-layout'>
                <StandardLayout layoutData={layoutData} isLoading={isLoading} />
            </div>
        );
    }

    if (config === 'custom') {
        layout = (
            <div data-testid='custom-layout' className='custom-layout'>
                <CustomLayout templateId={templateId} components={layoutContainers} />
            </div>
        );
    }

    return <div className='homepage'>{layout}</div>;
};

export default HomePage;
