import { useMutation } from "react-query";
import Client from "../services/axiosService";
import { DeletePostPayload } from "../../types/interface/DeletePostPayload";


export type deleteMessageParams = {
    threadID: string;
    messageID: string;
}

/**
 * @description: Makes a Delete request to the API to remove a message from a thread
 * @param param 
 * @returns 
 */
export const deleteMessage = async ({ threadID, messageID }: deleteMessageParams) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${threadID}/messages/${messageID}`,
        method: "delete",
    });
    return response.data;
}

/**
 * @description: A hook to remove a message from an individual thread
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useDeleteMessage = (onSuccess?: (data: DeletePostPayload, { threadID, messageID }: deleteMessageParams) => void, onError?: () => void) => {
    return useMutation(deleteMessage, {
        onSuccess: onSuccess,
        onError: onError,
    })
}
