import React, { FunctionComponent, ReactNode } from 'react';
import classnames from 'classnames';

import { CollapseHeightData } from '../../../../types/types';

import './collapse.scss';

type CollapseProps = {
    children?: ReactNode,
    isOpen: boolean,
    className: string,
    heightData: CollapseHeightData
}

const CollapseComponent: FunctionComponent<CollapseProps> = (props) => {
    const { isOpen, className, children, heightData } = props;

    return (
        <div data-testid="collapse" className={classnames(`collapser ${isOpen ? 'show' : 'hide'}`, className)}
            style={{ maxHeight: isOpen ? heightData.maxHeight : heightData.minHeight}}
        >
            { children }
        </div>
    );
}

export default CollapseComponent;