export default {
    "inbox": "Bandeja de entrada",
    "searchChats": "Buscar chats",
    "searchArchivedChats": "Buscar chats archivados",
    "searchContacts": "Buscar contactos",
    "description": "Descripción",
    "chatInfo": "Información de chat",
    "created": "Creado",
    "newChat": "Nueva conversación",
    "createChatPlaceholder": "Por favor cree un grupo o seleccione un contacto para enviarle un mensaje.",
    "allContacts": "Todos los contactos",
    "newUserChatPlaceholder": "No has enviado un mensaje a {{dynamicValue}} antes, comienza a escribir para comenzar.",
    "archived": "Archivado",
    "chatSettings": "Configuración de chat",
    "showArchivedChats": "Mostrar chats archivados",
    "participants": "Las participantes",
    "searchParticipants": "Buscar participantes",
    "muteMsg": "Las notificaciones están deshabilitadas para este hilo porque es público",
    "muteByAdminMsg": "Las notificaciones están deshabilitadas para este hilo porque está silenciado por un administrador",
    "prefixMuteMsg": "El hilo se silencia hasta",
    "startTyping": "Empieza a escribir",
    "muteNotifications": "Silenciar notificaciones",
    "newGroupChat": "Agregar participantes al grupo",
    "newGroup": "Nuevo grupo",
    "newGroupPlaceholder": "Seleccione participantes para su chat grupal",
    "groupSettings": "Configuración de grupo",
    "uploadGroupIcon": "Subir icono de grupo",
    "groupNamePlaceholder": "Introduzca el nombre del grupo",
    "groupDescriptionPlaceholder": "Da una descripción de tu grupo.",
    "updateGroup": "Grupo de actualización",
    "leaveGroup": "Dejar grupo",
    "leaveGroupErrorMsg": "Se produjo un error al enviar la solicitud de salida del grupo. Inténtalo de nuevo.",
    "leaveGroupModalTitle": "¿Estás segura de que quieres salir de este grupo?",
    "leaveGroupModalContent": "Dejar este grupo significa que no podrás volver a unirte sin una invitación.",
    "manageParticipants": "Administrar participantes",
    "addParticipant": "Añada participante",
    "removeParticipant": "Eliminar participante",
    "addParticipantErrorMsg": "Se produjo un error al enviar la solicitud para agregar participante. Inténtalo de nuevo.",
    "removeParticipantErrorMsg": "Se produjo un error al enviar la solicitud de eliminación de participante. Inténtalo de nuevo.",
    "manage": "Administrar",
    "sendMessage": "Enviar mensaje",
    "failedToSendMessage": "No se pudo enviar el mensaje.",
    "admins": "Administradoras",
    "manageAdmins": "Administrar Administradores",
    "addAdmin": "Agregar administrador",
    "removeAdmin": "Eliminar administrador",
    "addAdminErrorMsg": "Se produjo un error al enviar la solicitud para agregar administrador. Inténtalo de nuevo.",
    "removeAdminErrorMsg": "Se produjo un error al enviar la solicitud de eliminación de administrador. Inténtalo de nuevo.",
    "mute": "Muda",
    "archive": "Archivo",
    "delete": "Borrar",
    "unarchive": "Desarchivar",
    "unmute": "Dejar de silenciar",
    "archiveErrorMsg": "Se produjo un error al enviar la solicitud de archivo. Inténtalo de nuevo.",
    "unArchiveErrorMsg": "Se produjo un error al enviar la solicitud de desarchivo. Inténtalo de nuevo.",
    "userDeletedMessage": "Eliminaste este mensaje.",
    "deletedMessage": "Este mensaje fue eliminado.",
    "deletedMessageThread": "Mensaje eliminado.",
};
