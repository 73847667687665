import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import "./orgChartModal.scss";
import { Tree, TreeNode } from "react-organizational-chart";
import { OrgChartData } from "../../../../types/types";
import { useSelector } from "react-redux";

interface props {
  isOpenChart: boolean;
  headerName: string;
  renderModalData: { data: OrgChartData };
  setIsOpenChart: Function;
}

const OrgChartModalComponent: FunctionComponent<props> = ({
  isOpenChart,
  headerName,
  renderModalData,
  setIsOpenChart,
}) => {
  
  const branding = useSelector((state:any) => state.app.branding);
  const fgColor = branding.org_chart.foreground_colour;
  const bgColor = branding.org_chart.background_colour;
  const managerFgColor = branding.org_chart.manager_foreground_colour;
  const managerBgColor = branding.org_chart.manager_background_colour;
  
  const { t } = useTranslation();
  const setVisibility = () => {
    setIsOpenChart(false);
  };

  const escHandle = useCallback((event: { keyCode: number; }) => {
    if (event.keyCode === 27) {
      setIsOpenChart(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escHandle, false);
    return () => {
      document.addEventListener("keydown", escHandle, false);
    };
  }, []);

  const renderTreeChildren = (data: OrgChartData) => {
    return data.children.map((kid) => {
      return (
        <React.Fragment>
          <TreeNode
            label={<div data-testid={`child-node-${kid.id}`} className="org-chart-label" style={{ backgroundColor: bgColor ? bgColor : "#294f60"}}>{renderNode(kid, true)}</div>}
          >
            {renderTreeChildren(kid)}
          </TreeNode>
        </React.Fragment>
      );
    });
  };

  const renderNode = (element: OrgChartData, childNode: boolean = false) => {
    return (
      <a 
        data-testid={`node-${element.id}`}
        className="node-element"
        href={`/connect/people/${element.contact_id}`}
        style={childNode ? {
          color: fgColor ? fgColor : "#ffffff",
        } : {
          color: managerFgColor ? managerFgColor : "#ffffff",
        }}
      >
        <img data-testid={`node-${element.id}-image`} className="tree-node-img root" src={element.image_url} />
        <strong data-testid={`node-${element.id}-name`}>{element.name}</strong>
        <span data-testid={`node-${element.id}-title`}>{element.title}</span>
        <span data-testid={`node-${element.id}-department`}>{element.department}</span>
      </a>
    );
  };

  return isOpenChart ? (
    <Modal data-testid="org-chart-modal" isOpen={isOpenChart} className={classnames("modal-xl")}>
      <ModalHeader>
        <span data-testid="org-chart-header-name" className="header-chart">{headerName}</span>
        <button
          data-testid="org-chart-modal-close-button"
          type="button"
          className="close close-btn"
          data-dismiss="modal"
          aria-label="Close"
          id="close-awards-list"
          onClick={setVisibility}
        >
          <span aria-hidden="true" className="fa-thin fa-xmark" />
        </button>
      </ModalHeader>

      <div data-testid="org-chart-contain" className="modal-body w-100 org-chart-container">
        <Tree
          lineWidth={"2px"}
          lineColor="#294f60"
          label={
            <div 
              data-testid="root-node" 
              className="org-chart-label root-node"
              style={{
                backgroundColor: managerBgColor ? managerBgColor : "#0091b8",
              }}
            >
              {renderNode(renderModalData.data)}
            </div>
          }
        >
          {renderTreeChildren(renderModalData.data)}
        </Tree>
      </div>
      <div className="m-1 p-3">
        <Button
          data-testid="org-chart-close-button"
          className="w-100 text-white button-colour"
          color="success"
          onClick={setVisibility}
        >
          {t("connect.close")}
        </Button>
      </div>
    </Modal>
  ) : null;
};

export default OrgChartModalComponent;
