import React, { useState, useEffect } from 'react';
import { Box, BoxProps } from '@mui/material';
import { CommunityCard } from '../../components/communityCard/communityCard';
import Posts from '../../components/connectPage/contactPage/postsPage';
import { PostTabDetailType } from '../../components/connectPage/contactPage/contactDetails';
import { useGroupItemQuery } from '../../hooks/query/useGroupItemQuery';
import { useNavigate } from 'react-router-dom';

export type GroupItemProps = {
    containerProps?: BoxProps;
    id: string;
    onMembersClick: (id: number) => void;
    displayButton: boolean;
    hideScrollBar: boolean;
    parentScrollIsBottom?: boolean;
    setParentScrollIsBottom?: React.Dispatch<React.SetStateAction<boolean>>;
    myGroups?: boolean | undefined;
    latestGroupPost?: any; //array of object post
    userCount?: number | undefined;
    loading: boolean;
    updateFeedData?: Function;
};

export const GroupItem = (props: GroupItemProps) => {
    const {
        containerProps,
        id,
        onMembersClick,
        displayButton,
        hideScrollBar = false,
        parentScrollIsBottom,
        setParentScrollIsBottom,
        myGroups,
        latestGroupPost,
        userCount,
        loading,
        updateFeedData,
    } = props;
    const [scrollIsBottom, setScrollIsBottom] = useState<boolean>(false);
    const [postTabDetail, setPostTabDetail] = useState<PostTabDetailType>({
        postData: [],
        pageNo: 1,
        nextPageAvailable: true,
        isLoadingMore: false,
        isPostsLoading: false,
        isInitialDataLoad: false,
    });
    const navigate = useNavigate();
    const { data: group, isLoading: fetchingGroup } = useGroupItemQuery(id as string);

    const handleScroll = (e: any) => {
        const isAtBottom = Math.round(e.target.clientHeight + e.target.scrollTop) >= e.target.scrollHeight;
        setScrollIsBottom(isAtBottom);
    };

    useEffect(() => {
        if (latestGroupPost) {
            setPostTabDetail((prev: any) => {
                return { ...prev, postData: [...latestGroupPost, ...postTabDetail.postData] };
            });
        }
    }, [latestGroupPost]);

    return (
        <Box
            {...containerProps}
            sx={{
                position: 'relative',
                ...containerProps?.sx,
            }}
        >
            {displayButton && (!loading || !fetchingGroup) && (
                <button
                    className='unstyled-btn'
                    style={{
                        cursor: 'pointer',
                        right: '0px',
                        top: '5px',
                        position: 'absolute',
                        zIndex: 1,
                    }}
                    data-testid='close-group-details'
                    onClick={() => {
                        navigate('/connect/groups');
                    }}
                >
                    <span className='fa-stack' style={{ fontSize: '16px' }}>
                        <i className='fa-solid fa-circle fa-stack-2x' style={{ color: '#f3f3f3', fontStyle: 'normal' }}></i>
                        <i className='fa-regular fa-xmark fa-stack-1x fa-inverse' style={{ color: '#000', fontStyle: 'normal' }}></i>
                    </span>
                </button>
            )}
            {myGroups ? (
                <Box
                    sx={{
                        height: hideScrollBar ? 'auto' : 'calc(75vh - 205px)',
                        overflow: hideScrollBar ? 'auto' : 'hidden',
                        overflowY: hideScrollBar ? 'hidden' : 'scroll',
                    }}
                    onScroll={handleScroll}
                >
                    <Posts
                        selectedInUserId={id}
                        scrollIsBottom={hideScrollBar ? (parentScrollIsBottom as boolean) : scrollIsBottom}
                        postTabDetail={postTabDetail}
                        setPostTabDetail={setPostTabDetail}
                        groupsOnly={true}
                        groupID={id}
                        myGroups={myGroups}
                        updateFeedData={updateFeedData}
                    />
                </Box>
            ) : (
                <>
                    <CommunityCard
                        title={group?.data.title}
                        description={group?.data.description}
                        members={group?.data?.user_count || userCount || 0}
                        img={group?.data?.image_url || ''}
                        handleClick={() => {}}
                        id={group?.data.id}
                        containersSx={{
                            cursor: 'default',
                        }}
                        membersClick={onMembersClick}
                        loading={loading || fetchingGroup}
                    />
                    <Box
                        sx={{
                            height: hideScrollBar ? 'auto' : 'calc(75vh - 205px)',
                            overflow: hideScrollBar ? 'auto' : 'hidden',
                            overflowY: hideScrollBar ? 'hidden' : 'scroll',
                        }}
                        onScroll={handleScroll}
                    >
                        <Posts
                            selectedInUserId={id}
                            scrollIsBottom={hideScrollBar ? (parentScrollIsBottom as boolean) : scrollIsBottom}
                            postTabDetail={postTabDetail}
                            setPostTabDetail={setPostTabDetail}
                            groupsOnly={true}
                            groupID={id}
                            myGroups={myGroups}
                            updateFeedData={updateFeedData}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};
