import { Grid, SxProps, Typography, Tooltip, GridProps } from '@mui/material';
import React from 'react';

export type LinkPreviewProps = {
    url: string | undefined;
    title: string | undefined;
    description?: string;
    image: string | undefined;
    imageProps?: GridProps;
    descriptionProps?: GridProps;
    imageDirection: 'left' | 'right' | 'top' | 'bottom';
    sx?: SxProps;
    textColor?: string;
};

export const LinkPreview = (props: LinkPreviewProps) => {
    const { url, title, description, image, imageProps, sx, descriptionProps, textColor="black" } = props;

    const LinkPreviewImage = (
        <Grid item {...imageProps}>
            <a
                href={url}
                target="_blank"
                style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', textDecoration: 'none', justifyContent: 'center' }}
            >
                {!image && (
                    <Tooltip title="No image found">
                        <i className="fa-regular fa-image-slash" style={{ fontSize: '50px', color: 'black', fontStyle: 'normal' }} />
                    </Tooltip>
                )}
            </a>
        </Grid>
    );

    const LinkPreviewDescription = (
        <Grid item {...descriptionProps}>
            <a href={url} target="_blank" style={{ textDecoration: 'none', color: textColor }}>
                <Typography sx={{ fontWeight: '700', fontSize: '18px' }}>{title ? title : 'Title could not be found'}</Typography>
                <Typography sx={{ fontWeight: '400', fontSize: '14px', wordWrap: 'break-word' }}>{description ? description : url}</Typography>
            </a>
        </Grid>
    );

    return (
        <Grid container sx={{ mb: '10px', ...sx }} data-testid="link-preview">
            {(props.imageDirection === 'left' || props.imageDirection === 'top') && (
                <>
                    {LinkPreviewImage}
                    {LinkPreviewDescription}
                </>
            )}
            {(props.imageDirection === 'right' || props.imageDirection === 'bottom') && (
                <>
                    {LinkPreviewDescription}
                    {LinkPreviewImage}
                </>
            )}
        </Grid>
    );
};
