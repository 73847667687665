import React, { FunctionComponent } from "react";
import { Layout } from "../../../../types/types";
import "../homePage.scss";
import StandardLayout from "./standardLayout";

type OneRowLayoutProps = {
  layoutData: Layout[];
  isLoading: boolean;
};

const OneRowLayout: FunctionComponent<OneRowLayoutProps> = ({
  layoutData,
  isLoading,
}) => {
  return (
    <StandardLayout
      cardStyle={{ display: "flex", justifyContent: "flex-end" }}
      imageStyle={{ display: "flex", alignItems: "center" }}
      layoutData={layoutData}
      isLoading={isLoading}
    />
  );
};

export default OneRowLayout;
