import Client from "../services/axiosService";
import { AxiosError, AxiosResponse } from "axios";

const analyticsProvider = {
  analytics(activityId: string, success: Function, error?: Function) {
    Client(false)
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/analytics`,
        method: "post",
        data: {
          activityId: activityId,
        },
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};
export default analyticsProvider;
