import { useQuery } from "react-query";
import Client from "../../services/axiosService";

/**
 * @description: Fetches side nave items from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchSideNaveItems = async () => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/nav/items`,
        method: "get",
    })

    return response.data;
}

/**
 * @description: Fetches locations results from the API and returns the data in the form of infinite query
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useSideNavItemsQuery = () => {
    return useQuery(
        'sideNavItems',
        fetchSideNaveItems,
        {
            refetchOnWindowFocus: false,
            enabled: false,
        }
    );
}