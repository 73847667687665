import './customLayout.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { Row } from 'reactstrap';
import { AxiosResponse } from 'axios';
import CustomLayout from './customLayout';
import { ContentIdParam, ContentType, LayoutContainer, LayoutData } from '../../../types/types';
import customLayoutService from '../../services/customLayoutService';
import AnalyticsComponent from '../../components/common/analytics/analyticsComponent';
import { useTranslation } from 'react-i18next';

const CustomLayoutView: FunctionComponent = () => {
    const { i18n } = useTranslation();
    const params = useParams<ContentIdParam>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [templateId, setTemplateId] = useState<number>(-1);
    const [layoutContainers, setLayoutContainers] = useState<LayoutContainer[]>([]);
    const [activityId, setActivityId] = useState<string>('');

    useEffect(() => {
        const fetchData = () => {
            if (params.id && params.id.length) {
                setIsLoading(true);
                customLayoutService.getLayoutData(params.type as ContentType, parseInt(params.id), (response: AxiosResponse) => {
                    const layoutData: LayoutData = response.data[0];

                    setLayoutContainers(layoutData.data.containers);

                    setTemplateId(layoutData.data.templateId);

                    setIsLoading(false);

                    setActivityId(layoutData.data.activity_id);
                });
            }
        };
        fetchData();

        i18n.on('languageChanged', () => {
            setTimeout(() => {
                fetchData();
            }, 500); // Added timeout to wait until changeLanguage method is executed
        });

        return () => {
            i18n.off('languageChanged', fetchData);
        };
    }, [params.id, params.type, i18n]);

    return (
        <Row className={'h-100 custom-layout'} data-testid='custom-layout-section'>
            <AnalyticsComponent activityId={activityId} />
            {isLoading ? (
                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
            ) : (
                <CustomLayout templateId={templateId} components={layoutContainers} />
            )}
        </Row>
    );
};

export default CustomLayoutView;
