export default {
  "menus": {
    "home": "Home",
    "feed": "Feed",
    "messages": "Messages",
    "connect": "Connect",
    "awards": "Awards",
    "settings": "Settings and Privacy",
    "profile": "Profile",
    "notifications": "Notifications",
    "change password": "Change Password",
    "signOut": "Sign Out",
    "mark as read": "Mark as Read"
  },
  "settingOptions": {
    "title": "Privacy Settings",
    "content": "Cookies are small text files that are placed on your computer or mobile device when you visit a site or use an app. They are widely used in order to make websites and apps work, or work more efficiently, as well as to provide information to the owners of the site/app. The table below explains the cookies we use and why.",
    "subQuestion": "What cookies do we use?",
    "subText": "For more details, see our ",
    "subTextDefault": "For more details, see our privacy and cookie policy",
    "necessaryTitle": "Strictly necessary",
    "necessary": "Necessary cookies are essential for core app functionality and security. If you agree, we also want to use cookies to ",
    "performanceTitle": "Performance cookies",
    "notificationSettings": "Notification Settings",
    "emails": "Emails",
    "p2pEmailsLabel": "Send me email notifications for {{p2pLabel}}",
    "performance": {
      "firstLine": "• Enhance the performance of the App",
      "secondLine": "• Understand how the App is used",
      "thirdLine": "• Optimise content"
    },
    "analyticsTitle": "Analytics cookies",
    "analytics": {
      "firstLine": "• Improve app experience",
      "secondLine": "• Personalise services"
    },
    "save": "Save preferences",
    "error": {
      "label": "Error",
      "text": "There was an error, please try again later."
    }
  },
  "type search field": "Type and hit Enter..."
};
