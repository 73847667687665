export default {
  "award": "{{text, de-handle-an}}",
  "awards": "Auszeichnungen",
  "send": "{{text, en-handle-an}} senden",
  "all": "Alle",
  "inbox": "Zu mir",
  "sent": "Gesendet",
  "team": "Mannschaft",
  "choose": "Wählen",
  "toggleManager": "Toggle Manager-Nachrichten",
  "manager": "Geschäftsführer",
  "received": "erhalten {{text, en-handle-an}}",
  "from": "von",
  "message": "Nachricht",
  "managerMessage": "Nachricht an den Manager",
  "setVisibility": "Sichtbarkeit festlegen",
  "showInFeed": "Im Feed angezeigt",
  "onlyRecipients": "Nur von Empfängern gesehen",
  "recipients": "Empfänger",
  "for": "zum",
  "messagePlaceholder": "Geben Sie hier Ihre Nachricht ein",
  "thankYou": "Danke"
};