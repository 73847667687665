import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { GroupCard } from '../../components/groupCard/groupCard';

export type GroupsListProps = {
    loading: boolean;
    containerProps?: GridProps;
    groups: any;
    handleGroupCardClick: (id: number) => void;
    onMembersClick: (id: number) => void;
    groupID: string;
    onJoinOrLeaveGroupClick: (id: number, following: boolean) => void;
};

export const GroupsList = (props: GroupsListProps) => {
    const { loading, containerProps, groups, handleGroupCardClick, onMembersClick, groupID, onJoinOrLeaveGroupClick } = props;
    if (loading) {
        return (
            <>
                {Array(12)
                    .fill(0)
                    .map((_, index) => {
                        return (
                            <Grid item key={index} {...containerProps}>
                                <GroupCard
                                    loading={loading}
                                    img=''
                                    title=''
                                    description=''
                                    members={0}
                                    is_member={false}
                                    handleClick={() => {}}
                                    onMembersClick={() => {}}
                                    onJoinOrLeaveGroupClick={() => {}}
                                    can_leave={true}
                                />
                            </Grid>
                        );
                    })}
            </>
        );
    }
    
    return (
        <>
            {groups &&
                groups?.pages.map((page: any, index: number) => {
                    return page.data.map((group: any, index: number) => {
                        return (
                            <Grid item key={index} {...containerProps}>
                                <GroupCard
                                    containerSx={{
                                        width: '100%',
                                        background: group?.id == groupID ? '#E3E3E3' : 'white',
                                    }}
                                    onMembersClick={onMembersClick}
                                    id={group.id}
                                    handleClick={handleGroupCardClick}
                                    loading={loading}
                                    img={group.image_url}
                                    title={group.title}
                                    description={group.description}
                                    members={group?.user_count || 0}
                                    is_member={group.is_member}
                                    can_leave={group.can_leave}
                                    onJoinOrLeaveGroupClick={() => onJoinOrLeaveGroupClick(group.id, group.is_member)}
                                />
                            </Grid>
                        );
                    });
                })}
        </>
    );
};
