import React, { FormEvent, FunctionComponent, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DynamicAttributesData, EditUserData } from '../../../../types/types';
import '../contactPage/contactEditProfile.scss';
import '../contactPage/contactPage.scss';
import moment from 'moment';
import ImageModalComponent from '../../common/modal/imageModal';
import connectService from '../../../services/connectService';
import { AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { addNotification }          from '../../../../shared/reducers/notifications/actionTypes';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar }      from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Select } from '../../select/select';
import { updateOptions } from '../../../utils/formatSelectOptions';
import { isWhite } from '../../../utils/colourCheck';

type EditProperties = {
    editUserData: EditUserData;
    isLoadingCallBack: Function;
};

const ContactEditProfilePage: FunctionComponent<EditProperties> = ({ editUserData, isLoadingCallBack }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const tenantBranding = useSelector((state:any) => state.app.branding);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [imageUploaded, setImageUploaded] = useState<string>('');
    const [headerName, setHeaderName] = useState<string>('');
    const [modalType, setModalType] = useState<string>('image');
    const [aspectRatio, setAspectRatio] = useState<number>(1);
    const [startDate, setStartDate] = useState<string>(editUserData.start_date);
    const [dObDate, setDoBDate] = useState<string>(editUserData.date_of_birth);
    const [percentage, setPercentage] = useState<number>(0);
    const [singleSelectedValue, setSingleSelectedValue] = useState<any>({});
    const [multiSelectedValue, setMultiSelectedValue] = useState<any>({});
    
    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const buttonBgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    const buttonFgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

    useEffect(() => {
        if (imageUploaded) {
            if (modalType === 'image') {
                const userImage = document.getElementById('connect-user-profile-image');
                if (userImage !== null) {
                    userImage.setAttribute('src', imageUploaded);
                }
                editUserData.user.image_url = imageUploaded;
            } else {
                const image = 'url(' + imageUploaded + ')';
                if (document.getElementById('contact-header-img') !== null) {
                    document.getElementById('contact-header-img')!.style.backgroundImage = image;
                    editUserData.user.header_image_url = imageUploaded;
                }
            }
        }
        calculatePercentage();
    }, [imageUploaded]);

    const modalTriggerHeader = () => {
        setImageUrl(editUserData.user.header_image_url!);
        setIsOpen(true);
        setHeaderName(`${t('connect.headerImageHeader')}`);
        setModalType('header');
        setAspectRatio(32 / 9);
    };

    const modalTrigger = () => {
        setImageUrl(editUserData.user.image_url!);
        setIsOpen(true);
        setHeaderName(`${t('connect.profileImageHeader')}`);
        setModalType('image');
        setAspectRatio(1);
    };

    const dynamicTextAreaChange = (event: React.ChangeEvent<HTMLTextAreaElement>, id: number) => {
        // Save values in the user data object
        const elem = document.getElementById(event.target.id) as HTMLInputElement;
        updateDynamicData(id, elem.value);
    };

    const dynamicInputChange = (event: React.ChangeEvent<HTMLInputElement>, type: string, id: number) => {
        const elem = document.getElementById(event.target.id) as HTMLInputElement;

        // Update the date selector
        if (type === 'date') {
            elem.value = moment(new Date(event.target.value)).format('YYYY-MM-DD');
        }

        // Save values in the user data object
        updateDynamicData(id, elem.value);
    };

    const updateDynamicData = (id: number, value: string) => {
        editUserData.dynamic_attributes.map((item) => {
            if (item.id === id) {
                item.value = value;
                calculatePercentage();
            }
        });
    };

    const updateField = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const value = event.currentTarget.value;

        switch (field) {
            case 'first_name':
                editUserData.user.first_name = value;
                break;
            case 'last_name':
                editUserData.user.last_name = value;
                break;
            case 'job_title':
                editUserData.user.job_title = value;
                break;
            case 'department':
                editUserData.department = value;
                break;
            case 'email':
                editUserData.email = value;
                break;
        }
        calculatePercentage();
    };

    const updateTextAreaField = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        editUserData.description = event.currentTarget.value ?? '';
        calculatePercentage();
    };

    const dobDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(new Date(event.target.value)).format('YYYY-MM-DD');
        setDoBDate(newDate);

        //Update the actual data
        editUserData.date_of_birth = newDate;

        // Update the calculation
        calculatePercentage();
    };

    const startDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newDate = moment(new Date(event.target.value)).format('YYYY-MM-DD');
        setStartDate(newDate);

        // Update the actual data
        editUserData.start_date = newDate;

        // Update the calculation
        calculatePercentage();
    };

    const handleSingleSelect = (attrKey: any, selectedOption: any) => {
        setSingleSelectedValue((prev: any) => ({
            ...prev,
            [attrKey]: selectedOption,
        }));
    };

    const handleMultiSelect = (attrKey: any, selectedOptions: any) => {
        setMultiSelectedValue((prev: any) => ({
            ...prev,
            [attrKey]: selectedOptions,
        }));
    };

    const renderMainDetails = () => {
        return (
            <Row className='padding-left-20 padding-right-20'>
                {isOpen ? (
                    <ImageModalComponent
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        imageUrl={imageUrl}
                        setImageUploaded={setImageUploaded}
                        headerName={headerName}
                        type={modalType}
                        ratio={aspectRatio}
                    />
                ) : null}
                {/*FIRST NAME IS ALWAYS VISIBLE*/}
                <Col xs={12} md={6} lg={6} xl={6}>
                    <label className='attribute-label'>{t('connect.fName')}</label>
                    <input
                        required={true}
                        type='text'
                        className='form-control attribute-input'
                        defaultValue={editUserData.user.first_name}
                        disabled={!editUserData.user.is_editable_firstName}
                        onChange={(e) => updateField(e, 'first_name')}
                    ></input>
                </Col>
                {/*LAST NAME IS ALWAYS VISIBLE*/}
                <Col xs={12} md={6} lg={6} xl={6}>
                    <label className='attribute-label'> {t('connect.lName')}</label>
                    <input
                        required={true}
                        type='text'
                        className='form-control attribute-input'
                        defaultValue={editUserData.user.last_name}
                        disabled={!editUserData.user.is_editable_lastName}
                        onChange={(e) => updateField(e, 'last_name')}
                    ></input>
                </Col>
                {editUserData.user.is_visible_title ? (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'> {t('connect.title')}</label>
                        <input
                            type='text'
                            className='form-control attribute-input'
                            defaultValue={editUserData.user.job_title}
                            disabled={!editUserData.user.is_editable_title}
                            onChange={(e) => updateField(e, 'job_title')}
                        ></input>
                    </Col>
                ) : null}
                {editUserData.department_visible ? (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'> {t('connect.department')}</label>
                        <input
                            type='text'
                            className='form-control attribute-input'
                            defaultValue={editUserData.department}
                            disabled={!editUserData.department_editable}
                            onChange={(e) => updateField(e, 'department')}
                        ></input>
                    </Col>
                ) : null}
                {editUserData.email_visible ? (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'> {t('connect.primaryEmail')}</label>
                        <input
                            type='email'
                            className='form-control attribute-input'
                            defaultValue={editUserData.email}
                            disabled={!editUserData.email_editable}
                            onChange={(e) => updateField(e, 'email')}
                        ></input>
                    </Col>
                ) : null}
                {editUserData.date_of_birth_visible ? (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'> {t('connect.dob')}</label>
                        <input
                            type='date'
                            className='form-control attribute-input'
                            defaultValue={dObDate}
                            disabled={!editUserData.date_of_birth_editable}
                            onChange={(e) => dobDateChange(e)}
                        ></input>
                    </Col>
                ) : null}
                {editUserData.start_date_visible ? (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'> {t('connect.start')}</label>
                        <input
                            type='date'
                            className='form-control attribute-input'
                            defaultValue={startDate}
                            disabled={!editUserData.start_date_editable}
                            onChange={(e) => startDateChange(e)}
                        ></input>
                    </Col>
                ) : null}
                {editUserData.description !== undefined ? (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'> {t('connect.bio')}</label>
                        <textarea
                            name='description'
                            className='form-control attribute-textarea'
                            disabled={!editUserData.editable.description}
                            onChange={(e) => updateTextAreaField(e)}
                        >
                            {editUserData.description}
                        </textarea>
                    </Col>
                ) : null}
            </Row>
        );
    };

    const renderAdditionalDetails = (element: DynamicAttributesData) => {
        switch (element.attribute_type.toLowerCase()) {
            case 'date':
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'>{element.title}</label>
                        <input
                            type='date'
                            id={element.id.toString()}
                            className='form-control attribute-input'
                            defaultValue={element.value}
                            disabled={element.editable !== 1}
                            onChange={(e) => dynamicInputChange(e, 'date', element.id)}
                        ></input>
                    </Col>
                );
            case 'email':
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'>{element.title}</label>
                        <input
                            type='email'
                            id={element.id.toString()}
                            className='form-control attribute-input'
                            defaultValue={element.value}
                            disabled={element.editable !== 1}
                            onChange={(e) => dynamicInputChange(e, 'email', element.id)}
                        ></input>
                    </Col>
                );
            case 'url':
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'>{element.title}</label>
                        <input
                            type='url'
                            id={element.id.toString()}
                            className='form-control attribute-input'
                            defaultValue={element.value}
                            disabled={element.editable !== 1}
                            onChange={(e) => dynamicInputChange(e, 'url', element.id)}
                        ></input>
                    </Col>
                );
            case 'phone':
            case 'number':
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'>{element.title}</label>
                        <input
                            type='number'
                            id={element.id.toString()}
                            className='form-control attribute-input'
                            defaultValue={element.value}
                            disabled={element.editable !== 1}
                            onChange={(e) => dynamicInputChange(e, 'number', element.id)}
                        ></input>
                    </Col>
                );
            case 'multiline text':
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'>{element.title}</label>
                        <textarea
                            id={element.id.toString()}
                            className='form-control attribute-textarea'
                            disabled={element.editable !== 1}
                            onChange={(e) => dynamicTextAreaChange(e, element.id)}
                        >
                            {element.value}
                        </textarea>
                    </Col>
                );
            case 'single-select':
                const singleSelectOptions = updateOptions(element.options, 'key', 'value');
                let filteredOption = singleSelectOptions.filter((option: { id: string; title: string }) => option.id === element.value);
                let defaultValue = filteredOption.length > 0 ? filteredOption[0]?.title : '';
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <Select
                            selectContainerSx={{
                                marginBottom: '10px',
                            }}
                            labelStyle={{
                                fontWeight: 400,
                                lineHeight: '19px',
                                letterSpacing: 0,
                                textAlign: 'left',
                                marginBottom: '10px',
                            }}
                            selectSx={{
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                            }}
                            iconStyle={{
                                marginLeft: '0px',
                                marginRight: '10px',
                            }}
                            label={element.title}
                            inputLabel={false}
                            options={singleSelectOptions}
                            multiple={false}
                            selectedValue={singleSelectedValue[element.id] ? singleSelectedValue[element.id] : defaultValue}
                            setSelectedValue={(selectedOption) => handleSingleSelect(element.id, selectedOption)}
                            handleChange={(e: any) => {
                                e.stopPropagation();
                                handleSingleSelect(element.id, e.target.value);
                            }}
                            displaySearchField={false}
                        />
                    </Col>
                );
            case 'multi-select':
                const multiSelectOptions = updateOptions(element.options, 'key', 'value');
                const filteredMultiOptions = multiSelectOptions.filter((option: { id: string; title: string }) => element.value?.includes(option.id));
                const defaultValues = filteredMultiOptions.map((option: { id: string; title: string }) => option.title);
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <Select
                            selectContainerSx={{
                                marginBottom: '10px',
                            }}
                            labelStyle={{
                                fontWeight: 400,
                                lineHeight: '19px',
                                letterSpacing: 0,
                                textAlign: 'left',
                                marginBottom: '10px',
                            }}
                            selectSx={{
                                border: '1px solid #ced4da',
                                borderRadius: '0.25rem',
                            }}
                            iconStyle={{
                                marginLeft: '0px',
                                marginRight: '10px',
                            }}
                            label={element.title}
                            inputLabel={false}
                            options={updateOptions(element.options, 'key', 'value')}
                            multiple={true}
                            selectedValue={multiSelectedValue[element.id] ? multiSelectedValue[element.id] : defaultValues}
                            setSelectedValue={(selectedOption) => handleMultiSelect(element.id, selectedOption)}
                            handleChange={(e: any) => {
                                e.stopPropagation();
                                handleMultiSelect(element.id, e.target.value);
                            }}
                            displaySearchField={false}
                        />
                    </Col>
                );
            default:
                return (
                    <Col xs={12} md={6} lg={6} xl={6} className='mt-15'>
                        <label className='attribute-label'>{element.title}</label>
                        <input
                            type='text'
                            id={element.id.toString()}
                            className='form-control attribute-input'
                            defaultValue={element.value}
                            disabled={element.editable !== 1}
                            onChange={(e) => dynamicInputChange(e, 'input', element.id)}
                        ></input>
                    </Col>
                );
        }
    };

    // Visible and editable fields add to the percentage
    const calculatePercentage = () => {
        let completed = 0;
        let attrCount = 0;

        if (editUserData.user.is_editable_firstName) {
            attrCount++;
            if (editUserData.user.first_name && editUserData.user.first_name !== '') {
                completed++;
            }
        }
        if (editUserData.user.is_editable_lastName) {
            attrCount++;
            if (editUserData.user.last_name && editUserData.user.last_name !== '') {
                completed++;
            }
        }
        if (editUserData.user.is_visible_title && editUserData.user.is_editable_title) {
            attrCount++;
            if (editUserData.user.job_title && editUserData.user.job_title !== '') {
                completed++;
            }
        }
        if (editUserData.user.image_url) {
            attrCount++;
            if (
              (editUserData.user.image_url &&
                editUserData.user.image_url !== '' &&
                editUserData.user.image_url !== 'https://cdn.engageesp.com/img/person.png'
              ) || !editUserData.user.can_edit_image
            ) {
                completed++;
            }
        }
        if (editUserData.user.header_image_url) {
            attrCount++;
            if (
                (editUserData.user.header_image_url &&
                    editUserData.user.header_image_url !== '' &&
                    editUserData.user.header_image_url !== 'https://cdn.engageesp.com/img/1px.png') ||
                !editUserData.user.can_edit_header
            ) {
                completed++;
            }
        }
        if (editUserData.email_visible && editUserData.email_editable) {
            attrCount++;
            if (editUserData.email && editUserData.email !== '') {
                completed++;
            }
        }
        if (editUserData.department_visible && editUserData.department_editable) {
            attrCount++;
            if (editUserData.department && editUserData.department !== '') {
                completed++;
            }
        }
        if (editUserData.editable.description) {
            attrCount++;
            if (editUserData.description && editUserData.description !== '') {
                completed++;
            }
        }

        // Calculate the dynamic fields
        if (editUserData.dynamic_attributes && editUserData.dynamic_attributes.length > 0) {
            editUserData.dynamic_attributes.map((attr) => {
                if (attr.editable) {
                    attrCount++;
                    if (attr.value && attr.value !== '') {
                        completed++;
                    }
                }
            });
        }
        setPercentage(Math.round((completed / attrCount) * 100));
    };

    const saveAction = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.currentTarget.disabled = true;

        connectService.updateProfile(
            JSON.stringify(getFormatData()),
            editUserData.id,
            (resp: AxiosResponse) => {
                isLoadingCallBack(true);
                dispatch(
                    addNotification({
                        label: t('connect.success'),
                        text: t('connect.successLong'),
                        type: 'success',
                    })
                );
                navigate(-1);
            },
            (resp: AxiosResponse) => {
                dispatch(
                    addNotification({
                        label: t('connect.error'),
                        text: t('connect.errorLong'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const getFormatData = () => {
        const formattedData: any = {
            id: editUserData.id,
            dynamic_attributes: processDynamicData(),
        };
        for (let key in editUserData.editable) {
            if (editUserData.user[key]) {
                formattedData[key] = editUserData.user[key];
            } else if (editUserData[key]) {
                formattedData[key] = editUserData[key];
            }
        }
        return formattedData;
    };

    const processDynamicData = () => {
        let dynamicAttributes: { id: number; value: any }[] = [];
        if (editUserData.dynamic_attributes && editUserData.dynamic_attributes.length > 0) {
            editUserData.dynamic_attributes.map((attr) => {
                if (attr.editable) {
                    if (attr.attribute_type === 'multi-select') {
                        if (!multiSelectedValue[attr.id]) {
                            dynamicAttributes.push({
                                id: attr.id,
                                value: attr.value,
                            });
                        } else {
                            let selectedValues = attr.options.filter((option: { key: string; value: string }) => {
                                return multiSelectedValue[attr.id]?.includes(option.value);
                            });
                            let selectedKeys = selectedValues.map((option: { key: string; value: string }) => option.key);
                            dynamicAttributes.push({
                                id: attr.id,
                                value: selectedKeys,
                            });
                        }
                    } else if (attr.attribute_type === 'single-select') {
                        if (!singleSelectedValue[attr.id]) {
                            dynamicAttributes.push({
                                id: attr.id,
                                value: attr.value,
                            });
                        } else {
                            let selectedValue = attr.options.filter((option: { key: string; value: string }) => {
                                return singleSelectedValue[attr.id] === option.value;
                            });
                            let selectedKey = selectedValue.map((option: { key: string; value: string }) => option.key);
                            dynamicAttributes.push({
                                id: attr.id,
                                value: selectedKey[0] ? selectedKey[0] : '',
                            });
                        }
                    } else {
                        dynamicAttributes.push({
                            id: attr.id,
                            value: attr.value,
                        });
                    }
                }
            });
        }
        return dynamicAttributes;
    };

    return (
        <Row
            style={{
                backgroundColor: 'white',
            }}
        >
            <Form onSubmit={saveAction}>
                <Col xs={12} md={12} lg={12} xl={12} className='mb-100'>
                    <div
                        id='contact-header-img'
                        className='position-relative thumb128 ml-auto mr-auto contact-header mb-100'
                        style={{
                            backgroundSize: 'cover',
                            backgroundImage: `url(${
                                editUserData.user.header_image_url
                                    ? editUserData.user.header_image_url
                                    : editUserData.user.default_header_img
                                    ? editUserData.user.default_header_img
                                    : ''
                            })`,
                        }}
                    >
                        {editUserData.user.can_edit_header ? (
                            <div className='position-absolute profile-image font-16 header-edit-trigger cursor-pointer' onClick={modalTriggerHeader}>
                                <img src='https://cdn.engageesp.com/img/pencil-light.svg' className='edit-pencil border-0' alt={'edit-image'} />
                            </div>
                        ) : null}
                        <div className='profile-image-edit'>
                            <div className={'image-placement-container' + (editUserData.user.can_edit_image ? ' cursor-pointer' : '')}
                                  onClick={editUserData.user.can_edit_image ? modalTrigger : () => {}}>
                                <img
                                    id={'connect-user-profile-image'}
                                    className={
                                        'media-object img-responsive rounded-circle thumb128 mb-lg connect-profile-image border-engage-primary image-edit-placement'
                                    }
                                    style={{ position: 'absolute' }}
                                    src={editUserData.user.image_url ? editUserData.user.image_url : 'https://cdn.engageesp.com/img/person.png'}
                                />
                                <div className='position-absolute'>
                                    <CircularProgressbar
                                        value={percentage}
                                        styles={{
                                            path: {
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(0.50turn)',
                                                transformOrigin: 'center center',
                                            },
                                        }}
                                    />
                                    <div className={'percentage-container'}>{percentage}% complete</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} className={'mt-5'}>
                        {renderMainDetails()}
                        {editUserData.dynamic_attributes && editUserData.dynamic_attributes.length > 0 ? (
                            <Row className='padding-left-20 padding-right-20 mt-4'>
                                {editUserData.dynamic_attributes.map((res) => renderAdditionalDetails(res))}
                            </Row>
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={12} className={'mt-3'}>
                        <Button className='button-container'
                                style={{
                                    backgroundColor: buttonBgColor,
                                    borderColor: buttonBgColor,
                                    color: buttonFgColor
                                }}>
                            {t('connect.save')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Row>
    );
};
export default ContactEditProfilePage;
