import React, { FunctionComponent } from 'react';
import { Modal, ModalHeader, Button } from 'reactstrap';
import classnames from 'classnames';
import { FileData } from '../../../../types/types';
import { useTranslation } from 'react-i18next';
import Scrollbar from '../../common/scrollbar/scrollbar';

type FilesListProps = {
    isOpen: boolean,
    toggle: any,
    files: Array<FileData>
    modalClass?: string,
    fileClickCallback: Function
}

const FilesList: FunctionComponent<FilesListProps> = ({ isOpen, toggle, files, modalClass, fileClickCallback }) => {
    const { t } = useTranslation();

    const renderMediaFile = (file: FileData) => {
        const imgPreviewUrl = file.preview || file.url;

        return (
            <div key={file.name} data-testid="file-section" className="file-item c-pointer d-flex justify-content-between p-1" onClick={() => fileClickCallback(file)}>
                { file.type === 'image' ?
                    <img data-testid="file-img-preview" className="file-item-img-preview" src={imgPreviewUrl} />
                : 
                    <div data-testid="file-video-preview" className="file-item-video-preview d-flex justify-content-center align-items-center" style={{ backgroundImage: `url(${imgPreviewUrl})` }}>
                        <img className="video-preview-icon position-absolute img-responsive cursor-pointer"
                            src="/assets/img/video-preview-icon.svg" alt="video"
                        /> 
                    </div> 
                }

                <div data-testid="file-name" className="d-flex align-items-center p-2 text-truncate">
                    { file.name }
                </div>
            </div>
        );
    }

    return (
        <Modal data-testid="files-list" isOpen={isOpen} toggle={toggle} className={classnames('files-list-modal', modalClass)}>
            <ModalHeader toggle={toggle}>
                <h4 data-testid="files-list-modal-header" className="fw-bold text-light-black">{ t('feed.attachments') }</h4>
            </ModalHeader>

            <div className="files-container p-1">
                <Scrollbar>
                    { files.map(file => renderMediaFile(file)) }
                </Scrollbar>
            </div>

            <div className="m-1">
                <Button data-testid="files-list-modal-done-btn" className="w-100 text-white" color="success" style={{ backgroundColor: '#47a58d' }} onClick={toggle}>
                    { t('feed.done') }    
                </Button>
            </div>
        </Modal>
    );
}

export default FilesList;