import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalHeader } from "reactstrap";
import "./cookieModalStyles.scss";
import Switch from "react-switch";
import navigationService from "../../../web/services/navigationSevice";
import { addNotification } from "../../../shared/reducers/notifications/actionTypes";
import { AxiosResponse } from "axios";

type CookieModalProps = {
  isOpen: boolean;
  recordPerformance: boolean;
  recordAnalytics: boolean;
  cookieTermsLink: string;
  onSaveAction: Function;
};

const CookieModal: FunctionComponent<CookieModalProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [performanceCookieStatus, setPerformanceCookieStatus] =
    useState<boolean>(props.recordPerformance);
  const [analyticsCookieStatus, setAnalyticsCookieStatus] = useState<boolean>(
    props.recordAnalytics
  );
  const branding = useSelector((state: any) => state.app.branding);

  const saveCookiePreferences = () => {
    navigationService.savePreferences(
      {
        essential_cookies: true, // Always enabled as we need them!
        performance_cookies: performanceCookieStatus,
        analytics_cookies: analyticsCookieStatus,
      },
      (response: AxiosResponse) => {
        // Callback to close the modal and show message.
        props.onSaveAction(response.data.data);
      },
      () => {
        dispatch(
          addNotification({
            label: t("partials.settingOptions.error.label"),
            text: t("partials.settingOptions.error.text"),
            type: "danger",
          })
        );
      }
    );
  };

  const renderNecessarySwitch = () => {
    return (
      <Switch
        onChange={() => {
          // No changes required as the essentials can't be changed.
        }}
        checked={true}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={22}
        height={25}
        width={50}
        onColor="#71D641"
        offColor="#AFAFAF"
        onHandleColor="#ffffff"
        offHandleColor="#ffffff"
        className="rounded-circle rounded-pill mx-2"
        disabled={true}
      />
    );
  };

  const renderPerformanceSwitch = () => {
    let status = performanceCookieStatus ? performanceCookieStatus : false;
    return (
      <Switch
        onChange={() => {
          setPerformanceCookieStatus(!status);
        }}
        checked={status}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={22}
        height={25}
        width={50}
        onColor="#71D641"
        offColor="#AFAFAF"
        onHandleColor="#ffffff"
        offHandleColor="#ffffff"
        className="rounded-circle rounded-pill mx-2"
      />
    );
  };

  const renderAnalyticsSwitch = () => {
    let status = analyticsCookieStatus ? analyticsCookieStatus : false;

    return (
      <Switch
        onChange={() => {
          setAnalyticsCookieStatus(!status);
        }}
        checked={status}
        uncheckedIcon={false}
        checkedIcon={false}
        handleDiameter={22}
        height={25}
        width={50}
        onColor="#71D641"
        offColor="#AFAFAF"
        onHandleColor="#ffffff"
        offHandleColor="#ffffff"
        className="rounded-circle rounded-pill mx-2"
      />
    );
  };

  return (
    <Modal isOpen={props.isOpen} className={"cookies-modal"}>
      <ModalHeader
        className={"cookies-header"}
        style={{ backgroundColor: branding.cookies_header_colour }}
      >
        <div className="fw-bold">{t("partials.settingOptions.title")}</div>
      </ModalHeader>

      <div className="cookie-container">
        <div className={"cookie-titles mb-3"}>
          {t("partials.settingOptions.title")}
        </div>
        <div>
          {branding.cookies_text
            ? branding.cookies_text
            : t("partials.settingOptions.content")}
        </div>
        <div className={"cookie-titles mt-3 mb-3"}>
          {t("partials.settingOptions.subQuestion")}
        </div>

        <div className={"ml-3"}>
          <div className={"mb-3 separator"}>
            <div className={"row"}>
              <div className={"col-10 cookie-sub-titles"}>
                {t("partials.settingOptions.necessaryTitle")}
              </div>
              <div className={"col-2"}>{renderNecessarySwitch()}</div>
            </div>
            <div className={"row mb-3"}>
              <div className={"col-12"}>
                {t("partials.settingOptions.necessary")}
              </div>
            </div>
          </div>

          <div className={"mb-3 separator"}>
            <div className={"row"}>
              <div className={"col-10 cookie-sub-titles"}>
                {t("partials.settingOptions.performanceTitle")}
              </div>
              <div className={"col-2"}>{renderPerformanceSwitch()}</div>
            </div>
            <div className={"row mb-3"}>
              <div className={"col-12"}>
                {t("partials.settingOptions.performance.firstLine")} <br />
                {t("partials.settingOptions.performance.secondLine")} <br />
                {t("partials.settingOptions.performance.thirdLine")} <br />
              </div>
            </div>
          </div>

          <div className={"mb-3 separator"}>
            <div className={"row"}>
              <div className={"col-10 cookie-sub-titles"}>
                {t("partials.settingOptions.analyticsTitle")}
              </div>
              <div className={"col-2"}>{renderAnalyticsSwitch()}</div>
            </div>
            <div className={"row mb-3"}>
              <div className={"col-12"}>
                {t("partials.settingOptions.analytics.firstLine")}
                <br />
                {t("partials.settingOptions.analytics.secondLine")}
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className={"mt-4 mb-3"}>
          {branding.cookies_link && branding.cookies_link !== "" ? (
            <div
              dangerouslySetInnerHTML={{
                __html:
                  t("partials.settingOptions.subText") + branding.cookies_link,
              }}
            ></div>
          ) : (
            t("partials.settingOptions.subTextDefault")
          )}
        </div>
      </div>

      <div className="m-1">
        <Button
          className="btn-container"
          onClick={saveCookiePreferences}
          style={{
            backgroundColor: branding.cookies_button_background,
            borderColor: branding.cookies_button_background,
          }}
        >
          {t("partials.settingOptions.save")}
        </Button>
      </div>
    </Modal>
  );
};

export default CookieModal;
