export default {
  "menus": {
    "home": "Home",
    "feed": "Voer",
    "messages": "Berichten",
    "connect": "Verbind",
    "awards": "Onderscheidingen",
    "settings": "Instellingen en privacy",
    "profile": "Profiel",
    "notifications": "Meldingen",
    "change password": "Wachtwoord wijzigen",
    "signOut": "Uitloggen",
    "mark as read": "Markeren als gelezen"
  },
  "settingOptions": {
    "title": "Privacy-instellingen",
    "content": "Cookies zijn kleine tekstbestanden die op uw computer of mobiele apparaat worden geplaatst wanneer u een site bezoekt of een app gebruikt. Ze worden veel gebruikt om websites en apps te laten werken of efficiënter te laten werken, en om informatie te verstrekken aan de eigenaren van de site/app. In de onderstaande tabel wordt uitgelegd welke cookies we gebruiken en waarom.",
    "subQuestion": "Welke cookies gebruiken we?",
    "subText": "Voor meer informatie, zie onze",
    "subTextDefault": "Zie ons privacy- en cookiebeleid voor meer informatie",
    "necessaryTitle": "Strikt noodzakelijk",
    "necessary": "Noodzakelijke cookies zijn essentieel voor de kernfunctionaliteit en beveiliging van de app. Als u akkoord gaat, willen we ook cookies gebruiken om",
    "performanceTitle": "Prestatiecookies",
    "notificationSettings": "Instellingen voor meldingen",
    "emails": "E-mails",
    "p2pEmailsLabel": "Stuur me e-mailmeldingen voor {{P2pLabel}}",
    "performance": {
      "firstLine": "• Verbeter de prestaties van de app",
      "secondLine": "• Begrijp hoe de app wordt gebruikt",
      "thirdLine": "• Inhoud optimaliseren"
    },
    "analyticsTitle": "Analytische cookies",
    "analytics": {
      "firstLine": "• De app-ervaring verbeteren",
      "secondLine": "• Diensten personaliseren"
    },
    "save": "Voorkeuren opslaan",
    "error": {
      "label": "Fout",
      "text": "Er is een fout opgetreden, probeer het later opnieuw."
    }
  },
  "type search field": "Typ en druk op Enter..."
};