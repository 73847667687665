export default {
  "inbox": "Inbox",
  "searchChats": "Chats zoeken",
  "searchArchivedChats": "Zoek in gearchiveerde chats",
  "searchContacts": "Contactpersonen zoeken",
  "description": "Omschrijving",
  "chatInfo": "Chatinformatie",
  "created": "Gemaakt",
  "newChat": "Nieuwe chat",
  "createChatPlaceholder": "Maak een groep aan of selecteer een contact om een bericht naar te sturen.",
  "allContacts": "Alle contactpersonen",
  "newUserChatPlaceholder": "Je hebt nog nooit een bericht gestuurd naar {{DynamicValue}}, begin met typen om te beginnen.",
  "archived": "Gearchiveerd",
  "chatSettings": "Chat-instellingen",
  "showArchivedChats": "Gearchiveerde chats weergeven",
  "participants": "Deelnemers",
  "searchParticipants": "Deelnemers zoeken",
  "muteMsg": "Meldingen zijn uitgeschakeld voor deze thread omdat deze openbaar is",
  "muteByAdminMsg": "Meldingen zijn uitgeschakeld voor deze thread omdat deze door een beheerder wordt gedempt",
  "prefixMuteMsg": "De draad is gedempt totdat",
  "startTyping": "Begin met typen",
  "muteNotifications": "Meldingen negeren",
  "newGroupChat": "Groepsdeelnemers toevoegen",
  "newGroup": "Nieuwe groep",
  "newGroupPlaceholder": "Selecteer alstublieft deelnemers voor uw groepschat",
  "groupSettings": "Groepsinstellingen",
  "uploadGroupIcon": "Groepspictogram uploaden",
  "groupNamePlaceholder": "Voer de groepsnaam in",
  "groupDescriptionPlaceholder": "Geef een beschrijving van je groep",
  "updateGroup": "Groep bijwerken",
  "leaveGroup": "Groep verlaten",
  "leaveGroupErrorMsg": "Er is een fout opgetreden bij het indienen van de aanvraag voor een verlofgroep. Probeer het opnieuw.",
  "leaveGroupModalTitle": "Weet je zeker dat je deze groep wilt verlaten?",
  "leaveGroupModalContent": "Als je deze groep verlaat, kun je niet opnieuw lid worden zonder een uitnodiging.",
  "manageParticipants": "Deelnemers beheren",
  "addParticipant": "Deelnemer toevoegen",
  "removeParticipant": "Deelnemer verwijderen",
  "addParticipantErrorMsg": "Er is een fout opgetreden bij het indienen van het verzoek om deelnemer toe te voegen. Probeer het opnieuw.",
  "removeParticipantErrorMsg": "Er is een fout opgetreden bij het indienen van het verzoek om de deelnemer te verwijderen. Probeer het nog eens.",
  "manage": "Beheren",
  "sendMessage": "Bericht verzenden",
  "failedToSendMessage": "Het verzenden van het bericht is mislukt.",
  "admins": "Beheerders",
  "manageAdmins": "Beheerders beheren",
  "addAdmin": "Admin toevoegen",
  "removeAdmin": "Admin verwijderen",
  "addAdminErrorMsg": "Er is een fout opgetreden bij het verzenden van het admin-verzoek. Probeer het nog eens.",
  "removeAdminErrorMsg": "Er is een fout opgetreden bij het indienen van het verwijderingsverzoek van de beheerder. Probeer het nog eens.",
  "mute": "Dempen",
  "archive": "Archiveren",
  "delete": "Verwijderen",
  "unarchive": "Uit het archief halen",
  "unmute": "Dempen niet meer",
  "archiveErrorMsg": "Er is een fout opgetreden bij het indienen van de archiefaanvraag. Probeer het nog eens.",
  "unArchiveErrorMsg": "Er is een fout opgetreden bij het indienen van het unarchiveringsverzoek. Probeer het nog eens.",
  "userDeletedMessage": "Je hebt dit bericht verwijderd.",
  "deletedMessage": "Dit bericht is verwijderd.",
  "deletedMessageThread": "Bericht verwijderd."
};