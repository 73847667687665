export default {
  "locations": "Standorte",
  "contacts": "Kontakte",
  "favourites": "Favoriten",
  "all": "Alle",
  "noDataFound": "Keine Daten gefunden",
  "thereAreNoPost": "Es gibt keine Beiträge in sozialen Netzwerken, fange an zu posten, um sie hier zu sehen!",
  "noOrgChart": "Diesem Benutzer ist kein Organigramm zugeordnet.",
  "awarded": "Verliehen",
  "expires": "Läuft ab",
  "contactDetails": "Angaben zur Kontaktperson",
  "followers": "Anhänger",
  "following": "Folgend",
  "follow": "Folgen",
  "unfollow": "Nicht mehr folgen",
  "unsnooze": "Schlummern",
  "snooze": "Schlummern",
  "1Hour": "1 Stunde",
  "8Hours": "8 Stunden",
  "24Hours": "24 Stunden",
  "1Week": "1 Woche",
  "1Month": "1 Monat",
  "1Year": "1 Jahr",
  "message": "Nachricht",
  "details": "Einzelheiten",
  "posts": "Beiträge",
  "organisation": "Organisation",
  "profileImageHeader": "Profilbild",
  "headerImageHeader": "Profil-Header-Bild",
  "uploadFile": "Datei hochladen",
  "zoom": "Zoomen (%)",
  "rotate": "Rotieren",
  "done": "Erledigt",
  "ratio": "Seitenverhältnis beibehalten",
  "on": "Aktiviere",
  "off": "Deaktiviert",
  "search": "Suche",
  "noFavourites": "Fangen Sie an, Lieblingskontakte hinzuzufügen!",
  "manager": "Geschäftsführer",
  "location": "Standort",
  "primaryEmail": "Primäre E-Mail",
  "dob": "Datum der Geburt",
  "start": "Startdatum",
  "bio": "Bio",
  "chart": "Organigramm",
  "close": "Schliessen",
  "address": "Adresse",
  "description": "Beschreibung",
  "fName": "Vorname",
  "lName": "Nachname",
  "title": "Titel",
  "department": "Abteilung",
  "save": "Speichern",
  "error": "Es gab einen Fehler,",
  "errorLong": "Bitte versuchen Sie es später erneut.",
  "success": "Erfolg!",
  "successLong": "Details wurden korrekt gespeichert",
  "email": "E-Mail",
  "badge": "Abzeichen",
  "skills": "Fähigkeiten",
  "phone": "Telefon",
  "hobbies": "Hobbies",
  "clearAll": "Alles löschen",
  "quickFilters": "Schnelle Filter",
  "customSearchTextPlaceholder": "Beginnen Sie mit der Eingabe und drücken Sie die Eingabetaste...",
  "working hours": "Arbeitszeiten",
  "fun facts": "Unterhaltsame Fakten",
  "noResultsFound": "Keine Ergebnisse gefunden",
  "charsLimitMsg": "Bitte geben Sie 3 oder mehr Zeichen ein, um zu suchen",
  "errorLoadingFollowers": "Fehler beim Laden von Followern",
  "errorLoadingFollowings": "Fehler beim Laden der Follower",
  "followsYou": "Folgt dir",
  "searchContacts": "Kontakte suchen",
  "searchLocations": "Standorte suchen",
  "allContacts": "Alle Kontakte",
  "edit": "Bearbeiten",
  "people": "Leute",
  "directory": "Verzeichnis",
  "noSearchResultsContactDescription": "Hoppla! Offenbar konnten wir keine Kontakte finden, die Ihrer Suche entsprechen.",
  "noContactResultsDescription": "Hoppla! Offenbar konnten wir keine Kontakte finden.",
  "noSearchResultsFavouriteDescription": "Hoppla! Offenbar konnten wir keine Lieblingskontakte finden, die Ihrer Suche entsprechen.",
  "noFavouriteResultsDescription": "Hoppla! Keine Lieblingskontakte gefunden. Fügen Sie Kontakte zu Ihren Favoriten hinzu, um sie hier anzusehen.",
  "noSearchResultsLocationDescription": "Hoppla! Offenbar konnten wir keine Standorte finden, die Ihrer Suche entsprechen.",
  "noLocationResultsDescription": "Hoppla! Offenbar konnten wir keine Standorte finden.",
  "clearFilters": "Filter zurücksetzen",
  "filters": "Filter",
  "noLocationPermission": "Ups! Es sieht so aus, als hätten Sie keine Berechtigungen, um dies zu sehen. Versuchen Sie es an einem anderen Ort.",
  "groups": "Gruppen",
  "joinGroup": "Gruppe beitreten",
  "leaveGroup": "Gruppe verlassen",
  "mandatoryGroup": "Pflichtgruppe",
  "noSearchResultsGroupsDescription": "Hoppla! Es scheint, dass wir keine Gruppen gefunden haben, die deiner Suche entsprechen.",
  "noGroupsResultsDescription": "Hoppla! Es scheint, dass wir keine Gruppen gefunden haben.",
  "groupMembers": "Gruppenmitglieder",
  "noGroupMembers": "Hoppla! Es sieht so aus, als gäbe es in dieser Gruppe noch keine Mitglieder.",
  "cannotLeaveGroupTooltip": "Sie wurden von einem Administrator automatisch zu dieser Gruppe hinzugefügt. Wenn Sie die Gruppe verlassen möchten, wenden Sie sich bitte an einen Gruppenadministrator um Unterstützung."
};