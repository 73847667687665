import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { useSelector } from 'react-redux';

import './leaveGroupModal.scss';
import { isWhite } from '../../utils/colourCheck';

export type LeaveGroupModalProps = {
    isOpen: boolean,
    leaveGroupLoading?: boolean,
    handleOnSubmit?: Function,
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
};

const LeaveGroupModal: React.FC<LeaveGroupModalProps> = ({
    isOpen,
    leaveGroupLoading,
    handleOnSubmit,
    setIsOpen,
}) => {

    const { t } = useTranslation();
    const tenantBranding = useSelector((state: any) => state.app.branding);

    const onSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (handleOnSubmit) handleOnSubmit();
    };

    const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
    const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
    return (
        <Modal isOpen={isOpen} className="leave-group-modal" data-testid="leave-group-modal">
            <div className="row m-0">
                <div className="col-12 ps-0 pe-0">
                    <ModalHeader className='leave-group-header p-4 pe-0'>
                        <div data-testid="leave-group-title" className="fw-bold">{t("chat.leaveGroupModalTitle")}</div>
                        <div data-testid="leave-group-msg" className="leave-group-msg">{t("chat.leaveGroupModalContent")}</div>
                    </ModalHeader>
                    <div className="d-flex justify-content-end pt-2 pb-3 pe-4">
                        <Button 
                            className="me-2 no-btn" 
                            data-testid="reject" 
                            onClick={() => setIsOpen(false)}
                            style={{
                                opacity: leaveGroupLoading ? 0.5 : 1,
                                color: bgColor ? bgColor : "#15385B",
                                border: bgColor ? `2px solid ${bgColor}` : "2px solid #15385B",
                            }}
                            disabled={leaveGroupLoading ? true : false}
                        >
                            {t("general.no")}
                        </Button>
                        <Button 
                            className="yes-btn text-white" 
                            data-testid="accept" 
                            onClick={onSubmit}
                            style={{
                                opacity: leaveGroupLoading ? 0.5 : 1,
                                background: bgColor ? bgColor : "#15385B",
                                borderColor: bgColor ? bgColor : "#15385B",
                            }}
                            disabled={leaveGroupLoading ? true : false}
                        >
                            {t("general.yes")}
                        </Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default LeaveGroupModal;