import React from 'react';
import { Dialog, DialogTitle, IconButton, DialogContent } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/pro-solid-svg-icons';
import { SearchField, SearchFieldProps } from '../../components/searchField/searchField';
import { MembersList } from '../MembersList/MembersList';
import { InfiniteQueryObserverResult, FetchNextPageOptions } from 'react-query';
import { useTranslation } from 'react-i18next';

export type MembersListModalProps = {
    open: boolean;
    handleModalClose: () => void;
    header?: string;
    searchFieldProps?: SearchFieldProps;
    displaySearchField?: boolean;
    memberList: {
        full_name: string;
        hasManager: boolean;
        id: number;
        image_url: string;
        job_title: string;
        contact_id: number;
    }[];
    loading: boolean;
    fetchingNextPage: boolean;
    handleScroll: (e: any) => void;
    fetchNextPage: (options?: FetchNextPageOptions | undefined) => Promise<InfiniteQueryObserverResult<any, unknown>>;
    totalMembers: number;
};

export const MembersListModal = (props: MembersListModalProps) => {
    const {
        open,
        handleModalClose,
        header,
        searchFieldProps,
        memberList,
        displaySearchField,
        loading,
        fetchingNextPage,
        handleScroll,
        totalMembers,
    } = props;
    const { t } = useTranslation();
    return (
        <Dialog
            open={open}
            data-testid='members-list-modal'
            onClose={handleModalClose}
            PaperProps={{
                sx: {
                    maxWidth: '600px',
                    width: '100%',
                },
            }}
        >
            <DialogTitle
                sx={{
                    pb: 0,
                    fontSize: '20px',
                    fontWeight: 500,
                    lineHeight: '1.5',
                }}
            >
                {header || t('general.members').toLocaleLowerCase()} ({totalMembers})
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={handleModalClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 10,
                }}
                disableFocusRipple
                disableRipple
            >
                <FontAwesomeIcon
                    icon={faClose}
                    style={{
                        color: 'black',
                    }}
                />
            </IconButton>
            <DialogContent>
                {displaySearchField && <SearchField sx={{ width: '100%' }} searchQuery='' {...searchFieldProps} />}
                <MembersList
                    memberList={memberList}
                    containerSx={{
                        mt: 2,
                        height: '500px',
                        overflowY: 'auto',
                    }}
                    loading={loading}
                    handleScroll={handleScroll}
                    fetchingNextPage={fetchingNextPage}
                />
            </DialogContent>
        </Dialog>
    );
};
