import { useState } from 'react';

export type useCopyType = {
    copyText: string | null;
    copyAttribute: string | null;
    copy: (text: string, attribute: string) => void;
};

export const useCopy = (): useCopyType => {
    const [copyText, setCopyText] = useState<string | null>(null);
    const [copyAttribute, setCopyAttribute] = useState<string | null>(null);

    const copy = async (text: string, attribute: string) => {
        try {
        await navigator.clipboard.writeText(text);
        setCopyText(text);
        setCopyAttribute(attribute);
        setTimeout(() => {
            setCopyText(null);
            setCopyAttribute(null);
        }, 2000);
    } catch (err) {
        console.error('Failed to copy: ', err);
        setCopyText(null);
    }}

    return { copyText, copyAttribute, copy };
};
