import { useMutation } from 'react-query';
import Client from '../services/axiosService';

/**
 * @description: Makes a Post request to the API to mark a thread as read
 * @param threadId: The id of the thread to mark as read
 * @returns: The response from the API
 */
const postMarkAsRead = async (threadId: number) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${threadId}/mark-as-read`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}

/**
 * @description: A hook to mark a thread as read
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useMarkAsRead = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(postMarkAsRead, {
        onSuccess: onSuccess,
        onError: onError,
    });
}
