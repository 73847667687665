import { useMutation } from "react-query";
import Client from "../services/axiosService";

export type addReactionParams = {
    threadID: string;
    messageID: string;
    reaction: string;
}

/**
 * @description: Makes a Post request to the API to add a reaction for an individual message
 * @param param0 
 * @returns 
 */
const addReaction = async ({ threadID, messageID, reaction } : addReactionParams) => {
    let formdata = new FormData();
    formdata.append("reaction", reaction);
    const response = await Client(true, false).request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${threadID}/messages/${messageID}/reaction`,
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        data: formdata,
    });
    return response.data;
}

/**
 * @description: A hook to add a reaction to an individual message
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
*/
export const useAddReaction = (onSuccess?: (data: any) => void, onError?: () => void) => {
    return useMutation(addReaction, {
        onSuccess: onSuccess,
        onError: onError,
    })
}