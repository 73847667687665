import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const configProvider = {
    getByKeys(keys: string, tenantId: string, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/api/v1/config/get/${tenantId}/${keys}`,
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default configProvider;