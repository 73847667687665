export default {
  "please enter your company reference": "Veuillez saisir la référence de votre entreprise",
  "enter user registration credentials": "Veuillez saisir vos coordonnées ci-dessous",
  "enter email": "Veuillez saisir votre adresse e-mail",
  "enter password": "Veuillez saisir votre mot de passe",
  "repeat password": "Veuillez répéter votre mot de passe",
  "registration successful": "Enregistrement réussi.",
  "registration successful instruction": "Un lien de confirmation limité dans le temps a été envoyé à l'adresse e-mail spécifiée. Veuillez suivre les instructions figurant dans l'e-mail pour terminer la configuration du compte. (Veuillez vérifier votre dossier de courrier indésirable si vous ne pouvez pas voir l'e-mail)",
  "errors": {
    "domain name can't be empty": "Le nom de domaine ne peut pas être vide",
    "domain does not exist": "Le domaine « {{domain}} » n'existe pas",
    "email can not be empty": "L'e-mail ne peut pas être vide",
    "password can not be empty": "Le mot de passe ne peut pas être vide",
    "custom fields not set": "Les champs obligatoires ne sont pas tous remplis",
    "passwords dont match": "Les mots de passe ne correspondent pas",
    "email is not available": "L'e-mail sélectionné n'est pas disponible",
    "email format is not valid": "Le format de l'e-mail n'est pas valide",
    "password requirements": "Le mot de passe doit contenir 8 caractères, des chiffres, une majuscule et une minuscule",
    "User with this email already exists": "Un utilisateur existe déjà avec les informations que vous avez saisies",
    "Error loading security information from server": "Erreur lors du chargement des informations de sécurité depuis le serveur. Veuillez recharger la page pour réessayer.",
    "Page doesnt have data parameters loaded": "Aucun paramètre de données n'est chargé sur la page. Veuillez réessayer d'ouvrir le lien de l'e-mail."
  },
  "verify": "ENREGISTRER",
  "back": "RETOUR"
};