import { createStore, combineReducers } from 'redux';

import reducers from './shared/reducers/reducers';
import { applyMiddleware } from 'redux';
import { routerReducer } from 'react-router-redux';

// Middlewares
import logger from 'redux-logger';
import thunk from 'redux-thunk';

const middlewares = [];

if (process.env.NODE_ENV !== 'production') {
  // Only enable the redux-logger in non-prod environments
  middlewares.push(logger);
}

middlewares.push(thunk);

let middleware = applyMiddleware(...middlewares);

const store = createStore(
    combineReducers({
            routing: routerReducer,
            app: reducers
    }),
    middleware
);

export default store;