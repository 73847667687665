export default {
    "inbox": "Inbox",
    "searchChats": "Search chats",
    "searchArchivedChats": "Search archived chats",
    "searchContacts": "Search contacts",
    "description": "Description",
    "chatInfo": "Chat Info",
    "created": "Created",
    "newChat": "New Chat",
    "createChatPlaceholder": "Please create a group or select a contact to send message to.",
    "allContacts": "All contacts",
    "newUserChatPlaceholder": "You haven’t messaged {{dynamicValue}} before, start typing to get started..",
    "archived": "Archived",
    "chatSettings": "Chat settings",
    "showArchivedChats": "Show archived chats",
    "participants": "Participants",
    "searchParticipants": "Search participants",
    "muteMsg": "Notifications are disabled for this thread because it is public",
    "muteByAdminMsg": "Notifications are disabled for this thread because it is muted by an admin",
    "prefixMuteMsg": "Thread is muted until",
    "startTyping": "Start typing",
    "muteNotifications": "Mute Notifications",
    "newGroupChat": "Add group participants",
    "newGroup": "New Group",
    "newGroupPlaceholder": "Please select participants for your group chat",
    "groupSettings": "Group settings",
    "uploadGroupIcon": "Upload group icon",
    "groupNamePlaceholder": "Enter Group Name",
    "groupDescriptionPlaceholder": "Give a description of your group",
    "updateGroup": "Update Group",
    "leaveGroup": "Leave group",
    "leaveGroupErrorMsg": "There was an error submitting the leave group request. Please try again.",
    "leaveGroupModalTitle": "Are you sure you want to exit this group?",
    "leaveGroupModalContent": "Leaving this group means you won’t be able to rejoin without an invite.",
    "manageParticipants": "Manage Participants",
    "addParticipant": "Add Participant",
    "removeParticipant": "Remove Participant",
    "addParticipantErrorMsg": "There was an error submitting the add participant request. Please try again.",
    "removeParticipantErrorMsg": "There was an error submitting the remove participant request. Please try again.",
    "manage": "Manage",
    "sendMessage": "Send Message",
    "failedToSendMessage": "Failed to send message.",
    "admins": "Admins",
    "manageAdmins": "Manage Admins",
    "addAdmin": "Add Admin",
    "removeAdmin": "Remove Admin",
    "addAdminErrorMsg": "There was an error submitting the add admin request. Please try again.",
    "removeAdminErrorMsg": "There was an error submitting the remove admin request. Please try again.",
    "mute": "Mute",
    "archive": "Archive",
    "delete": "Delete",
    "unarchive": "Unarchive",
    "unmute": "Unmute",
    "archiveErrorMsg": "There was an error submitting the archive request. Please try again.",
    "unArchiveErrorMsg": "There was an error submitting the unarchive request. Please try again.",
    "userDeletedMessage": "You deleted this message.",
    "deletedMessage": "This message was deleted.",
    "deletedMessageThread": "Message deleted.",
};
