import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const commentsProvider = {
    likeComment(commentId: number, isLiked: boolean, success: Function, error?: Function) {
        Client(false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/comments/like`,
            method: 'post',
            data: {
                id: commentId,
                is_liked: isLiked
            }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    },

    deleteComment(commentId: number, success: Function, error?: Function) {
        Client(false).request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/comments/delete/${commentId}`,
            method: 'delete'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default commentsProvider;