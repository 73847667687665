import styled from '@emotion/styled';
import { FormControlLabel, Popover, Radio, RadioGroup, Switch } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isWhite } from '../../utils/colourCheck';
import { UseMutateFunction } from 'react-query';
import { AxiosResponse } from 'axios';

export interface MuteThreadProps {
    isOpen: boolean;
    muted: boolean;
    anchorEl?: (EventTarget & SVGSVGElement) | HTMLButtonElement | null;
    mutedUntil: string | undefined;
    muteOptionValue: string;
    threadID: string | undefined;
    isFromThreadList?: boolean;
    setMuteOptionValue: React.Dispatch<React.SetStateAction<string>>;
    setOpenMutePopover?: React.Dispatch<React.SetStateAction<boolean>>;
    setAnchorEl: React.Dispatch<React.SetStateAction<(EventTarget & SVGSVGElement) | null>> | React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
    handleMutePopoverClose?:  () => void;
    muteMutation: UseMutateFunction<AxiosResponse<any, any>, unknown, any, unknown>;
}

const MuteThread: React.FC<MuteThreadProps> = ({ isOpen, anchorEl, muted, threadID, isFromThreadList, mutedUntil, muteOptionValue, muteMutation, handleMutePopoverClose, setMuteOptionValue, setAnchorEl }) => {
    const { t } = useTranslation();
    const brandingColors = useSelector((state: any) => state.app.branding);

    const handleMuteOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation();
        setMuteOptionValue(e.target.value);
    };

    const handlePopoverClose = (e:any) => {
        e.stopPropagation();
        setAnchorEl(null);
        let date = new Date();
        date.setMinutes(date.getMinutes() + parseInt(muteOptionValue));
        muteMutation({
            threadID: threadID,
            formData: {
                muted_until: date.toISOString()
            },
        });
        if (handleMutePopoverClose) handleMutePopoverClose();
    };

    const bgColor = brandingColors.web_navbar_background.toLowerCase();
    const customColor = isWhite(bgColor)
            ? brandingColors.web_navbar_foreground
            : brandingColors.web_navbar_background;

    const CustomRadioButton = styled(Radio)`
        color: black !important;

        &.MuiButtonBase-root {
            color: ${customColor} !important;
        }
    `;

    return (
        <Popover
            open={isOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
                vertical: isFromThreadList ? 'top' : 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: isFromThreadList ? 'left' : 'right',
            }}
            className="mute-popover-menu"
            data-testid="mute-popover-menu"
        >
            { isOpen && (
                <div style={{ padding: muted ? "10px 15px" : "10px 10px 10px 19px"}} className="text-engage-secondary mute-section">
                    <RadioGroup
                        defaultValue="60"
                        name="radio-buttons-group"
                        data-testid="mute-options"
                    >
                        <FormControlLabel onClick={(e) => e.stopPropagation()} className="radio-option" value="60" control={<CustomRadioButton onClick={(e) => e.stopPropagation()} onChange={handleMuteOptionChange} />} label={t("connect.1Hour")} />
                        <FormControlLabel onClick={(e) => e.stopPropagation()} className="radio-option" value="480" control={<CustomRadioButton onClick={(e) => e.stopPropagation()} onChange={handleMuteOptionChange} />} label={t("connect.8Hours")} />
                        <FormControlLabel onClick={(e) => e.stopPropagation()} className="radio-option" value="10080" control={<CustomRadioButton onClick={(e) => e.stopPropagation()} onChange={handleMuteOptionChange} />} label={t("connect.1Week")} />
                        <FormControlLabel onClick={(e) => e.stopPropagation()} className="radio-option" value="525600" control={<CustomRadioButton onClick={(e) => e.stopPropagation()} onChange={handleMuteOptionChange} />} label={t("connect.1Year")} />
                    </RadioGroup>
                </div>
            )}
        </Popover>
    )
}

export default MuteThread;