export default {
  "award": "{{text, en-handle-an}}",
  "awards": "Récompenses",
  "send": "Envoyer {{text, en-handle-an}}",
  "all": "Tous",
  "inbox": "Pour moi",
  "sent": "Envoyé",
  "team": "L'équipe",
  "choose": "Choisissez",
  "toggleManager": "Toggle Manager Messages",
  "manager": "Directeur",
  "received": "reçu {{text, en-handle-an}}",
  "from": "à partir de",
  "message": "Message",
  "managerMessage": "Message au responsable",
  "setVisibility": "Définissez la visibilité",
  "showInFeed": "Présenté dans le fil",
  "onlyRecipients": "Vu uniquement par les destinataires",
  "recipients": "Bénéficiaires",
  "for": "pour",
  "messagePlaceholder": "Tapez votre message ici",
  "thankYou": "Merci"
};