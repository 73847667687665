import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { LocationCard } from '../../components/locationCard/locationCard';
import { InfiniteData } from 'react-query';
import { useParams } from 'react-router-dom';

export type LocationListProps = {
    locations: InfiniteData<any> | undefined;
    loading: boolean;
    containerProps?: GridProps;
    handleLocationCardClick?: (id: number) => void;
};

export const LocationList = (props: LocationListProps) => {
    const { locations, loading, containerProps, handleLocationCardClick } = props;
    const { id: locationID } = useParams();

    if (loading) {
        return (
            <>
                {Array(12)
                    .fill(0)
                    .map((_, index) => {
                        return (
                            <Grid item key={index} {...containerProps}>
                                <LocationCard loading={loading} />
                            </Grid>
                        );
                    })}
            </>
        );
    }

    return (
        <>
            {locations &&
                locations?.pages.map((page: any, index: number) => {
                    return page.data.map((data: any, index: number) => {
                        return (
                            <Grid item key={index} {...containerProps}>
                                <LocationCard
                                    locationTitle={data.location_name}
                                    latitude={data.latitude}
                                    longitude={data.longitude}
                                    phoneNo={data.phone}
                                    address={data.address}
                                    handleClick={handleLocationCardClick}
                                    id={data.contact_id}
                                    containerSx={{
                                        width: '100%',
                                        backgroundColor: data.contact_id == locationID ? '#E3E3E3' : 'white',
                                    }}
                                />
                            </Grid>
                        );
                    });
                })}
        </>
    );
};
