import initState from './initState';
import { SET_PROFILE, LOGOUT, UserActionTypes } from './actionTypes';

import { UserReducerState } from '../../../types/types';
import objectAssign from 'object-assign';

export default function UserReducer(
    state: UserReducerState = initState,
    action: UserActionTypes
){
    switch(action.type){
        case SET_PROFILE:
            return objectAssign({}, state, action.payload);
        case LOGOUT:
            return objectAssign({}, initState);
        default:
            return state;
    }
}