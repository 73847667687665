export default {
  "menus": {
    "home": "Zuhause",
    "feed": "Füttern",
    "messages": "Nachrichten",
    "connect": "Verbinde",
    "awards": "Auszeichnungen",
    "settings": "Einstellungen und Datenschutz",
    "profile": "Profil",
    "notifications": "Benachrichtigungen",
    "change password": "Passwort ändern",
    "signOut": "Abmelden",
    "mark as read": "Als gelesen markieren"
  },
  "settingOptions": {
    "title": "Privatsphäre-Einstellungen",
    "content": "Cookies sind kleine Textdateien, die auf Ihrem Computer oder Mobilgerät abgelegt werden, wenn Sie eine Website besuchen oder eine App verwenden. Sie werden häufig verwendet, damit Websites und Apps funktionieren oder effizienter funktionieren, und um den Eigentümern der Website/App Informationen zur Verfügung zu stellen. Die folgende Tabelle erklärt, welche Cookies wir verwenden und warum.",
    "subQuestion": "Welche Cookies verwenden wir?",
    "subText": "Weitere Informationen finden Sie in unserer",
    "subTextDefault": "Weitere Informationen finden Sie in unserer Datenschutz- und Cookie-Richtlinie",
    "necessaryTitle": "Unbedingt notwendig",
    "necessary": "Notwendige Cookies sind für die Kernfunktionalität und Sicherheit der App unerlässlich. Wenn Sie damit einverstanden sind, möchten wir Cookies auch verwenden, um",
    "performanceTitle": "Leistungs-Cookies",
    "notificationSettings": "Einstellungen für Benachrichtigungen",
    "emails": "E-Mails",
    "p2pEmailsLabel": "Schicken Sie mir E-Mail-Benachrichtigungen für {{p2pLabel}}",
    "performance": {
      "firstLine": "• Verbessern Sie die Leistung der App",
      "secondLine": "• Verstehe, wie die App verwendet wird",
      "thirdLine": "• Inhalte optimieren"
    },
    "analyticsTitle": "Analytische Cookies",
    "analytics": {
      "firstLine": "• Verbessern Sie das App-Erlebnis",
      "secondLine": "• Personalisieren Sie Dienste"
    },
    "save": "Einstellungen speichern",
    "error": {
      "label": "Fehler",
      "text": "Es ist ein Fehler aufgetreten, bitte versuchen Sie es später erneut."
    }
  },
  "type search field": "Tippe und drücke Enter..."
};