import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Tablist } from '../../components/tablist/tablist';
import { TabLinks } from '../../../types/interface/TabLinks';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isWhite } from '../../utils/colourCheck';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Connect = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [value, setValue] = useState<number>(1);
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const highlightColor = isWhite(tenantBranding.web_navbar_background.toLowerCase())
        ? tenantBranding.web_navbar_foreground
        : tenantBranding.web_navbar_background;
    const { t } = useTranslation();
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const Tabs: TabLinks[] = [
        {
            name: t('connect.favourites'),
            path: 'people?starred',
            icon: location.search.includes('starred') ? 'fa-solid fa-star fw-bolder' : 'fa-light fa-star',
            index: 0,
        },
        {
            name: t('connect.people'),
            path: 'people',
            icon: 'fa-light fa-address-book',
            index: 1,
        },
        {
            name: t('connect.locations'),
            path: 'locations',
            icon: 'fa-light fa-location-dot',
            index: 2,
        },
        {
            name: t('connect.groups'),
            path: 'groups',
            icon: 'fa-light fa-people-group',
            index: 3,
        },
    ];

    useEffect(() => {
        if (location.pathname === '/connect') {
            navigate('/connect/people');
            setValue(1);
        } else {
            if (location.search.includes('starred')) {
                setValue(0);
            } else {
                let index = Tabs.findIndex((tab) => tab.path === location.pathname.split('/')[2]);
                setValue(index);
            }
        }
    }, [location.pathname]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        event.stopPropagation();
        setValue(newValue);
    };

    return (
        <Grid container data-testid='connect-page' spacing={1}>
            <Grid item xs={12} sx={{ display: 'flex', gap: '20px', flexDirection: isXsScreen ? 'column' : 'row' }}>
                <Typography sx={{ fontSize: '24px', fontWeight: '600', textAlign: isXsScreen ? 'center' : 'left' }}>
                    {t('connect.directory')}
                </Typography>
                <Tablist
                    value={value}
                    handleChange={handleChange}
                    tabs={Tabs}
                    links={true}
                    tabSx={{
                        minHeight: '0px',
                        fontSize: '16px',
                        fontWeight: '600',
                        textTransform: 'capitalize',
                        color: '#000',
                        padding: '5px 10px',
                        span: {
                            fontWeight: '400',
                            fontSize: '16px',
                            lineHeight: '0.1',
                        },
                        ':hover': {
                            color: highlightColor,
                            filter: 'opacity(0.8)',
                        },
                    }}
                    tabsSx={{
                        minHeight: '40px',
                        '& .MuiTabs-flexContainer': {
                            gap: '10px',
                            justifyContent: isXsScreen ? 'center' : 'left',
                        },
                        '& .Mui-selected': {
                            color: '#000 !important',
                        },
                        '& .MuiTabs-indicator': {
                            backgroundColor: highlightColor,
                            height: '4px',
                        },
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Outlet />
            </Grid>
        </Grid>
    );
};
