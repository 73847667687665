import { Grid, SxProps, Typography, Checkbox, Box, CheckboxProps, Button, Skeleton } from '@mui/material';
import React, { useState } from 'react';
import { IconCard } from '../../components/IconCard/iconCard';
import { AlphabetList, AlphabetListProps } from '../../components/alphabetList/alphabetList';
import SpinnerLoad from '../../components/common/spinnerLoad/spinnerLoad';
import { NoResultsWidget } from '../../components/noResultWidget/noResultWidget';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export type MembersListProps = {
    memberList: {
        full_name: string;
        hasManager: boolean;
        id: number;
        image_url: string;
        job_title: string;
        contact_id: number;
    }[];
    displayAlphabetFilter?: boolean;
    alphabetListProps?: AlphabetListProps;
    containerSx?: SxProps;
    memberListContainerSx?: SxProps;
    displayCheckBox?: boolean;
    checkboxProps?: CheckboxProps;
    displayRemoveButton?: boolean;
    loading: boolean;
    handleScroll?: (e: any) => void;
    fetchingNextPage?: boolean;
};

export const MembersList = (props: MembersListProps) => {
    const {
        memberList,
        displayAlphabetFilter,
        containerSx,
        alphabetListProps,
        memberListContainerSx,
        displayCheckBox,
        checkboxProps,
        displayRemoveButton,
        loading,
        handleScroll,
        fetchingNextPage,
    } = props;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [displayRemoveHoverBtn, setDisplayRemoveHoverBtn] = useState<{
        display: boolean;
        index: number;
    }>({
        display: false,
        index: -1,
    });

    const navigateUser = (e: any, userId: string) => {
        e.preventDefault();
        navigate(`/connect/people/${userId}`);
    };

    if (loading) {
        return (
            <Grid container sx={containerSx} spacing={1} data-testid='members-list'>
                {Array.from({ length: 10 }).map((_, index) => (
                    <Grid
                        item
                        xs={12}
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px',
                            gap: '10px',
                        }}
                    >
                        <Skeleton variant='circular' height={50} width={50} />
                        <Skeleton variant='rectangular' width='80%' height={50} />
                    </Grid>
                ))}
            </Grid>
        );
    }

    if (memberList.length === 0) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }} data-testid='members-list'>
                <NoResultsWidget
                    title=''
                    description={t('connect.noGroupMembers')}
                    iconSx={{
                        height: '275px',
                        width: '275px',
                    }}
                />
            </Box>
        );
    }

    return (
        <Grid container sx={containerSx} spacing={1} data-testid='members-list' onScroll={handleScroll}>
            <Grid item xs={displayAlphabetFilter ? 11 : 12} sx={memberListContainerSx}>
                {memberList.map((member, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center' }} >
                        {displayCheckBox && (
                            <Checkbox
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                                sx={{
                                    paddingBottom: index === 0 ? '20px' : 'default',
                                }}
                                {...checkboxProps}
                            />
                        )}
                        <IconCard
                            handleClick={(e) => navigateUser(e, member.contact_id.toString())}
                            icon={member.image_url}
                            iconStyle={{
                                marginRight: '15px',
                            }}
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                paddingTop: index === 0 ? '0px' : '10px',
                                width: '100%',
                            }}
                            title={''}
                            titleStyle={{
                                width: '100%',
                            }}
                            containerProps={{
                                onMouseEnter: () => {
                                    setDisplayRemoveHoverBtn({
                                        display: true,
                                        index,
                                    });
                                },
                                onMouseLeave: () => {
                                    setDisplayRemoveHoverBtn({
                                        display: false,
                                        index: -1,
                                    });
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box sx={{ mt: '2px' }}>
                                    <Typography
                                        sx={{
                                            lineHeight: '1.2',
                                            fontSize: '16px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {member.full_name}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '12px',
                                            fontWeight: 400,
                                        }}
                                    >
                                        {member.job_title}
                                    </Typography>
                                </Box>
                                {displayRemoveButton && displayRemoveHoverBtn.display && displayRemoveHoverBtn.index === index && (
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        size='small'
                                        sx={{
                                            borderRadius: '10px',
                                            textTransform: 'none',
                                            fontSize: '13px',
                                            height: '35px',
                                            px: '15px',
                                            backgroundColor: '#E4E4E4',
                                            letterSpacing: '0.5px',
                                            color: '#000',
                                            marginRight: '15px',
                                            ':hover': {
                                                backgroundColor: '#15385B',
                                                color: '#FFF',
                                            },
                                        }}
                                    >
                                        Remove
                                    </Button>
                                )}
                            </Box>
                        </IconCard>
                    </Box>
                ))}
                {fetchingNextPage && <SpinnerLoad size={40} />}
            </Grid>
            {displayAlphabetFilter && (
                <Grid item xs={1}>
                    <AlphabetList {...alphabetListProps} />
                </Grid>
            )}
        </Grid>
    );
};
