import awardsProvider from "../providers/awardsProvider";
import { NewAwardInfo } from "../../types/types";

const awardsService = {
  getAll(page: number, success: Function, error?: Function) {
    awardsProvider.getAll(page, success, error);
  },
  getInbox(page: number, success: Function, error?: Function) {
    awardsProvider.getInbox(page, success, error);
  },
  getSent(page: number, success: Function, error?: Function) {
    awardsProvider.getSent(page, success, error);
  },
  getTeam(page: number, success: Function, error?: Function) {
    awardsProvider.getTeam(page, success, error);
  },
  getImages(success: Function, error?: Function) {
    awardsProvider.getImages(success, error);
  },
  getCounts(success: Function, error?: Function) {
    awardsProvider.getCounts(success, error);
  },
  newAward(
    awardData: NewAwardInfo,
    userIds: Array<number>,
    cardType: number,
    success: Function,
    error?: Function
  ) {
    awardsProvider.newAward(awardData, userIds, cardType, success, error);
  },
  analytics(success: Function, error?: Function) {
    awardsProvider.analytics(success, error);
  },
  analyticsNew(success: Function, error?: Function) {
    awardsProvider.analyticsNew(success, error);
  },
};

export default awardsService;
