import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

import { addNotification } from "../../../../../shared/reducers/notifications/actionTypes";
import connectService from "../../../../services/connectService";
import Scrollbar from "../../scrollbar/scrollbar";
import SpinnerLoad from "../../spinnerLoad/spinnerLoad";
import {
	DefaultFilterType,
	filterSearchPickListString,
	SearchPickListStringType,
} from "../ConnectFilter";
import "./CustomSearchPickList.scss";
import useDebouncedCallback from "../../../../hooks/useDebouncedCallback";

export type FilterItemType = {
	id: number;
	title: string;
	image?: string;
	isChecked: boolean;
};

const firstPage = 1;

type CustomSearchPickListProps = {
	label: string;
	className?: string;
	selectedItems: FilterItemType[];
	dropdownOptions: FilterItemType[];
	clearFilters: boolean;
	customSearchString: string;
	setSelectedItems: React.Dispatch<React.SetStateAction<FilterItemType[]>>;
	setCustomSearchString: React.Dispatch<
		React.SetStateAction<SearchPickListStringType>
	>;
	setClearFilters: React.Dispatch<React.SetStateAction<boolean>>;
	setDropdownOptions: React.Dispatch<React.SetStateAction<FilterItemType[]>>;
	handleSearchFilterChange: (
		label: string,
		text: string,
		selectedItems: FilterItemType[]
	) => void;
};

const CustomSearchPickList: React.FC<CustomSearchPickListProps> = ({
	label,
	className,
	selectedItems,
	customSearchString,
	setSelectedItems,
	dropdownOptions,
	clearFilters,
	setCustomSearchString,
	setDropdownOptions,
	setClearFilters,
	handleSearchFilterChange,
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [showDropdown, setShowDropdown] = useState(false);
	const [hasMoreData, setHasMoreData] = useState<boolean>(true);
	const [page, setCurrentPage] = useState<number>(firstPage);
	const [isLoading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState(
		customSearchString.length > 0 ? customSearchString : ""
	);

	const setPrevItemAsSelected = (connectFilters: FilterItemType[]) => {
		let data = connectFilters.map((item: FilterItemType) => ({
			...item,
			isChecked: false,
		}));
		if (selectedItems.length > 0) {
			selectedItems.map((item: FilterItemType) => {
				data
					.filter((checkItem) => checkItem.id === item.id)
					.map((checkItem) => {
						checkItem.isChecked = true;
					});
			});
			return data;
		}
		return data;
	};

	const fetchFilterItemList = (page: number, type: string, searchValue: string, loadFirstPageData: boolean = true) => {
		setLoading(true);
		connectService.getConnectFilters(
			page,
			{ type: type, search: searchValue },
			(resp: AxiosResponse) => {
				setDropdownOptions(loadFirstPageData ? setPrevItemAsSelected(resp.data.data) : [...dropdownOptions, ...setPrevItemAsSelected(resp.data.data)]);
				setHasMoreData(resp.data.links.next ? true : false);
				setLoading(false);
			},
			() => {
				setLoading(false);
				dispatch(
					addNotification({
						label: `Connect Filter API`,
						text: "Failed to load filter data",
						type: "danger",
					})
				);
			}
		);
	};

	useEffect(() => {
		if (clearFilters) {
			setSelectedItems([]);
			setSearchValue("");
			setCustomSearchString({ ...filterSearchPickListString });
			setShowDropdown(false);
			setClearFilters(false);
			setCurrentPage(firstPage);
			fetchFilterItemList(firstPage, label.toLowerCase(), "");
		}
	}, [clearFilters]);

	const getConcatedSearchString = (selectedItems: FilterItemType[]) => {
		let filterText = "";
		selectedItems.map((item: FilterItemType, index: number) => {
			if (index === 0) {
				filterText = item.title;
			} else {
				filterText += " or " + item.title;
			}
		});
		return filterText;
	};

	const togglePickListDropdown = () =>
		setShowDropdown((prevState) => !prevState);

	const handleOptionSelection = (
		item: FilterItemType
	) => {
		let tempItems: FilterItemType[] = [];
		if (!item.isChecked) {
			item.isChecked = true;
			tempItems = [...selectedItems, item];
			setSelectedItems(tempItems);
			handleSearchFilterChange(label, getConcatedSearchString(tempItems), tempItems);
		} else {
			item.isChecked = false;
			tempItems = [
				...selectedItems.filter((checkedItem) => checkedItem.id !== item.id),
			]
			setSelectedItems(tempItems);
			handleSearchFilterChange(label, getConcatedSearchString(tempItems), tempItems);
		}
	};

	const handleSearchValueChange = useDebouncedCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		fetchFilterItemList(firstPage, label.toLowerCase(), e.target.value);

		switch (label) {
			case DefaultFilterType.Location:
				setCustomSearchString((prev: SearchPickListStringType) => {
					return {
						...prev,
						location: e.target.value,
					};
				});
				break;
			case DefaultFilterType.Badge:
				setCustomSearchString((prev: SearchPickListStringType) => {
					return {
						...prev,
						badge: e.target.value,
					};
				});
				break;
			case DefaultFilterType.Manager:
				setCustomSearchString((prev: SearchPickListStringType) => {
					return {
						...prev,
						manager: e.target.value,
					};
				});
				break;
			default:
				break;
		}
	});

	const pageScroll = (e: {
		scrollTop: number;
		clientHeight: number;
		scrollHeight: number;
	  }) => {
		if (
		  !isLoading &&
		  hasMoreData &&
		  Math.ceil(e.scrollTop + e.clientHeight) >= e.scrollHeight
		) {
			setCurrentPage(page + 1);
			fetchFilterItemList(page + 1, label.toLowerCase(), searchValue, false);
		}
	  };

	return (
		<>
			<Dropdown
				data-testid="custom-search-pick-list-dropdown"
				className={`dropdown-filter ${className}`}
				isOpen={showDropdown}
				toggle={togglePickListDropdown}
			>
				<DropdownToggle
					data-testid={`${label.toLowerCase()}-dropdown-filter-btn`}
					onClick={() => {
						if (dropdownOptions.length === 0)
							fetchFilterItemList(page, label.toLowerCase(), searchValue);
					}}
					className="dropdown-filter-toggle-btn w-100"
				>
					<p
						data-testid="custom-search-pick-list-label"
						className="m-0 custom-search-filter-label"
					>
						{t(`connect.${label.toLowerCase()}`)}{" "}
						{selectedItems && selectedItems.length > 0
							? `/ ${selectedItems.length}`
							: ""}
					</p>
					<p className="fa-regular fa-angle-down m-0 ms-2"></p>
				</DropdownToggle>
				<DropdownMenu className="custom-search-filter-menu">
					<div className="search-section d-flex align-items-center">
						<p className="fa-regular form-control search-icon fa-magnifying-glass m-0"></p>
						<input
							data-testid={`${label.toLowerCase()}-custom-search-pick-list-input`}
							className="search-box form-control"
							type="text"
							placeholder="Search.."
							value={searchValue}
							onChange={(e) => {
								setSearchValue(e.target.value);
								setCurrentPage(firstPage);
								handleSearchValueChange(e);
							}}
						/>
					</div>
					<Scrollbar
						onScroll={pageScroll}
						className="custom-search-pick-list-scrollbar"
						rightScroll={true}
						noScrollX
					>
						<div
							data-testid="dropdown-pick-list-options"
							className="filter-item ps-1 mt-2"
						>
							{dropdownOptions.map((item) => (
								<div key={item.id} className="form-check mt-2 d-flex">
									<input
										data-testid={`${label.toLowerCase()}-option-checkbox-input`}
										className="form-check-input"
										type="checkbox"
										id={item.title}
										name={item.title}
										value={item.title.toLowerCase()}
										onChange={() => handleOptionSelection(item)}
										checked={item.isChecked}
									/>
									{item.image && (
										<img
											data-testid="custom-search-pick-list-item-image"
											src={item.image}
											className="badge-color mt-1 ms-2"
										></img>
									)}
									<label
										data-testid="custom-search-pick-list-item-title"
										className="form-check-label ms-2"
										onClick={() => handleOptionSelection(item)}
									>
										{item.title}
									</label>
								</div>
							))}
							{isLoading && <SpinnerLoad className="mt-2" />}
						</div>
					</Scrollbar>
				</DropdownMenu>
			</Dropdown>
		</>
	);
};

export default CustomSearchPickList;
