import brandingProvider from '../providers/brandingProvider';
import objectAssign from 'object-assign';
import { AxiosResponse } from 'axios';

import initState from '../../shared/reducers/branding/initState';

const brandingService = {
    getStyles(success: Function, error?: Function){
        brandingProvider.getStyles((resp: AxiosResponse) => {
            const brandingInfo = objectAssign({}, initState, resp.data.data);
            this.storeStyleInfo(JSON.stringify(brandingInfo));
            success(resp);
        }, error);
    },

    storeStyleInfo(data: string){
        localStorage.setItem('brandingInfo', data);
    },

    removeStyleInfo(){
        localStorage.removeItem('brandingInfo');
    },

    getStyleFromStore(){
        let info = localStorage.getItem('brandingInfo');
        if(info){
            return JSON.parse(info);
        }
        return null;
    }
}

export default brandingService;