import initState from './initState';
import { SET_APP_STATE, AppActionTypes } from './actionTypes';
import objectAssign from 'object-assign';
import { AppReducerState } from '~/types/types';

export default function TenantReducer(
    state: AppReducerState = initState,
    action: AppActionTypes
){
    switch(action.type){
        case SET_APP_STATE:
            return objectAssign({}, state, action.payload);
        default:
            return state;
    }
}