import React from 'react';
import { Box, Typography } from '@mui/material';
import { ThreadBranding } from '../../../types/types';
import { useSelector } from "react-redux";
const renderHtml = require('html-react-parser');


export type ReplyToMessageModalProps = {
    message: any;
    threadBranding: ThreadBranding
    handleClose: () => void;
};

export const ReplyToMessageModal = (props: ReplyToMessageModalProps) => {
    const { message, threadBranding, handleClose } = props;
    const tenantBranding = useSelector((state: any) => state.app.branding);
    
    const replyColour = threadBranding?.receiver_bubble_colour ?? tenantBranding.messaging.receiver_bubble_colour;
    
    return (
        <Box sx={{ display: 'flex', margin: '10px 0' }} data-testid="reply-to-message-modal">
            <Box
                sx={{
                    backgroundColor: '#EEEEEE',
                    padding: '10px 20px',
                    borderRadius: '20px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: 'calc(100% - 30px)',
                    position: 'relative',
                }}
            >
                <Box
                    sx={{
                        padding: '0 15px',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        height: '80px',
                        '&::before': {
                          height: 'calc(100% - 10px) !important',
                          backgroundColor: replyColour,
                        }
                    }}
                    className="vertical-line"
                >
                    <Typography
                        sx={{
                            fontWeight: '600',
                            fontSize: '14px',
                        }}
                        data-testid="reply-to-message-modal-author-name"
                    >
                        {message?.author?.name}
                    </Typography>
                    <Typography
                        sx={{
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#333333',
                            '-webkit-line-clamp': '3 !important',
                        }}
                        data-testid="reply-to-message-modal-post"
                        className="preview-text"
                    >
                        {renderHtml(message?.post)}
                    </Typography>
                </Box>
                {message?.files?.length > 0 && (
                    <Box sx={{ height: '80px', position: 'relative' }}>
                        <img
                            src={message?.files?.[0]?.preview}
                            alt="reply to image"
                            height="80px"
                            style={{
                                borderRadius: '10px',
                                filter: message?.files?.length > 1 ? 'brightness(0.5)' : 'none',
                            }}
                            data-testid="reply-to-message-modal-image"
                        />
                        {message.files?.length > 1 && (
                            <Box className="chat-bubble-img-placeholder-text" data-testid="reply-to-message-modal-image-placeholder-text">
                                +{message.files?.length - 1}
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mx: '15px' }}>
                <button className="unstyled-btn" onClick={handleClose} data-testid="reply-to-message-modal-close-btn">
                    <span className="fa-solid fa-xmark font-24" />
                </button>
            </Box>
        </Box>
    );
};
