import React, { FunctionComponent } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import classnames from 'classnames';

import UserList from './usersList';

type UsersListModalProps = {
    title: string,
    btnText: string,
    isOpen: boolean,
    doneCallback: Function,
    toggle?: any,
    modalClass?: string
};

const UsersListModal: FunctionComponent<UsersListModalProps> = ({ title, btnText, isOpen, doneCallback, toggle, modalClass }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} className={classnames('users-list-modal', modalClass, 'modal-dialog-centered')}>
            <ModalHeader toggle={toggle}>
                <h4 className="fw-bold text-light-black">{ title }</h4>
            </ModalHeader>

            <UserList btnText={btnText} doneCallback={doneCallback} fieldsToGet={['']} />
        </Modal>
    );
}

export default UsersListModal;