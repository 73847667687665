import initState from './initState';
import { SET_BRANDING, SET_MENU_ITEMS, BrandingActionTypes } from './actionTypes';
import objectAssign from 'object-assign';

import { BrandingReducerState } from '../../../types/types';
import brandingService from '../../../web/services/brandingService';

export default function BrandingReducer(
    state: BrandingReducerState = objectAssign({}, initState, brandingService.getStyleFromStore()),
    action: BrandingActionTypes
){
    switch(action.type){
        case SET_BRANDING:
            brandingService.storeStyleInfo( JSON.stringify(action.payload) );
            return objectAssign({}, state, action.payload);
        case SET_MENU_ITEMS:
            state.menu_items.push(...action.payload);
            return objectAssign({}, state);
        default:
            return state;
    }
}