import Client from "../services/axiosService";
import { AxiosResponse, AxiosError } from "axios";

const navigationProvider = {
  getSideNavigation(success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/nav/items",
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  savePreferences(data: any, success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/preferences",
        method: "post",
        data: data,
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};

export default navigationProvider;
