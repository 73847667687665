import { BrandingReducerState } from "../../../types/types";

const brandingInitState: BrandingReducerState = {
  web_navbar_background: "#FFFFFF",
  web_navbar_foreground: "#909090",

  web_menubar_background: "#FFFFFF",
  web_menubar_foreground: "#b2b2b2",
  web_menubar_active_foreground: "#b2b2b2",
  web_menubar_active_background: "#FFFFFF",

  web_sidebar_foreground: "#FFFFFF",
  web_sidebar_background: "#294f60",

  cookies_link: "",
  cookies_header_colour: "#61717B",
  cookies_button_background: "#002030",
  cookies_text: "",
  menu_items: [],
  messaging: {
    receiver_bubble_colour: "#15385b",
    receiver_text_colour: "#ffffff",
    sender_bubble_colour: "#62257d",
    sender_text_colour: "#ffffff",
  }
};

export default brandingInitState;
