import Client from "../services/axiosService";
import { AxiosResponse, AxiosError } from "axios";

const homepageProvider = {
  getData(success: Function, error?: Function) {
    Client()
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/home`,
        method: "get",
      })
      .then((response: AxiosResponse) => {
        success(response);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};

export default homepageProvider;
