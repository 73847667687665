import Client from "../services/axiosService";
import { AxiosResponse, AxiosError } from "axios";

const notificationProvider = {
    saveNotification(data:any, success: Function, error?: Function) {
        Client()
            .request({
                url: process.env.REACT_APP_CMS_API_URL + "/react/user-notification",
                method: "post",
                data: data
            })
            .then((resp: AxiosResponse) => {
                success(resp);
            })
            .catch((err: AxiosError) => {
                if (error) {
                    error(err);
                }
            });
    },
}

export default notificationProvider;