export default {
    "inbox": "Caixa de entrada",
    "searchChats": "Pesquisar bate-papos",
    "searchArchivedChats": "Pesquisar bate-papos arquivados",
    "searchContacts": "Pesquisar contatos",
    "description": "Descrição",
    "chatInfo": "Informações de bate-papo",
    "created": "Criado",
    "newChat": "Novo chat",
    "createChatPlaceholder": "Crie um grupo ou selecione um contato para enviar a mensagem.",
    "allContacts": "Todos os contatos",
    "newUserChatPlaceholder": "Você ainda não enviou uma mensagem para {{dynamicValue}}, comece a digitar para começar.",
    "archived": "Arquivado",
    "chatSettings": "Configurações de bate-papo",
    "showArchivedChats": "Mostrar bate-papos arquivados",
    "participants": "Participantes",
    "searchParticipants": "Pesquisar participantes",
    "muteMsg": "As notificações estão desativadas para este tópico porque ele é público",
    "muteByAdminMsg": "As notificações estão desativadas para este tópico porque ele está silenciado por um administrador",
    "prefixMuteMsg": "O tópico é silenciado até",
    "startTyping": "Comece a digitar",
    "muteNotifications": "Silenciar notificações",
    "newGroupChat": "Adicionar participantes do grupo",
    "newGroup": "Novo grupo",
    "newGroupPlaceholder": "Selecione os participantes para o seu bate-papo em grupo",
    "groupSettings": "Configurações de grupo",
    "uploadGroupIcon": "Carregar ícone de grupo",
    "groupNamePlaceholder": "Digite o nome do grupo",
    "groupDescriptionPlaceholder": "Dê uma descrição do seu grupo",
    "updateGroup": "Atualizar grupo",
    "leaveGroup": "Deixar o grupo",
    "leaveGroupErrorMsg": "Ocorreu um erro ao enviar a solicitação de saída do grupo. Por favor, tente novamente.",
    "leaveGroupModalTitle": "Tem certeza de que deseja sair deste grupo?",
    "leaveGroupModalContent": "Sair deste grupo significa que você não poderá entrar novamente sem um convite.",
    "manageParticipants": "Gerenciar participantes",
    "addParticipant": "Adicionar participante",
    "removeParticipant": "Remover participante",
    "addParticipantErrorMsg": "Ocorreu um erro ao enviar a solicitação de adição de participante. Por favor, tente novamente.",
    "removeParticipantErrorMsg": "Ocorreu um erro ao enviar a solicitação de remoção de participante. Por favor, tente novamente.",
    "manage": "Gerenciar",
    "sendMessage": "Enviar mensagem",
    "failedToSendMessage": "Falha ao enviar mensagem.",
    "admins": "Administradores",
    "manageAdmins": "Gerenciar Administradores",
    "addAdmin": "Adicionar administrador",
    "removeAdmin": "Remover administrador",
    "addAdminErrorMsg": "Ocorreu um erro ao enviar a solicitação de adição de administrador. Por favor, tente novamente.",
    "removeAdminErrorMsg": "Ocorreu um erro ao enviar a solicitação de remoção do administrador. Por favor, tente novamente.",
    "mute": "Muda",
    "archive": "Arquivo",
    "delete": "Excluir",
    "unarchive": "Desarquivar",
    "unmute": "Ativar som",
    "archiveErrorMsg": "Ocorreu um erro ao enviar a solicitação de arquivo. Por favor, tente novamente.",
    "unArchiveErrorMsg": "Ocorreu um erro ao enviar a solicitação de desarquivamento. Por favor, tente novamente.",
    "userDeletedMessage": "Você excluiu esta mensagem.",
    "deletedMessage": "Esta mensagem foi excluída.",
    "deletedMessageThread": "Mensagem excluída.",
};
