import Client from "../services/axiosService";
import { NewAwardInfo } from "../../types/types";
import { AxiosResponse, AxiosError } from "axios";

const awardsProvider = {
  getAll(page: number, success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/cards?page=" + page,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getInbox(page: number, success: Function, error?: Function) {
    Client()
      .request({
        url:
          process.env.REACT_APP_CMS_API_URL + "/react/cards/inbox?page=" + page,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getSent(page: number, success: Function, error?: Function) {
    Client()
      .request({
        url:
          process.env.REACT_APP_CMS_API_URL + "/react/cards/sent?page=" + page,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getTeam(page: number, success: Function, error?: Function) {
    Client()
      .request({
        url:
          process.env.REACT_APP_CMS_API_URL + "/react/cards/team?page=" + page,
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  getImages(success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/cards/images",
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  newAward(
    awardData: NewAwardInfo,
    userIds: Array<number>,
    cardType: number,
    success: Function,
    error?: Function
  ) {
    // isPrivate: 1 - private, 2 - public
    Client()
      .request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/cards/store`,
        method: "post",
        data: {
          ...awardData,
          visibility: awardData.isVisible ? 2 : 1,
          userIds,
          cardTypeId: cardType,
        },
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  getCounts(success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/cards/counts",
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },

  analytics(success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/cards/analytics",
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
  analyticsNew(success: Function, error?: Function) {
    Client()
      .request({
        url: process.env.REACT_APP_CMS_API_URL + "/react/cards/analytics/new",
        method: "get",
      })
      .then((resp: AxiosResponse) => {
        success(resp);
      })
      .catch((err: AxiosError) => {
        if (error) {
          error(err);
        }
      });
  },
};

export default awardsProvider;
