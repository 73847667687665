export default {
  "inbox": "Posta in arrivo",
  "searchChats": "Cerca nelle chat",
  "searchArchivedChats": "Cerca nelle chat archiviate",
  "searchContacts": "Cerca contatti",
  "description": "Descrizione",
  "chatInfo": "Informazioni sulla chat",
  "created": "Creato",
  "newChat": "Nuova chat",
  "createChatPlaceholder": "Crea un gruppo o seleziona un contatto a cui inviare un messaggio.",
  "allContacts": "Tutti i contatti",
  "newUserChatPlaceholder": "Non hai mai inviato un messaggio a {{dynamicValue}} prima, inizia a digitare per iniziare.",
  "archived": "Archiviata",
  "chatSettings": "Impostazioni chat",
  "showArchivedChats": "Mostra le chat archiviate",
  "participants": "Partecipanti",
  "searchParticipants": "Cerca partecipanti",
  "muteMsg": "Le notifiche sono disattivate per questo thread perché è pubblico",
  "muteByAdminMsg": "Le notifiche sono disattivate per questo thread perché è silenziato da un amministratore",
  "prefixMuteMsg": "Il thread viene disattivato fino a",
  "startTyping": "Inizia a digitare",
  "muteNotifications": "Disattiva le notifiche",
  "newGroupChat": "Aggiungi partecipanti al gruppo",
  "newGroup": "Nuovo gruppo",
  "newGroupPlaceholder": "Seleziona i partecipanti per la chat di gruppo",
  "groupSettings": "Impostazioni del gruppo",
  "uploadGroupIcon": "Carica l'icona del gruppo",
  "groupNamePlaceholder": "Inserisci il nome del gruppo",
  "groupDescriptionPlaceholder": "Fornisci una descrizione del tuo gruppo",
  "updateGroup": "Gruppo di aggiornamento",
  "leaveGroup": "Abbandona il gruppo",
  "leaveGroupErrorMsg": "Si è verificato un errore durante l'invio della richiesta di congedo per il gruppo. Per favore riprova.",
  "leaveGroupModalTitle": "Sei sicuro di voler uscire da questo gruppo?",
  "leaveGroupModalContent": "Uscire da questo gruppo significa che non potrai rientrare senza un invito.",
  "manageParticipants": "Gestisci i partecipanti",
  "addParticipant": "Aggiungi partecipante",
  "removeParticipant": "Rimuovi partecipante",
  "addParticipantErrorMsg": "Si è verificato un errore durante l'invio della richiesta di aggiunta partecipante. Per favore riprova.",
  "removeParticipantErrorMsg": "Si è verificato un errore durante l'invio della richiesta di rimozione del partecipante. Per favore riprova.",
  "manage": "Gestire",
  "sendMessage": "Invia messaggio",
  "failedToSendMessage": "Impossibile inviare il messaggio.",
  "admins": "Amministratrici",
  "manageAdmins": "Gestisci amministratori",
  "addAdmin": "Aggiungi amministratore",
  "removeAdmin": "Rimuovi amministratore",
  "addAdminErrorMsg": "Si è verificato un errore durante l'invio della richiesta di aggiunta dell'amministratore. Per favore riprova.",
  "removeAdminErrorMsg": "Si è verificato un errore durante l'invio della richiesta di rimozione dell'amministratore. Per favore riprova.",
  "mute": "Muta",
  "archive": "Archivio",
  "delete": "Eliminare",
  "unarchive": "Annulla l'archiviazione",
  "unmute": "Riattiva",
  "archiveErrorMsg": "Si è verificato un errore durante l'invio della richiesta di archiviazione. Per favore riprova.",
  "unArchiveErrorMsg": "Si è verificato un errore durante l'invio della richiesta di annullamento dell'archiviazione. Per favore riprova.",
  "userDeletedMessage": "Hai eliminato questo messaggio.",
  "deletedMessage": "Questo messaggio è stato eliminato.",
  "deletedMessageThread": "Messaggio eliminato.",
};