import React, { FunctionComponent, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
} from "reactstrap";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { faArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./filterPanel.scss";
import doubleTick from "../../../icons/circle-check-solid.svg";
import { ContentData } from "~/types/types";

export enum Sort {
  mostViewed = "mostViewed",
  mostLiked = "mostLiked",
  oldestDate = "oldestDate",
  newestDate = "newestDate",
  alphabeticalAscending = "alphabeticalAscending",
  alphabeticalDescending = "alphabeticalDescending"
}

type FilterPanelProps = {
  search: string;
  showFilter: boolean;
  showSearch: boolean;
  showSort: boolean;
  contentFiltered: ContentData[];
  handleOnChangeSearch: (e: React.FormEvent<HTMLInputElement>) => void;
  handleOnClickResetAliasFilter: () => void;
  handleOnClickFilterByName: (name: string) => void;
  handleOnClickFilterMostViewed: React.MouseEventHandler<HTMLElement>;
  handleOnClickFilterMostLiked: React.MouseEventHandler<HTMLElement>;
  handleOnClickFilterOldestFirst: React.MouseEventHandler<HTMLElement>;
  handleOnClickFilterNewestFirst: React.MouseEventHandler<HTMLElement>;
  handleOnClickFilterAlphabetically: (isAscending: boolean) => void;
};

const FilterPanel: FunctionComponent<FilterPanelProps> = ({
  search,
  showFilter,
  showSearch,
  showSort,
  contentFiltered,
  handleOnChangeSearch,
  handleOnClickResetAliasFilter,
  handleOnClickFilterByName,
  handleOnClickFilterMostViewed,
  handleOnClickFilterMostLiked,
  handleOnClickFilterOldestFirst,
  handleOnClickFilterNewestFirst,
  handleOnClickFilterAlphabetically,
}) => {
  const [toggleSort, setToggleSort] = useState<boolean>(false);
  const [toggleAlias, setToggleAlias] = useState<boolean>(false);

  let uniqueAliases: { [key: string]: any } = {};

  contentFiltered.forEach((content) => {
      const alias = content.contentPage.publisher;
      if (alias) {
        uniqueAliases[`${alias.entity}_${alias.id}`] = alias;
      }
  });

  const toggleSortHandleOnClick = () => {
    setToggleSort(!toggleSort);
  };

  const toggleAliasHandleOnClick = () => {
    setToggleAlias(!toggleAlias);
  };

  return (
    <div
      data-testid="filter-panel"
      className={`filter-panel-dropdown ${
        showSearch || showFilter || showSort ? "" : "d-none"
      }`}
    >
      {showSearch ? (
        <InputGroup>
          <Input
            data-testid="search-element"
            placeholder="Search"
            className="rounded"
            onChange={handleOnChangeSearch}
            value={search}
            data-cy="search"
          />
          <span
            style={{
              position: "absolute",
              right: "10px",
              transform: "translate(-36%, 36%)",
            }}
          >
            <FontAwesomeIcon icon={faSearch} size="lg" />
          </span>
        </InputGroup>
      ) : (
        ""
      )}
      {showFilter ? (
        <div data-testid="filter-element">
          <Dropdown toggle={toggleAliasHandleOnClick} isOpen={toggleAlias}>
            <DropdownToggle
              caret
              className="filter-panel-dropdown-button"
              data-cy="alias-filter"
            >
              Alias
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem data-testid="all-filter-item" onClick={handleOnClickResetAliasFilter}>
                <img
                  src={doubleTick}
                  alt="all"
                  className="media-object rounded-circle"
                  height="43"
                  width="43"
                  style={{ marginRight: "5px", objectFit: "cover" }}
                />
                All
              </DropdownItem>
              {Object.values(uniqueAliases).map((alias) => {
                return (
                  <DropdownItem
                    data-testid="alias-name"
                    key={alias.id}
                    onClick={() => handleOnClickFilterByName(alias.name)}
                  >
                    <img
                      src={alias.image}
                      alt="alias-group"
                      className={`media-object ${
                        alias?.is_round ? "rounded-circle" : ""
                      }`}
                      height="43"
                      width="43"
                      style={{ marginRight: "5px", objectFit: "cover" }}
                    />
                    {alias.name}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      ) : (
        ""
      )}
      {showSort ? (
        <div data-testid="sort-element">
          <Dropdown toggle={toggleSortHandleOnClick} isOpen={toggleSort}>
            <DropdownToggle caret className="filter-panel-dropdown-button">
              <FontAwesomeIcon
                icon={faArrowUp}
                style={{ marginRight: "3px" }}
              />
              Sort
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem data-testid="ascending-sort" onClick={() => handleOnClickFilterAlphabetically(true)}>
                A to Z
              </DropdownItem>
              <DropdownItem data-testid="descending-sort" onClick={() => handleOnClickFilterAlphabetically(false)}>
                Z to A
              </DropdownItem>
              <DropdownItem data-testid="most-viewed-sort" onClick={handleOnClickFilterMostViewed}>
                Most viewed
              </DropdownItem>
              <DropdownItem data-testid="most-liked-sort" onClick={handleOnClickFilterMostLiked}>
                Most liked
              </DropdownItem>
              <DropdownItem data-testid="oldest-sort" onClick={handleOnClickFilterOldestFirst}>
                Oldest first
              </DropdownItem>
              <DropdownItem data-testid="newest-sort" onClick={handleOnClickFilterNewestFirst}>
                Newest first
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default FilterPanel;
