import React, { FunctionComponent } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import classNames from 'classnames';

type SpinnerLoadProps = {
    className?: string;
    size?: number;
    style?: React.CSSProperties;
};

const SpinnerLoad: FunctionComponent<SpinnerLoadProps> = ({ size, className, style }) => {
    return (
        <div data-testid="spinner-load" className={classNames(className)} style={style}>
            <BounceLoader size={size || 60} cssOverride={{ margin: 'auto' }} />
        </div>
    );
};

export default SpinnerLoad;
