import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import './shared/i18next/i18next';
import App from './App';
import './styles/main.scss';
import { QueryClientProvider, QueryClient } from 'react-query';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <QueryClientProvider client={new QueryClient()}>
        <Suspense fallback={<div />}>
            <App />
        </Suspense>
    </QueryClientProvider>,
)