/**
 * @description: Checks whether a colour is white
 * @param colour : The colour to check
 * @returns : boolean
 */
const isWhite = (colour: string): boolean => {
    const isWhite = colour === "white" ||
    colour === "#ffffff" ||
    colour === "#fff" ||
    colour === "rgb(255, 255, 255)" ||
    colour === "rgba(255, 255, 255, 1)";
    
    return isWhite;
};

export {
    isWhite
}