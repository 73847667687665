import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { addNotification } from '../../../../shared/reducers/notifications/actionTypes';

import ValidatedInput from '../../common/validatedInput/validatedInput';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMailBulk, faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { EmailCheckInfo } from '../../../../types/types';

import registrationService from '../../../services/registrationService';
import { AxiosError } from 'axios';

  type EmailInputProps = {
      tenantId: string,
      className?: string,
      placeholder?: string,
      setFormEmail: Function,
      setEmailValidated: Function,

      foregroundColor?: string,
      backgroundColor?: string,
      borderColor?: string,
      labelColor?: string,

      label: string,
      name: string,
      getValidation: Function
      required: boolean
      disabled?: boolean
      confirmEmail?: string
      setErrorMessage: Function
      setEmailValid: Function
      errorMessage: string
      emailValid: boolean | null
  }

const EmailInput: FunctionComponent<EmailInputProps> = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    let emailInputValidator: Function;
    const [checkLoading, setCheckLoading] = useState(false);
    const [emailCheckTimeout, setEmailTimeout] = useState<any>();

    const checkEmail = (email: string) => {
        props.setEmailValidated(false);

        if(emailCheckTimeout)
            clearTimeout(emailCheckTimeout);

        if(!emailInputValidator(email))
            return;

        setEmailTimeout(setTimeout(() => {
            setCheckLoading(true);
            props.setErrorMessage('');

            const emailInfo: EmailCheckInfo = {
                tenant_id: props.tenantId,
                email: email
            }

            if(props.confirmEmail && props.confirmEmail !== email && props.name === 'confirm_email') {
              props.setEmailValid(false)
              props.setErrorMessage('Please ensure that the entered email matches the confirmation email.')
              props.setEmailValidated(false)
              setCheckLoading(false)
              return
            }

            registrationService.checkEmailAvailability(emailInfo, () => {
                props.setEmailValid(true)
                setCheckLoading(false);
                props.setErrorMessage('')
                props.setEmailValidated(true);
            }, (err: AxiosError) => {
                dispatch(addNotification({ label: `Email`, text: t('general.errors.errorLoadingData'), type: 'danger' }));
                props.setEmailValid(false)
                setCheckLoading(false);
                props.setErrorMessage(err.response ? (err.response as any).data.error  : 'Unable to process email');
                props.setEmailValidated(false);
            });
        }, 500));
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value.trim();
        props.setFormEmail(value);

        if(value.length > 4){
            checkEmail(value);
        } else {
            props.setEmailValid(null)
        }
    }

    const renderLoadingStatus = () => {
        if(checkLoading)
            return <SpinnerLoad size={20} />;

        switch(props.emailValid) {
            case true:
                return <FontAwesomeIcon icon={faCheck} />;
            case false:
                return <FontAwesomeIcon icon={faTimes} />;
            default: 
                return <FontAwesomeIcon icon={faMailBulk} style={getLabelStyles()}/>;
        }
    }

    const getLabelStyles = () => {
        const styles: React.CSSProperties = {};
        if(props.labelColor)
            styles.color = props.labelColor;
        return styles;
    }

    const statusColor = (props.emailValid === null) ? 'text-primary' :
    props.emailValid ? 'text-success' : 'text-danger'
        
    return (
        <React.Fragment>
            <div data-testid={props.name + '-input'} className={classnames("d-flex", props.className)}>
                <ValidatedInput className="w-100" 
                    name={props.name} label={props.label} onChange={handleInput}
                    getValidation={(e: Function) => { emailInputValidator = e; props.getValidation(e); }}
                    regExpError="Email format incorrect" regExp={/[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/g}
                    foregroundColor={props.foregroundColor} 
                    backgroundColor={props.backgroundColor} 
                    borderColor={props.borderColor} 
                    labelColor={props.labelColor}
                    required={props.required}
                    disabled={props.disabled}
                />

                <div className={classnames("p-2 pt-4 flex-shrink-1", statusColor)}>
                    { renderLoadingStatus() }
                </div>
            </div>

            <div className="text-danger">
                {props.errorMessage &&
                    <small data-testid="email-availability-error-msg" className="font-weight-bold" dangerouslySetInnerHTML={{__html: props.errorMessage }}></small>
                }
            </div>
        </React.Fragment>
    );
}

export default EmailInput;