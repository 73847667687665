import { AxiosResponse } from 'axios';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalHeader } from 'reactstrap';
import { addNotification } from '../../../../shared/reducers/notifications/actionTypes';
import notificationService from '../../../services/notificationService';
import './notificationPermissionModal.scss';

type notificationModalProps = {
    setPermission: Function
}

enum NotificationPermission {
    Granted = 'granted',
    Denied = 'denied',
    Default = 'default'
}

const NotificationPermissionModal: FunctionComponent<notificationModalProps> = ({setPermission}) => {

    const [modalOpen, setModal] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state:any) => state.app);

    const updateUserNotificationPermission = (permission = '') => {
        notificationService.saveNotificationPermission({
            ...user,
            notification_permission: permission.length > 0 ? permission : Notification.permission
        },
        (resp: AxiosResponse) => { },
        () => {
            dispatch(
                addNotification({
                label: `Save User Notification Preference`,
                text: t("general.errors.errorLoadingData"),
                type: "danger",
                })
            );
        }
    )
    }

    useEffect(() => {
        if (user.id > 0) {
            if (!("Notification" in window)) {
                console.log("Browser does not support desktop notification");
            } else {
                if (Notification.permission !== localStorage.getItem('userNotificationPermission')) {
                    if (!(Notification.permission === NotificationPermission.Default && localStorage.getItem('userNotificationPermission') === NotificationPermission.Denied))  {
                        localStorage.setItem('userNotificationPermission', Notification.permission);
                        updateUserNotificationPermission();
                    }
                }
                
                if ( Notification.permission === NotificationPermission.Default && (localStorage.getItem("userNotificationPermission") !== NotificationPermission.Denied || !localStorage.getItem('userNotificationPermission'))) {
                    setModal(true);
                }
            }
        }
    }, [user.id])

    const notifyUser = async () => {
        await Notification.requestPermission().then((permission: any) => {
            setModal(false);
            setPermission(permission ?? Notification.permission);
            if (permission === NotificationPermission.Granted) {
                localStorage.setItem('userNotificationPermission',NotificationPermission.Granted);
                new Notification("Thank you for enabling notifications!");
                updateUserNotificationPermission(NotificationPermission.Granted);
            } else {
                localStorage.setItem('userNotificationPermission',NotificationPermission.Denied);
                updateUserNotificationPermission(NotificationPermission.Denied);
            }
            
        })
    }

    const setInCookie = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setPermission(NotificationPermission.Denied)
        localStorage.setItem('userNotificationPermission',NotificationPermission.Denied);
        setModal(false);
        updateUserNotificationPermission(NotificationPermission.Denied);
    }

    const setNotificationPermission = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        await notifyUser().then(() => {
            setModal(false);
        });
    }

    return ( 
        <Modal isOpen={modalOpen} data-testid="permission-modal">
            <div className="row m-0">
                <div className="col-2 d-flex justify-content-center pe-0">
                    <img src="/assets/img/logo.png" data-testid="ESG-profile-image" alt="ESG profile image" style={{ height: "110px" }} />
                </div>
                <div className="col-10 ps-0">
                    <ModalHeader className='notification-header'>
                        <div data-testid="notification-title" className="fw-bold text-light-black">Engage would like to send you browser notifications</div>
                        <div data-testid="notification-msg" className="text-light-black notification-msg">Get notified about messages, comments and new content by turning on your browser notifications. You can change this at any time in your browser settings.</div>
                    </ModalHeader>
                    <div className="p-3 d-flex justify-content-end pt-2">
                        <Button className="me-2 reject-btn" data-testid="reject" onClick={(e) => setInCookie(e)} >Reject</Button>
                        <Button className="accept-btn  text-white" data-testid="accept" onClick={(e) => setNotificationPermission(e)}>Accept</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default NotificationPermissionModal;