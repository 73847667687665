import { UserReducerState } from '../../../types/types';

export const SET_PROFILE = "SET_PROFILE";
export function setProfile(userData: UserReducerState){
    return {
        type: SET_PROFILE,
        payload: userData
    }
}

export const LOGOUT = "LOGOUT";
export function logout(){
    return {
        type: LOGOUT
    }
}

interface SetProfileAction {
    type: typeof SET_PROFILE,
    payload: UserReducerState
}

interface LogoutAction {
    type: typeof LOGOUT
}

export type UserActionTypes = SetProfileAction | LogoutAction;