import { TenantReducerState } from "../../../types/types";
import { getI18n } from "react-i18next";

const tenantInitState: TenantReducerState = {
  title: "",
  logo: "/assets/img/ENGAGE-APP-LOGO.png",
  feedLogo: "https://cdn.engageesp.com/img/ENGAGE_APP_LOGO.png",
  ref: "",
  bodyTemplate: "{{**PageUrl**}}",
  shareSubject: "Engage Page Share",
  messagesEnabled: false,
  allowPublic: false,
  allowCookies: false,
  p2pTypeDefault: "PRIVATE",
  allowP2P: 0,
  session: {
    session_timeout: 120,
    before_end: 2,
  },

  tyBranding: {
    tycIcon: "https://cdn.engageesp.com/img/feed/tyc.png",
    tycText: {
      btn: getI18n().t("general.tyCard"),
      header: getI18n().t("general.tyCards"),
      menu: getI18n().t("general.tyCards"),
      pn: getI18n().t("general.tyCard"),
    },
  },
  webPNStatus: "1",
};

export default tenantInitState;
