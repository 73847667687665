import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const webPNProvider = {
    /**
     *
     * @param pushSubscription
     * @param success
     * @param error
     */
    sendPush(pushSubscription: PushSubscription, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/react/push`,
            method: 'post',
            data: pushSubscription
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
            if(error){
                error(err);
            }
        });
    }
}
export default webPNProvider;