import { useMutation } from "react-query";
import Client from "../services/axiosService";

export type PostSendMessage = {
  request_id: string;
  threadId: number;
  message: string;
  files: File[];
  recipientId?: string;
  parent_id?: string;
}

/**
 * @description: Makes a Post request to the API to send a message
 * @param threadId: The id of the thread to send a message to
 * @param message: The message to send
 * @returns: The response from the API
 */
const postSendMessage = async ({
  threadId,
  message,
  files,
  recipientId,
  parent_id,
  request_id
  }: PostSendMessage) => {
let formData = new FormData();
formData.append("body", message);
formData.append("request_id", request_id);
(recipientId && !threadId) && formData.append("recipient", recipientId);
parent_id && formData.append("parent_id", parent_id);
files.forEach((file: File) => {
  formData.append("files[]", file);
});
let urlPath = threadId ? `${threadId}/messages` : `send-private-message`;
const response = await Client(true, false).request({
  url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/` + urlPath,
  method: "post",
  headers: { "Content-Type": "multipart/form-data" },
  data: formData,
});
return response.data;
}

/**
 * @description: A hook to send a message
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useSendMessage = (onSuccess?: (data: any, variable: PostSendMessage) => void, onError?: (error: any, variable: PostSendMessage) => void) => {
  return useMutation(postSendMessage, {
    onSuccess: onSuccess,
    onError: onError,
  })
}