import { useMutation } from 'react-query';
import Client from '../../services/axiosService';

/**
 * @description: Makes a request to the be join a community group
 * @param groupID: The id of thegroup to join
 * @returns: The response from the API
 */
const joinOrLeaveGroup = async ({
    groupID,
    type,
}: {
    groupID: number;
    type: 'join' | 'leave';
}) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/community-groups/${groupID}/${type}`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
}

/**
 * @description: A hook to join community groups
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useJoinOrLeaveGroupQuery = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(joinOrLeaveGroup, {
        onSuccess: onSuccess,
        onError: onError,
    });
}
