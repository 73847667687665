import socialPostsProvider from '../providers/socialPostsProvider';

const socialPostsService = {
    likePost(postId: number, isLiked: boolean, success: Function, error?: Function) {
        socialPostsProvider.likePost(postId, isLiked, success, error);
    },
    loadPostLikes(postId: number, page: number = 1, success: Function, error?: Function) {
        socialPostsProvider.loadPostLikes(postId, page, success, error);
    },
    loadPostComments(postId: number, success: Function, error?: Function) {
        socialPostsProvider.loadPostComments(postId, success, error);
    },
    loadRepostUsers(postId: number, page: number = 1, success: Function, error?: Function) {
        socialPostsProvider.loadRepostUsers(postId, page, success, error);
    },
    leaveComment(postId: number, value: string, parentCommentId: number | null, files: Array<File>, success: Function, error?: Function) {
        socialPostsProvider.leaveComment(postId, value, parentCommentId, files, success, error);
    },
    deletePost(postId: number, success: Function, error?: Function) {
        socialPostsProvider.deletePost(postId, success, error);
    },
    deleteComment(postId: number, commentId: number, success: Function, error?: Function) {
        socialPostsProvider.deleteComment(postId, commentId, success, error);
    },
    unfollowUser(userId: number, success: Function, error?: Function) {
        socialPostsProvider.unfollowUser(userId, success, error);
    },
    reportPost(postId: number, message: string, success: Function, error?: Function) {
        socialPostsProvider.reportPost(postId, message, success, error);
    },
    saveSocialPost(data: any, success: Function, error?: Function) {
        socialPostsProvider.saveSocialPost(data, success, error);
    },
    saveRepostWithoutThoughts(postId: number, success: Function, error?: Function) {
        socialPostsProvider.saveRepostWithoutThoughts(postId, success, error);
    },
    saveRepostWithThoughts(postId: number, message: string, success: Function, error?: Function) {
        socialPostsProvider.saveRepostWithThoughts(postId, message, success, error);
    },
    saveSocialPostFile(data: any, success: Function, error?: Function) {
        socialPostsProvider.saveSocialPostFile(data, success, error);
    },
    saveSocialPostForGroup(data: any, groupID: number, success: Function, error?: Function) {
        socialPostsProvider.saveSocialPostForGroup(data, groupID, success, error);
    },
    saveGroupsPostsData(id: string, page: number, success: Function, error?: Function) {
        socialPostsProvider.saveGroupsPostsData(id, page, success, error);
    },
};

export default socialPostsService;
