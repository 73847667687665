import { TenantReducerState } from '../../../types/types';

export const SET_DATA = "SET_DATA";
export function setTenantData(tenantData: TenantReducerState){
    return {
        type: SET_DATA,
        payload: tenantData
    }
}

interface SetTenantDataAction {
    type: typeof SET_DATA,
    payload: TenantReducerState
}

export type TenantActionTypes = SetTenantDataAction;