import { useInfiniteQuery,  } from "react-query";
import Client from "../../services/axiosService";

export type GroupMembersQueryParams = {
    searchQuery: string | null;
    id: string | undefined;
}

/**
 * @description: Fetches community group memebrs for a group from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchGroups = async ({ searchQuery, id }: GroupMembersQueryParams, page: number) => {
    if(!id) return;
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/community-groups/${id}/users?search=${searchQuery}&page=${page}`,
        method: "get"
    })

    return response.data;
}

/**
 * @description: Fetches group members results from the API and returns the data in the form of infinite query
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useGroupMembersQuery = (groupMembersQueryParams: GroupMembersQueryParams) => {
    const { searchQuery, id } = groupMembersQueryParams;
    return useInfiniteQuery(
        ['groups-members', searchQuery, id],
        ({ pageParam = 1 }) => fetchGroups(groupMembersQueryParams, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
            enabled: !!id,
            retry: 1
        }
    );
}