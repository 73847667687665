import React, { FunctionComponent, useState, useEffect } from "react";
import { Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import usersService from "../../../services/usersService";
import { UsersListItem } from "../../../../types/types";
import objectAssign from "object-assign";
import { addNotification } from "../../../../shared/reducers/notifications/actionTypes";
import { AxiosResponse } from "axios";

import Scrollbar from "../scrollbar/scrollbar";
import SpinnerLoad from "../spinnerLoad/spinnerLoad";

type UsersListProps = {
  btnText?: string;
  doneCallback?: Function;
  clickCallback?: Function;
  fieldsToGet?: Array<string>;
  fieldsToShow?: Array<string>;
};

type UserLoadData = {
  searchStr: string;
  newData: boolean;
};

const UsersList: FunctionComponent<UsersListProps> = ({
  btnText,
  doneCallback,
  clickCallback,
  fieldsToGet,
  fieldsToShow = [],
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [usersData, setUsers] = useState<any>(null);
  const [searchFilter, setSearch] = useState<string>("");
  const [selectedUsers, setSelected] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchTimeout, setSearchTimeout] = useState<any>();
  const [inputLoadSpinner, setInputLoad] = useState<boolean>(false);

  useEffect(() => {
    loadUsers();
  }, []);

  const scrollCapture = (e: any) => {
    if (e.scrollTop + e.clientHeight >= e.scrollHeight) {
      loadUsers();
    }
  };

  const loadUsers = (loadData: UserLoadData | null = null) => {
    if (isLoading) return;

    let page = 0;
    if (usersData && !loadData) {
      if (usersData.meta.current_page === usersData.meta.last_page) return;
      page = usersData.meta.current_page + 1;
    }

    setLoading(true);

    const search = loadData ? loadData.searchStr : searchFilter;
    const loadPage = loadData && loadData.newData ? 0 : page;

    usersService.getUsers(
      search,
      loadPage,
      (resp: AxiosResponse) => {
        const curData = usersData ? usersData.data : [];
        if (loadData && loadData.newData) {
          setUsers(resp.data);
        } else {
          setUsers(
            objectAssign({}, resp.data, {
              data: [...curData, ...resp.data.data],
            })
          );
        }

        // If there is a user selected from the profile, assign it to the list
        const toId = localStorage.getItem("to_id");

        if (toId !== null) {
          const userInList = resp.data.data.filter((user: any) => {
            if (user.id.toString() === toId) return user;
          });

          if (userInList[0] !== undefined) {
            setSelected([userInList[0]]);
            if (clickCallback) clickCallback([userInList[0]]);
          } else {
            const userNotInList = [
              {
                id: parseInt(toId),
                full_name: localStorage.getItem("to_name"),
                hasManager: localStorage.getItem("to_manager") === "true",
                image_url: localStorage.getItem("to_image"),
              },
            ];
            setSelected(userNotInList);
            if (clickCallback) clickCallback(userNotInList);
          }
          localStorage.removeItem("to_id");
          localStorage.removeItem("to_name");
          localStorage.removeItem("to_manager");
          localStorage.removeItem("to_image");
        }
        setLoading(false);
      },
      () => {
        dispatch(
          addNotification({
            label: `Users`,
            text: t("general.errors.errorLoadingData"),
            type: "danger",
          })
        );
        setLoading(false);
      }
    );
  };

  const searchHandler = (e: any) => {
    setInputLoad(true);

    const value = e.currentTarget.value;
    setSearch(value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        setInputLoad(false);
        loadUsers({ searchStr: value.trim(), newData: true });
      }, 1000)
    );
  };

  const userCheckHandler = (e: any) => {
    const target = e.currentTarget;
    const userId = Number(target.getAttribute("data-id"));
    checkUser(userId, target.checked);
  };

  const userClickHandler = (e: any) => {
    const target = e.currentTarget;
    const userId = Number(target.getAttribute("data-id"));
    const isChecked = !target.querySelector(".user-checkbox").checked;
    checkUser(userId, isChecked);
  };

  const pullUserData = (userId: number) => {
    const user = usersData.data.find((u: any) => u.id === userId);
    if (!user || !fieldsToGet) return {};
    let newItem: any = { id: user.id };
    fieldsToGet.forEach((field) => {
      newItem[field] = user[field];
    });
    return newItem;
  };

  const checkUser = (userId: number, isChecked: boolean) => {
    let newSelected: Array<any>;
    if (isChecked) newSelected = [...selectedUsers, pullUserData(userId)];
    else newSelected = selectedUsers.filter((u) => u.id !== userId);

    setSelected(newSelected);

    if (clickCallback) clickCallback(newSelected);
  };

  const onSaveClickHandler = () => {
    setSelected([]);
    if (doneCallback) doneCallback(selectedUsers);
  };

  const renderUser = (user: UsersListItem) => {
    return (
      <div
        id={`user${user.id}`}
        data-id={user.id}
        onClick={userClickHandler}
        className="d-flex align-items-center justify-content-between mt-2 pb-2 border-bottom c-pointer"
      >
        <div className="thread-user-container d-flex align-items-center">
          <img className="rounded-circle thumb48" alt={`${user.full_name} profile image`} src={user.image_url} />
          <div className="ms-3">
            <h5 className="fw-bold text-secondary">{user.full_name}</h5>
            {fieldsToShow.map((field: string) => {
              const u: any = user;
              return (
                <h5 className="text-primary-dark mb-0">{u[field] || ""}</h5>
              );
            })}
          </div>
        </div>
        <div className="me-2">
          <input
            className="thumb20 user-checkbox"
            aria-label={`${selectedUsers.find((u: any) => u.id === user.id) ? 'deselect' : 'select'} user ${user.full_name}`}
            type="checkbox"
            value="${id}"
            data-id={user.id}
            checked={selectedUsers.find((u: any) => u.id === user.id)}
            onChange={userCheckHandler}
          />
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="ps-3 pe-3 pt-2 d-flex">
        <input
          className="form-control"
          type="text"
          placeholder={`${t('general.search')}`}
          aria-label={`${t('general.search')}`}
          value={searchFilter}
          onChange={searchHandler}
        />
        {inputLoadSpinner && (
          <SpinnerLoad
            size={25}
            className="d-flex align-items-center d-inline-block ms-2"
          />
        )}
      </div>
      <div className="modal-body w-100" style={{ height: "400px" }}>
        <Scrollbar onScroll={scrollCapture} rightScroll={true}>
          {usersData ? (
            usersData.data.map((user: UsersListItem) => renderUser(user))
          ) : (
            <div />
          )}
          {isLoading && <SpinnerLoad className="w-100 text-center mt-3" />}
        </Scrollbar>
      </div>
      {btnText && (
        <div className="m-1">
          <Button
            className="w-100"
            color="success"
            disabled={isLoading}
            style={{ backgroundColor: "#47a58d" }}
            onClick={onSaveClickHandler}
          >
            {btnText}
          </Button>
        </div>
      )}
    </React.Fragment>
  );
};

export default UsersList;
