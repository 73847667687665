import React, { FunctionComponent, useState, useEffect } from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navbar, NavbarBrand } from 'reactstrap';
import LocaleSelector from '../../common/localeSelector/localeSelector';
import RightNavComponent from '../rightNavComponent/rightNavComponent';
import { SideNavItem } from '../../../../types/types';
import LeftNavMobileView from '../leftNavMobileView/leftNavMobileView';
import { useMobileScreen } from '../../../hooks/useMobileScreen';
import { SearchField } from '../../searchField/searchField';
import { Box } from '@mui/material';
import { SearchHook, useSearchHook } from '../../../hooks/useSearchHook';
import { useNavigate } from 'react-router-dom';

export type TopNavComponentProps = {
    menuItems: SideNavItem[];
    unreadThreads: number;
    className?: string;
    showSearch?: boolean;
    showRight?: boolean;
};

const TopNavComponent: FunctionComponent<TopNavComponentProps> = ({ unreadThreads, menuItems, className, showRight = true, showSearch = true }) => {
    const { t, i18n } = useTranslation();
    const [titleText, setTitleText] = useState('');
    const location = useLocation();
    const brandingColors = useSelector((state: any) => state.app.branding);
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const isMobileView = useMobileScreen();
    const navigate = useNavigate();
    const { searchQuery: searchQuery, handleChange: handleSearch, handleKeyDown: handleSearchKeyDown }: SearchHook = useSearchHook(undefined);

    const handleSearchSubmit: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter') {
            if (!e.currentTarget.value) return;
            handleSearchKeyDown && handleSearchKeyDown(e);
            navigate(`/search?search=${e.currentTarget.value}`, { replace: true });
        }
    };

    useEffect(() => {
        brandingColors.menu_items.map((item: SideNavItem) => {
            let urlBase: string = location.pathname.split('/')[1];
            if (item.route_url.split('/')[1] === urlBase) {
                setTitleText(item.title);
            }
        });
    }, [brandingColors]);

    useEffect(() => {
        brandingColors.menu_items.map((item: any) => {
            let urlBase: string = location.pathname ? location.pathname.split('/')[1] : '';
            if (item.route_url.split('/')[1] === urlBase) {
                setTitleText(item.title);
            }
        });
    }, [location]);

    i18n.on('languageChanged', () => {
        brandingColors.menu_items.map((item: any) => {
            let urlBase: string = location.pathname.split('/')[1];
            if (item.route_url.split('/')[1] === urlBase) {
                setTitleText(item.title);
            }
        });
    });

    const brandingStyle = { color: brandingColors.web_navbar_foreground, backgroundColor: brandingColors.web_navbar_background };
    return (
        <Navbar data-testid="top-nav" id="page-frame-top" className={classNames('navbar py-0', className)} style={brandingStyle}>
            <div className="d-flex align-items-center">
                {isMobileView && (
                    <RightNavComponent
                        iconClass={'font-15 right-pop-icon'}
                        colorForeground={brandingColors.web_sidebar_foreground}
                        colorBackground={brandingColors.web_sidebar_background}
                        isMobileView={isMobileView}
                        menuItems={menuItems}
                        unreadThreads={unreadThreads}
                    />
                )}

                <NavbarBrand href={'/'} className={'p-0 ms-2'} aria-label="engage logo with home page link">
                    <img data-testid="esg-brand-logo" className={'logo-img-size'} src={tenantInfo.logo} alt={''} />
                </NavbarBrand>

                <SearchField
                    handleSearch={handleSearch}
                    searchQuery={searchQuery}
                    placeholder={t('partials.type search field') || 'Type and hit Enter...'}
                    handleKeyDown={handleSearchSubmit}
                    sx={{
                        borderRadius: '18px',
                        backgroundColor: '#FFF',
                        color: '#000',
                        fontSize: '16px',
                        height: '40px',
                        width: '300px',
                    }}
                />
            </div>
            <Box className={'d-flex justify-content-between'} sx={{ gap: '15px' }}>
                <LocaleSelector />
                {/* {showSearch &&
                    <SearchComponent iconClass={'mx-4 font-16'} inputStyle={brandingStyle} />
                } */}
                {showRight && !isMobileView && (
                    <RightNavComponent
                        iconClass={'font-15 right-pop-icon'}
                        isMobileView={isMobileView}
                        colorForeground={brandingColors.web_sidebar_foreground}
                        colorBackground={brandingColors.web_sidebar_background}
                        menuItems={menuItems}
                        unreadThreads={unreadThreads}
                    />
                )}
            </Box>
        </Navbar>
    );
};

export default TopNavComponent;
