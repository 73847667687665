import React , { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Form, FormGroup, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BrowserView } from 'react-device-detect';

import ValidatedInput from '../../common/validatedInput/validatedInput';
import Scrollbar from '../../common/scrollbar/scrollbar';
import EmailInput from '../emailInput/emailInput';

import RegistrationService from '../../../services/registrationService';
import configService from '../../../services/configService';

import { LoginConfigs } from '../../../../types/types';
import { AxiosResponse } from 'axios';

type LoginUserScreenProps = {
    tenantRef: string,
    setRegData: Function,
    data: LoginConfigs,
    loginData: LoginConfigs
}

const RegistrationUserScreen: FunctionComponent<LoginUserScreenProps> = (props) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const matchParams = useParams();

    // Validators for Custom fields from ValidatedInput components
    const validationInputs: Array<Function> = [];
    const getInputValues: Array<Function> = [];
    
    const [customFields, setFields] = useState([]);
    const [userEmail, setUserEmail] = useState<string>('');
    const [emailValidated, setEmailValidated] = useState<boolean>(false);
    const [userConfirmEmail, setUserConfirmEmail] = useState<string>('')
    const [confirmEmailValidated, setConfirmEmailValidated] = useState<boolean>(false);
    const [confirmEmailErrorMessage, setConfirmEmailErrorMessage] = useState<string>('')
    const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
    const [confirmEmailValid, setConfirmEmailValid] = useState<boolean | null>(null)
    const [emailValid, setEmailValid] = useState<boolean | null>(null)
    const [errorsList, setErrorsList] = useState<string[]>([]);

    useEffect(() => {
        RegistrationService.getInfo(props.tenantRef, (resp: AxiosResponse) => {
            try {
                const fieldsJson = JSON.parse(resp.data.fields).fields;
                setFields(fieldsJson);
            } catch(e) {
                setErrorsList(['Error parsing fields data']);
            }
        }, () => {
            setErrorsList(['Error loading registration data']);
        });
    }, []);

    const validateCustomFields = () => {
        let isValid = true;
        validationInputs.forEach((validator: Function) => {
            const result = validator();
            if(isValid && !result)
                isValid = false;
        });
        return isValid;
    }

    const verifyForm = (e: any) => {
        e.preventDefault();

        if(!validateCustomFields())
            return;

        const regErrors = [];
        if(!emailValidated)
            regErrors.push( t('There are problems with the Email.') );

        setErrorsList(regErrors);
        if(regErrors.length > 0)
            return;

        sendRegData();
    }
    
    const sendRegData = () => {
        const data: any = { 
            tenant_id: props.data.id, 
            email: userEmail
        };
        getInputValues.forEach((valueGetter: Function) => {
            const inputInfo = valueGetter();
            data[inputInfo.name] = inputInfo.value;
        });

        props.setRegData(data);
    }

    const goToLogin = () => {
        let tenantPrefix = '';
        if(matchParams && matchParams.tenantName)
            tenantPrefix = `${matchParams.tenantName}/`;
            navigate(`/${tenantPrefix}login`);
    }

    const config = (key: string, def: any) => {
        return configService.getFromData(props.loginData, key, def);
    }

    useEffect(() => {
      if(userConfirmEmail.length > 4) {
        let isEmailConfirmed = userEmail === userConfirmEmail
        setConfirmEmailValid(isEmailConfirmed)
        setConfirmEmailValidated(isEmailConfirmed)
        setConfirmEmailErrorMessage(isEmailConfirmed ? '' : 'Please ensure that the entered email matches the confirmation email.')
      }
    }, [userEmail])

    const renderCustomFields = () => {
        return (
            <React.Fragment>
                {
                    customFields.map((item: any) => 
                        {
                          if(item.name === 'confirm_email') {
                            return <EmailInput key={item.name} className="mb-1" placeholder={ `${t('registration.enter email')}` }
                            tenantId={props.data.id} label={item.label} name={item.name}
                            setFormEmail={setUserConfirmEmail} setEmailValidated={setConfirmEmailValidated} 
                            getValidation={(e: Function) => { validationInputs.push(e); }}
                            foregroundColor={props.loginData.textbox_foreground_colour} 
                            backgroundColor={props.loginData.textbox_background_colour} 
                            borderColor={props.loginData.textbox_border_colour} 
                            labelColor={props.loginData.label_colour} required={item.is_required}
                            disabled={userEmail || userConfirmEmail ? false : true}
                            confirmEmail={userEmail} setEmailValid={setConfirmEmailValid} emailValid={confirmEmailValid}
                            setErrorMessage={setConfirmEmailErrorMessage} errorMessage={confirmEmailErrorMessage}/>
                          } 
                          if(item.name === 'email') {
                            return  <EmailInput key={item.name} className="mb-1" placeholder={ `${t('registration.enter email')}` }
                            tenantId={props.data.id} label={item.label} name={item.name}
                            setFormEmail={setUserEmail} setEmailValidated={setEmailValidated} 
                            getValidation={(e: Function) => { validationInputs.push(e); }}
                            foregroundColor={props.loginData.textbox_foreground_colour} 
                            backgroundColor={props.loginData.textbox_background_colour} 
                            borderColor={props.loginData.textbox_border_colour} 
                            labelColor={props.loginData.label_colour} required={item.is_required}
                            confirmEmail={userConfirmEmail} setEmailValid={setEmailValid} emailValid={emailValid}
                            setErrorMessage={setEmailErrorMessage} errorMessage={emailErrorMessage}/>
                          } 
                          return ( <ValidatedInput key={item.name} label={item.label} name={item.name}
                              type={item.type} required={item.is_required}
                              getValidation={(e: Function) => { validationInputs.push(e); }}
                              getValue={(e: Function) => { getInputValues.push(e); }}
                              foregroundColor={props.loginData.textbox_foreground_colour} 
                              backgroundColor={props.loginData.textbox_background_colour} 
                              borderColor={props.loginData.textbox_border_colour} 
                              labelColor={props.loginData.label_colour}
                            />
                        )}
                    )
                }
            </React.Fragment>
        );
    }

    return (
        <Scrollbar contentProps={{ className: 'd-flex justify-content-center align-items-center' }}>
            <Col data-testid="registration-user-screen" xs={'12'} sm={'8'} md={'6'} lg={'3'} className={'card reg-card b0 m-auto mt-2 mb-2'}>
                <div className={'card-header reg-header border-bottom-0 text-center w-100'}>
                    <img data-testid="registration-logo" src={config('foreground_logo', '/assets/img/logo.png')} alt="Image" onClick={validateCustomFields}
                        className={'block-center w-60'}
                    />
                </div>

                <Form data-testid="registration-form" className={'card-body p-3'} onSubmit={verifyForm}>
                    { errorsList && errorsList.length > 0 &&
                        <div data-testid="error-list" className={'alert-warning rounded px-3 py-2 mb-3'}>
                            { errorsList.map( error => (
                                <p key={error} className={'font-weight-bold mb-0'}>{ error }</p>  
                            )) }
                        </div>
                    }

                    <div className={'message text-center mb-2'} style={{color: config('label_colour', 'black')}}>
                        { t('registration.enter user registration credentials') }
                    </div>

                    <FormGroup>
                        { renderCustomFields() }
                    </FormGroup>

                    <Button data-testid="verify-registration-btn" disabled={!emailValidated || !confirmEmailValidated} className={'my-3 w-100'} onClick={verifyForm}
                        style={{color: config('button_foreground_colour', 'black'), backgroundColor: config('button_background_colour', '#006985') }}
                    >
                        { t('registration.verify') }
                    </Button>
                    <BrowserView>
                        <Button data-testid="back-btn" onClick={goToLogin} className={'w-100'}
                            style={{color: config('button_foreground_colour', 'black'), backgroundColor: config('button_background_colour', '#b2b2b2') }}
                        >
                            <FontAwesomeIcon icon="arrow-left" className={'me-2'} />
                            { t('registration.back') }
                        </Button>
                    </BrowserView>
                </Form>
            </Col>
        </Scrollbar>
    );
}

export default RegistrationUserScreen;