import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AxiosResponse } from "axios";
import Scrollbar from "../../common/scrollbar/scrollbar";
import SpinnerLoad from "../../common/spinnerLoad/spinnerLoad";
import locationsService from "../../../services/locationsService";
import LocationsListPage from "./locationslist";
import "./locationsPage.scss";
import { Link } from "react-router-dom";
import { getAlphabets } from "../utils";
import { LocationItem } from "../../../../types/types";
import "../connectPage.scss";
import useDebouncedCallback from "../../../hooks/useDebouncedCallback";
import { useSelector } from "react-redux";
import { isWhite } from "../../../utils/colourCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faLocationDot } from "@fortawesome/pro-regular-svg-icons";

type LocationProps = {
  selectedLocationId: string;
};

const LocationsPage: FunctionComponent<LocationProps> = (
  selectedLocationId
) => {
  const { t } = useTranslation();
  const tenantBranding = useSelector((state:any) => state.app.branding);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [locationPage, setLocationPage] = useState<number>(1);
  const [locationData, setLocationData] = useState<Array<any>>([]);
  const [showHeading, setShowHeading] = useState<boolean>(true);

  type Props = {
    isLoading: boolean;
    hasMoreData: boolean;
    loadOnMount: boolean;
    children?: React.ReactNode;
  };

  const PageListComponent: React.FC<Props> = ({
    isLoading,
    hasMoreData,
    loadOnMount,
    children,
  }) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (loadOnMount && initialLoad) {
        getLocationData();
        setInitialLoad(false);
      }
    }, [loadOnMount, initialLoad]);

    return <div ref={contentRef}>{children}</div>;
  };

  const getLocationData = () => {
    setLocationPage((locationPage) => locationPage + 1);
    locationsService.getLocationListingData(
      locationPage,
      (resp: AxiosResponse) => {
        setPageLoading(false);
        if (resp.data.data.length > 0) appendLocationData(resp.data.data);
        setHasMoreData(locationPage < resp.data.meta.last_page);
      },
      () => {}
    );
  };

  const searchFilter = (e: { target: { value: string } }) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);
    replaceLocationData([]);
    setShowHeading(false);
    setPageLoading(true);
    if (keyword !== "" && keyword.length > 2) {
      setLocationPage(1);
      makeSearch(keyword);
    } else {
      if (keyword === "") {
        replaceLocationData([]);
        getLocationData();
        setSearchTerm("");
        setShowHeading(true);
      }
    }
  };

  const makeSearch = useDebouncedCallback((keyword: string) => {
    locationsService.getSearchedLocationData(
      keyword,
      locationPage,
      (resp: AxiosResponse) => {
        replaceLocationData(resp.data.data);
        setPageLoading(false);
        setHasMoreData(locationPage < resp.data.meta.last_page);
      },
      () => {}
    );
  }, 1000);

  const renderItems = () => {
    const items: Array<React.ReactElement> = [];
    if (!locationData) return items;
    locationData.forEach((item) => {
      items.push(
        <LocationsListPage
          item={item.location}
          locationId={item.id}
          selectedLocationId={selectedLocationId.selectedLocationId}
        />
      );
    });
    if (items.length > 0) {
      return items;
    } else {
      if (!pageLoading) {
        return (
          <div className="d-flex align-items-center justify-content-space-between w-100 pb pt contactpage location-item">
            <h3 className="mt0 mb0 text-secondary">
              {t("connect.noDataFound")}
            </h3>
          </div>
        );
      }
    }
  };

  const alphabetFilter = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setSearchTerm("");
    const el = e.target as HTMLInputElement;
    const selAlphabet = el.getAttribute("data-char");
    if (selAlphabet !== "") {
      setLocationPage(1);
      locationsService.getSearchedLocationData(
        selAlphabet + "_",
        1,
        (resp: AxiosResponse) => {
          replaceLocationData(resp.data.data);
          setHasMoreData(1 < resp.data.meta.last_page);
        },
        () => {}
      );
    } else {
      getLocationData();
    }
  };

  const pageScroll = (e: {
    scrollTop: number;
    clientHeight: number;
    scrollHeight: number;
  }) => {
    if (
      !pageLoading &&
      hasMoreData &&
      e.scrollTop + e.clientHeight >= e.scrollHeight
    ) {
      getLocationData();
    }
  };

  const appendLocationData = (newElem: Array<LocationItem>) => {
    setLocationData([...locationData, ...newElem]);
  };

  const replaceLocationData = (newElem: Array<LocationItem>) => {
    setLocationData([...newElem]);
  };

  const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
  const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
  const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

  return (
    <div className={"connect-container"}>
      <div className="topnav">
        <ul className="nav">
          <Link 
            to={`/connect`} 
            className="w-50 mb0 font-18 inactive-contact-tab d-flex align-items-center justify-content-center"
          >
            <FontAwesomeIcon icon={faAddressBook} className="me-2 contact-icon" />
            {t("connect.contacts")}
          </Link>
          <a 
            href="" 
            className="active-location-tab locations-tab locationColors w-50 mb0 font-18"
            style={{
              backgroundColor: bgColor,
              color: fgColor
            }}
          >
            <FontAwesomeIcon icon={faLocationDot} className="me-2 active-location-icon" />
            {t("connect.locations")}
          </a>
        </ul>
      </div>
      <div className="p-3">
        <div className="p border-right">
          <div className="location-search-section d-flex align-items-center">
              <p className="fa-regular form-control location-search-icon fa-magnifying-glass m-0"></p>
              <input
                  type="text"
                  className="location-search-input"
                  placeholder={`${t("connect.searchLocations")}`}
                  value={searchTerm}
                  onChange={searchFilter}
              />
          </div>
        </div>
      </div>
      <div className={"d-flex justify-content-center listing-scroll"}>
        <Scrollbar
          onScroll={pageScroll}
          rightScroll={true}
          className="me-2"
          noScrollX
        >
          <PageListComponent
            hasMoreData={hasMoreData}
            isLoading={pageLoading}
            loadOnMount={true}
          >
            <div>
              {showHeading ? (
                <h3 className="mt0 ml-sm all-contact-title">{t("connect.all")}</h3>
              ) : null}
              {renderItems()}
            </div>
            {pageLoading ? (
              <SpinnerLoad
                className="h-100 w-100 d-flex align-items-center justify-content-center mt-3"
                size={50}
              />
            ) : (
              ""
            )}
          </PageListComponent>
        </Scrollbar>
        <div className="list w-5 mr d-flex flex-direction-column location-search-list mt0 ">
          {getAlphabets().map((item, index) => {
            return (
              <span
                data-char={item}
                key={index}
                onClick={alphabetFilter}
                className="list-char-item cursor-pointer text-bold text-center text-engage-dark-gray d-inline-block"
                id={item}
              >
                {item}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LocationsPage;
