import React, { FunctionComponent, useEffect, useState } from 'react';
import { Modal, ModalHeader, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import authService from '../../../services/authService';

declare global {
    interface Window { sessionTimeoutOpen: boolean; }
}

let sessionTimeout: any = null;
let timerInterval: any = null;

const SessionTimeout: FunctionComponent = () => {
    const { t } = useTranslation();
    const tenantInfo = useSelector((state: any) => state.app.tenant);

    const [modalOpen, setModal] = useState(false);
    const [sessionEnd] = useState<number>(tenantInfo.session.session_timeout);
    const [beforeEnd] = useState<number>(tenantInfo.session.before_end);
    
    const [timerCount, setCount] = useState<number>(sessionEnd * 60);
    useEffect(() => {
        if(timerCount < 1) {
            logoutUser();
        }
    }, [timerCount]);

    useEffect(() => {
        const events = ['keyup', 'mouseup', 'mousemove', 'touchend', 'touchmove'];
        events.forEach((event) => {
            window.addEventListener(event, startTimer);
        });

        startTimer();

        return () => {
            events.forEach((event) => {
                window.removeEventListener(event, startTimer);
            });
        }
    }, []);

    const setModalOpen = (isOpen: boolean) => {
        setModal(isOpen);
        window.sessionTimeoutOpen = isOpen;
    }

    const logoutUser = () => {
        if (tenantInfo) {
            window.location.href = `/${tenantInfo.ref}/logout`;
        } else {
            window.location.href = `/login`;
        }

        authService.logout();
    }

    const stayConnected = () => {
        setModalOpen(false);
        clearInterval(timerInterval);
        startTimer();
    }

    const startTimer = () => {
        if(window.sessionTimeoutOpen)
            return;

        clearTimeout(sessionTimeout);

        sessionTimeout = setTimeout(() => {
            const count = (beforeEnd) * 60;
            setCount(count);
            let innerTimer = count;
            setModalOpen(true);

            timerInterval = setInterval(() => {
                    setCount(innerTimer--);
            }, 1000);
        }, (sessionEnd - beforeEnd) * 60000);
    }

    const progressWidth =  `${timerCount / (beforeEnd * 60 / 100)}%`;
    return (
        <Modal isOpen={modalOpen}>
            <ModalHeader>
                <h4 className="fw-bold text-light-black">{ t('sessionTimeout.expireSoon') }</h4>
            </ModalHeader>

            <div className="p-2">
                <p>{ t('sessionTimeout.timeLeft') }</p>
                <div style={{ height: '1rem' }}>
                    <div className="progress-bar countdown-bar active bg-success text-white" style={{ minWidth: '100px', width: progressWidth }}>
                        { `${timerCount} ${t('sessionTimeout.seconds')}` }
                    </div>
                </div>
                <div className="text-end mt-3">
                    <Button className="me-1 bg-white text-primary" onClick={logoutUser}>{ t('sessionTimeout.logout') }</Button>
                    <Button className="bg-white text-primary" onClick={stayConnected}>{ t('sessionTimeout.stay') }</Button>
                </div>
            </div>
        </Modal>
    );
}

export default SessionTimeout;