export default {
  "award": "{{text, it-handle-an}}",
  "awards": "Premi",
  "send": "Invia {{text, en-handle-an}}",
  "all": "Tutti",
  "inbox": "A me",
  "sent": "Inviato",
  "team": "Squadra",
  "choose": "Scegli",
  "toggleManager": "Attiva messaggi di Toggle Manager",
  "manager": "Manager",
  "received": "ricevuto {{text, en-handle-an}}",
  "from": "da",
  "message": "Messaggio",
  "managerMessage": "Messaggio al manager",
  "setVisibility": "Imposta la visibilità",
  "showInFeed": "Mostrato nel feed",
  "onlyRecipients": "Visto solo dai destinatari",
  "recipients": "Destinatari",
  "for": "per",
  "messagePlaceholder": "Digita qui il tuo messaggio",
  "thankYou": "Grazie"
};