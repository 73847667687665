import { useMutation } from "react-query";
import Client from "../services/axiosService";

/**
 * @description: Makes a Post request to the API to mark a thread as read
 * @param threadId: The id of the thread to mark as read
 * @returns: The response from the API
 */
const leaveGroupThreadRequest = async (threadId: string) => {
    const response = await Client(true, true).request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/messages/threads/${threadId}/leave`,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return response.data;
};

/**
 * @description: A hook to mark a thread as read
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const useLeaveGroupThread = (onSuccess? : () => void, onError? : () => void) => {
    return useMutation(leaveGroupThreadRequest, {
        onSuccess: onSuccess,
        onError: onError,
    });
};