import initState from './initState';
import { SET_DATA, TenantActionTypes } from './actionTypes';
import authService from '../../../web/services/authService';
import objectAssign from 'object-assign';
import { TenantReducerState } from '~/types/types';

export default function TenantReducer(
    state: TenantReducerState = objectAssign({}, initState, authService.getTenantFromStore()),
    action: TenantActionTypes
){
    switch(action.type){
        case SET_DATA:
            authService.storeTenantInfo( JSON.stringify(action.payload) );
            
            // Set page title
            document.title = action.payload.title;

            return objectAssign({}, state, action.payload);
        default:
            return state;
    }
}