export default {
  "locations": "Ubicaciones",
  "contacts": "Contactos",
  "favourites": "Favoritos",
  "all": "Todos",
  "noDataFound": "Datos no encontrados",
  "thereAreNoPost": "No hay publicaciones, ¡Comienza a publicar para verlas aquí!",
  "noOrgChart": "No existe ningún organigrama asociado a este usuario.",
  "awarded": "Galardonado",
  "expires": "Expira",
  "contactDetails": "Detalles del contacto",
  "followers": "Seguidores",
  "following": "Siguiendo",
  "follow": "Seguir",
  "unfollow": "Dejar de seguir",
  "unsnooze": "Reactivar",
  "snooze": "Domitar",
  "1Hour": "1 Hora",
  "8Hours": "8 Horas",
  "24Hours": "24 Horas",
  "1Week": "1 Semana",
  "1Month": "1 Mes",
  "1Year": "1 Año",
  "message": "Mensaje",
  "details": "Detalles",
  "posts": "Publicaciones",
  "organisation": "organización",
  "profileImageHeader": "Imagen del perfil",
  "headerImageHeader": "Image de la cabecera",
  "uploadFile": "Subir fichero",
  "zoom": "Aumento (%): ",
  "rotate": "Rotar: ",
  "done": "Hecho",
  "ratio": "Mantener Ratio",
  "on": "Habilitado",
  "off": "Deshabilitado",
  "search": "Buscar",
  "noFavourites": "¡Empieza a añadir a tus contactos favoritos!",
  "manager": "Superior",
  "location": "Localización",
  "primaryEmail": "Correo Electrónico Principal",
  "dob": "Fecha de Nacimiento",
  "start": "Fecha de comienzo",
  "bio": "Biografia",
  "chart": "Organigrama",
  "close": "Cerrar",
  "address": "Dirección",
  "description": "Descripción",
  "fName": "Nombre",
  "lName": "Apellidos",
  "title": "Puesto",
  "department": "Departamento",
  "save": "Guardar",
  "error": "Se ha producido error,",
  "errorLong": "por favor intentelo de nuevo más tarde.",
  "success": "Exito!",
  "successLong": "Datos guardados correctamente",
  "email": "Correo electrónico",
  "badge": "Insignias",
  "skills": "Habilidades",
  "phone": "Teléfono",
  "hobbies": "Aficiones",
  "clearAll": "Limpiar todo",
  "quickFilters": "Filtros rápidos",
  "customSearchTextPlaceholder": "Comienza a escribir y presiona enter...",
  "working hours": "Horas Laborales",
  "fun facts": "Hechos graciosos",
  "noResultsFound": "No se han encontrado resultados",
  "charsLimitMsg": "Por favor ingrese 3 o más caracteres para buscar",
  "errorLoadingFollowers": "Error al cargar seguidores",
  "errorLoadingFollowings": "Error al cargar lo siguiente",
  "followsYou": "Te sigue",
  "searchContacts": "Buscar contactos",
  "searchLocations": "Buscar ubicaciones",
  "allContacts": "Todos los contactos",
  "edit": "Editar",
  "people": "Personas",
  "directory": "Directorio",
  "noSearchResultsContactDescription": "¡Vaya! Parece que no podemos encontrar ningún contacto que coincida con tu búsqueda.",
  "noContactResultsDescription": "¡Vaya! Parece que no podemos encontrar ningún contacto.",
  "noSearchResultsFavouriteDescription": "¡Vaya! Parece que no podemos encontrar ningún contacto favorito que coincida con tu búsqueda.",
  "noFavouriteResultsDescription": "¡Vaya! No se encontraron contactos favoritos. Agrega contactos a tus favoritos para verlos aquí.",
  "noSearchResultsLocationDescription": "¡Vaya! Parece que no encontramos ninguna ubicación que coincida con tu búsqueda.",
  "noLocationResultsDescription": "¡Vaya! Parece que no encontramos ninguna ubicación.",
  "clearFilters": "Borrar filtros",
  "filters": "Filtros",
  "noLocationPermission": "¡Vaya! Parece que no tienes los permisos para ver esto. Intenta en otra ubicación.",
  "groups": "Grupos",
  "joinGroup": "Unirse al grupo",
  "leaveGroup": "Salir del grupo",
  "mandatoryGroup": "Grupo obligatorio",
  "noSearchResultsGroupsDescription": "¡Vaya! Parece que no pudimos encontrar grupos que coincidan con tu búsqueda.",
  "noGroupsResultsDescription": "¡Vaya! Parece que no pudimos encontrar grupos.",
  "groupMembers": "Miembros del grupo",
  "noGroupMembers": "¡Vaya! parece que aún no hay miembros en este grupo.",
  "cannotLeaveGroupTooltip": "Has sido añadido automáticamente a este grupo por un administrador. Si deseas salir, contacta a un administrador del grupo para obtener asistencia."
};
