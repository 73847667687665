export default {
  "please enter your company reference": "Bitte geben Sie Ihre Firmenreferenz ein",
  "enter user registration credentials": "Bitte geben Sie unten Ihre Daten ein",
  "enter email": "Bitte geben Sie Ihre E-Mail ein",
  "enter password": "Bitte gib dein Passwort ein",
  "repeat password": "Bitte wiederholen Sie Ihr Passwort",
  "registration successful": "Registrierung erfolgreich.",
  "registration successful instruction": "Ein zeitlich begrenzter Bestätigungslink wurde an die angegebene E-Mail gesendet. Bitte folgen Sie den Anweisungen in der E-Mail, um die Kontoeinrichtung abzuschließen. (Bitte überprüfen Sie Ihren Spam-Ordner, falls Sie die E-Mail nicht sehen können)",
  "errors": {
    "domain name can't be empty": "Domainname darf nicht leer sein",
    "domain does not exist": "Domain '{{domain}}' existiert nicht",
    "email can not be empty": "E-Mail darf nicht leer sein",
    "password can not be empty": "Passwort darf nicht leer sein",
    "custom fields not set": "Nicht alle Pflichtfelder sind ausgefüllt",
    "passwords dont match": "Passwörter stimmen nicht überein",
    "email is not available": "Die gewählte E-Mail ist nicht verfügbar",
    "email format is not valid": "E-Mail-Format ist nicht gültig",
    "password requirements": "Das Passwort muss 8 Zeichen, Zahlen, einen Groß- und einen Kleinbuchstaben enthalten",
    "User with this email already exists": "Es existiert bereits ein Benutzer mit den von Ihnen eingegebenen Daten",
    "Error loading security information from server": "Fehler beim Laden der Sicherheitsinformationen vom Server. Bitte laden Sie die Seite neu, um es erneut zu versuchen.",
    "Page doesnt have data parameters loaded": "Auf der Seite wurden keine Datenparameter geladen. Bitte versuchen Sie erneut, den E-Mail-Link zu öffnen."
  },
  "verify": "REGISTRIEREN",
  "back": "ZURÜCK"
};