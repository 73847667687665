import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { AxiosError, AxiosResponse } from "axios";
import connectService from "../../services/connectService";
import Scrollbar from "../common/scrollbar/scrollbar";
import SpinnerLoad from "../common/spinnerLoad/spinnerLoad";
import ContactPageList from "../connectPage/contactPage/contactPagelist";
import { Link } from "react-router-dom";
import { getAlphabets } from "./utils";
import "./connectPage.scss";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import ConnectFilter, {
  removeEmptyKeyValue,
  SearchFilterData,
} from "../common/connectFilter/ConnectFilter";
import { useSelector } from "react-redux";
import { isWhite } from "../../utils/colourCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faLocationDot } from "@fortawesome/pro-regular-svg-icons";
import { Grid } from "@mui/material";
import { PostTabDetailType } from "./contactPage/contactDetails";

type filterModelType = {
  search: { [key: string]: any },
  text_search: string
}

const initialFilterData = {
  search: {},
  text_search: ""
}

type ConnectProps = {
  addFavorite: string;
  removeFavorite: string;
  clickCallback: Function;
  updateFavCallback: Function;
  contactUserId: string;
  loggedInUserLocationId: string;
  selectedUserLocationId: string;
  setPostTabDetail: React.Dispatch<React.SetStateAction<PostTabDetailType>>;
};

const ConnectPage: FunctionComponent<ConnectProps> = ({
  contactUserId,
  loggedInUserLocationId,
  selectedUserLocationId,
  addFavorite,
  removeFavorite,
  clickCallback,
  updateFavCallback,
  setPostTabDetail,
}) => {
  const { t } = useTranslation();
  const tenantBranding = useSelector((state:any) => state.app.branding);
  const [contactData, setContactData] = useState<Array<any>>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [page, setCurrentPage] = useState<number>(1);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [favouritesData, setFavouritesData] = useState<Array<any>>([]);
  const [initialContactLoad, setInitialContactLoad] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [alphabetSearchTerm, setAlphabetSearchTerm] = useState<string>("");
  const [hideFavs, setHideFavs] = useState<boolean>(false);
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const [filterData, setFilterData] = useState<filterModelType>(initialFilterData);

  type Props = {
    loadOnMount: boolean;
    hasMoreData: boolean;
    isLoading: boolean;
    children: React.ReactNode;
  };

  const PageListComponent: React.FC<Props> = ({ loadOnMount, children }) => {
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (addFavorite != "") {
        updateFavourite(addFavorite, true);
      }
      if (removeFavorite != "") {
        updateFavourite(removeFavorite, false);
      }
      if (loadOnMount && initialLoad) {
        getContactFavouritesData();
        loadMoreUsersInListWithFilter(page, true, initialFilterData);
        setInitialLoad(false);
      }
    }, [loadOnMount, initialLoad, addFavorite, removeFavorite]);

    return <div ref={contentRef}>{children}</div>;
  };


  const loadMoreUsersInListWithFilter = (page:number, loadFirstPageData: boolean, data: any) => {
    setCurrentPage(page);
    setPageLoading(true);
    connectService.getFilteredContactData(
      page,
      data,
      (resp: AxiosResponse) => {
        if (initialContactLoad) setInitialContactLoad(false);
        if (loadFirstPageData) {
          setContactData([...resp.data.data]);
        } else {
          appendContactData(resp.data.data);
        }
        setHasMoreData(page < resp.data.meta.last_page);
        setPageLoading(false);
      },
      (e: AxiosError) => {
        setPageLoading(false);
      }
    );
  }

  const pageScroll = (e: {
    scrollTop: number;
    clientHeight: number;
    scrollHeight: number;
  }) => {
    if (
      !pageLoading &&
      hasMoreData &&
      Math.ceil(e.scrollTop + e.clientHeight) >= e.scrollHeight
    ) {
      if (selectedFilterCount === 0 && filterData.text_search === "") {
        loadMoreUsersInListWithFilter(page + 1, false, initialFilterData);
      } else {
        loadMoreUsersInListWithFilter(page + 1, false, filterData);
      }
    }
  };

  const updateFavourite = (id: string, favourite: boolean) => {
    let formData = new FormData();
    formData.append("is_favorite", favourite ? "1" : "0");
    connectService.updateFavouritesList(id, formData, () => {
      // If the user has been added as part of a search don't reset the list
      if (searchTerm === "" || !favourite) {
        getContactFavouritesData();
        initContactList();
        clickCallback();
        updateFavCallback(id, true);
      }
    });
  };

  const getContactFavouritesData = () => {
    connectService.getFavourites(
      (resp: AxiosResponse) => {
        setFavouritesData(resp.data.data);
      },
      () => {}
    );
  };

  const initContactList = () => {
    connectService.getMoreUsersData(1, (resp: AxiosResponse) => {
      initContactData(resp.data.data);
    });
  };

  const appendContactData = (newElem: Array<any>) => {
    setContactData([...contactData, ...newElem]);
  };

  const initContactData = (newElem: Array<any>) => {
    setContactData([...newElem]);
  };

  const renderContacts = (items: Array<any>, displayAlphabet:boolean = false) => {
    const renderData: Array<React.ReactElement> = [];
    if (items.length === 0 && !pageLoading) {
      return (
        <div className="d-flex justify-content-center">{ (searchTerm.length < 3 && searchTerm.length !== 0 && hideFavs)  ? t('connect.charsLimitMsg') : t('connect.noResultsFound')}</div>
      );
    }
    // Logic to display alphabet character
    let firstChar: string =  '';
    let prevChar: string =  '';
    items.forEach((item, index) => {
      if (index == 0) {
        firstChar = item.user.full_name.charAt(0).toUpperCase();
      }
      if (firstChar != item.user.full_name.charAt(0).toUpperCase()) {
        firstChar = item.user.full_name.charAt(0).toUpperCase();
      }
      renderData.push(
        <>
          {displayAlphabet && (firstChar != prevChar) && <h3 className="section-title mb-2">{firstChar}</h3>}
          <ContactPageList
            item={item.user}
            is_favourite={item.is_favorite}
            updateItem={updateFavourite}
            setPostTabDetail={setPostTabDetail}
            contactUserId={contactUserId}
          />
        </>
      );
      prevChar = firstChar;
    });
    return renderData;
  };

  const searchFilter = useDebouncedCallback(async (e: { target: { value: string } }) => {
    const keyword = e.target.value;
    setContactData([]);
    setHideFavs(true);
    if (keyword !== "" && keyword.length > 2) {
      setCurrentPage(1);
      let data = {
        search: {...filterData.search},
        text_search: keyword
      }
      setFilterData(data);
      loadMoreUsersInListWithFilter(1, true, data);
    } else {
      if (keyword === "") {
        setContactData([]);
        setCurrentPage(1);
        setHideFavs(false);
        getContactFavouritesData();
        let data = {
          search: {...filterData.search},
          text_search: keyword
        };
        setFilterData(data);
        loadMoreUsersInListWithFilter(1, true, selectedFilterCount > 0 ? data : initialFilterData);
        setSearchTerm("");
      }
    }
  });


  const alphabet = getAlphabets();

  const alphabetFilter = (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setContactData([]);
    setHideFavs(true);
    setPageLoading(true);
    const prevSearchTerm = alphabetSearchTerm;
    setAlphabetSearchTerm("");
    const el = e.target as HTMLInputElement;
    const selAlphabet = el.getAttribute("data-char");
    if (selAlphabet !== "" && selAlphabet + "_" !== prevSearchTerm) {
      setAlphabetSearchTerm(selAlphabet + "_");
      setCurrentPage(1);
      let data = {
        search: {...filterData.search},
        text_search: selAlphabet + "_"
      }
      setFilterData(data);
      loadMoreUsersInListWithFilter(1, true, data);
    } else {
      setHideFavs(false);
      setPageLoading(false);
      getContactFavouritesData();
      setCurrentPage(1);
      if (selectedFilterCount > 0) {
        let data = {
          search: {...filterData.search},
          text_search: ""
        }
        setFilterData(data);
        loadMoreUsersInListWithFilter(1, true, data);
      } else {
        setFilterData(initialFilterData);
        loadMoreUsersInListWithFilter(1, true, initialFilterData);
      }
    }
  };

  const handleFilterContactList = (filters: SearchFilterData, isClear: boolean = false) => {
    setSelectedFilterCount(Object.keys(filters).length);
    let filterModel = {
      ...filters,
      location:
        filters.location && filters.location.length > 0
          ? filters.location.map((loc) => loc.id)
          : [],
      badge:
        filters.badge && filters.badge.length > 0
          ? filters.badge.map((b) => b.id)
          : [],
      manager:
        filters.manager && filters.manager.length > 0
          ? filters.manager.map((m) => m.id)
          : [],
    };

    // API call to filter contact list
    setPageLoading(true);
    let data = {
      search: removeEmptyKeyValue(filterModel),
      text_search: filterData.text_search
    };
    let page = 1;
    setFilterData(data);
    setCurrentPage(page);
    if (!isClear) {
      loadMoreUsersInListWithFilter(page, true, data);
    } else {
      loadMoreUsersInListWithFilter(page, true, {
        search: {},
        text_search: filterData.text_search
      });
    }
  };

  const isHeaderWhite = isWhite(tenantBranding.web_navbar_background.toLowerCase());
  const bgColor = isHeaderWhite ? tenantBranding.web_navbar_foreground : tenantBranding.web_navbar_background;
  const fgColor = isHeaderWhite ? tenantBranding.web_navbar_background : tenantBranding.web_navbar_foreground;

  return (
    <div className={"connect-container"}>
      <div className="topnav">
        <ul className="nav">
          <a 
            href="" 
            className="active-contact-tab contacts-tab w-50 mb0 font-18" style={{
              backgroundColor: bgColor,
              color: fgColor
            }}
            
          >
            <FontAwesomeIcon icon={faAddressBook} className="me-2 contact-icon" />
            {t("connect.contacts")}
          </a>
          <Link
            to={`/location/${
              loggedInUserLocationId
                ? loggedInUserLocationId
                : selectedUserLocationId
            }`}
            className="w-50 mb0 font-18 inactive-location-tab d-flex align-items-center justify-content-center"
            
          >
            <FontAwesomeIcon icon={faLocationDot} className="me-2 location-icon" />
            {t("connect.locations")}
          </Link>
        </ul>
      </div>
      <div className="pb-3 ps-3 pt-3">
        <Grid container spacing={2} className="w-100 connect-search-filter-grid-container">
          <Grid item xs={12} className="d-flex connect-search-filter-grid align-items-center justify-content-between">
            <div className="connect-search-section d-flex align-items-center">
              <p className="fa-regular form-control connect-search-icon fa-magnifying-glass m-0"></p>
              <input
                  type="text"
                  className="connect-search-input"
                  placeholder={`${t("connect.searchContacts")}`}
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    searchFilter(e);
                  }}
              />
            </div>
            <ConnectFilter handleFilterContactList={handleFilterContactList} />
          </Grid>
        </Grid>
      </div>
      <div className={"d-flex justify-content-center listing-scroll"}>
        <Scrollbar
          onScroll={pageScroll}
          rightScroll={true}
          className="me-2"
          noScrollX
        >
          <PageListComponent
            hasMoreData={hasMoreData}
            isLoading={pageLoading}
            loadOnMount={true}
          >
            <Grid container spacing={2} className="mb-20 mt-20" columnSpacing={{ xl: 1 }}>
              {!hideFavs && favouritesData.length != 0 && (
                <Grid item xs={12}>
                  <div className="fav-section">
                    <h3 className="mt0 ml-sm favorite-title">
                      {t("connect.favourites")}
                    </h3>
                    {renderContacts(favouritesData)}
                  </div>
                </Grid>
              )}
              {(!initialContactLoad && contactData.length > 0) && (
                <>
                  <Grid item xs={11} className="all-contacts">
                    <div className="contacts w-100">
                      {!hideFavs && favouritesData.length != 0 && (<h3 className="all-contact-title mt-4 ml-sm">{t("connect.allContacts")}</h3>)}
                        {renderContacts(contactData, true)}
                    </div>
                  </Grid>
                  <Grid item xs={1} style={{ position: "sticky", top: 0, paddingTop: (!hideFavs && favouritesData.length != 0) ? "16px" : "0px" }}>
                    <div className="list sticky-top w-5 mr d-flex flex-direction-column search-list mt0 text-secondary overflow-hidden">
                        {alphabet.map((item, index) => {
                          return (
                            <span
                              data-char={item}
                              key={index}
                              onClick={alphabetFilter}
                              className={`${
                                alphabetSearchTerm == item + "_" ? "fw-bold" : ""
                              } list-char-item cursor-pointer text-center d-inline-block`}
                              id={item}
                            >
                              {item}
                            </span>
                          );
                        })}
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            {pageLoading ? (
              <SpinnerLoad
                className="h-100 w-100 d-flex align-items-center justify-content-center mt-3"
                size={50}
              />
            ) : (
              ""
            )}
          </PageListComponent>
        </Scrollbar>
      </div>
    </div>
  );
};

export default ConnectPage;
