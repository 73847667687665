import React, { FunctionComponent, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Form, FormGroup, Input, Button, Container } from "reactstrap";
import { InputType } from "reactstrap/types/lib/Input";
import { addNotification } from "../../../../shared/reducers/notifications/actionTypes";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import configService from "../../../services/configService";

import { LoginConfigs, LoginFormModel } from "../../../../types/types";
type LoginUserScreenProps = {
  loginData: LoginConfigs;
  setForm: Function;
  setNameAndPassword: Function;
  userLoginErrors: string[];
  loginAuthTenent: Function;
  isDisabled: boolean;
};

const LoginUserScreen: FunctionComponent<LoginUserScreenProps> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const matchParams = useParams();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [errorsList, setErrorsList] = useState<string[]>([]);
  const [passwordInputType, setPasswordInputType] =
    useState<InputType>("password");

  const defaultStyle = {
    hoverColor: "#234352",
    foregroundLogo: "/assets/img/logo.png",
    welcomeColor: "#000000",
    buttonColor: "#15385b",
    buttonText: "#ffffff",
    forgotLinkColor: "#15385b",
    newUserBtn: "#fff",
  };

  useEffect(() => {
    const root = document.documentElement;
    root?.style.setProperty("--text-color", props.loginData.placeholder_colour);
    root?.style.setProperty(
      "--btn-hover-color",
      props.loginData.button_colour_hover?.length > 0
        ? props.loginData.button_colour_hover
        : defaultStyle.hoverColor
    );
    
    if (!userName || userName === "") {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.has("user")) {
        setUserName(urlParams.get("user") || "");
        
      }
    }
  }, []);
  useEffect(() => {
    setErrorsList(props.userLoginErrors);
  }, [props.userLoginErrors]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.currentTarget.value.trim();
    switch (e.currentTarget.name) {
      case "username":
        setUserName(value);
        break;
      case "password":
        setUserPassword(value);
        break;
    }
  };

  const verifyForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await props.loginAuthTenent();

    let errors: string[] = [];
    if (userName === "") {
      errors.push(t("login.errors.user name can't be empty"));
    }
    if (userPassword === "") {
      errors.push(t("login.errors.password can't be empty"));
    }
    if (errors.length !== 0) {
      setErrorsList(errors);
      return;
    }
    setErrorsList([]);

    setTimeout(() => {
      props.setNameAndPassword(new LoginFormModel(userName, userPassword));
    }, 1000);
  };

  const moveToRegistration = () => {
    if (!matchParams || !matchParams.tenantRef) {
      dispatch(
        addNotification({
          label: ``,
          text: "Can't find tenant ref in match params",
          type: "danger",
        })
      );
      return;
    }
    
    window.location.href = config(
      "server.self_reg_url",
      `/${matchParams.tenantRef}/registration`
    );
  };

  const config = (key: string, def: any) => {
    return configService.getFromData(props.loginData, key, def);
  };

  const handleShowHidePassword = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    event.preventDefault();
    setPasswordInputType(
      passwordInputType === "password" ? "text" : "password"
    );
  };

  const leftSideImg =
    props.loginData.background_logo.length > 0 ? (
      <img
        src={props.loginData.background_logo}
        alt="Image"
        className={"block-center left-img w-100"}
      />
    ) : (
      <img
        src={config("foreground_logo", defaultStyle.foregroundLogo)}
        alt="Image"
        className={"block-center left-logo-img"}
      />
    );

  const moveToNewUser = () => {
    if (!matchParams || !matchParams.tenantRef) {
      dispatch(
        addNotification({
          label: ``,
          text: "Can't find tenant ref in match params",
          type: "danger",
        })
      );
      return;
    }
    window.location.href = config(
      "server.new_user_url",
      `/${matchParams.tenantRef}/setup/new-user`
    );
  };

  return (
    <Container
      data-testid="login-user-screen"
      fluid
      className={`main-container p-0 ${
        props.loginData.background_logo.length == 0 ? "h-100 d-flex" : ""
      }`}
    >
      <div
        className={`row login-row h-100 m-0 d-flex ${
          props.loginData.background_logo.length == 0
            ? "position-absolute top-0 left-0 right-0 bottom-0"
            : ""
        }`}
        style={{ zIndex: 0 }}
      >
        <div
          className={`col-lg-8 col-md-7 col-sm-0 p-0 left-section ${
            props.loginData.background_logo.length == 0
              ? "align-items-center"
              : ""
          }`}
        >
          {leftSideImg}
        </div>
        <div data-testid="login-right-section" className={`col-lg-4 col-md-5 col-sm-12 p-0 d-flex flex-column justify-content-center align-items-center right-section ${props.isDisabled ? "disable-right-panel" : ""}`}
             style={{ backgroundColor: props.loginData.background_colour }}>
          <div className="container content">
            <div
              className={`d-flex justify-content-center ${
                !props.loginData.background_logo.length ? "logo-wrapper" : ""
              }`}
            >
              <img
                src={config("foreground_logo", defaultStyle.foregroundLogo)}
                alt="Image"
                className={"block-center mb-3 h-100 login-logo"}
              />
            </div>
            {props.loginData.welcome_text && (
              <h1
                className="hello-title"
                style={{
                  color: config("welcome_colour", defaultStyle.welcomeColor),
                }}
              >
                {config("welcome_text", `${t("login.hello")}!`)}
              </h1>
            )}
            <Form className={"card-body p-0"} onSubmit={verifyForm}>
              {errorsList && errorsList.length > 0 && (
                <div
                  data-testid="error-msg"
                  className={"alert-warning rounded px-3 py-2 mb-3"}
                >
                  {errorsList.map((error) => (
                    <p key={error} className={"font-weight-bold mb-0"}>
                      {error}
                    </p>
                  ))}
                </div>
              )}

              <FormGroup>
                {props.loginData.username_label != "" ? (
                  <label
                    style={{
                      color: config("label_colour", defaultStyle.welcomeColor),
                    }}
                    className={"d-block text-left"}
                  >
                    {config(
                      "username_label",
                      `${t("login.please enter your user ID and password")}`
                    )}
                  </label>
                ) : (
                  ""
                )}
                <Input
                  name="username"
                  data-testid="username"
                  className="mt-2 placeholder-font"
                  placeholder={config(
                    "username_field_placeholder",
                    t("login.please enter user id")
                  )}
                  value={userName}
                  onChange={handleInput}
                  autoFocus={true}
                  style={{
                    color: props.loginData.textbox_foreground_colour,
                    backgroundColor: props.loginData.textbox_background_colour,
                    borderColor: props.loginData.textbox_border_colour,
                  }}
                />
              </FormGroup>

              <FormGroup>
                {props.loginData.password_label && (
                  <label
                    style={{
                      color: config("label_colour", defaultStyle.welcomeColor),
                    }}
                    className={"d-block text-left"}
                  >
                    {config("password_label", "")}
                  </label>
                )}
                <div className="mt-2 d-flex justify-content-space-between align-items-center position-relative">
                  <Input
                    data-testid="password-field"
                    name="password"
                    type={passwordInputType}
                    placeholder={config(
                      "password_field_placeholder",
                      t("login.enter password")
                    )}
                    value={userPassword}
                    onChange={handleInput}
                    className="input-password placeholder-font"
                    style={{
                      color: props.loginData.textbox_foreground_colour,
                      backgroundColor:
                        props.loginData.textbox_background_colour,
                      borderColor: props.loginData.textbox_border_colour,
                    }}
                  />
                  <i
                    data-testid="eye-button"
                    className={`fa fa-eye${
                      passwordInputType === "password" ? "-slash" : ""
                    } position-absolute eye-button mb-1`}
                    style={{ cursor: "pointer", color: props.loginData.textbox_foreground_colour }}
                    onClick={handleShowHidePassword}
                  ></i>
                </div>
              </FormGroup>
              <FormGroup>
                <div className="w-100 d-flex justify-content-end mt-2 mb-1">
                  <a
                    href={props.loginData.forgot_password_link}
                    style={{
                      color:
                        `${props.loginData.forgot_password_link_colour}` ||
                        defaultStyle.forgotLinkColor,
                    }}
                  >
                    {t("login.forgot password")}?
                  </a>
                </div>
              </FormGroup>
              <Button
                data-testid="login-btn"
                className={"login-button login-buttons my-3 w-100"}
                style={{
                  backgroundColor: config(
                    "button_background_colour",
                    defaultStyle.buttonColor
                  ),
                  color: config("button_foreground_colour", defaultStyle.buttonText)
                }}
                type="submit"
              >
                {props.isDisabled ? <span data-testid="spinner" className="spinner-border spinner-border-sm me-2"></span> : null}
                {config("app.login.verifyLabel", t("login.log in"))}
              </Button>
              <div className="d-flex justify-content-center position-relative">
                <div className="line mt-2 mb-4"
                     style={{
                       backgroundColor: config("label_colour", defaultStyle.buttonColor)}}></div>
                <div className="or-keyword position-absolute" style={{
                  color: config("label_colour", defaultStyle.welcomeColor),
                  backgroundColor: props.loginData.background_colour
                }}>OR</div>
              </div>
              <Button
                data-testid="new-user-button"
                className={"new-user-button login-buttons mb-3 w-100"}
                style={{
                  color: config(
                    "new_user_text_colour",
                    defaultStyle.newUserBtn
                  ),
                  backgroundColor: config(
                    "button_background_colour",
                    defaultStyle.buttonColor
                  ),
                }}
                onClick={moveToNewUser}
              >
                <FontAwesomeIcon
                  icon={["fal", "arrow-left"]}
                  className={"me-2"}
                />
                {config("new_user_button_text", t("login.new user"))}
              </Button>
              {props.loginData.is_registration_enabled && (
                <Button
                  data-testid="create-new-account"
                  className={"new-user-button login-buttons mb-3 w-100"}
                  style={{
                    backgroundColor: config(
                      "button_background_colour",
                      defaultStyle.buttonColor
                    ),
                    color: config("button_foreground_colour", defaultStyle.buttonText)
                  }}
                  onClick={moveToRegistration}
                >
                  {t("login.create new account")}
                </Button>
              )}
            </Form>
            <div className="contact-support-link w-100 d-flex justify-content-center">
              <a
                href="#"
                data-testid="contact-support"
                style={{ color: props.loginData.contact_support_link_colour }}
                className="contact-support-link text-decoration-none d-flex"
                onClick={() => {
                  window.location.href = `mailto:${props.loginData.support_email}`;
                }}
              >
                <i
                  style={{ fontStyle: "normal" }}
                  className="fa-regular fa-circle-info me-2"
                ></i>
                {t("login.contact support")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LoginUserScreen;
