export default {
  "please enter your company reference": "Voer uw bedrijfsreferentie in",
  "enter user registration credentials": "Vul hieronder je gegevens in",
  "enter email": "Voer uw e-mailadres in",
  "enter password": "Voer uw wachtwoord in",
  "repeat password": "Herhaal alstublieft uw wachtwoord",
  "registration successful": "De registratie is geslaagd.",
  "registration successful instruction": "Er is een bevestigingslink voor een beperkte tijd naar de opgegeven e-mail gestuurd. Volg de instructies in de e-mail om de accountconfiguratie te voltooien. (Controleer uw spam-map als u de e-mail niet kunt zien)",
  "errors": {
    "domain name can't be empty": "Domeinnaam mag niet leeg zijn",
    "domain does not exist": "Domein '{{domain}}' bestaat niet",
    "email can not be empty": "E-mailadres mag niet leeg zijn",
    "password can not be empty": "Wachtwoord mag niet leeg zijn",
    "custom fields not set": "Niet alle verplichte velden zijn ingevuld",
    "passwords dont match": "Wachtwoorden komen niet overeen",
    "email is not available": "De geselecteerde e-mail is niet beschikbaar",
    "email format is not valid": "E-mailformaat is niet geldig",
    "password requirements": "Het wachtwoord moet 8 tekens, cijfers, een hoofdletter en een kleine letter bevatten",
    "User with this email already exists": "Er bestaat al een gebruiker met de gegevens die je hebt ingevoerd",
    "Error loading security information from server": "Fout bij het laden van beveiligingsinformatie vanaf de server. Laad de pagina opnieuw om het opnieuw te proberen.",
    "Page doesnt have data parameters loaded": "De gegevensparameters zijn niet geladen op de pagina. Probeer de e-maillink opnieuw te openen."
  },
  "verify": "REGISTREREN",
  "back": "TERUG"
};