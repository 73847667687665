import React from 'react';
import { Box, Skeleton, SxProps, Typography, Button, Tooltip } from '@mui/material';
import { IconText } from '../iconText/IconText';
import { useSelector } from 'react-redux';
import { isWhite } from '../../utils/colourCheck';
import { useTranslation } from 'react-i18next';

export type GroupCardProps = {
    loading: boolean;
    img: string;
    title: string;
    description: string;
    members: number;
    is_member: boolean;
    containerSx?: SxProps;
    handleClick?: (id: number) => void;
    id?: number;
    onMembersClick: (id: number) => void;
    onJoinOrLeaveGroupClick: (id: number, is_member: boolean) => void;
    can_leave: boolean;
};

export const GroupCard = (props: GroupCardProps) => {
    const { loading, containerSx, img, title, description, members, is_member, handleClick, id, onMembersClick, onJoinOrLeaveGroupClick, can_leave } =
        props;
    const tenantBranding = useSelector((state: any) => state.app.branding);
    const highlightColor = isWhite(tenantBranding.web_navbar_background.toLowerCase())
        ? tenantBranding.web_navbar_foreground
        : tenantBranding.web_navbar_background;
    const { t } = useTranslation();

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    background: '#FFFFFF',
                    boxShadow: '0px 5px 5px 0px #00000026',
                    padding: '10px',
                    gap: '5px',
                }}
                data-testid='group-card-loading'
            >
                <Box
                    sx={{
                        display: 'flex',
                        gap: '5px',
                    }}
                >
                    <Skeleton variant='rectangular' width={100} height={90} />
                    <Box>
                        <Skeleton variant='text' width={250} height={20} />
                        <Skeleton variant='text' width={150} height={20} />
                        <Skeleton variant='rectangular' width={250} height={40} sx={{ marginTop: '10px' }} />
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', gap: '5px' }}>
                    <Skeleton variant='rectangular' width={300} height={20} />
                    <Skeleton variant='rectangular' width={50} height={20} />
                </Box>
            </Box>
        );
    }

    return (
        <Box
            sx={{
                height: '165px',
                borderRadius: '12px',
                background: '#FFFFFF',
                boxShadow: '0px 5px 5px 0px #00000026',
                padding: '10px',
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                cursor: 'pointer',
                width: '100%',
                ...containerSx,
            }}
            data-testid='group-card'
            onClick={(e) => {
                e.stopPropagation();
                handleClick && handleClick(id as number);
            }}
        >
            <Box
                sx={{
                    height: '100px',
                    display: 'flex',
                    gap: '5px',
                }}
            >
                <Box sx={{ aspectRatio: '16/9' }}>
                    <img src={img} alt='group' style={{ width: '100%', height: '100%', borderRadius: '12px', objectFit: 'cover' }} />
                </Box>
                <Box
                    sx={{
                        padding: '0 10px',
                        maxHeight: '100px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        overflow: 'hidden',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '18px',
                            fontWeight: 600,
                            color: '#000000',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {title}
                    </Typography>
                    <IconText
                        icon='fa-light fa-users'
                        text={members.toString()}
                        iconStyle={{
                            fontWeight: '300 !important',
                        }}
                        IconTextContainerSx={{
                            width: 'fit-content',
                            ':hover': {
                                borderBottom: '0.5px solid #000000',
                            },
                        }}
                        handleClick={(e) => {
                            e.stopPropagation();
                            onMembersClick(id as number);
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#000000',
                            display: '-webkit-box',
                            overflow: 'hidden',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                        }}
                    >
                        {description}
                    </Typography>
                </Box>
            </Box>
            <Tooltip title={!can_leave && is_member && t('connect.cannotLeaveGroupTooltip')}>
                <Box
                    sx={{
                        display: 'flex',
                        gap: '10px',
                    }}
                >
                    <Button
                        variant='contained'
                        disableTouchRipple
                        disableRipple
                        disableFocusRipple
                        disabled={is_member && !can_leave}
                        sx={{
                            backgroundColor: is_member ? '#E9E9E9' : highlightColor,
                            color: is_member ? '#000' : tenantBranding.web_navbar_foreground,
                            flexGrow: 1,
                            '&:hover': {
                                backgroundColor: is_member ? '#E9E9E9' : highlightColor,
                            },
                            boxShadow: 'none',
                            textTransform: 'none',
                            borderRadius: '8px',
                            fontWeight: 600,
                        }}
                        onClick={(e) => {
                            e.stopPropagation();
                            onJoinOrLeaveGroupClick(id as number, is_member);
                        }}
                    >
                        <span
                            className={
                                is_member && !can_leave ? 'fa-solid fa-lock' : is_member ? 'fa-solid fa-person-from-portal' : 'fa-solid fa-user-plus'
                            }
                            style={{
                                marginRight: '10px',
                                fontSize: is_member ? '1.2em' : 'inherit',
                            }}
                        />
                        <span
                            style={{
                                marginTop: '3px',
                            }}
                        >
                            {is_member ? (!can_leave ? t('connect.mandatoryGroup') : t('connect.leaveGroup')) : t('connect.joinGroup')}
                        </span>
                    </Button>
                </Box>
            </Tooltip>
        </Box>
    );
};
