export default {
  "inbox": "Boîte de réception",
  "searchChats": "Rechercher des chats",
  "searchArchivedChats": "Rechercher des discussions archivées",
  "searchContacts": "Rechercher des contacts",
  "description": "Descriptif",
  "chatInfo": "Informations sur le chat",
  "created": "Créé",
  "newChat": "Nouveau chat",
  "createChatPlaceholder": "Créez un groupe ou sélectionnez un contact à qui envoyer un message.",
  "allContacts": "Tous les contacts",
  "newUserChatPlaceholder": "Vous n'avez jamais envoyé de message à {{DynamicValue}} auparavant, commencez à taper pour commencer.",
  "archived": "Archivé",
  "chatSettings": "Paramètres du chat",
  "showArchivedChats": "Afficher les discussions archivées",
  "participants": "Les participants",
  "searchParticipants": "Rechercher des participants",
  "muteMsg": "Les notifications sont désactivées pour ce fil car il est public",
  "muteByAdminMsg": "Les notifications sont désactivées pour ce fil car il est désactivé par un administrateur",
  "prefixMuteMsg": "Le fil est mis en sourdine jusqu'à",
  "startTyping": "Commencez à taper",
  "muteNotifications": "Désactiver les notifications",
  "newGroupChat": "Ajouter des participants au groupe",
  "newGroup": "Nouveau groupe",
  "newGroupPlaceholder": "Veuillez sélectionner les participants pour votre discussion de groupe",
  "groupSettings": "Paramètres du groupe",
  "uploadGroupIcon": "Icône de téléchargement du groupe",
  "groupNamePlaceholder": "Entrez le nom du groupe",
  "groupDescriptionPlaceholder": "Donnez une description de votre groupe",
  "updateGroup": "Mettre à jour le groupe",
  "leaveGroup": "Quitter le groupe",
  "leaveGroupErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande de groupe de congés. Veuillez réessayer.",
  "leaveGroupModalTitle": "Êtes-vous sûr de vouloir quitter ce groupe ?",
  "leaveGroupModalContent": "En quittant ce groupe, vous ne pourrez pas le rejoindre sans invitation.",
  "manageParticipants": "Gérer les participants",
  "addParticipant": "Ajouter un participant",
  "removeParticipant": "Supprimer un participant",
  "addParticipantErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande d'ajout de participant. Réessayez, s'il vous plaît.",
  "removeParticipantErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande de suppression d'un participant. Réessayez, s'il vous plaît.",
  "manage": "Gérez",
  "sendMessage": "Envoyer un message",
  "failedToSendMessage": "Impossible d'envoyer le message.",
  "admins": "Administrateurs",
  "manageAdmins": "Gérer les administrateurs",
  "addAdmin": "Ajouter un administrateur",
  "removeAdmin": "Supprimer l'administrateur",
  "addAdminErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande d'ajout d'administrateur. Veuillez réessayer.",
  "removeAdminErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande de suppression de l'administrateur. Réessayez, s'il vous plaît.",
  "mute": "Muet",
  "archive": "Archive",
  "delete": "Supprimer",
  "unarchive": "Désarchiver",
  "unmute": "Désactiver le son",
  "archiveErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande d'archivage. Veuillez réessayer.",
  "unArchiveErrorMsg": "Une erreur s'est produite lors de l'envoi de la demande de désarchivage. Réessayez, s'il vous plaît.",
  "userDeletedMessage": "Vous avez supprimé ce message.",
  "deletedMessage": "Ce message a été supprimé.",
  "deletedMessageThread": "Message supprimé."
};