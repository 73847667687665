import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const sectionProvider = {
    likePage(pageId: number | string, isLiked: boolean, success: Function, error?: Function) {
        Client().request({
            url: `${process.env.REACT_APP_CMS_API_URL}/json/page/${pageId}/like`,
            method: 'post',
            data: { is_liked: isLiked === true ? 1 : 0 }
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default sectionProvider;