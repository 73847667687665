import { useSelector } from 'react-redux';
import webPNService from "../../../../web/services/webPNService";

type PnServiceProps = {
    permission: string
}

const PnServiceWorker: any = ({ permission }:PnServiceProps) => {
    const tenantInfo = useSelector((state: any) => state.app.tenant);

    const register = () => {
        if ("serviceWorker" in navigator && "PushManager" in window && "Notification" in window && tenantInfo.webPNStatus === '1') {
            //register the service worker
            navigator.serviceWorker.register('../../../../sw.js')
                .then(() => {
                    initPush();
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            //service worker isn't supported, don't use it here if you use service worker for other stuff.
            navigator.serviceWorker.getRegistrations().then( function(registrations) {
                if (registrations[0]) {
                    registrations[0].unregister();
                }
            });
            return;
        }
    }

    const initPush = () => {
        if (!navigator.serviceWorker.ready) {
            return;
        }


        if (permission.length > 0) {
            if (permission !== 'granted') {
                throw new Error('We weren\'t granted permission.');
            }
            subscribeUser();
        }
    }

    const subscribeUser = () => {
        navigator.serviceWorker.ready
            .then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(
                        'BCT69pRI7PPo1YRZmOUsVDChix1esBJfPRJ8ZEr2z82yOOHcr4_fTcRCsmq5JbBzj_MzoQzIzeh-yvFboXleazg'
                    )
                };
                return registration.pushManager.subscribe(subscribeOptions);
            })
            .then((pushSubscription) => {
                storePushSubscription(pushSubscription);
            });
    }

    const urlBase64ToUint8Array = (base64String: string) => {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);

        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }

        return outputArray;
    }

    const storePushSubscription = (pushSubscription: PushSubscription) => {
        webPNService.sendPush(pushSubscription, (res: any) => {
            return res;
        }, (err: any) => {
            console.log(err);
        });
    }

    register();

    return null;
}

export default PnServiceWorker;