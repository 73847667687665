export default {
  "award": "{{text, en-handle-an}}",
  "awards": "Onderscheidingen",
  "send": "Verstuur {{text, en-handle-an}}",
  "all": "Alles",
  "inbox": "Voor mij",
  "sent": "Verzonden",
  "team": "team",
  "choose": "Kies",
  "toggleManager": "Manager-berichten in- en uitschakelen",
  "manager": "Manager",
  "received": "ontvangen {{text, en-handle-an}}",
  "from": "uit",
  "message": "Bericht",
  "managerMessage": "Bericht aan manager",
  "setVisibility": "Zichtbaarheid instellen",
  "showInFeed": "Wordt weergegeven in de feed",
  "onlyRecipients": "Alleen gezien door ontvangers",
  "recipients": "Ontvangers",
  "for": "voor",
  "messagePlaceholder": "Typ hier je bericht",
  "thankYou": "Bedankt"
};