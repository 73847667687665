import { useInfiniteQuery } from 'react-query';
import Client from '../../services/axiosService';

/**
 * @description: Fetches search results from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchSearchResults = async (searchQuery: string | null, page: number) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/pages/search?term=${searchQuery}&page=${page}`,
        method: 'get',
    })
  
    return response.data;
}

/**
 * @description: Fetches search results from the API and returns the data
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useSearchQuery = (searchQuery: string | null) => {
    return useInfiniteQuery(
        ['search', searchQuery],
        ({ pageParam = 1 }) => fetchSearchResults(searchQuery, pageParam),
        {
            getNextPageParam: (lastPage, pages) => {
                if (lastPage?.links?.next) return pages.length + 1;
                return undefined;
            },
            refetchOnWindowFocus: false,
        }
    );
}