import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import "./awardsModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";

interface props {
  isOpen: any;
  setIsOpen?: any;
  awards: any;
  className?: string;
  renderModalData: any;
  username?: string | undefined;
}

const AwardsModalComponent: FunctionComponent<props> = ({
  isOpen,
  awards,
  setIsOpen,
  renderModalData,
  className,
  username,
}) => {

  const { t } = useTranslation();

  const setVisibility = () => {
    setIsOpen(false);
  };

  const escHandle = useCallback((event: { keyCode: number; }) => {
    if (event.keyCode === 27) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escHandle, false);
    return () => {
      document.addEventListener("keydown", escHandle, false);
    };
  }, []);

  return isOpen ? (
    <Modal data-testid="awards-modal" isOpen={isOpen} className={classnames("files-list-modal")}>
      <ModalHeader className="w-100 d-flex px-4 align-items-center justify-content-between">
        <h5 data-testid="awards-modal-title" className="awards-modal-title m-0 text-black">
          {username} {t("awards.awards")}
        </h5>
        <button
          data-testid="awards-modal-close-button"
          type="button"
          className="close close-awards-list m-0 p-0"
          data-dismiss="modal"
          aria-label="Close"
          id="close-awards-list"
          onClick={setVisibility}
        >
          <FontAwesomeIcon icon={faXmark} className="text-black" />
        </button>
      </ModalHeader>

      <div className="px-4 pt-1">
        <div className="award-modal-search-section d-flex align-items-center">
          <p className="fa-regular form-control award-modal-search-icon fa-magnifying-glass m-0"></p>
          <input
            type="text"
            data-testid="awards-modal-search-field"
            className="awards-modal-search-input"
            placeholder={`Search awards`}
          />
        </div>
      </div>
      <div
        className="slimScrollDiv ps-4"
        style={{
          position: "relative",
          overflow: "auto",
          width: "680px",
          height: "400px",
          marginBottom: "24px",
          marginTop: "20px",
          paddingRight: "0px",
        }}
      >
        <div
          className="modal-body w-100"
          style={{ width: "680px", height: "400px" }}
        >
          {renderModalData()}
        </div>
      </div>
    </Modal>
  ) : null;
};

export default AwardsModalComponent;
