export default {
  "repliedToYourComment": "Respondeu ao seu comentário",
  "likedYourComment": "Gostou do seu comentário",
  "sentYou": "Enviou-lhe {{text, en-handle-an}}",
  "received": "Recebido/a(s) {{text, en-handle-an}}",
  "sendEmail": "Email",
  "sendMessage": "Mensagem",
  "createThread": "Criar Nova Conversa",
  "attachments": "Anexos",
  "done": "Concluído",
  "expand": "Expandir",
  "hide": "Ocultar",
  "showMoreComments": "Mostrar Mais Comentários",
  "hideComments": "Ocultar Comentários",
  "showComments": "Mostrar Comentários",
  "hideReplies": "Ocultar Respostas",
  "showReplies": "Mostrar Respostas",
  "deleteComment": "Eliminar Comentário",
  "deleteCommentDesc": "Tem a certeza de que quer eliminar este comentário?",
  "noCommentsYet": "Ainda não há comentários...",
  "chooseAction": "Selecione uma ação...",
  "deletePost": "Eliminar",
  "deletePostDesc": "Tem a certeza de que quer eliminar esta publicação?",
  "unfollow": "Deixar de seguir",
  "snooze": "Adiar...",
  "reportPost": "Denunciar publicação",
  "report": "Denunciar",
  "reportReason": "Motivo de denúncia da publicação:",
  "messageToManager": "Mensagem ao gestor",
  "delete": "Apagar",
  "emptyAwardsMessage": "Não há nada para mostrar aqui agora, pois você ainda não recebeu nada!",
  "Followers": "Seguidoras",
  "Following": "Seguindo",
  "SendAThankYou": "Envie um {{label}}",
  "SeeAll": "Ver tudo",
  "SentToYou": "Enviado para você",
  "CreateASocialPost": "Criar uma postagem social",
  "Photo": "Foto",
  "Video": "Video",
  "Files": "Arquivos",
  "Post": "Publicar",
  "LatestComments": "Últimos Comentários",
  "SentBy": "Enviado Por",
  "AllPosts": "Tudo",
  "MyGroups": "Os Meus Grupos",
  "Search": "Pesquisa...",
  "SpecificGroup": "Grupo Específico",
  "selectGroupToPost": "Selecione um grupo para postar...",
  "cannotPostAlert": "Parece que você não tem permissão para postar neste grupo. Por favor, selecione outro grupo."
};