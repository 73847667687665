export default {
  "inbox": "Posteingang",
  "searchChats": "Chats durchsuchen",
  "searchArchivedChats": "Archivierte Chats durchsuchen",
  "searchContacts": "Kontakte suchen",
  "description": "Beschreibung",
  "chatInfo": "Informationen zum Chat",
  "created": "Erstellt",
  "newChat": "Neuer Chat",
  "createChatPlaceholder": "Bitte erstellen Sie eine Gruppe oder wählen Sie einen Kontakt aus, an den Sie eine Nachricht senden möchten.",
  "allContacts": "Alle Kontakte",
  "newUserChatPlaceholder": "Sie haben {{dynamicValue}} noch nie eine Nachricht gesendet. Beginnen Sie mit der Eingabe, um loszulegen.",
  "archived": "Archiviert",
  "chatSettings": "Chat-Einstellungen",
  "showArchivedChats": "Archivierte Chats anzeigen",
  "participants": "Teilnehmer",
  "searchParticipants": "Teilnehmer suchen",
  "muteMsg": "Benachrichtigungen sind für diesen Thread deaktiviert, da er öffentlich ist",
  "muteByAdminMsg": "Benachrichtigungen sind für diesen Thread deaktiviert, da er von einem Admin stummgeschaltet wurde",
  "prefixMuteMsg": "Der Thread ist stummgeschaltet bis",
  "startTyping": "Beginne zu tippen",
  "muteNotifications": "Benachrichtigungen stummschalten",
  "newGroupChat": "Gruppenteilnehmer hinzufügen",
  "newGroup": "Neue Gruppe",
  "newGroupPlaceholder": "Bitte wähle Teilnehmer für deinen Gruppenchat aus",
  "groupSettings": "Einstellungen für Gruppen",
  "uploadGroupIcon": "Gruppensymbol hochladen",
  "groupNamePlaceholder": "Gruppennamen eingeben",
  "groupDescriptionPlaceholder": "Beschreiben Sie Ihre Gruppe",
  "updateGroup": "Gruppe aktualisieren",
  "leaveGroup": "Gruppe verlassen",
  "leaveGroupErrorMsg": "Beim Senden der Anfrage zum Verlassen der Gruppe ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "leaveGroupModalTitle": "Bist du sicher, dass du diese Gruppe verlassen willst?",
  "leaveGroupModalContent": "Wenn Sie diese Gruppe verlassen, können Sie ohne Einladung nicht wieder beitreten.",
  "manageParticipants": "Teilnehmer verwalten",
  "addParticipant": "Teilnehmer hinzufügen",
  "removeParticipant": "Teilnehmer entfernen",
  "addParticipantErrorMsg": "Beim Senden der Anfrage zum Hinzufügen eines Teilnehmers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "removeParticipantErrorMsg": "Beim Senden der Anfrage zum Entfernen eines Teilnehmers ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "manage": "Managen",
  "sendMessage": "Nachricht senden",
  "failedToSendMessage": "Nachricht konnte nicht gesendet werden.",
  "admins": "Admins",
  "manageAdmins": "Admins verwalten",
  "addAdmin": "Admin hinzufügen",
  "removeAdmin": "Admin entfernen",
  "addAdminErrorMsg": "Beim Senden der Admin-Anfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "removeAdminErrorMsg": "Beim Senden der Admin-Anfrage zum Entfernen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "mute": "Stumm",
  "archive": "Archivieren",
  "delete": "Löschen",
  "unarchive": "Archivierung aufheben",
  "unmute": "Stummschalten",
  "archiveErrorMsg": "Beim Senden der Archivierungsanfrage ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "unArchiveErrorMsg": "Beim Senden der Anfrage zum Aufheben der Archivierung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.",
  "userDeletedMessage": "Sie haben diese Nachricht gelöscht.",
  "deletedMessage": "Diese Nachricht wurde gelöscht.",
  "deletedMessageThread": "Nachricht wurde gelöscht."
};