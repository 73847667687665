import React, { FunctionComponent, useState, useEffect } from 'react';
import classnames from 'classnames';
import { Notification } from '../../../../types/types';

type NotificationItemProps = {
    notification: Notification,
    removeNotification: Function
}

const NotificationItem: FunctionComponent<NotificationItemProps> = ({notification, removeNotification}) => {
    const [isOpened, setOpened] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOpened(true);

            setTimeout(() => {
                setOpened(false);
                setTimeout(() => {
                    removeNotification();
                }, 1000);
            }, 5000);
        }, 100);
    }, []);

    const getBgColorClass = () => {
        switch(notification.type) {
            case 'success':
                return 'bg-success';
            default:
                return 'bg-danger';
        }
    } 

    const translateStr = isOpened ? '0%' : '100%';
    const bgColorClass = getBgColorClass();
    return (
        <div data-testid="notification-item" className={classnames("notification text-white p-2 mt-1", bgColorClass, { "closed": !isOpened})}
            style={{ transform: `translateX(${ translateStr })`, transition: '1s' }}
        >
            <div data-testid="notification-label" className="notification-title font-weight-bold">
                { notification.label }
            </div>
            <div data-testid="notification-message" className="notification-message">
                <small>{ notification.text }</small>
            </div>
        </div>
    );
}

export default NotificationItem;