import React from 'react';
import { Avatar, Badge, BadgeProps, BoxProps, Box, SxProps } from '@mui/material';

export type IconCardProps = {
    title?: string | React.ReactNode | undefined;
    icon: string | React.ReactNode;
    sx?: SxProps;
    handleClick?: (e: any) => void;
    children?: React.ReactNode;
    className?: string;
    titleClassName?: string;
    iconClassName?: string;
    titleStyle?: SxProps;
    iconStyle?: SxProps;
    iconBadge?: boolean;
    iconBadgeCount?: number;
    iconBadgeProps?: BadgeProps;
    containerProps?: BoxProps;
};

export const IconCard = (props: IconCardProps) => {
    const {
        title,
        icon,
        sx,
        handleClick,
        children,
        className,
        titleClassName,
        titleStyle,
        iconStyle,
        iconClassName,
        iconBadge,
        iconBadgeProps,
        iconBadgeCount,
        containerProps,
    } = props;

    const renderIcon = () => {
        if (typeof icon === 'string') {
            const avatarElement = (
                <Avatar
                    sx={{
                        width: '45px',
                        height: '45px',
                        mr: '12px',
                        ...iconStyle,
                    }}
                    src={icon}
                    className={iconClassName}
                    alt={title ? `image for ${title}` : 'avatar-img'}
                />
            );

            return iconBadge && iconBadgeCount ? (
                <Badge {...iconBadgeProps} data-testid='icon-card-badge'>
                    {avatarElement}
                </Badge>
            ) : (
                avatarElement
            );
        }

        return iconBadge && iconBadgeCount ? (
            <Badge {...iconBadgeProps} data-testid='icon-card-badge'>
                {icon}
            </Badge>
        ) : (
            icon
        );
    };

    return (
        <Box
            sx={{ padding: '10px 0', ...sx }}
            className={className ? className : 'c-pointer d-flex align-items-center'}
            onClick={handleClick}
            data-testid='icon-card'
            {...containerProps}
        >
            <Box data-testid='icon-card-avatar'>{renderIcon()}</Box>
            <Box sx={titleStyle} data-testid='icon-card-title'>
                {title && <span className={titleClassName}>{title}</span>}
                {children}
            </Box>
        </Box>
    );
};
