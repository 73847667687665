import Client from '../services/axiosService';
import { AxiosResponse, AxiosError } from 'axios';

const brandingProvider = {
    getStyles(success: Function, error?: Function){
        Client().request({
            url: process.env.REACT_APP_CMS_API_URL+'/react/branding',
            method: 'get'
        })
            .then((resp: AxiosResponse) => {
                success(resp);
            }).catch((err: AxiosError) => {
                if(error){
                    error(err);
                }
            });
    }
}

export default brandingProvider;