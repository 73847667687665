import React, { FunctionComponent, ReactNode, useState } from 'react';
import classnames from 'classnames';

import SessionTimeout from '../common/sessionTimeout/sessionTimeout';
import NotificationPermissionModal from '../common/notificationPermissionModal/notificationPermissionModal';
import TopNavComponent from './topNavComponent/topNavComponent';
import SideNavComponent from './sideNavComponent/sideNavComponent';

import './pageFrame.scss';
import { SideNavItem } from '../../../types/types';

type PageFrameProps = {
    className?: string,
    children?: ReactNode,
    setPermission: Function
}

const PageFrame: FunctionComponent<PageFrameProps> = ({ className, children, setPermission }) => {
    const [sideNavDetail, setSideNavDetail] = useState<{ menuItems: SideNavItem[]; unreadThreads: number }>({
        menuItems: [],
        unreadThreads: 0
    })

    return (
        <React.Fragment>
            <NotificationPermissionModal setPermission={setPermission} />
            <SessionTimeout />
            <TopNavComponent
                menuItems={sideNavDetail.menuItems}
                unreadThreads={sideNavDetail.unreadThreads}
            />
            <SideNavComponent
                setSideNavDetail={setSideNavDetail}
            />

            <section id={"page-frame-contents"} className={classnames(className, 'position-absolute w-100 h-100')}>
                {children}
            </section>
        </React.Fragment>
    );
}

export default PageFrame;