import { useMutation } from 'react-query';
import Client from '../services/axiosService';

/**
 * @description: Makes a Post request to the API to get a filtered list of people
 * @param page: The page number
 * @param data: The data to filter the list by
 * @returns: The response from the API
 */
export const getFilteredContactData = async ({ page, data, is_favorite }: { page: number; data: any; is_favorite?: boolean }) => {
    let url = is_favorite
        ? `${process.env.REACT_APP_CMS_API_URL}/react/connect/favorites?page=${page}`
        : `${process.env.REACT_APP_CMS_API_URL}/react/connect?page=${page}`;

    return await Client().request({
        url,
        method: 'post',
        data: data,
    });
};

/**
 * @description: A hook to get a filtered list of people
 * @param onSuccess: A callback function to run on success
 * @param onError: A callback function to run on error
 * @returns: The response from the API
 */
export const usePeopleFilteredList = (onSuccess?: (data: any) => void, onError?: () => void) => {
    return useMutation(getFilteredContactData, {
        onSuccess: onSuccess,
        onError: onError,
    });
};
