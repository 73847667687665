import { useQuery } from 'react-query';
import Client from '../../services/axiosService';

/**
 * @description: Fetches individual group item and returns the data as a promise
 * @param id | string
 * @returns
 */
const fetchGroupItem = async (id: string) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/community-groups/${id}`,
        method: 'get',
    });

    return response.data;
};

/**
 * @description: Fetches group item results from the API and returns the data in the form of query
 * @param debouncedSearchQuery
 * @returns
 */
export const useGroupItemQuery = (id: string) => {
    return useQuery(['individual-group-item', id], () => fetchGroupItem(id), {
        refetchOnWindowFocus: false,
        enabled: !!id,
    });
};
